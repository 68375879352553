import React from 'react';

export const family = (
  <svg id="family_layer_1" data-name="Layer 1" viewBox="0 0 95 95">
    <path className="cls-1" d="M95,47.5A47.5,47.5,0,1,1,47.5,0,47.5,47.5,0,0,1,95,47.5" />
    <circle style={{ fill: '#fff' }} cx="37.18" cy="18.07" r="7.19" />
    <circle style={{ fill: '#fff' }} cx="62.47" cy="21.32" r="6.53" />
    <path
      style={{ fill: '#fff' }}
      d="M50.7,80.07V69H48.86V80.07a2.19,2.19,0,0,1-.76,1.66h3.36A2.19,2.19,0,0,1,50.7,80.07Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M76.82,51.49C76,47.67,74,40.77,73.42,38.32a12.51,12.51,0,0,0-1.86-4.43,8.51,8.51,0,0,0-3.91-3.16,7.73,7.73,0,0,0-.89-0.28,4.76,4.76,0,0,0-1.48-.26H59.65a4.83,4.83,0,0,0-1.54.27,8.08,8.08,0,0,0-2.12.91,8.8,8.8,0,0,0-2.62,2.52,12.48,12.48,0,0,0-1.86,4.43l-0.06.24c-0.13-.73-0.27-1.43-0.43-2.07a11.5,11.5,0,0,0-2.58-5,10.73,10.73,0,0,0-2.7-2.16A12.13,12.13,0,0,0,42.06,28l-0.7-.11a5.27,5.27,0,0,0-1.25-.15H34.25a5.38,5.38,0,0,0-1.26.16,12.42,12.42,0,0,0-3.46,1,10.88,10.88,0,0,0-3.61,2.62,11.46,11.46,0,0,0-2.58,5,45.32,45.32,0,0,0-.84,4.61c-0.67,4.94-1,10.43-1,10.47a2.65,2.65,0,1,0,5.3.27s0-.14,0-0.41c0.05-.94.21-3.38,0.48-6.09,0.14-1.35.3-2.77,0.49-4.1a17.16,17.16,0,0,1,.76-3.54A1.81,1.81,0,0,1,30,36.36L29,50.79a5.41,5.41,0,0,0,0,.65c0,0.09,0,.17,0,0.26V78.35a3.38,3.38,0,1,0,6.76,0V56h2.81V78.35A3.38,3.38,0,0,0,42,81.72h3.24a2.19,2.19,0,0,1-.76-1.66V67.83c0-.06,0-0.11,0-0.17a3.53,3.53,0,0,1,0-.43L45,57.06a2.71,2.71,0,0,0-.8,1.51L43.42,66.5a1.79,1.79,0,0,1-1.78,1.61H41.45a1.79,1.79,0,0,1-1.6-2l0.83-8s0-.09,0-0.14A5.69,5.69,0,0,1,46.13,53h7.29A5.69,5.69,0,0,1,58.86,58s0,0.09,0,.14l0.83,8a1.79,1.79,0,0,1-1.6,2H57.92a1.79,1.79,0,0,1-1.78-1.61l-0.82-7.93a2.71,2.71,0,0,0-.8-1.51l0.61,10.17a3.67,3.67,0,0,1,0,.43c0,0.06,0,.11,0,0.17V80.07a2.19,2.19,0,0,1-.76,1.66h4.21A2.42,2.42,0,0,0,61,79.31V61.54h3V79.31a2.41,2.41,0,1,0,4.82,0V61.54H68.9a3.43,3.43,0,0,1,.53,0h2.85C71.43,50,66.54,44,67.5,39.88A16.33,16.33,0,0,1,68.4,37a9.26,9.26,0,0,1,.78,2.22c0.53,2.38,2.56,9.26,3.39,13.12A2.17,2.17,0,0,0,76.82,51.49Zm-27-.55a4.7,4.7,0,1,1,4.7-4.7A4.71,4.71,0,0,1,49.78,50.94Z"
    />
  </svg>
);

export default family;
