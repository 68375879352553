import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Box, Button } from '../../../../../../common/components';
// import SchemeImportModal from '../SchemeImportModal/SchemeImportModal'
import './ProductListItem.scss';

class ProductListItem extends Component {
  render() {
    const { product } = this.props;

    return (
      <Box className="hoverable-section">
        <Row>
          <Col sm={12}>
            <h2 className="title-with-icon">
              <img src={product.attributes.icon} alt={product.attributes.name} />
              {product.attributes.name}
            </h2>
          </Col>
        </Row>
        {/*<div className="divider" />*/}
        <div className="section-item">
          <Row>
            <Col sm={12}>
              <Button
                className="btn-blue pad-right"
                link
                to={`/admin/products/${product.id}/scheme-management`}
              >
                Scheme Management
              </Button>
              {/*<Button*/}
              {/*className="btn-blue pad-right"*/}
              {/*link*/}
              {/*to={`/admin/products/${product.id}/definitions`}*/}
              {/*>*/}
              {/*Definitions*/}
              {/*</Button>*/}
              {/*<Button*/}
              {/*className="btn-blue pad-right"*/}
              {/*link*/}
              {/*to={`/admin/products/${product.id}/declaration`}*/}
              {/*>*/}
              {/*Declaration*/}
              {/*</Button>*/}
              <Button
                className="btn-blue pad-right"
                link
                to={`/admin/products/${product.id}/documents`}
              >
                Static Documents
              </Button>
              <Button
                className="btn-blue pad-right"
                link
                to={`/admin/products/${product.id}/dynamic-documents`}
              >
                Dynamic Documents
              </Button>
              <Button
                className="btn-blue pad-right"
                link
                to={`/admin/products/${product.id}/document-pack`}
              >
                Document Pack
              </Button>
              <Button
                className="btn-blue pad-right"
                link
                to={`/admin/products/${product.id}/brochure-content`}
              >
                Brochure Content
              </Button>
              <Button
                className="btn-blue pad-right"
                link
                to={`/admin/products/${product.id}/covid-message-content`}
              >
                Covid Message Content
              </Button>
              {/*<Button*/}
              {/*className="btn-blue pad-right"*/}
              {/*link*/}
              {/*to={`/admin/products/${product.id}/admin-fees`}*/}
              {/*>*/}
              {/*Admin Fees*/}
              {/*</Button>*/}
              {/*<SchemeImportModal />*/}
            </Col>
          </Row>
        </div>
      </Box>
    );
  }
}

export default ProductListItem;
