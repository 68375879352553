import React from 'react';

const challenge = (
  <svg
    id="cha"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 150.49 150.49"
  >
    <circle cx="75.25" cy="75.25" r="75.25" />
    <path
      style={{ fill: '#fff' }}
      d="M64,49.49c-1.58.76-3.26,3.31-4.75,8.6-1,3.5-.39,6.84,1.24,7.88a1.58,1.58,0,0,0,.68.22c.21,0,3-.05,5.29-4.51.15-.3,1.78-3.75,2-4.26,0,0-1-4-1.16-4.44C67,52.21,65.57,48.71,64,49.49Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M123.16,42.13h-18.7V35.66A10.59,10.59,0,0,0,94,25.13H56.5c-5.73,0-10,4.8-10,10.53v6.47H27.33a5.75,5.75,0,0,0-5.86,6V56.5c0,12.37,14.78,26,35.09,27a35.58,35.58,0,0,0,6.09,6.18C66.1,92.83,67,96.08,67,100.25s-1.91,8.33-8.16,8.33S46.47,112.75,46.47,119v4.17c0,1.17.52,2,1.7,2h54.17a2,2,0,0,0,2.14-2V119c0-6.25-6.28-10.42-12.53-10.42s-8.35-4.17-8.35-8.33.9-7.42,4.36-10.55a35,35,0,0,0,6.21-6.18c20.31-1,35.31-14.65,35.31-27V48.16A6.11,6.11,0,0,0,123.16,42.13ZM29.47,56.5V50.13H46.08a58.64,58.64,0,0,0,4.84,24.21C38.1,71.67,29.47,62.88,29.47,56.5Zm44.4,11.72c-2.86-1.1-3.36-4.9-3.36-4.9S66.7,69.17,60.45,69c-.38,0-4.83.12-6.39-5.11-2.45-8.21,5.74-17.6,11-17.35,5,.23,6.07,5.42,6.12,5.49l.05-.16a6.94,6.94,0,0,1,6.22-5l.58,0h1.6c-.42,0-1.86.81-2.82,2.89-1.48,3.2-3.71,7.9-3.71,7.9a3.18,3.18,0,0,1,.13.41c.14.49,1.45,4.46,2,6A3,3,0,0,0,78.08,66l2,0A4.77,4.77,0,0,1,73.86,68.21ZM90,59.57a7.84,7.84,0,0,1-7.54,5l-3.93,0a2.27,2.27,0,0,1-2.15-1.6c-.57-1.7-.36-1-.61-1.81-.18-.56,1-1.5,2.3-1.53,2,0,3.72,0,5.67-.06,3.43,0,5.75-.71,7.28-2.63Zm3-6.23a7.84,7.84,0,0,1-7.54,5c-2.52,0-3.93.14-6,0-3.18-.15-3.91,2-3.91,2A8.11,8.11,0,0,1,76,56.33a5.05,5.05,0,0,1,4-2.84l6.74-.08c3.43,0,5.75-.71,7.28-2.63Zm2.9-6.17a7.84,7.84,0,0,1-7.54,5L81,52.29A5.86,5.86,0,0,0,77,54.16l1.88-4a5.28,5.28,0,0,1,4-2.84l6.74-.08c3.43,0,5.75-.71,7.28-2.63Zm25.61,9.32c0,6.38-8.85,15.18-21.68,17.85,2.73-6,4.63-14.21,4.63-24.21h17.05Z"
    />
  </svg>
);

export default challenge;
