import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Button } from '../../../../common/components';
import { findCondition, startDeclaration } from '../../redux/screeningActions';
import { debounce } from 'lodash';
import { Alert } from 'react-bootstrap';
import './styles.scss';
import selectTheme, { styles } from '../../../../helpers/selectTheme';

export default class ConditionSearch extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    screening: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      condition: '',
      displayMinimum: false,
    };

    this.search = debounce(this.search, 200);
    this.changeState = this.changeState.bind(this);
  }

  // Keep parent and child states in sync
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.conditionProps !== prevState.condition) {
      this.props.conditionChange(prevState.condition);
    }
  }

  changeState(condition) {
    this.setState({ condition }, this.props.conditionChange(condition));
  }

  search(query) {
    if (query && query.length > 0 && query.length < 3) {
      this.setState({
        displayMinimum: true,
      });
    } else {
      this.setState({
        displayMinimum: false,
      });
    }
    this.props.dispatch(findCondition(query));
  }

  render() {
    const { index, screening, dispatch } = this.props;
    const { condition } = this.state;

    let isSurgical = false;

    screening.conditions.map(function(condition) {
      if (condition.label === 'SURPROC') {
        isSurgical = true;
      }
    });

    return (
      <div className="search-conditions form-horizontal">
        <div className="form-group">
          {isSurgical ? (
            <div className="col-xs-12">
              <Alert bsStyle="danger">
                You have searched for a procedure or a surgery, please enter the name for
                the medical condition.
              </Alert>
            </div>
          ) : null}
          {screening.hasMisspelledConditions ? (
            <div className="col-xs-12">
              <Alert bsStyle="danger">
                No medical condition was found matching your search. However, did you
                mean:
              </Alert>
            </div>
          ) : null}
          {this.state.displayMinimum && (
            <div>
              <Alert bsStyle="danger">
                A minimum of three letters are required for the search.
              </Alert>
            </div>
          )}
          <label className="control-label col-sm-4 col-md-3">Search conditions</label>
          <div className="col-sm-8 col-md-7 search-conditions-select">
            <Select
              name={'conditions-search-' + index}
              theme={(theme) => selectTheme(theme)}
              styles={styles}
              cache="false"
              value={condition}
              isLoading={screening.isSearching}
              options={isSurgical ? [] : screening.conditions}
              placeholder="Enter Condition"
              filterOptions={false}
              onInputChange={(search) => this.search(search)}
              onChange={(condition) => this.changeState(condition)}
            />
          </div>
          <div className="col-sm-12 col-md-2">
            <br className="visible-xs visible-sm" />
            {condition && (
              <Button
                bsStyle="primary"
                id="screen"
                isLoading={screening.isSubmitting}
                label="Screen"
                handleClick={() => dispatch(startDeclaration(condition))}
                block
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
