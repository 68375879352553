import React from 'react';

export const breakdown = (
  <svg
    id="breakdown_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 95 95"
  >
    <circle className="cls-1" cx="47.5" cy="47.5" r="47.5" />
    <path
      style={{ fill: '#fff' }}
      d="M25.47,55a8.43,8.43,0,1,0,8.43,8.43A8.43,8.43,0,0,0,25.47,55Zm0,12a3.61,3.61,0,1,1,3.61-3.61A3.62,3.62,0,0,1,25.47,67Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M71.25,55a8.43,8.43,0,1,0,8.43,8.43A8.43,8.43,0,0,0,71.25,55Zm0,12a3.61,3.61,0,1,1,3.61-3.61A3.62,3.62,0,0,1,71.25,67Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M83.48,44l-8.62-3.45L66,28.14a10.68,10.68,0,0,0-8.69-4.47H46V37.83l-30.35-15a1,1,0,0,0-1.13,1.52L16,25.84V34h.64V26.53L34.11,45.4H9.85c0,.39,0,.77,0,1.16V61a3.62,3.62,0,0,0,3.61,3.61h1.33a10.67,10.67,0,0,1-.12-1.2,10.84,10.84,0,1,1,21.68,0,10.67,10.67,0,0,1-.12,1.2H60.53a10.67,10.67,0,0,1-.12-1.2,10.84,10.84,0,1,1,21.68,0,10.67,10.67,0,0,1-.12,1.2H83.3A3.62,3.62,0,0,0,86.91,61V49A5.45,5.45,0,0,0,83.48,44Zm-64-17.13,14.82,7.34h-8Zm16.11,8,.66.33v9.76L26.92,34.83Zm1.3.64L46,40V45.4H36.93ZM62.45,50.28a3.32,3.32,0,0,1-3.7.22l-9.36,7.14h0l-.23.18a3.32,3.32,0,0,1-4.58,3.9l.52-.4,2-1.55L46.93,58l-1.69-.67-2,1.55-.52.4a3.32,3.32,0,0,1,5-3.4l9.59-7.32a3.32,3.32,0,0,1,4.58-3.9l-.52.4-2,1.55.2,1.81,1.69.67,2-1.55.52-.4A3.32,3.32,0,0,1,62.45,50.28ZM50.77,40.53v-12h5.84a8.25,8.25,0,0,1,6.35,3l5.55,9.07Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M15.25,37.17l.24.07c1.74.68,2.74,2.33,2.21,3.67a2.77,2.77,0,0,1-4.11,1.21c-1.45-.92-.46-3.11-1.41-2.36l-.2.34c-.47,1.78.54,4.11,4.31,4.11A4.6,4.6,0,0,0,21,40.1c.2-2.32-1.72-4.29-3-4.29-.9,0-.69-1.73-.69-1.73a9.48,9.48,0,0,0-1-.1,5.15,5.15,0,0,0-1,.1Z"
    />
  </svg>
);

export default breakdown;
