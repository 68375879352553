import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InfoLabel, Box, Button, Icon } from '../../../../common/components';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import {
  deactivateBroker,
  reactivateBroker,
  viewStatement,
} from '../../redux/brokerActions';
import TransferBrokerButton from '../TransferBrokerButton';
import { brokerStatuses } from '../../../../schemas/broker';
import displayName from '../../../../helpers/displayName';
import displayAddress from '../../../../helpers/displayAddress';
import { Row, Col, Panel } from 'react-bootstrap';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import { Link } from 'react-router';
import { get } from 'lodash';
import './styles.scss';

export default class BrokerDetails extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    broker: PropTypes.object.isRequired,
    resource: PropTypes.object.isRequired,
    brokerLabel: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
    this.openClose = this.openClose.bind(this);
  }

  openClose() {
    this.setState((oldState) => ({
      open: !oldState.open,
    }));
  }

  deactivateBroker() {
    this.props.dispatch(deactivateBroker(this.props.resource.data.id));
  }

  reactivateBroker() {
    this.props.dispatch(reactivateBroker(this.props.resource.data.id));
  }

  viewStatement() {
    this.props.dispatch(viewStatement(this.props.resource.data.id));
  }

  buttons() {
    const resource = this.props.resource.data.attributes;
    const brokerType = get(resource, 'broker_type', 'broker');
    const brokerId = this.props.resource.data.id;
    const organisation = getIncludedResource(
      this.props.resource.data,
      this.props.resource.included,
      'organisation',
    );
    const account = getIncludedResource(
      organisation.data,
      this.props.resource.included,
      'account',
    );

    const actionButtons = [
      {
        types: ['broker', 'tracking', 'introducer'],
        permissions: ['broker.edit'],
        button: (
          <Button
            label={brokerType !== 'tracking' ? 'Edit Broker' : 'Edit Tracking Link'}
            link
            to={'/brokers/' + brokerId + '/edit'}
            className="pull-right"
            bsStyle="primary"
            rightIcon="pencil-square-o"
          />
        ),
      },
      {
        types: ['broker', 'introducer'],
        permissions: ['account.view'],
        button: (
          <Button
            className="pull-right"
            bsStyle="primary"
            rightIcon="list"
            // isLoading={this.props.broker.isGeneratingStatement}
            handleClick={::this.viewStatement}
            label="View Statement"
          />
        ),
      },
      {
        types: ['broker', 'introducer'],
        permissions: ['account.view'],
        button: (
          <Button
            className="pull-right"
            bsStyle="primary"
            link
            to={'/accounts/ledger/' + account.data.id}
            label="View Account"
          />
        ),
      },
      {
        types: ['broker', 'introducer'],
        permissions: ['broker.edit'],
        button: (
          <TransferBrokerButton
            className="pull-right"
            label="Merge Agencies"
            resource={this.props.resource}
            resourceType={'brokers'}
          />
        ),
      },
      {
        types: ['broker', 'introducer'],
        statuses: ['active'],
        permissions: ['broker.deactivate'],
        button: (
          <Button
            className="pull-right"
            label="Deactivate"
            bsStyle="primary"
            rightIcon="ban"
            isLoading={this.props.broker.isDeactivating}
            handleClick={::this.deactivateBroker}
          />
        ),
      },
      {
        types: ['broker', 'introducer'],
        statuses: ['inactive'],
        permissions: ['broker.deactivate'],
        button: (
          <Button
            className="pull-right"
            label="Reactivate"
            bsStyle="primary"
            rightIcon="check"
            isLoading={this.props.broker.isReactivating}
            handleClick={::this.reactivateBroker}
          />
        ),
      },
      {
        types: ['broker', 'introducer'],
        permissions: ['organisation.view'],
        button: (
          <Button
            className="pull-right"
            label="View organisation"
            bsStyle="primary"
            link
            to={'/admin/organisations/' + organisation.data.id}
          />
        ),
      },
    ];

    return actionButtons.filter((button) => {
      if (button.types.indexOf(brokerType) === -1) {
        return false;
      }

      return !(button.statuses && button.statuses.indexOf(resource.status) === -1);
    });
  }

  render() {
    const resource = this.props.resource.data.attributes;
    const organisation = getIncludedResource(
      this.props.resource.data,
      this.props.resource.included,
      'organisation',
    );
    const region = getIncludedResource(
      this.props.resource.data,
      this.props.resource.included,
      'broker_region',
    );
    const networks = getIncludedResource(
      this.props.resource.data,
      this.props.resource.included,
      'networks',
    );
    const users = getIncludedResource(
      this.props.resource.data,
      this.props.resource.included,
      'users',
    );
    const primaryContacts = getIncludedResource(
      this.props.resource.data,
      this.props.resource.included,
      'primary_contacts',
    );
    const accountsContacts = getIncludedResource(
      this.props.resource.data,
      this.props.resource.included,
      'accounts_contacts',
    );
    // const commissions = getIncludedResource(this.props.resource.data, this.props.resource.included, 'active_commissions')
    const address = displayAddress(this.props.resource.data.attributes.address);
    const trackingLinks = get(
      this.props.resource.data,
      'relationships.tracking_links.meta',
      [],
    );

    return (
      <Box>
        <h2 className="resource-name">
          {resource.name} {this.props.brokerLabel}
        </h2>

        <Row>
          <Col sm={6}>
            <InfoLabel
              label="Organisation"
              value={organisation.data.attributes.name}
              labelSize={4}
            />
            <InfoLabel label="Trading Name" value={resource.trading_name} labelSize={4} />
            <InfoLabel label="Branch Name" value={resource.branch_name} labelSize={4} />
            <InfoLabel
              label="Region"
              value={region.data.attributes ? region.data.attributes.name : ''}
              labelSize={4}
            />
          </Col>

          <Col sm={6}>
            <InfoLabel label="Broker Type" value={resource.broker_type} labelSize={4} />
            <InfoLabel label="Reference" value={resource.reference} labelSize={4} />
            <InfoLabel
              label="Hold Client Money"
              value={get(resource, 'metadata.hold_client_money', '')}
              labelSize={4}
            />
            {/*<InfoLabel label="FCA Number"*/}
            {/*value={organisation.data.attributes.regulatory_authority_number}*/}
            {/*labelSize={4}/>*/}
            <InfoLabel label="Address" value={address} labelSize={4} />
            <InfoLabel label="Phone Number" value={resource.phone_number} labelSize={4} />
            <InfoLabel label="Display Number" value={resource.metadata.display_phone_number} labelSize={4} />
            <InfoLabel label="Own Tracking Url" labelSize={4} value={resource.url} />
          </Col>
        </Row>

        <div className="hr" />

        <Row>
          <Col sm={12}>
            <Panel className="panel-tracking-links">
              <Panel.Heading>
                <Panel.Toggle componentClass="div" onClick={this.openClose}>
                  <label>Tracking Links</label>
                  <div className="pull-right">
                    <Icon name={!this.state.open ? 'minus' : 'plus'} />
                  </div>
                </Panel.Toggle>
              </Panel.Heading>
              <Panel.Collapse>
                <Panel.Body>
                  {trackingLinks.map((link, key) => {
                    return (
                      <Row key={key}>
                        <Col sm={3}>
                          <label>{link.channel_name}</label>
                        </Col>
                        <Col sm={9}>{link.url}</Col>
                      </Row>
                    );
                  })}
                </Panel.Body>
              </Panel.Collapse>
            </Panel>
          </Col>
        </Row>

        <div className="hr" />

        <Row className="row">
          <Col sm={6}>
            {this.props.resource.data.relationships.networks.data && (
              <InfoLabel
                label="Networks"
                value={networks.map((network, i) => {
                  let detail = i ? ', ' : '';
                  return detail + network.attributes.name;
                })}
                labelSize={4}
              />
            )}
            <InfoLabel
              label="Network Reference"
              value={get(resource, 'metadata.network_reference', '')}
              labelSize={4}
            />
            <InfoLabel
              label="Other Networks"
              value={get(resource, 'metadata.other_networks')}
              labelSize={4}
            />
          </Col>

          <Col sm={6}>
            <InfoLabel
              label="Status"
              value={displayName(brokerStatuses[get(resource, 'status', 'applied')])}
              labelSize={4}
            />
            <InfoLabel
              label="Created At"
              date
              value={resource.created_at}
              labelSize={4}
            />
            <InfoLabel
              label="Updated At"
              date
              value={resource.updated_at}
              labelSize={4}
            />
            {/*<InfoLabel label="Last Meeting" date value={ get(resource, 'metadata.last_meeting', '') } labelSize={4}/>*/}
          </Col>
        </Row>

        <div className="hr" />

        <Row>
          <Col sm={12}>
            <InfoLabel
              label="Users"
              labelSize={1}
              value={users.map((user) => {
                return (
                  <div className="user-item" key={user.id}>
                    <Link to={'/admin/users/' + user.id}>
                      {user.attributes.full_name}
                    </Link>
                  </div>
                );
              })}
            />
          </Col>
        </Row>

        <div className="hr" />

        <Row>
          <Col sm={6}>
            <InfoLabel
              label="Primary Contacts"
              labelSize={2}
              value={primaryContacts.map((contact) => {
                return (
                  <div className="user-item">
                    <Link to={'/admin/users/' + contact.id}>
                      {contact.attributes.full_name}
                    </Link>
                  </div>
                );
              })}
            />
          </Col>
          <Col sm={6}>
            <InfoLabel
              label="Accounts Contacts"
              labelSize={2}
              value={accountsContacts.map((contact) => {
                return (
                  <div className="user-item">
                    <Link to={'/admin/users/' + contact.id}>
                      {contact.attributes.full_name}
                    </Link>
                  </div>
                );
              })}
            />
          </Col>
        </Row>

        <div className="hr" />

        <Row>
          <Col sm={12}>
            <InternalButtonBar buttons={this.buttons()} />
          </Col>
        </Row>
      </Box>
    );
  }
}
