import React from 'react';
import {
  DataTable,
  CreateNoteButton,
  NotesList,
} from '../../../../common/components';
import { refreshData } from '../../../../common/components/DataTable/redux/dataTableActions';
import { FormattedDate } from 'react-intl';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import ProductContainer from '../ProductContainer/ProductContainer';

class NotesForm extends React.Component {
  formatDate(cell) {
    return <FormattedDate value={new Date(cell)} />;
  }

  handleRowClick() {
    //this.props.dispatch(push('/admin/users/' + row.id));
    return false;
  }

  refreshList() {
    this.props.dispatch(refreshData('notes'));
  }

  render() {
    const { scheme } = this.props;
    return (
      <ProductContainer>
        <h2>Notes</h2>
        <div>
          <DataTable
            source={'/notes'}
            autoFilter={scheme.id}
            autoFilterType="filter[resource]"
            name="notes"
            showTotal
            onRowSelect={this.handleRowClick.bind(this)}
            component={NotesList}
          />
          <Row>
            <Col sm={12}>
              <InternalButtonBar
                buttons={[
                  {
                    permissions: ['scheme.edit'],
                    button: (
                      <CreateNoteButton
                        label="Add a Note"
                        className="pull-right"
                        notableResourceType={'scheme-groups'}
                        notableResource={null}
                        callback={::this.refreshList}
                      />
                    ),
                  },
                ]}
              />
            </Col>
          </Row>
        </div>
      </ProductContainer>
    );
  }
}

export default connect()(NotesForm);
