import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Label, Panel, Row, Col } from 'react-bootstrap';
import { startCase, get, isEmpty } from 'lodash';
import moment from 'moment/moment';
import {
  sendCustomerLoginEmail,
  sendMedicalScreeningEmail,
  sendEndorsementEmail,
  createAccount,
  emailDocuments,
  getQuotesHistory,
  clearQuoteHistory,
  uncancelPolicy,
  downloadCSPLiveMembershipData,
  updateRenewalStatus,
} from '../../redux/policyActions';
import { Box, Button } from '../../../../common/components';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import { isOwner, hasPermission } from '../../../auth/redux/authActions';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import displayAddress from '../../../../helpers/displayAddress';
import Endorsements from '../Endorsements';
import TransferPolicyButton from '../TransferPolicyButton';
import CancelButton from '../CancelButton';
import LapseButton from '../LapseButton';
import Summary from './components/Summary';
import Travellers from './components/Travellers';
import CustomerDetails from './components/CustomerDetails';
import History from './components/History';
import Icon from '../../../../common/components/Icon/Icon';
import { connect } from 'react-redux';
import './styles.scss';
import { isBroker } from '../../../auth/redux/authActions';
import confirm from 'react-confirm2';
import ExpiredMidTermAdjustmentButton from '../ExpiredMidTermAdjustmentButton/ExpiredMidTermAdjustmentButton';
import PremiumRow from '../PremiumRow/PremiumRow';
import NonAutoRenewalButton from '../NonAutoRenewalButton/NonAutoRenewalButton';
// import PreventRenewalButton from '../PreventRenewalButton/PreventRenewalButton';
// import ReferRenewalButton from '../ReferRenewalButton/ReferRenewalButton';
// import NonAutoRenewalButton from '../ManualRenewalButton/ManualRenewalButton';


export class PolicyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      disableEditQuote: false,
      data: [],
    };
    this.openClose = this.openClose.bind(this);
    this.handleCustomerLogin = this.handleCustomerLogin.bind(this);
    this.handleMedicalScreeningEmail = this.handleMedicalScreeningEmail.bind(this);
    this.handlePolicyQuoteEmail = this.handlePolicyQuoteEmail.bind(this);
    this.handleUncancel = this.handleUncancel.bind(this);
    this.handleEndorsementEmail = this.handleEndorsementEmail.bind(this);
    this.handleCreateUser = this.handleCreateUser.bind(this);
    this.getQuotesHistory = this.getQuotesHistory.bind(this);
    this.handleCSPDownloadLiveMembershipData = this.handleCSPDownloadLiveMembershipData.bind(this);
    this.handleAutoRenewal = this.handleAutoRenewal.bind(this);
  }

  componentDidMount() {
    const { resource, dispatch } = this.props;
    dispatch(clearQuoteHistory());
    if (get(resource, 'data.attributes.status') === 'quote') {
      this.getQuotesHistory();
    }
  }

  openClose() {
    this.setState((oldState) => ({
      open: !oldState.open,
    }));
  }

  handleCreateUser(customerId) {
    const { dispatch } = this.props;
    dispatch(createAccount(customerId));
  }

  getLabelStyle(status, policy) {
    const resource = this.props.resource.data.attributes;

    if (resource.quote_type === 'mid term adjustment' && status === 'quote') {
      return 'warning';
    }

    if (
      policy &&
      (status === 'quote' ||
        status === 'referral approved' ||
        status === 'on cover' ||
        status === 'active' ||
        status === 'issued' ||
        status === 'on cover')
    ) {
      return 'success';
    }

    if (status === 'declined' || status === 'cancelled') {
      return 'danger';
    }

    if (status === 'referred') {
      return 'warning';
    }

    return 'default';
  }

  getQuoteDate(premiums) {
    let quoteDate = '';
    Array.isArray(premiums) &&
    premiums.map((premium) => {
      if (premium.attributes.premium_type === 'initial premium') {
        quoteDate = premium.attributes.created_at;
      }
    });
    return quoteDate;
  }

  handleCSPDownloadLiveMembershipData() {
    const { resource, dispatch } = this.props;
    dispatch(
      downloadCSPLiveMembershipData(
        resource.data.id
      )
    );
  }

  handleAutoRenewal() {
    const { resource, dispatch } = this.props;
    dispatch(updateRenewalStatus(resource, {}, 'auto'));
  }

  handleCustomerLogin() {
    const { resource, dispatch } = this.props;
    dispatch(
      sendCustomerLoginEmail(
        resource.data.attributes.policy_number ? 'policies' : 'policies/applications',
        resource.data.id,
      ),
    );
  }

  handleMedicalScreeningEmail() {
    const { resource, dispatch } = this.props;
    dispatch(
      sendMedicalScreeningEmail(
        resource.data.attributes.policy_number ? 'policies' : 'policies/applications',
        resource.data.id,
      ),
    );
  }

  handlePolicyQuoteEmail() {
    const { resource, dispatch } = this.props;
    const customer = getIncludedResource(resource.data, resource.included, 'customer');
    const values = {
      meta: {
        email: customer.data.attributes.email,
        recipient_name: customer.data.attributes.customer_name,
      },
    };
    const resourceType = resource.data.attributes.policy_number
      ? 'customers/policies'
      : 'customers/policy-applications';

    confirm('Quote/Policy Email', {
      confirmLabel: 'Continue',
      description: (
        <div>
          <p>
            Respecting the security of Personal and Sensitive Data, documents will not be
            emailed from the system.
            <br/>
          </p>
          <p>
            If you have saved your clients email address at the quote stage, they will now
            receive a link to access our Customer Portal where they can securely retrieve
            their documents.
            <br/>
          </p>
          <p>
            If you would like access to these documents, please refer to the Documents tab
            at the top of this page where you are able to view and download the documents
            as required.
          </p>
        </div>
      ),
      done: () => dispatch(emailDocuments(values, resource, resourceType)),
    });
  }

  handleUncancel() {
    const { resource, dispatch } = this.props;

    dispatch(uncancelPolicy(resource));
  }

  handleEndorsementEmail() {
    const { resource, dispatch } = this.props;
    dispatch(sendEndorsementEmail(resource.data.id));
  }

  getQuotesHistory() {
    const { dispatch, resource } = this.props;
    const quoteReference = get(resource, 'data.attributes.quote_reference');
    if (quoteReference) {
      dispatch(getQuotesHistory(quoteReference));
    } else {
      dispatch(clearQuoteHistory());
    }
  }

  hasStatus(statuses) {
    if (!statuses) {
      return true;
    }

    const status = this.props.resource.data.attributes.status;
    return statuses.indexOf(status) !== -1;
  }

  buttons() {
    const resource = this.props.resource.data.attributes;
    const type = resource.policy_number ? 'policy' : 'application';
    const user = getIncludedResource(
      this.props.resource.data,
      this.props.resource.included,
      'user',
    );
    const schemes = getIncludedResource(
      this.props.resource.data,
      this.props.resource.included,
      'schemes',
    );
    const product = getIncludedResource(
      schemes && schemes.length ? schemes[0] : this.props.resource.data,
      this.props.resource.included,
      'product',
    );
    const customer = getIncludedResource(
      this.props.resource.data,
      this.props.resource.included,
      'customer',
    );

    let isScreening = false;

    if (get(product, 'data.attributes.product_code') === 'BMC') {
      isScreening = true;
    }

    const actionButtons = [
      // {
      //   type: 'policy',
      //   permissions: ['policy.quote'],
      //   statuses: ['issued', 'on cover', 'active', 'expired', 'cancelled'],
      //   button: (
      //     <PreventRenewalButton
      //       resource={this.props.resource}
      //     />
      //   ),
      // },
      // {
      //   type: 'policy',
      //   permissions: ['policy.quote'],
      //   statuses: ['issued', 'on cover', 'active', 'expired', 'cancelled'],
      //   button: (
      //     <NonAutoRenewalButton
      //       resource={this.props.resource}
      //       label={'Manual Renewal'}
      //     />
      //   ),
      // },
      // {
      //   type: 'policy',
      //   permissions: ['policy.quote'],
      //   statuses: ['issued', 'on cover', 'active', 'expired', 'cancelled'],
      //   button: (
      //     <ReferRenewalButton
      //       resource={this.props.resource}
      //     />
      //   ),
      // },
      {
        type: 'policy',
        statuses: ['expired'],
        permissions: ['policy.underwriter'],
        button: <CancelButton className="pull-right" resource={this.props.resource}/>,
      },
      {
        type: 'policy',
        statuses: ['issued', 'on cover', 'active'],
        permissions: ['policy.cancel'],
        conditions: ['isInsideCancellationPeriod'],
        button: <CancelButton className="pull-right" resource={this.props.resource}/>,
      },
      {
        type: 'policy',
        statuses: ['issued', 'on cover', 'active'],
        permissions: ['policy.underwriter'],
        conditions: ['isOutsideCancellationPeriod'],
        button: <CancelButton className="pull-right" resource={this.props.resource}/>,
      },
      {
        type: 'policy',
        statuses: ['cancelled'],
        permissions: ['policy.underwriter'],
        button: (
          <Button
            label="Un-cancel"
            bsStyle="primary"
            className="pull-right"
            handleClick={this.handleUncancel}
          />
        ),
      },
      {
        type: 'policy',
        permissions: ['broker.edit'],
        button: (
          <TransferPolicyButton
            className="pull-right"
            resource={this.props.resource}
            user={user}
            resourceType={'policies'}
          />
        ),
      },
      {
        type: 'application',
        permissions: ['broker.edit'],
        button: (
          <TransferPolicyButton
            className="pull-right"
            resource={this.props.resource}
            user={user}
            resourceType={'policy-applications'}
          />
        ),
      },
      {
        type: 'policy',
        permissions: ['customer.view'],
        button: (
          <Button
            link
            className="pull-right"
            to={'/customers/' + customer.data.id}
            bsStyle="primary"
            label="View Customer"
          />
        ),
      },
      {
        type: 'application',
        permissions: ['customer.view'],
        button: (
          <Button
            link
            className="pull-right"
            to={'/customers/' + customer.data.id}
            bsStyle="primary"
            label="View Customer"
          />
        ),
      },
      {
        type: 'policy',
        permissions: ['policy.mta'],
        conditions: ['isNotBroker'],
        statuses: ['issued', 'on cover', 'active'],
        button: (
          <Button
            label="Mid-term Adjustment"
            link
            to={
              '/products/' +
              product.data.id +
              '/adjustment/' +
              this.props.resource.data.id
            }
            className="pull-right"
            bsStyle="primary"
            rightIcon="pencil"
          />
        ),
      },
      {
        type: 'policy',
        permissions: ['policy.mta_expired'],
        statuses: ['expired'],
        button: (
          <ExpiredMidTermAdjustmentButton
            productId={product.data.id}
            policyId={this.props.resource.data.id}
          />
        ),
      },
      {
        type: 'policy',
        permissions: ['policy.quote'],
        statuses: ['issued', 'on cover', 'active'],
        conditions: ['hasMta', 'isNotBUPAProduct'],
        button: (
          <Button
            label="Send Endorsement Email"
            bsStyle="primary"
            className="pull-right"
            handleClick={this.handleEndorsementEmail}
          />
        ),
      },
      // {
      //   type: 'policy',
      //   permissions: ['policy.quote'],
      //   statuses: ['issued', 'on cover', 'active'],
      //   button: (
      //     <SendSmsButton
      //       className="pull-right"
      //       resource={this.props.resource}
      //       phoneNumber={get(
      //         customer,
      //         'data.attributes.phone2',
      //         get(customer, 'data.attributes.phone1'),
      //       )}
      //     />
      //   ),
      // },
      {
        type: 'policy',
        permissions: ['policy.quote'],
        statuses: ['issued', 'on cover', 'active', 'expired', 'cancelled'],
        conditions: ['isNotBUPAProduct'],
        button: (
          <Button
            label="Send Policy Email"
            bsStyle="primary"
            className="pull-right"
            handleClick={this.handleCustomerLogin}
          />
        ),
      },
      {
        type: 'application',
        permissions: ['policy.quote'],
        statuses: ['quote', 'referral approved'],
        conditions: ['checkMtaPermission'],
        button: (
          <Button
            label="Send Quote Email"
            bsStyle="primary"
            className="pull-right"
            handleClick={isScreening ? this.handleMedicalScreeningEmail : this.handleCustomerLogin}
          />
        ),
      },
      {
        type: 'application',
        permissions: ['policy.quote'],
        statuses: ['quote', 'applied', 'referred', 'referral approved', 'renewal', 'renewal invited'],
        conditions: ['checkMtaPermission', 'checkOwnerLocked'],
        button: (
          <Button
            label="Edit"
            className="pull-right"
            bsStyle="primary"
            rightIcon="pencil-square-o"
            link
            to={
              '/products/' +
              product.data.id +
              '/quote/' +
              this.props.resource.data.id
            }
          />
        ),
      },
      {
        type: 'application',
        permissions: ['policy.quote'],
        statuses: ['applied', 'quote'],
        button: (
          <Button
            label="Clone"
            link
            to={
              '/products/' +
              product.data.id +
              '/quote/' +
              this.props.resource.data.id +
              '?clone=true'
            }
            className="pull-right"
            bsStyle="primary"
            rightIcon="pencil-square-o"
          />
        ),
      },
      {
        type: 'application',
        permissions: ['policy.quote'],
        statuses: ['applied', 'quote', 'renewal', 'referred', 'referral approved'],
        button: <LapseButton className="pull-right" resource={this.props.resource}/>,
      }
    ];
    if (get(product, 'data.attributes.product_code') === 'CSP') {
      actionButtons.push({
        type: 'policy',
        permissions: ['policy.quote'],
        statuses: ['issued', 'on cover', 'active', 'expired', 'cancelled'],
        button: (
          <Button
            label="Download Live Membership Data"
            bsStyle="primary"
            className="pull-right"
            handleClick={this.handleCSPDownloadLiveMembershipData}
          />
        ),
      });
    }

    if (['NPO', 'NPOR', 'CSPA', 'CSPAR', 'FPS', 'FPR', 'CSS', 'CSSR'].includes(get(product, 'data.attributes.product_code'))) {
      actionButtons.push({
        type: 'policy',
        permissions: ['policy.quote'],
        statuses: ['issued', 'on cover', 'active'],
        button: (
          <Button
            label="Auto Renewal"
            bsStyle="primary"
            className="pull-right"
            handleClick={this.handleAutoRenewal}
          />
        ),
      });

      actionButtons.push({
        type: 'policy',
        permissions: ['policy.quote'],
        statuses: ['issued', 'on cover', 'active', 'quote', 'applied', 'renewal', 'renewal invited'],
        button: (
          <NonAutoRenewalButton
            className="pull-right btn btn-primary"
            resource={this.props.resource}
          />
        ),
      });
    }

    return actionButtons.filter((button) => {
      if (button.type !== type) {
        return false;
      }

      let render = true;
      if (button.conditions) {
        button.conditions.forEach((condition) => {
          switch (condition) {
            case 'isInsideCancellationPeriod':
              if (!resource.is_inside_cancellation_period) {
                render = false;
              }
              break;

            case 'isOutsideCancellationPeriod':
              if (resource.is_inside_cancellation_period) {
                render = false;
              }
              break;

            case 'hasMta':
              if (resource.version === 1) {
                render = false;
              }
              break;

            case 'checkMtaPermission':
              if (
                (type === 'policy' && resource.version > 1) ||
                resource.quote_type === 'mid term adjustment'
              ) {
                button['permissions'].push('policy.mta');
              }
              break;

            case 'checkOwnerLocked':
              if (resource.owner_locked) {
                button['permissions'].push('policy.underwriter');
              }
              break;

            case 'checkReferralPermission':
              if (resource.status === 'referred') {
                button['permissions'].push('policy.handle_all_referrals');
              }
              break;
            case 'isNotBroker':
              render = !isBroker();
              break;
            case 'isNotBUPAProduct':
              if (get(product, 'data.attributes.product_code') === 'CSP') {
                render = false;
              }
              break;
          }
        });
      }

      return render ? this.hasStatus(button.statuses) : false;
    });
  }

  render() {
    const { reference, quotesHistory } = this.props;
    const resource = get(this.props.resource, 'data.attributes');
    const isMta = resource.quote_type === 'mid term adjustment';

    // Todo: Fix to get the information from API:
    // const declaration={get(currentProduct, 'data.attributes.metadata.purchase_declaration', '')}

    let declarationText = {
      main:
        'This insurance is only available to residents of the UK & Channel Islands \n\n You must have have resided in the UK for no less than 6 months, have a permanent UK address and be registered with the UK General Practitioner. \n\n This insurance must be purchase prior to you leaving the UK on your planned trip',
      information:
        'There are certain disclosures we are required to make at this stage, in relation to our status, terms of business, our complaints procedure and the fact that we are subject to the Financial Services Compensation scheme. All of this information will be provided to you together with the documents we are sending in the post/via/email/about to give you now.',
      question:
        'Would you like to go through all this information now or are you happy wait to receive it in writing?',
    };

    const tumc = getIncludedResource(
      schemes && schemes.length ? schemes[0] : this.props.resource.data,
      this.props.resource.included,
      'product',
    );

    if (get(tumc, 'data.attributes.product_code') === 'TUMC') {
       declarationText = {
        main:
          '* I and all persons to be insured are permanently resident in the United Kingdom (England, Wales, Scotland and Northern Ireland), Channel Islands and the Isle of Man are in the United Kingdom at the time of purchasing this policy;\n * I have familiarised myself with the terms and conditions of the Insurance Policy;\n ',
        information:
          '* I have purchased my main travel [Insurance Policy](policy_wording), either prior to purchasing this top up policy or at the same time as purchasing this top up policy.',
        question:
          'Would you like to go through all this information now or are you happy wait to receive it in writing?',
      };
    }

    const customer = getIncludedResource(
      this.props.resource.data,
      this.props.resource.included,
      'customer',
    );
    const startDate = moment(get(resource, 'starts_at', get(resource, 'commences_at'))).startOf('day');
    const requiresBackDateCover = !isMta && startDate.isBefore(moment().startOf('day'));

    const premiums = getIncludedResource(
      this.props.resource.data,
      this.props.resource.included,
      'premiums',
    );
    const applications = getIncludedResource(
      this.props.resource.data,
      this.props.resource.included,
      'issued_applications',
    );
    const policy = getIncludedResource(
      this.props.resource.data,
      this.props.resource.included,
      'policy',
    );
    const schemes = getIncludedResource(
      this.props.resource.data,
      this.props.resource.included,
      'schemes',
    );
    const product = getIncludedResource(
      schemes && schemes.length ? schemes[0] : this.props.resource.data,
      this.props.resource.included,
      'product',
    );

    const user = getIncludedResource(
      this.props.resource.data,
      this.props.resource.included,
      'user',
    );
    const broker = getIncludedResource(
      this.props.resource.data,
      this.props.resource.included,
      'broker',
    );
    const channel = getIncludedResource(
      this.props.resource.data,
      this.props.resource.included,
      'channel',
    );
    const source = getIncludedResource(
      this.props.resource.data,
      this.props.resource.included,
      'source',
    );
    const isBrokerBySource =
      get(source, 'data.attributes.is_broker', false) ||
      get(broker, 'data.id') !== undefined;

    const isBrokerCheck = isBrokerBySource || isBroker();

    const mtas = getIncludedResource(
      this.props.resource.data,
      this.props.resource.included,
      'mid_term_adjustment_applications',
    );
    const address = displayAddress(customer.data.attributes.address);

    const channelLogo = get(channel, 'data.attributes.logo');

    const renderLogo = () => {
      if (!resource) {
        return <div/>;
      }

      if (channelLogo) {
        return (
          <Row>
            <Col className="scheme-logo">
              <img
                className="img-responsive img-adjustment pull-right"
                src={channelLogo}
                alt={'Channel Logo'}
              />
            </Col>
          </Row>
        );
      }

      if (!isEmpty(schemes)) {
        return (
          <Row>
            <Col sm={12} className="scheme-logo">
              {/*<img className='img-responsive img-adjustment' src={get(head(schemes), 'attributes.logo')} />*/}
              <img
                className="img-responsive img-adjustment pull-right"
                src={get(premiums[0], 'attributes.scheme.logo')}
                alt={'Scheme Logo'}
              />
            </Col>
          </Row>
        );
      }
    };

    const renderReference = () => {
      return <span className="pull-left">{reference}</span>;
    };

    let fieldsRequired = {};

    if (!resource.policy_number) {
      fieldsRequired =
        resource &&
        resource.validation &&
        Object.keys(resource.validation).filter((key) => !resource.validation[key]);
    }

    return (
      <Box>
        <div className="policy-details">
          {renderLogo()}
          <h2 className="resource-name">
            {renderReference()}
            <Label
              className="pull-right"
              bsStyle={this.getLabelStyle(resource.status, resource.policy_number)}
            >
              {startCase(resource.status)}
            </Label>
          </h2>

          {get(product, 'data.attributes.product_code') && (
            <Summary
              resource={resource}
              policy={policy}
              product={product}
              premiums={premiums}
            />
          )}

          {get(product, 'data.attributes.product_code') !== 'CSP' && (
            <div>
              <Travellers
                isBrokerCheck={isBrokerCheck}
                isSaving={this.props.isSaving}
                doctorForm={this.props.doctorForm}
                resource={resource}
                fullResource={this.props.resource}
                params={this.props.params}
              />
              <div className="hr"/>
            </div>
          )}

          <CustomerDetails
            customer={customer}
            address={address}
            handleCreateUser={this.handleCreateUser}
            productCode={get(product, 'data.attributes.product_code')}
          />

          <History
            quotesHistory={quotesHistory}
            applications={applications}
            mtas={mtas}
            resource={resource}
            policy={policy}
            broker={broker}
            source={source}
            user={user}
          />


          {get(resource, 'metadata.endorsements', []).length > 0 && (
            <Row>
              <Col sm={12}>
                <Endorsements endorsements={resource.metadata.endorsements}/>
              </Col>
            </Row>
          )}

          <Row>
            <Col sm={12}>
              <h3>Premiums</h3>
            </Col>
            <Col sm={12}>
              {Array.isArray(premiums) &&
              premiums.map((premium, i) => {
                if (premium.attributes) {
                  const scheme = getIncludedResource(
                    premium,
                    this.props.resource.included,
                    'scheme',
                  );
                  const documents = getIncludedResource(
                    scheme.data,
                    this.props.resource.included,
                    'documents',
                  );
                  const showButtons = (resource) => {
                    if (!resource.valid) {
                      return false;
                    }

                    // FIXME Referrals date
                    if (
                      startDate.format('Y-m-d') !==
                      moment(
                        get(premium, 'attributes.information.start_date'),
                      ).format('Y-m-d')
                    ) {
                      return false;
                    }

                    if (
                      premium.attributes.has_referrals &&
                      (resource.status !== 'referral approved' &&
                        resource.status !== 'quote' &&
                        resource.status !== 'renewal invited' &&
                        resource.status !== 'renewal'
                      )
                    ) {
                      return false;
                    }

                    if (
                      get(premium, 'attributes.premium_type') ===
                      'initial premium' &&
                      get(premium, 'attributes.gross') === 0
                    ) {
                      return false;
                    }

                    if (requiresBackDateCover && !isOwner() && !hasPermission('policy.backdate_cover')) {
                      return false;
                    }

                    if (isMta && !hasPermission('policy.mta')) {
                      return false;
                    }

                    if ((resource.status === 'renewal invited' || resource.status === 'renewal') && resource.version === 1) {
                      return false;
                    }

                    return resource.status === 'quote' ||
                      resource.status === 'referral approved' ||
                      resource.status === 'renewal invited' ||
                      resource.status === 'renewal';
                  };

                  const showPremium = (resource) => {
                    if (isBroker() && premium.attributes.has_referrals) {
                      if (
                        resource.status === 'quote' ||
                        resource.status === 'referred' ||
                        resource.status === 'lapsed'
                      ) {
                        return false;
                      }
                    }

                    if ((resource.status === 'renewal invited' || resource.status === 'renewal') && resource.version === 1) {
                      return false;
                    }

                    return true;
                  };

                  if (get(premium, 'attributes.scheme.name', '') === 'Coach Plus Frequent Traveller') {
                    return;
                  }

                  return (
                    <PremiumRow
                      key={i}
                      logo={channelLogo ? channelLogo : premium.attributes.scheme.logo}
                      schemeDetails={scheme.data.attributes}
                      showButtons={showButtons(resource)}
                      showPremium={showPremium(resource)}
                      premium={premium}
                      resource={this.props.resource}
                      documents={documents}
                      isBrokerCheck={isBrokerCheck}
                      product={product}
                      declarationText={declarationText}
                    />
                  );
                }
              })}

            </Col>
          </Row>

          {requiresBackDateCover && !isOwner() && !hasPermission('policy.backdate_cover') && resource.status === 'quote' ? (
            <div className="alert-danger" style={{ padding: '2px' }}>
              Cannot back date cover.
            </div>
          ) : null}
          {resource.status === 'quote' && !resource.valid ? (
            <div className="warning-message-details">
              <Panel
                collapsible
                expanded={this.state.open}
                header={
                  <h4>
                    <strong>
                      More details are required before this policy can be issued
                    </strong>{' '}
                    <Icon name={!this.state.open ? 'plus' : 'minus'}/>
                  </h4>
                }
                onSelect={this.openClose}
              >
                {<div>{fieldsRequired.join(', ') + '.'}</div>}
              </Panel>
            </div>
          ) : null}

          <div className="hr transparent"/>
          <Row>
            <Col sm={12}>
              <InternalButtonBar buttons={this.buttons()}/>
            </Col>
          </Row>
        </div>
      </Box>
    );
  }
}

PolicyDetails.propTypes = {
  dispatch: PropTypes.func.isRequired,
  policy: PropTypes.object.isRequired,
  resource: PropTypes.object.isRequired,
  reference: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    policyRedux: state.policy,
    upload: state.upload,
  };
}

export default connect(mapStateToProps)(PolicyDetails);
