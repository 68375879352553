import React from 'react';
import InternalContainer from '../../layout/containers/Internal';

const AccountsAdjustJournalContainer = ({location})=> {

  console.log(location);

  return (
    <InternalContainer title={'Adjust Journal'}>



    </InternalContainer>
  )
}

export  default AccountsAdjustJournalContainer;
