import React from 'react';
import {
  HorizontalFormControl,
  InfoLabel,
  Icon,
} from '../../../../../../common/components';
import { Field } from 'redux-form';
import { Row, Col, Button } from 'react-bootstrap';
import { map } from 'lodash';

class SubBenefitsFields extends React.Component {
  state = {
    viewDetails: false,
  };

  componentWillMount() {
    const {
      dispatch,
      change,
      subBenefit,
      benefitKey,
      index,
      itsNewSubBenefit,
    } = this.props;
    dispatch(
      change(`meta.config.benefits[${benefitKey}].sub_benefits[${index}]`, subBenefit),
    );

    if (itsNewSubBenefit) {
      this.setState({
        viewDetails: true,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.showAllDetails) {
      this.setState({
        viewDetails: true,
      });
    }

    if (!nextProps.showAllDetails) {
      this.setState({
        viewDetails: false,
      });
    }
  }

  toggleDetails = () => {
    this.setState((prevState) => ({
      viewDetails: !prevState.viewDetails,
    }));
  };

  render() {
    const { index, onRemoveSub, subBenefit, benefitKey, viewOnly } = this.props;
    const { viewDetails } = this.state;
    return (
      <Col sm={12}>
        <div className="section-title join" onClick={this.toggleDetails}>
          {subBenefit.name ? subBenefit.name : 'New Sub Benefit'}
          <span className="remove-benefit pull-right">
            <Icon name={viewDetails ? 'minus' : 'plus'} />
          </span>
        </div>
        <div className="product-container-section join">
          {viewDetails && (
            <div className="section-item">
              <Row>
                <Col sm={12}>
                  <h4>Sub Benefit Details:</h4>
                </Col>
                <Col sm={6}>
                  <Field
                    name={`meta.config.benefits[${benefitKey}].sub_benefits[${index}].name`}
                    label="Name:"
                    labelSize={3}
                    component={viewOnly ? InfoLabel : HorizontalFormControl}
                  />
                </Col>
                <Col sm={6}>
                  <Field
                    name={`meta.config.benefits[${benefitKey}].sub_benefits[${index}].text`}
                    label="Display Text:"
                    labelSize={3}
                    component={viewOnly ? InfoLabel : HorizontalFormControl}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <h4>Cover Level:</h4>
                </Col>
                {map(subBenefit.cover_level, (item, key) => {
                  return (
                    <Col key={key} sm={12}>
                      <Row>
                        <Col sm={6}>
                          <Field
                            name={`meta.config.benefits[${benefitKey}].sub_benefits[${index}].cover_level.${key}`}
                            label={`${key}:`}
                            labelSize={3}
                            mdFieldSize={9}
                            component={viewOnly ? InfoLabel : HorizontalFormControl}
                          />
                        </Col>
                      </Row>
                    </Col>
                  );
                })}
                <Col sm={6}>
                  <Field
                    name={`meta.config.benefits[${benefitKey}].sub_benefits[${index}].excess`}
                    label="Excess:"
                    labelSize={3}
                    component={viewOnly ? InfoLabel : HorizontalFormControl}
                  />
                </Col>
                {!viewOnly && (
                  <Col sm={12}>
                    <Button onClick={onRemoveSub} className="pull-right">
                      Remove <Icon name={'trash'} />
                    </Button>
                  </Col>
                )}
              </Row>
            </div>
          )}
        </div>
      </Col>
    );
  }
}

export default SubBenefitsFields;
