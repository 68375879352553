import React from 'react';
import { Form, reduxForm } from 'redux-form';
import { map } from 'lodash';
import AgencyFee from './components/AgencyFee/AgencyFee';
import AdminFee from './components/AdminFee/AdminFee';
import ScreeningFee from './components/ScreeningFee/ScreeningFee';
import { Col, Row } from 'react-bootstrap';
import Button from '../../../../common/components/Button/Button';
import ProductContainer from '../ProductContainer/ProductContainer';

class FeesForm extends React.Component {
  componentWillMount() {
    const { dispatch, change, scheme } = this.props;
    dispatch(change('meta.patch.fees', scheme.fees));
  }

  render() {
    const { handleSubmit, scheme, viewOnly } = this.props;
    return (
      <ProductContainer>
        <Form onSubmit={handleSubmit}>
          {map(scheme.fees, (fee, key) => {
            if (key === 'agency_fee') {
              return <AgencyFee key={key} fee={fee} viewOnly={viewOnly} />;
            }

            if (key === 'admin_fee') {
              return <AdminFee key={key} fee={fee} viewOnly={viewOnly} />;
            }

            if (key === 'screening_fee') {
              return <ScreeningFee index={key} key={key} fee={fee} viewOnly={viewOnly} />;
            }
          })}
          {!viewOnly && (
            <Row>
              <Col sm={12}>
                <Button bsStyle="primary" className="pull-right" type="submit">
                  Save Changes
                </Button>
              </Col>
            </Row>
          )}
        </Form>
      </ProductContainer>
    );
  }
}

export default reduxForm({
  form: 'FessForm',
})(FeesForm);
