import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Field } from 'redux-form';
import { map, get } from 'lodash';
import { NoLabelFormControl, InfoLabel } from '../../../../../../common/components';
import displayName from '../../../../../../helpers/displayName';

class MultiRiskOptionRatesTable extends React.Component {
  componentWillMount() {
    const { rates, optionKey, dispatch, change } = this.props;

    map(rates, (rate, key) => {
      return dispatch(change(`meta.patch.options[${optionKey}].rates.${key}`, rate));
    });
  }

  render() {
    const { name, rates, rateType, optionKey, schemeType, viewOnly } = this.props;
    const group1 = schemeType === 'single' ? get(rates.a, 'uk') : get(rates.a, 'europe');
    return (
      <div>
        <h2>{name}</h2>
        {map(rates, (rates, key) => {
          return (
            <div key={key}>
              <h4>
                <span className="text-capitalize">risk category {key}</span>
              </h4>
              <h6>Rate Type: {rateType}</h6>
              <div className="section-title join">
                <Row>
                  <Col xs={2}>Ages</Col>
                  <Col xs={10}>
                    <Row>
                      {map(rates, (rate, key) => {
                        return (
                          <Col key={key} xs={schemeType === 'single' ? 2 : 4}>
                            {displayName(key)}
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className="product-container-section join">
                <Row className="section-item">
                  <Col xs={2}>
                    <div>
                      {map(group1, (rate, key) => {
                        return (
                          <div className="option-label" key={key}>
                            {key}
                          </div>
                        );
                      })}
                    </div>
                  </Col>
                  <Col xs={10}>
                    <Row className="option-row">
                      {map(rates, (rate, index) => {
                        return (
                          <Col
                            key={index}
                            xs={schemeType === 'single' ? 2 : 4}
                            className="options-col"
                          >
                            {map(rate, (value, age) => {
                              return (
                                <div key={age} className="option-field">
                                  <Field
                                    name={`meta.patch.options[${optionKey}].rates.${key}.${index}.${age}`}
                                    component={viewOnly ? InfoLabel : NoLabelFormControl}
                                  />
                                </div>
                              );
                            })}
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default MultiRiskOptionRatesTable;
