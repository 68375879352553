import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, DataTable } from '../../../../common/components';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { FormattedDate, FormattedTime } from 'react-intl';
import CreateContractButton from '../CreateContractButton';
import { refreshData } from '../../../../common/components/DataTable/redux/dataTableActions';
import { Col, Row } from 'react-bootstrap';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';

export default class BrokerContracts extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
    brokerLabel: PropTypes.object.isRequired,
  };

  formatDate(cell) {
    if (cell == null) {
      return '';
    }

    const date = new Date(cell);
    if (date !== 'Invalid Date') {
      return (
        <span>
          <FormattedDate value={new Date(cell)} />{' '}
          <FormattedTime value={new Date(cell)} />
        </span>
      );
    }
    return '';
  }

  handleRowClick(row) {
    if (window) {
      window.open(row.file_path, '_blank');
    }

    return false;
  }

  refreshList() {
    this.props.dispatch(refreshData('contracts'));
  }

  render() {
    const resource = this.props.resource.data.attributes;

    return (
      <div>
        <Box>
          <h2 className="resource-name">
            {resource.name} {this.props.brokerLabel}
          </h2>

          <DataTable
            source={'/brokers/contracts'}
            name="contracts"
            showTotal
            autoFilter={this.props.resource.data.id}
            autoFilterType="filter[broker]"
            onRowSelect={this.handleRowClick.bind(this)}
          >
            <TableHeaderColumn dataField="id" isKey hidden />
            <TableHeaderColumn
              dataField="created_at"
              dataFormat={this.formatDate}
              width={'180px'}
              dataSort
            >
              Created
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="agreed_at"
              dataFormat={this.formatDate}
              width={'180px'}
              dataSort
            >
              Agreed
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="ended_at"
              dataFormat={this.formatDate}
              width={'180px'}
              dataSort
            >
              Ended
            </TableHeaderColumn>
            <TableHeaderColumn dataField="status" dataSort width={'180px'}>
              Status
            </TableHeaderColumn>
          </DataTable>
          <Row>
            <Col sm={12}>
              <InternalButtonBar
                buttons={[
                  {
                    permissions: ['broker.edit'],
                    button: (
                      <CreateContractButton
                        broker={this.props.resource.data}
                        callback={::this.refreshList}
                      />
                    ),
                  },
                ]}
              />
            </Col>
          </Row>
        </Box>
      </div>
    );
  }
}
