import React from 'react';
import { flow } from 'lodash';
import { Row, Col, Button } from 'react-bootstrap';
import BenefitsFields from './BenefitsFields';
import confirm from 'react-confirm2';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // change background colour if dragging
  boxShadow: isDragging && '0 4px 28px rgba(0,0,0,0.3)',
  margin: '0 0 15px 0',
  borderRadius: '4px',

  // styles we need to apply on draggables
  ...draggableStyle,
});

class Benefits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      benefitsList: [],
      itsNewBenefit: false,
      showAll: false,
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentWillMount() {
    const { scheme } = this.props;
    if (scheme.benefits.length > 0) {
      this.setState({
        benefitsList: scheme.benefits,
      });
    }
  }

  toggleBenefitsDetails = () => () => {
    this.setState((prevState) => ({
      showAll: !prevState.showAll,
    }));
  };

  handleRemoveBenefit = (index) => () => {
    const { scheme } = this.props;
    confirm('Warning', {
      description: 'Are you sure you want to remove this benefit?',
      done: () => {
        scheme.benefits.splice(index, 1);
        this.setState({
          benefitsList: scheme.benefits,
        });
      },
    });
  };

  handleAddBenefit = () => () => {
    const { scheme } = this.props;

    scheme.benefits.push({
      name: '',
      text: '',
      option: '',
      excess: '',
      cover_level: {
        super: '',
        super_duper: '',
      },
      sub_benefits: [],
    });

    this.setState({
      benefitsList: scheme.benefits,
      itsNewBenefit: true,
    });
  };

  renderBenefits = () => {
    const { scheme, disabled, viewOnly } = this.props;
    const { benefitsList, itsNewBenefit, showAll } = this.state;

    if (benefitsList.length > 0) {
      return benefitsList.map((benefit, index) => {
        return (
          <Draggable key={index} draggableId={index} index={index}>
            {(provided, snapshot) => (
              <div>
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                >
                  <div className="row benefit-row">
                    <BenefitsFields
                      viewOnly={viewOnly}
                      showAll={showAll}
                      itsNewBenefit={itsNewBenefit}
                      benefit={benefit}
                      benefitKey={index}
                      disabled={disabled}
                      benefitsList={benefitsList}
                      onRemove={this.handleRemoveBenefit(index)}
                      {...this.props}
                    />
                  </div>
                </div>
                {provided.placeholder}
              </div>
            )}
          </Draggable>
        );
      });
    }

    if (scheme.benefits.length === 0) {
      return (
        <div>
          <h3>No Benefits found.</h3>
        </div>
      );
    }
  };

  onDragEnd(result) {
    const { benefitsList } = this.state;
    if (!result.destination) {
      return;
    }

    const items = reorder(benefitsList, result.source.index, result.destination.index);

    this.setState({
      benefitsList: items,
    });
  }

  render() {
    const { viewOnly } = this.props;
    const { showAll } = this.state;
    return (
      <div className="form-horizontal">
        <div className="title-with-btn-right">
          <h2>Benefits</h2>
          <Button
            bsStyle="link"
            className="pull-right"
            onClick={this.toggleBenefitsDetails()}
          >
            {showAll ? 'Show less' : 'Show more'}
          </Button>
        </div>

        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div ref={provided.innerRef}>
                {this.renderBenefits()}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {!viewOnly && (
          <Row>
            <Col sm={12}>
              <Button
                className="benefit-btn"
                bsStyle="primary"
                size="xs"
                onClick={this.handleAddBenefit()}
              >
                Add Benefit
              </Button>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default flow()(Benefits);
