import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import {
  Button,
  Modal,
  DatePickerDob,
  HorizontalFormControl,
  SelectCurrency,
  SelectBroker,
} from '../../../../common/components';
import { openModal, closeModal } from '../../../../common/components/Modal/ModalRedux';
import { postAdjustment, cacheTransactions } from '../../redux/accountActions';
import { get, set } from 'lodash';
import moment from 'moment/moment';
import { bindActionCreators } from 'redux';

const FORM_NAME = 'makeAdjustmentForm';

const validate = (values) => {
  const errors = {};

  if (!get(values, 'data.attributes.date')) {
    set(errors, 'data.attributes.date', 'Date is required');
  }

  if (!get(values, 'data.attributes.amount')) {
    set(errors, 'data.attributes.amount', 'Amount is required');
  }

  return errors;
};

class MakeAdjustmentButton extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    handleSubmit: PropTypes.func,
    resource: PropTypes.object.isRequired,
    amount: PropTypes.number,
    callback: PropTypes.func,
    label: PropTypes.string,
    block: PropTypes.bool,
  };

  componentWillMount() {
    const { dispatch, change } = this.props;

    dispatch(change('data.attributes.currency', 'GBP'));
  }

  componentWillUpdate(newProps) {
    const { actions, submitSucceeded, callback } = this.props;
    if (newProps.submitSucceeded && !submitSucceeded) {
      if (callback) {
        callback('');
      }
      actions.closeModal(FORM_NAME);
    }
  }

  handleSubmit(values) {
    const { actions, resource } = this.props;

    return actions.postAdjustment(values, resource);
  }

  handleOpen() {
    const { actions, dispatch, change, reset, amount, matchedTransactions } = this.props;

    actions.cacheTransactions(matchedTransactions);
    dispatch(reset());
    dispatch(change('data.attributes.amount', amount));
    actions.openModal(FORM_NAME);
  }

  render() {
    const { label, handleSubmit, submitting, block, resource: { data: { id } } } = this.props;
    const title = label || 'Post Adjustment';
    const submitButton = (
      <Button
        type="submit"
        bsStyle="primary"
        isLoading={submitting}
        label="Save Adjustment"
      />
    );

    return (
      <div>
        <Button
          className="matching-sidebar-btn"
          bsStyle="primary"
          label={title}
          handleClick={::this.handleOpen}
          block={block}
          isLoading={submitting}
        />
        <Modal
          name={FORM_NAME}
          title={title}
          close
          handleSubmit={handleSubmit(::this.handleSubmit)}
          footer={submitButton}
        >
          <Field
            async
            name={'data.relationships.broker.data.id'}
            key={'select-broker-' + id}
            label="Broker"
            labelSize={3}
            component={SelectBroker}
            filters={{ account: id }}
          />
          <hr/>
          <Field
            name={'data.attributes.date'}
            label="Date"
            labelSize={3}
            maxDate={moment().format()}
            component={DatePickerDob}
          />
          <Field
            name={'data.attributes.description'}
            label="Description"
            labelSize={3}
            component={HorizontalFormControl}
          />
          <Field
            name={'data.attributes.currency'}
            label="Currency"
            labelSize={3}
            defaultValue={{ value: 'GBP', label: 'Pound sterling' }}
            component={SelectCurrency}
          />
          <Field
            name={'data.attributes.amount'}
            label="Amount"
            labelSize={3}
            currency
            component={HorizontalFormControl}
          />
        </Modal>
      </div>
    );
  }
}

const form = reduxForm({ form: FORM_NAME, validate })(MakeAdjustmentButton);
const mapStateToProps = (state, props) => {
  return {
    initialValues: {
      data: {
        relationships: {
          broker: {
            data: {
              id: '',
            },
          },
        },
        attributes: {
          date: '',
          currency: 'GBP',
          amount: props.amount || 0,
          metadata: {
            match_item: true,
          },
        },
      },
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      { openModal, closeModal, postAdjustment, cacheTransactions },
      dispatch,
    ),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(form);
