import React from 'react';
import { DataTable } from '../../../../common/components';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { refreshData } from '../../../../common/components/DataTable/redux/dataTableActions';
import { FormattedDate, FormattedTime } from 'react-intl';
import { connect } from 'react-redux';
import ProductContainer from '../ProductContainer/ProductContainer';

class AuditForm extends React.Component {
  formatDate(cell) {
    return (
      <span>
        <FormattedDate value={new Date(cell)} /> <FormattedTime value={new Date(cell)} />
      </span>
    );
  }

  refreshList() {
    this.props.dispatch(refreshData('audit'));
  }

  render() {
    const { scheme } = this.props;
    return (
      <ProductContainer>
        <h2>Audit</h2>
        <DataTable
          source={'/events'}
          autoFilter={scheme.id}
          autoFilterType="filter[resource]"
          name="audit"
          showTotal
        >
          <TableHeaderColumn dataField="id" isKey hidden />
          <TableHeaderColumn
            dataField="occurredAt"
            dataFormat={this.formatDate}
            width={'180px'}
            dataSort
          >
            Occurred at
          </TableHeaderColumn>
          <TableHeaderColumn dataField="action" width={'180px'} dataSort>
            Action
          </TableHeaderColumn>
          <TableHeaderColumn dataField="description" width={'550px'} dataSort>
            Description
          </TableHeaderColumn>
          <TableHeaderColumn dataField="user_name">User</TableHeaderColumn>
        </DataTable>
      </ProductContainer>
    );
  }
}

export default connect()(AuditForm);
