import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get, flow, find, forEach } from 'lodash';
import AsyncSelect from 'react-select/lib/Async';
import Axios from 'axios';
import selectTheme, { styles } from '../../../helpers/selectTheme';
import { actions as selectMenuActions } from './SelectMenuRedux';
import { bindActionCreators } from 'redux';

const CancelToken = Axios.CancelToken;

class AsyncSelectMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cancelTokens: {}
    };
  }

  handleOnBlur = () => {
    const { input } = this.props;

    if (input && input.onBlur) {
      input.onBlur(input.value);
    }
  };

  handleOnChange = (option) => {
    const { input, allData, onChangeCallback, name, dispatch, change } = this.props;

    if (option) {
      if (onChangeCallback) {
        const match = find(allData, { id: option.value });
        onChangeCallback(match);
      }

      if (input) {
        input.onChange(option.value);
      }

      if (name) {
        dispatch(change(name, option.value));
      }
    }
  };

  getSuggestions = (searchTerm, url, id, dispatch, filterName, sort, labelKeys) => {
    let token = this.state.cancelTokens[id];

    if (token) {
      token();
    }

    const requestCancelToken = new CancelToken(function executor(c) {
      token = c;
    });
    this.setState({
      cancelTokens: {
        ...this.state.cancelTokens,
        [id]: token
      }
    });

    let searchUrl = url;

    const joinChar = (url) => {
      return url.includes('?') ? '&' : '?';
    };

    if (filterName && searchTerm) {
      searchUrl += `${joinChar(searchUrl)}filter[${filterName}]=${searchTerm}`;
    }

    return Axios.get(
      searchUrl, { cancelToken: requestCancelToken }
    ).then((response) => {
      const options = get(response, 'data.data', []).map((source) => {
        let label = '';

        if (Array.isArray(labelKeys)) {
          labelKeys.forEach(labelKey => {
            if (label) {
              label += ' ';
            }
            label += get(source, labelKey, 'n/k');
          });
        }

        return {
          label: label,
          value: get(source, 'id'),
        };
      });

      if (response) {
        dispatch(selectMenuActions.setData(id, response.data, options));
      }

      return options;
    }).catch((error) => {
      console.error(error);
    });
  };

  getUrl() {
    const { url, filters, sort } = this.props;
    let uri = url;

    const joinChar = (url) => {
      return url.includes('?') ? '&' : '?';
    };

    if (filters) {
      forEach(filters, (filter, key) => {
        if (filter) {
          uri += `${joinChar(uri)}filter[${key}]=${filter}`;
        }
      });
    }

    if (sort) {
      uri += `${joinChar(uri)}sort=${sort}`;
    }

    return uri;
  }

  render() {
    const { meta, showError, errorMessage, id, key, dispatch, placeholder, filterName = 'name', sort, labelKeys = ['attributes.name'] } = this.props;
    const error = meta && meta.error;
    const touched = meta && meta.touched;
    const showErrorMsg = showError || (touched && meta);
    const errorMsg = errorMessage || error;

    return (
      <div className={'select-menu'}>
        <AsyncSelect
          defaultOptions
          loadOptions={(inputValue) => this.getSuggestions(inputValue, this.getUrl(), id || key, dispatch, filterName, sort, labelKeys)}
          theme={(theme) => selectTheme(theme)}
          styles={styles}
          onBlur={this.handleOnBlur}
          onChange={(option) => this.handleOnChange(option)}
          placeholder={placeholder || 'Type...'}
          {...this.props}
        />
        {(showErrorMsg && errorMsg) && <span className="input-error">{errorMsg}</span>}
      </div>
    );
  }
}

export default flow([
  connect(
    (state, props) => {
      return {
        allOptions: get(state, `selects.${props.id}.options`),
        displayOptions: get(state, `selects.${props.id}.displayOptions`),
        allData: get(state, `selects.${props.id}.data`),
        isFetching: get(state, `selects.${props.id}.isFetching`),
      };
    },
    (dispatch) => ({
      dispatch,
      selectMenuActions: bindActionCreators(selectMenuActions, dispatch),
    }),
  ),
])(AsyncSelectMenu);
