import React from 'react';
import { Field } from 'redux-form';
import { Row, Col } from 'react-bootstrap';
import {
  HorizontalFormControl,
  InfoLabel,
} from '../../../../../../common/components';

class OptionSingleRate extends React.Component {
  componentWillMount() {
    const { rate, optionKey, dispatch, change } = this.props;

    dispatch(change(`meta.patch.options[${optionKey}].rate`, rate));
  }

  render() {
    const { name, optionKey, key, rateType, viewOnly } = this.props;
    return (
      <div className="margin-bottom">
        <h2>{name}</h2>
        <h6>Rate Type: {rateType}</h6>
        <div className="form-horizontal">
          <div className="section-item">
            <Row>
              <Col sm={12}>
                <Row>
                  <Col sm={12}>
                    <div className="section-title join">Cover Level</div>
                    <div className="product-container-section join">
                      <Row className="section-item">
                        <Col sm={6}>
                          <Field
                            name={`meta.patch.options[${optionKey}].rate`}
                            label={`${key}:`}
                            labelSize={4}
                            mdFieldSize={4}
                            component={viewOnly ? InfoLabel : HorizontalFormControl}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default OptionSingleRate;
