import React from 'react';

export const winterSports = (
  <svg id="winter_sports_layer_1" data-name="Layer 1" viewBox="0 0 95 95">
    <circle className="cls-1" cx="47.5" cy="47.5" r="47.5" />
    <circle
      style={{ fill: '#fff' }}
      cx="68.64"
      cy="21.38"
      r="6.68"
      transform="translate(33.88 84.31) rotate(-78.39)"
    />
    <path
      style={{ fill: '#fff' }}
      d="M46.84,53.13L34.62,66a3.88,3.88,0,1,0,5.93,5L54.14,55.16"
    />
    <path
      style={{ fill: '#fff' }}
      d="M54.14,51L41.58,37.94a3.88,3.88,0,1,0-5,5.91L49.63,57.37"
    />
    <polygon
      style={{ fill: '#fff' }}
      points="26.77 22.29 23.86 21.31 25.95 23.56 58.06 44.27 58.89 43 26.77 22.29"
    />
    <ellipse
      style={{ fill: '#fff' }}
      cx="27.97"
      cy="23.96"
      rx="3.1"
      ry="1.29"
      transform="translate(-7.33 34.48) rotate(-57.18)"
    />
    <path
      style={{ fill: '#fff' }}
      d="M70.24,81.64a1.25,1.25,0,0,0-1.69.53,3.91,3.91,0,0,1-2.47,2.07c-2.37.68-5.55-.44-7.72-1.71L5.55,53.16,4.36,55.28,57.1,84.7a16.84,16.84,0,0,0,6.46,2.21,8.73,8.73,0,0,0,3.21-.26,6.38,6.38,0,0,0,4-3.32A1.25,1.25,0,0,0,70.24,81.64Z"
    />
    <circle
      style={{ fill: '#fff' }}
      cx="51.75"
      cy="53.13"
      r="3.19"
      transform="translate(-10.71 93.13) rotate(-78.39)"
    />
    <polyline style={{ fill: '#fff' }} points="59.94 28.15 55.88 41.7 67.25 48.94" />
    <path
      style={{ fill: '#fff' }}
      d="M44.86,45.84l-8.19-9.93s0.65-2.73,7.16-7.86a24.32,24.32,0,0,1,10-4.62c3.49-.92,5.61.16,6.93,1.34,3.1,2.78,1.4,6.81-1.81,9.46,0,0-3.48.23-8.37,3.69C44.44,42.25,44.86,45.84,44.86,45.84Z"
    />
    <rect
      style={{ fill: '#fff' }}
      x="35.25"
      y="33.53"
      width="12.25"
      height="12.87"
      rx="5.17"
      ry="5.17"
      transform="translate(-14.99 51.42) rotate(-55.49)"
    />
    <path
      className="cls-2"
      d="M41.38,65.31c2.72,4.81,5.15,6.11,3.83,9.91s-7.57-2.87-7.57-2.87Z"
    />
  </svg>
);

export default winterSports;
