import React from 'react';

export const wedding = (
  <svg
    id="wedding_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 95 95"
  >
    <circle className="cls-1" cx="47.5" cy="47.5" r="47.5" />
    <path
      style={{ fill: '#fff' }}
      d="M72.7,81.07,63.92,46.7l.37-1.81,8.3.87-6-23.48c-.78-1.88-1.61-3-2.79-3.48V16.65l-2.3,1.22L59.88,16.5l-1.63,1.37-2.31-1.22v2.24A6.28,6.28,0,0,0,53.2,22.3l-6,23.45,7.95-.84.36,1.87L46.84,81.07ZM53,33.12,58.3,32h0V30.22a6,6,0,0,1-1.38-11h6a6,6,0,0,1-1.42,11V32l5.34,1.13-6.91,6Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M45.88,30.12A2.5,2.5,0,0,0,44.3,28v0l-.14,0a2.5,2.5,0,0,0-.81-.11l-6-.85V25.71a6.93,6.93,0,1,0-3.86,0V27l-6.64,1-.15,0h0a2.51,2.51,0,0,0-2.07,2.26L22.73,53a2.51,2.51,0,0,0,4.1,2.14l.05,3.69,1.46-.19v20a3.1,3.1,0,0,0,3.1,3.1h.09a3.1,3.1,0,0,0,3.1-3.1V57.8l.59-.08.8.08V78.59a3.1,3.1,0,0,0,3.1,3.1h.09a3.1,3.1,0,0,0,3.1-3.1V58.45l1.58.16V54.92a2.51,2.51,0,0,0,4.16-2.11Zm-7.2,2.18-2.47-1.51a1.16,1.16,0,0,1-2.08,0l-2.5,1.53V28l2.55,1.56a1.16,1.16,0,0,1,2,0L38.68,28Z"
    />
  </svg>
);

export default wedding;
