import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../redux/ProductReducer';
import { Loader } from '../../../common/components';
import InternalContainer from '../../layout/containers/Internal';
import ProductTemplateBox from '../components/ProductTemplateBox/ProductTemplateBox';
import DocumentPacksForm from '../components/DocumentPacks/DocumentPacksForm';
import getIncludedResource from '../../../helpers/getIncludedResource';

class DocumentPacksEditContainer extends React.Component {
  componentDidMount() {
    const { actions, params } = this.props;
    actions.getProducts(params.productId);
  }

  handleUpdateProduct = () => (formValues) => {
    const { actions, params } = this.props;
    actions.updateDocumentPacks(formValues, params.productId);
  };

  componentWillUnmount() {
    const { actions } = this.props;
    actions.clearData();
  }

  render() {
    const { productManagement } = this.props;
    const product = get(productManagement, 'product');
    let documentPacks = [];


    if (!product) {
      return  <Loader />;
    }
    if (product) {
      documentPacks =
        product && getIncludedResource(product.data, product.included, 'document_packs');
    }
    return (
      <InternalContainer title={'Product Management'}>
        <ProductTemplateBox product={product.data} title={'Document Pack'}>
          <DocumentPacksForm
            product={product.data}
            onSubmit={this.handleUpdateProduct()}
            documentPacks={{ data: documentPacks || [] }}
          />
        </ProductTemplateBox>
      </InternalContainer>
    );
  }
}

export default connect(
  (state) => ({
    productManagement: state.productManagement,
  }),
  (dispatch) => ({
    actions: bindActionCreators({ ...actions }, dispatch),
  }),
)(DocumentPacksEditContainer);
