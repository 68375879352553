import React from 'react';
import { get, map } from 'lodash';
import { Row, Col } from 'react-bootstrap';
import DocumentListItem from './components/DocumentListItem';
import ViewEditDocumentModal from './components/ViewEditDocumentModal';
import Loader from '../../../../common/components/Loader/Loader';
import NewDocumentForm from './components/NewDocumentForm';
import './DocumentList.scss';

class DocumentList extends React.Component {
  renderDocumentList = () => {
    const { productManagement, isLoading, params, router, actions } = this.props;
    const documentManagement = productManagement.documentManagement;
    const documentTypeList = get(documentManagement, 'docTypeList');
    const insurerList = get(documentManagement, 'insurerList');

    if (isLoading) {
      return <Loader />;
    }

    return map(documentTypeList, (documentList, key) => {
      if (documentList.documents.length > 0) {
        return (
          <div className="animated fadeIn" key={key}>
            <DocumentListItem
              name={key}
              router={router}
              actions={actions}
              documentList={documentList.documents}
              productId={params.productId}
              insurerList={insurerList}
            />
          </div>
        );
      }
    });
  };

  render() {
    const { productManagement, dispatch, params } = this.props;
    const documentManagement = productManagement.documentManagement;
    const actionType = documentManagement.actionType;
    const isSubmitting = productManagement.isSubmitting;
    const schemeList = documentManagement.schemeList;
    const activeDocument = documentManagement.activeDocument;
    const showDocumentModal = documentManagement.showDocumentModal;

    return (
      <Row>
        <Col sm={12}>{this.renderDocumentList()}</Col>
        <NewDocumentForm
          onSubmit={null}
          dispatch={dispatch}
          params={params}
          documentManagement={documentManagement}
          isSubmitting={isSubmitting}
        />
        {showDocumentModal && (
          <ViewEditDocumentModal
            dispatch={dispatch}
            actionType={actionType}
            document={activeDocument}
            schemeList={schemeList}
            showDocumentModal={showDocumentModal}
            params={params}
            isSubmitting={isSubmitting}
            onSubmit={null}
          />
        )}
      </Row>
    );
  }
}

export default DocumentList;
