import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ScreeningSession from '../components/ScreeningSession';
import {
  finishScreening,
  recalculateScore,
  startScreening,
} from '../redux/screeningActions';
import { toastr } from 'react-redux-toastr';
import { debounce, isEmpty } from 'lodash';

const QUOTES_TAB = 4;

export class ScreeningContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    screening: PropTypes.object.isRequired,
    callback: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    traveller: PropTypes.object.isRequired,
    // region: PropTypes.string.isRequired,
    // destinations: PropTypes.array,
    changeConditionsCovered: PropTypes.func,
    conditionsCovered: PropTypes.bool,
  };

  componentWillMount() {
    this.recalculateScore = debounce(this.recalculateScore, 1000);
  }

  componentWillReceiveProps(nextProps) {
    const { currentTab } = nextProps;
    if (
      nextProps.region !== this.props.region ||
      nextProps.destinations !== this.props.destinations ||
      nextProps.tripType !== this.props.tripType
    ) {
      if (!isEmpty(nextProps.destinations) || !isEmpty(nextProps.region)) {
        // re-screen if region, destinations or trip type have changed and are not empty
        // Do not trigger recalculate when user changes regions in the QUOTE TAB for upSell
        if (currentTab !== QUOTES_TAB) {
          this.recalculateScore(nextProps);
        }
      }
    }
  }

  startScreeningSession() {
    const { index, traveller, dispatch, region, destinations } = this.props;

    if (!region && destinations.length === 0) {
      return toastr.error(
        'Trip Details Required',
        'Please select a travel destination or region before adding medical conditions.',
      );
    }

    dispatch(
      startScreening(index, traveller.screening_id, region, destinations, 'healix'),
    );
  }

  finishScreeningSession() {
    const { callback, dispatch, path } = this.props;
    dispatch(finishScreening(callback, path));
  }

  recalculateScore(nextProps) {
    const { traveller, callback, dispatch, region, destinations, path } = nextProps;

    if (traveller.screening_id) {
      dispatch(recalculateScore(callback, traveller.screening_id, region, destinations, path));
    }
  }

  render() {
    const {
      index,
      traveller,
      screening,
      dispatch,
    } = this.props;

    return (
      <ScreeningSession
        index={index}
        traveller={traveller}
        screening={screening}
        startScreening={::this.startScreeningSession}
        finishScreening={::this.finishScreeningSession}
        dispatch={dispatch}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    screening: state.screening,
  };
};

export default connect(mapStateToProps)(ScreeningContainer);
