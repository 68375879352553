import { logOut } from '../modules/auth/redux/authActions';

export const inactivityTimer = (seconds, inactivityCallback) => {
  let callbackCalled = false;
  let timerId;

  const events = [
    'click', // touchpad clicks
    'keypress',
    'mousemove',
    'mousedown',
    'touchstart', // touchscreen presses
    'scroll', // scrolling with arrow keys
  ];

  events.forEach((event) => document.addEventListener(event, resetTimer));

  function resetTimer() {
    if (callbackCalled) {
      return;
    }

    clearTimeout(timerId);

    timerId = setTimeout(() => {
      callbackCalled = true;
      events.forEach((event) => document.removeEventListener(event, resetTimer));
      inactivityCallback();
    }, 1000 * seconds);
  }

  return () => {
    clearTimeout(timerId);

    return true;
  };
};

export const logoutTimer = (store) => {
  const bindAuthTimer = () =>
    inactivityTimer(
      1800, // 30 mins
      () => {
        store.dispatch(logOut());
      },
    );

  let isLoggedIn = store.getState().auth.authenticated;
  let timer = null;

  if (isLoggedIn) {
    timer = bindAuthTimer();
  }

  store.subscribe(() => {
    const state = store.getState();

    if (state.auth.authenticated && !isLoggedIn) {
      if (timer) {
        timer(); // Cancel timer
      }

      timer = bindAuthTimer();
    }

    isLoggedIn = state.auth.authenticated;
  });
};
