import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../../common/components';
import HealixSession from '../HealixSession/HealixSession';
import CEGASession from '../CEGASession/CEGASession';
import { FormattedDate } from 'react-intl';
import './styles.scss';

export default class ScreeningSession extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    traveller: PropTypes.object.isRequired,
    screening: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    startScreening: PropTypes.func.isRequired,
    finishScreening: PropTypes.func.isRequired
  };

  render() {
    const {
      index,
      traveller,
      screening,
      dispatch,
      startScreening,
      finishScreening,
    } = this.props;

    return (
      <div key={index} className={'traveller-row col-xs-12 traveller' + index}>
        {screening.token && screening.index === index ? (
          <div className="screening-row screening-session">
            {!traveller.first_name ? (
              <p>Screening for Adult {index}</p>
            ) : (
              <p>
                Screening for{' '}
                {traveller.title + ' ' + traveller.first_name + ' ' + traveller.last_name}
              </p>
            )}

            {screening.service === 'cega' && (
              <CEGASession screening={screening} finishScreening={finishScreening} />
            )}

            {screening.service === 'healix' && (
              <HealixSession
                index={index}
                screening={screening}
                dispatch={dispatch}
                finishScreening={finishScreening}
              />
            )}
          </div>
        ) : (
          <div className="screening-row">
            {(!screening.index || screening.index === index) && (
              <Button
                className="pull-right"
                bsStyle="primary"
                id="conditions"
                label={traveller.screening_id ? 'Edit Conditions' : 'Add conditions'}
                isLoading={screening.isSubmitting && screening.index === index}
                disabled={screening.index !== 0 && screening.index !== index}
                handleClick={startScreening}
              />
            )}
            {!traveller.first_name ? (
              <h4>
                <strong>Adult {index}</strong>
              </h4>
            ) : (
              <div className="traveller-name-title">
                {traveller.title + ' ' + traveller.first_name + ' ' + traveller.last_name}
                {traveller.dob && (
                  <span className="traveller-dob">
                    (<FormattedDate value={new Date(traveller.dob)} />)
                  </span>
                )}
              </div>
            )}
            <ul
              className={
                'declared-conditions-list' +
                (traveller.screening && traveller.screening.meta ? '' : ' empty')
              }
            >
              {traveller.screening && traveller.screening.meta ? (
                traveller.screening.meta.conditions.map((condition, i) => {
                  return (
                    <li key={i}>
                      <span>{condition}</span>
                    </li>
                  );
                })
              ) : (
                <li>
                  <span>No conditions declared</span>
                </li>
              )}
            </ul>

          </div>
        )}
      </div>
    );
  }
}
