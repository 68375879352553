import React from 'react';
import { forEach } from 'lodash';
import DocumentVersionSelector from './DocumentVersionSelector';
import DocumentDropdownActions from './DocumentDropdownActions';
import SupersedingDocument from './SupersedingDocument';
import Button from '../../../../../common/components/Button/Button';
import { Modal } from 'react-bootstrap';
import { FormattedDate } from 'react-intl';

class DocumentListItemController extends React.Component {
  state = {
    selectedDocument: null,
    showSupersedingModal: false,
  };

  selectDocument = (document) => {
    this.setState({
      selectedDocument: document,
    });
  };

  openSupersidingModal = () => {
    this.setState({
      showSupersedingModal: true,
    });
  };

  closeSupersidingModal = () => {
    this.setState({
      showSupersedingModal: false,
    });
  };

  renderDocumentDates = (document) => {
    return (
      <span>
        From: <FormattedDate value={document.from_date} />
        {document.to_date && (
          <span>
            {' '}
            - To: <FormattedDate value={document.to_date} />
          </span>
        )}
      </span>
    );
  };

  renderInsurerLogo = (documentName) => {
    const { insurerList } = this.props;
    let activeInsurer = null;

    forEach(insurerList, (insurer) => {
      if (
        documentName.indexOf(insurer.id) !== -1 ||
        documentName.indexOf(insurer.name) !== -1
      ) {
        activeInsurer = insurer;
      }
    });

    if (!activeInsurer) {
      return null;
    }

    return (
      <div className="document-insurer-logo">
        <img src={activeInsurer.logo} alt={activeInsurer.name} />
      </div>
    );
  };

  render() {
    const { documentList } = this.props;
    const { selectedDocument, showSupersedingModal } = this.state;
    const displaySelectedDocument = selectedDocument ? selectedDocument : documentList[0];
    const isDocumentActive = displaySelectedDocument.id === documentList[0].id;

    return (
      <div className="document-container-section overflow-visible">
        <div className="document-content">
          <span className="pull-left">
            {displaySelectedDocument.attributes.name}
            {this.renderInsurerLogo(displaySelectedDocument.attributes.name)}
          </span>
          <div className="pull-right">
            <DocumentVersionSelector
              documentList={documentList}
              isDocumentActive={isDocumentActive}
              selectedDocument={displaySelectedDocument}
              onSelectDocument={this.selectDocument}
            />
            <DocumentDropdownActions document={displaySelectedDocument} />
          </div>
          <div className="superseded-documents">
            <h5>Superseding Documents:</h5>
            {displaySelectedDocument.supersededBy.length > 0 ? (
              <div>
                <Button className="btn-blue" handleClick={this.openSupersidingModal}>
                  View Documents ({displaySelectedDocument.supersededBy.length})
                </Button>
              </div>
            ) : (
              <div className="no-documents-alert">
                <span>No superseding document to show...</span>
              </div>
            )}
            <Modal
              bsSize="large"
              show={showSupersedingModal}
              onHide={this.closeSupersidingModal}
            >
              <Modal.Header closeButton>
                <Modal.Title>Superseding Documents</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="modal-body-with-max-height">
                  {displaySelectedDocument.supersededBy.map((supersededBy, key) => {
                    if (
                      supersededBy.attributes.version_number ===
                      displaySelectedDocument.attributes.version_number
                    ) {
                      return (
                        <div
                          className="document-container-section overflow-visible"
                          key={key}
                        >
                          <div className="document-content">
                            <h5 className="pull-left">
                              {supersededBy.attributes.name} - V
                              {supersededBy.attributes.version_number} - (
                              {this.renderDocumentDates(supersededBy.attributes)})
                            </h5>
                            <SupersedingDocument document={supersededBy} />
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button handleClick={this.closeSupersidingModal}>Close</Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

export default DocumentListItemController;
