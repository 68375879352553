import React from 'react';
import { Col, Row } from 'react-bootstrap';
import FaFilePdf from 'react-icons/lib/fa/file';
import DocumentListItemController from './DocumentListItemController';
import { map, forEach, orderBy } from 'lodash';

class DocumentListItem extends React.Component {
  state = {
    moreInfo: false,
    openEditInfoModal: false,
  };

  showInsurerInfo = () => {
    this.setState({
      moreInfo: true,
    });
  };

  hideInsurerInfo = () => {
    this.setState({
      moreInfo: false,
    });
  };

  renderMoreInfoContent = () => {
    const { documentList, insurerList } = this.props;

    // creates a list of empty arrays with key === document name
    const documentVersionedList = documentList.reduce((acc, curr) => {
      acc[curr.attributes.name] = [];
      return acc;
    }, {});

    // push documents to array above
    map(documentList, (document) => {
      forEach(documentVersionedList, (docArr, docName) => {
        if (document.attributes.name === docName) {
          documentVersionedList[docName].push(document);
        }
      });
    });

    return map(documentVersionedList, (documentList, key) => {
      const docListOrdered = orderBy(
        documentList,
        ['attributes.version_number'],
        ['desc'],
      );
      return (
        <Col sm={12} key={key}>
          <DocumentListItemController
            insurerList={insurerList}
            documentList={docListOrdered}
          />
        </Col>
      );
    });
  };

  render() {
    const { name } = this.props;
    const { moreInfo } = this.state;

    return (
      <div
        className={`document-container-section ${!moreInfo && 'section-collapsed'}`}
        onClick={!moreInfo ? this.showInsurerInfo : null}
      >
        <div className="document-content">
          <h4>
            <FaFilePdf /> {name}
          </h4>
          {moreInfo && (
            <div className="animated fadeIn margin-bottom">
              <div className="form-horizontal">
                <Row>{this.renderMoreInfoContent()}</Row>
              </div>
            </div>
          )}
        </div>
        {moreInfo && (
          <div onClick={this.hideInsurerInfo} className="close-document-container">
            Close
          </div>
        )}
      </div>
    );
  }
}

export default DocumentListItem;
