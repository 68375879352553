import React, { Component } from 'react';
import './StatsSectionTable.scss';
import displayCurrency from '../../../../helpers/displayCurrency';

export default class StatsSectionTable extends Component {
  render() {
    const { stats, range } = this.props;

    let displayStats;
    let displayPremiumStats;

    if (range === 'Today') {
      displayPremiumStats = stats.premiums.day;
    }

    if (range === 'This week') {
      displayPremiumStats = stats.premiums.week;
    }

    if (range === 'This month') {
      displayPremiumStats = stats.premiums.month;
    }

    if (range === 'Yesterday') {
      displayPremiumStats = stats.premiums.yesterday;
    }

    displayStats = displayPremiumStats;

    return (
      <div className="stats-section-table">
        <table className="table-dashboard">
          <tbody>
            <tr>
              <td className="caption">Initial Premium</td>
              <td>{displayCurrency(displayStats ? displayStats.IP : 0)}</td>
            </tr>
            <tr>
              <td className="caption">Additional Premium</td>
              <td>{displayCurrency(displayStats ? displayStats.AP : 0)}</td>
            </tr>
            <tr>
              <td className="caption">Return Premium</td>
              <td>{displayCurrency(displayStats ? displayStats.RP : 0)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
