import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { RadioGroup, SelectField } from '../../../../common/components/ReduxFormField';
import {
  Button,
  Modal,
  DatePickerDob,
  HorizontalFormControl,
  SelectCurrency,
  SelectBroker,
} from '../../../../common/components';
import { openModal, closeModal } from '../../../../common/components/Modal/ModalRedux';
import { savePaymentsAndReceipts, cachePremiums, cacheTransactions } from '../../redux/accountActions';
import { get, set, startCase } from 'lodash';
import moment from 'moment/moment';
import SelectCashAccount from '../../../../common/components/Selects/SelectCashAccount';
import { bindActionCreators } from 'redux';

const FORM_NAME = 'makeReceiptForm';

const validate = (values) => {
  const errors = {};

  if (!get(values, 'meta.cash_account')) {
    set(errors, 'meta.cash_account', 'Account is required');
  }

  if (!get(values, 'meta.ledger_entries[0].transaction_type')) {
    set(
      errors,
      'meta.ledger_entries[0].transaction_type',
      'Transaction type is required',
    );
  }

  if (!get(values, 'meta.ledger_entries[0].description')) {
    set(errors, 'meta.ledger_entries[0].description', 'Description is required');
  }

  if (!get(values, 'meta.ledger_entries[0].date')) {
    set(errors, 'meta.ledger_entries[0].date', 'Date is required');
  }

  if (!get(values, 'meta.ledger_entries[0].amount')) {
    set(errors, 'meta.ledger_entries[0].amount', 'Amount is required');
  }

  return errors;
};

class MakeReceiptButton extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
    broker: PropTypes.object,
    amount: PropTypes.number,
    callback: PropTypes.func,
    label: PropTypes.string,
    block: PropTypes.bool,
    formValues: PropTypes.object,
  };

  componentWillMount() {
    const { dispatch, change } = this.props;

    dispatch(change('data.attributes.currency', 'GBP'));
  }

  componentWillUpdate(newProps) {
    const { actions, submitSucceeded, callback } = this.props;
    if (newProps.submitSucceeded && !submitSucceeded) {
      if (callback) {
        callback('');
      }
      actions.closeModal(FORM_NAME);
      this.handleClose();
    }
  }

  handleSubmit(values) {
    const { resource, actions, filters } = this.props;
    return actions.savePaymentsAndReceipts(values, resource.data.id, filters);
  }

  handleOpen() {
    const { dispatch, change, reset, amount, actions, matchedTransactions, label } = this.props;

    actions.cacheTransactions(matchedTransactions);
    dispatch(reset());
    actions.openModal(FORM_NAME);
    let transactionType = label;
    if (amount !== 0) {
      transactionType = amount < 0 ? 'payment' : 'receipt';
    }
    dispatch(
      change(
        'meta.ledger_entries[0].transaction_type',
        transactionType,
      ),
    );
    dispatch(change('meta.ledger_entries[0].amount', Math.abs(amount).toFixed(2)));
  }

  handleClose() {
    const { dispatch, reset } = this.props;
    dispatch(reset());
  };

  render() {
    const { label, handleSubmit, submitting, block, resource: { data: { id } }, formValues } = this.props;
    const title = startCase(get(formValues, 'transaction_type') || label);
    const paymentTypes = [
      { label: 'BACS', value: 'BACS' },
      { label: 'Cheque', value: 'Cheque' },
    ];

    const submitButton = (
      <Button type="submit" bsStyle="primary" isLoading={submitting} label="Save"/>
    );

    return (
      <div>
        <Button
          className="matching-sidebar-btn"
          bsStyle="primary"
          label={title}
          handleClick={::this.handleOpen}
          block={block}
          isLoading={submitting}
        />
        <Modal
          name={FORM_NAME}
          title={title}
          close
          handleSubmit={handleSubmit(::this.handleSubmit)}
          onHide={::this.handleClose}
          footer={submitButton}
        >
          <Field
            name="meta.cash_account"
            label="Cash Account"
            labelSize={3}
            component={SelectCashAccount}
          />
          <Field
            id="transactionType"
            label={'Transaction Type'}
            labelSize={3}
            name={'meta.ledger_entries[0].transaction_type'}
            component={RadioGroup}
            inline
            options={[
              { label: 'Receipt', value: 'receipt' },
              { label: 'Payment', value: 'payment' },
            ]}
          />
          <Field
            name={'meta.ledger_entries[0].description'}
            label="Description"
            labelSize={3}
            component={HorizontalFormControl}
          />
          <Field
            async
            name={'meta.ledger_entries[0].broker'}
            key={'select-broker-' + id}
            label="Broker"
            labelSize={3}
            component={SelectBroker}
            filters={{ account: id }}
            isClearable
          />
          <hr/>
          <Field
            name={'meta.ledger_entries[0].date'}
            label="Date"
            labelSize={3}
            maxDate={moment().format()}
            component={DatePickerDob}
          />
          <Field
            name={'meta.ledger_entries[0].payment_type'}
            label="Type"
            labelSize={3}
            options={paymentTypes}
            component={SelectField}
          />
          <Field
            name={'meta.ledger_entries[0].currency'}
            label="Currency"
            labelSize={3}
            component={SelectCurrency}
            defaultValue={{ value: 'GBP', label: 'Pound sterling' }}
          />
          <Field
            name={'meta.ledger_entries[0].amount'}
            label="Amount"
            labelSize={3}
            currency
            component={HorizontalFormControl}
          />
        </Modal>
      </div>
    );
  }
}

const form = reduxForm({ form: FORM_NAME, validate })(MakeReceiptButton);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state, props) => {
  const values = selector(
    state,
    'meta.ledger_entries[0]',
  );
  return {
    initialValues: {
      meta: {
        cash_account: '',
        ledger_entries: [
          {
            account: props.resource.data.id,
            description: '',
            date: '',
            payment_type: '',
            transaction_type: null,
            currency: 'GBP',
            amount: 0,
            metadata: {
              match_item: true,
            },
          },
        ],
      },
    },
    formValues: values,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      { savePaymentsAndReceipts, cacheTransactions, cachePremiums, openModal, closeModal },
      dispatch,
    ),
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(form);
