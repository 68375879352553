import React from 'react';
import { Form, Field, reduxForm } from 'redux-form';
import { Row, Col } from 'react-bootstrap';
import {
  HorizontalFormControl,
  InfoLabel,
  Button,
} from '../../../../common/components';
import { map, startCase } from 'lodash';
import ProductContainer from '../ProductContainer/ProductContainer';

class ExcessForm extends React.Component {
  componentWillMount() {
    const { dispatch, change, scheme } = this.props;

    if (scheme) {
      {
        map(scheme.excess.standardExcess, (excess, key) => {
          return dispatch(change(`meta.standard_excess.${key}`, excess));
        });
      }
    }
  }

  render() {
    const { handleSubmit, scheme, viewOnly } = this.props;

    return (
      <ProductContainer>
        <Form onSubmit={handleSubmit}>
          <h2>Standard Excess</h2>
          <Row>
            {map(scheme.excess.standardExcess, (excess, key) => {
              return (
                <Col key={key} sm={4}>
                  <div className="section-title join">{startCase(key)}</div>
                  <div className="product-container-section join">
                    <div className="row form-horizontal section-item">
                      <div className="col-sm-12">
                        <Field
                          name={`meta.standard_excess.${key}`}
                          labelSize={6}
                          label={'Excess:'}
                          component={viewOnly ? InfoLabel : HorizontalFormControl}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
          {!viewOnly && (
            <Row>
              <Col sm={12}>
                <Button bsStyle="primary" className="pull-right" type="submit">
                  Save Changes
                </Button>
              </Col>
            </Row>
          )}
        </Form>
      </ProductContainer>
    );
  }
}

export default reduxForm({
  form: 'ExcessForm',
})(ExcessForm);
