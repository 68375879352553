import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loader, Button } from '../../../common/components';
import InternalContainer from '../../layout/containers/Internal';
import { actions } from '../redux/ProductReducer';
import { get, filter } from 'lodash';
import ProductFrontPage from '../components/ProductFrontPage/ProductFrontPage';
import { bindActionCreators } from 'redux';
import { InternalButtonBar } from '../../layout/components/InternalButtonBar/InternalButtonBar';

export class ActiveProductContainer extends Component {
  componentWillMount() {
    const { actions, params } = this.props;

    actions.getProducts(params.productId);
    actions.getProductsStats();
    actions.getDestinations();
    actions.getSchemeGroupList(params.productId);
  }

  componentWillUnmount() {
    const { actions } = this.props;
    actions.clearData();
  }

  render() {
    const { productManagement, params } = this.props;
    const product = get(productManagement, 'product.data');
    const destinations = get(productManagement, 'destinations.data');
    const stats = get(productManagement, 'stats.meta');
    const schemeGroupList = get(productManagement, 'schemeGroupList.data');
    const activeSchemeGroupSingle = get(productManagement, 'activeSchemeGroup.single');
    const activeSchemeGroupAnnual = get(productManagement, 'activeSchemeGroup.annual');
    const schemeGroupListSingle =
      schemeGroupList &&
      filter(schemeGroupList, (item) => item.attributes.group_key === 'single');
    const schemeGroupListAnnual =
      schemeGroupList &&
      filter(schemeGroupList, (item) => item.attributes.group_key === 'annual');
    const coverLevels = get(
      product,
      'attributes.metadata.shared_scheme_configs.cover_levels',
    );
    const quotationTypes = get(product, 'attributes.metadata.quotation_types');
    const maxTripDurations = get(product, 'attributes.metadata.annual_trip_durations');
    const regions = get(product, 'attributes.metadata.regions');
    const medicalQuestions = get(
      product,
      'attributes.metadata.medical_declaration_questions',
    );
    const options = get(product, 'attributes.metadata.options');
    const definitions = get(product, 'attributes.metadata.definitions');

    return (
      <div className="admin-area">
        <InternalContainer title="Product management">
          {productManagement.isLoading || !productManagement.product ? (
            <Loader />
          ) : (
            <div>
              <ProductFrontPage
                stats={stats && stats[product && product.attributes.product_code]}
                params={params}
                regions={regions}
                product={product}
                options={options}
                definitions={definitions}
                coverLevels={coverLevels}
                destinations={destinations}
                quotationTypes={quotationTypes}
                maxTripDurations={maxTripDurations}
                medicalQuestions={medicalQuestions}
                schemeGroupList={schemeGroupList}
                schemeGroupListAnnual={schemeGroupListAnnual}
                schemeGroupListSingle={schemeGroupListSingle}
                activeSchemeGroupAnnual={activeSchemeGroupAnnual}
                activeSchemeGroupSingle={activeSchemeGroupSingle}
                {...this.props}
              />
              <InternalButtonBar buttons={[
                {
                  permissions: ['scheme.edit'],
                  button: (
                    <Button link to={'/admin/products/' + product.id + '/edit'} label={'Edit'}/>
                  )
                }
              ]}/>
            </div>
          )}
        </InternalContainer>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    productManagement: state.productManagement,
  }),
  (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
  }),
)(ActiveProductContainer);
