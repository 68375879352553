import React from 'react';
import { map } from 'lodash';
import { Col, Row } from 'react-bootstrap';
import DynamicDocumentListItem from './components/DynamicDocumentListItem';
import './DynamicDocumentList.scss';

class DynamicDocumentList extends React.Component {
  state = {
    activeItem: null,
  };

  renderDocumentList = () => {
    const { productManagement, isLoading, params, router, actions } = this.props;
    const { activeItem } = this.state;
    const dynamicDocuments = productManagement.dynamicDocuments;

    // creates the versional-document-list
    const dynamicDocumentTypes = dynamicDocuments.list.reduce((acc, curr) => {
      acc[curr.attributes.name] = [];
      return acc;
    }, {});

    map(dynamicDocuments.list, (document) => {
      dynamicDocumentTypes[document.attributes.name].push(document);
    });

    return map(dynamicDocumentTypes, (documents, name) => {
      if (
        name.match(
          /(Broker Application Form|Toba|Broker Statement|Global Document Content)/,
        )
      ) {
        return null;
      }

      return (
        <div className="animated fadeIn" key={name}>
          <DynamicDocumentListItem
            index={name}
            router={router}
            insurerList={[]}
            actions={actions}
            documents={documents}
            isLoading={isLoading}
            activeItem={activeItem}
            productId={params.productId}
            onSubmit={this.handleSubmit}
            onSelectItem={this.handleSelectItem}
          />
        </div>
      );
    });
  };

  render() {
    return (
      <Row>
        <Col sm={12}>{this.renderDocumentList()}</Col>
      </Row>
    );
  }

  handleSelectItem = (index) => {
    this.setState({ activeItem: index });
  };

  handleSubmit = (formValues) => {
    const { onUpdate } = this.props;
    onUpdate(formValues);
  };
}

export default DynamicDocumentList;
