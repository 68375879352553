import React from 'react';
import { FormattedDate } from 'react-intl';

export default function formatDate(cell) {
  return (
    <span>
      <FormattedDate value={new Date(cell)} />
    </span>
  );
}
