import React from 'react';

export const individuals = (
  <svg id="individual_layer_1" data-name="Layer 1" viewBox="0 0 95 95">
    <path d="M95,47.5A47.5,47.5,0,1,1,47.5,0,47.5,47.5,0,0,1,95,47.5" />
    <circle style={{ fill: '#fff' }} cx="47.54" cy="17.51" r="6.63" />
    <path
      style={{ fill: '#fff' }}
      d="M62.45,39.74a46,46,0,0,0-.85-4.69A11.65,11.65,0,0,0,59,30a11.05,11.05,0,0,0-3.66-2.66,12.62,12.62,0,0,0-2.95-.91h0l-0.58-.09a5.36,5.36,0,0,0-1.27-.16h-6a5.36,5.36,0,0,0-1.27.16l-0.58.09h0a12.62,12.62,0,0,0-2.95.91A11.05,11.05,0,0,0,36.1,30a11.65,11.65,0,0,0-2.63,5.06,46,46,0,0,0-.85,4.69c-0.69,5-1,10.6-1,10.63a2.7,2.7,0,1,0,5.39.28s0-.14,0-0.41c0.05-1,.21-3.44.49-6.19,0.14-1.37.3-2.82,0.5-4.16a17.43,17.43,0,0,1,.77-3.59,1.84,1.84,0,0,1,1.41-1.38l-1,14.67a5.5,5.5,0,0,0,0,.66c0,0.09,0,.17,0,0.27V77.58a3.43,3.43,0,1,0,6.86,0V54.91H49V77.58a3.43,3.43,0,1,0,6.86,0V50.51c0-.09,0-0.18,0-0.27a5.5,5.5,0,0,0,0-.66l-1-14.67a1.84,1.84,0,0,1,1.41,1.38A17.43,17.43,0,0,1,57,39.89c0.2,1.34.36,2.79,0.5,4.16C57.8,46.8,58,49.28,58,50.24c0,0.27,0,.41,0,0.41a2.7,2.7,0,1,0,5.39-.28S63.14,44.76,62.45,39.74Z"
    />
  </svg>
);

export default individuals;
