import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PayMethodButtons } from './components/PayMethodButtons';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { CheckboxGroup, SelectField } from '../../../../common/components/ReduxFormField';
import { Col, Row, Form } from 'react-bootstrap';
import {
  HorizontalFormControl,
  Button,
  Modal,
  InfoButton,
  DatePickerDob,
} from '../../../../common/components';
import { openModal } from '../../../../common/components/Modal/ModalRedux';
import {
  issuePolicy,
  saveQuotes,
  patchQuotes,
  getPremiumIndicationFromApplication,
  setPremiumIndication,
  isSaving,
  submitSucceeded,
  saveQuotesOnly,
  // getMTAPremiumIndicationFromApplication,
} from '../../redux/productActions';
import displayCurrency from '../../../../helpers/displayCurrency';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import * as productActions from '../../redux/productActions';
import Markdown from 'react-commonmark';
import { get, map, isEmpty, cloneDeep, set, size } from 'lodash';
import moment from 'moment';
import 'moment/locale/en-gb';
import './styles.scss';
import axios from 'axios';
import Communications from '../Communications/Communications';
import PhoneFormControl from '../../../../common/components/PhoneFormControl/PhoneFormControl';
import Marketing from '../Marketing/Marketing';
import lessThan from '../../../../helpers/lessThan';

import * as luhn from 'luhn';
import ExpiryDateFields from '../../../../common/components/ReduxFormField/ExpiryDateFields';

const isLuhnHappy = (value) => {
  const val = value && value.replace(/\s/g, '');
  return val && luhn.validate(val) ? null : 'This is not a valid card number';
};

const FORM_NAME = 'issueForm';

class IssueButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      premium: null,
      currency: null,
      loading: false,
      previousCards: [],
      application: null,
      applicationErrors: [],
      Napplication: null,
      isSaving: false,
    };

    this.setPremium = this.setPremium.bind(this);
    this.handleWindowMessage = this.handleWindowMessage.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.transformDocLinks = this.transformDocLinks.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleHide = this.handleHide.bind(this);

    this.renderCommunicationBox = this.renderCommunicationBox.bind(this);
    this.renderMarketingBox = this.renderMarketingBox.bind(this);
    this.renderDeclaration = this.renderDeclaration.bind(this);
    this.renderUpsellOptions = this.renderUpsellOptions.bind(this);
    this.recalculateButton = this.recalculateButton.bind(this);
    this.isUpSell = this.isUpSell.bind(this);
  }

  handleHide() {
    const { dispatch } = this.props;
    this.setState({ isSaving: false });
    dispatch(setPremiumIndication([]));
  }

  recalculateButton(event, newValue) {
    const { schemeId, application, dispatch, formValues } = this.props;
    const getRegion =
      event === 'region' ? newValue : get(formValues, 'meta.cache.region');
    const getStartDate =
      event === 'startDate'
        ? newValue
        : get(
        formValues,
        'meta.cache.start_date',
        get(application, 'data.attributes.starts_at'),
        );
    const getMaxTripDuration =
      event === 'maxTripDuration'
        ? newValue
        : get(formValues, 'meta.cache.max_trip_duration');
    dispatch(
      getPremiumIndicationFromApplication(
        schemeId,
        application,
        getRegion,
        getStartDate,
        getMaxTripDuration,
      ),
    );
  }

  renderUpsellOptions() {
    const { product, coverLevel } = this.props;

    if (!this.isUpSell()) {
      return;
    }

    const regionOptions = get(product, 'data.attributes.metadata.regions');
    const maxTripOptions = get(product, 'data.attributes.metadata.annual_trip_durations');

    const regionOptionsToReact = map(regionOptions, (val, key) => {
      return {
        label: val,
        value: key,
      };
    });

    const maxTripDurationToReact = map(maxTripOptions, (val, key) => {
      return {
        label: val,
        value: key,
      };
    });

    let optionsFiltered = [...maxTripDurationToReact];

    if (coverLevel === 'essential') {
      optionsFiltered = [
        {
          label: '24 Days',
          value: 24,
        },
      ];
    }

    return (
      <div style={{ marginBottom: '10px' }}>
        <div className="hr" style={{ marginTop: '15px', marginBottom: '15px' }}/>

        <div className="form-horizontal">
          <div className="confirm-text">Please confirm the following:</div>
          <Row>
            <Field
              label="Region"
              labelSize={3}
              onChange={(props, newValue) => this.recalculateButton('region', newValue)}
              name="meta.cache.region"
              component={SelectField}
              options={regionOptionsToReact}
            />
            <Field
              label={'Start Date'}
              labelSize={3}
              onChange={(props, newValue) =>
                this.recalculateButton('startDate', newValue)
              }
              name="meta.cache.start_date"
              maxDate={moment()
                .add(31, 'days')
                .format()}
              component={DatePickerDob}
            />
          </Row>
          {size(maxTripDurationToReact) > 1 && (
            <Field
              label="Max Trip Duration"
              labelSize={3}
              onChange={(props, newValue) =>
                this.recalculateButton('maxTripDuration', newValue)
              }
              name="meta.cache.max_trip_duration"
              component={SelectField}
              options={optionsFiltered}
            />
          )}
        </div>
      </div>
    );
  }

  renderCard() {
    const { formValues } = this.props;
    const isPreviousCard = get(formValues, 'meta.previous_card', false);

    return (
      <div className="fadein-fields form-horizontal">
        {!isPreviousCard && (
          <div className="card-fields-container">
            <Field
              className="text-left"
              name={'meta.card_number'}
              label="Card number"
              id={'meta.card_number'}
              type="cleave"
              cleaveOptions={{ creditCard: true }}
              labelSize={3}
              mdFieldSize={6}
              component={HorizontalFormControl}
              validate={isLuhnHappy}
            />
            <Field
              className="text-left"
              name={'meta.cvv'}
              mdFieldSize={3}
              label="CVC/CV2"
              normalize={lessThan(3)}
              labelSize={3}
              infoButton={<InfoButton content={definitions.cvc}/>}
              component={HorizontalFormControl}
            />
            <ExpiryDateFields
              baseName={'meta'}
              label="Expiry date"
              labelSizes={{ sm: 3, md: 3, lg: 3 }}
              fieldSizes={{ sm: 6, md: 6, lg: 6 }}
            />
          </div>
        )}
      </div>
    );
  }

  setPremium(application, openAfter = true, errors = []) {
    const { dispatch, change, mta, product } = this.props;
    let schemeId = this.props.schemeId;

    if (application) {
      const premiums = getIncludedResource(
        application.data,
        application.included,
        'premiums',
      );

      const customer = getIncludedResource(
        application.data,
        application.included,
        'customer',
      );
      const validationErrors = get(application, 'data.attributes.validation', false);
      let selectedPremium = null;

      // Populate dates
      if (get(product, 'data.attributes.metadata.adjustable_end_date', false)) {
        if (get(application, 'data.attributes.ends_at')) {
          dispatch(change('meta.start_date', application.data.attributes.starts_at));
          dispatch(change('meta.end_date', application.data.attributes.ends_at));
        } else {
          const startDate = moment().format();
          dispatch(change('meta.start_date', startDate));
          dispatch(
            change(
              'meta.end_date',
              moment(startDate)
                .add(1, 'years')
                .subtract(1, 'days')
                .format(),
            ),
          );
        }
      }

      if (premiums.length) {
        if (premiums.length === 1) {
          selectedPremium = premiums[0];
          dispatch(change('data.relationships.premium.data.id', premiums[0].id));
          dispatch(change('meta.amount', premiums[0].attributes.gross));
        } else {
          premiums.map((premium) => {
            if (
              get(premium, 'attributes.scheme.id') === schemeId ||
              get(premium, 'attributes.premium_type') === 'return premium'
            ) {
              selectedPremium = premium;
              dispatch(change('data.relationships.premium.data.id', premium.id));
              dispatch(change('meta.amount', premium.attributes.gross));
            }
          });
        }
      }

      if (customer) {
        dispatch(change('meta.sms_number', get(customer, 'data.attributes.phone1', '')));
      }

      if (validationErrors) {
        Object.keys(validationErrors).map((errorKey) => {
          if (!validationErrors[errorKey]) {
            errors.push(errorKey + ' required');
          }
        });
      }

      this.setState({
        premium: selectedPremium,
        currency: get(selectedPremium, 'attributes.currency'),
        previousCards: mta ? get(customer, 'data.attributes.valid_cards', []) : [],
        loading: false,
        application,
      });
    }

    this.setState({ loading: false, applicationErrors: errors });

    if (openAfter) {
      dispatch(openModal(FORM_NAME + schemeId));
    }
  }

  saveQuoteOnly(application) {
    const { product, dispatch } = this.props;
    dispatch(submitSucceeded(false));
    dispatch(isSaving(true));
    return dispatch(
      saveQuotesOnly(application, product, (values) => {
        this.setState(() => ({
          application: values,
        }));

        dispatch(submitSucceeded(true));
        dispatch(isSaving(false));
      }),
    );
  }

  isUpSell() {
    const { application, schemeType } = this.props;
    const ANNUAL_KEY = 'Annual Multi-trip';
    const isSingle =
      get(application, 'data.attributes.metadata.scheme_type') === 'single';
    const isSchemeAnnual = schemeType === ANNUAL_KEY;
    const hasDestination = !isEmpty(
      get(application, 'data.attributes.metadata.destinations'),
    );
    return isSingle && hasDestination && isSchemeAnnual;
  }

  handleSubmit(values) {
    const { dispatch, application } = this.props;

    const getRegion = get(values, 'meta.cache.region');
    const getStartDate = get(
      values,
      'meta.cache.start_date',
      get(application, 'data.attributes.starts_at'),
    );
    const getMaxTripDuration = get(values, 'meta.cache.max_trip_duration');

    const clonedApplication = cloneDeep(application);
    let selectedPremium = null;

    if (this.isUpSell()) {
      dispatch(isSaving(true));

      set(clonedApplication, 'data.attributes.metadata.region', getRegion);
      set(clonedApplication, 'data.attributes.metadata.scheme_type', 'annual');
      set(clonedApplication, 'data.attributes.metadata.end_date', '');
      set(clonedApplication, 'data.attributes.metadata.start_date', getStartDate);
      set(
        clonedApplication,
        'data.attributes.metadata.max_trip_duration',
        getMaxTripDuration,
      );
      set(clonedApplication, 'data.attributes.metadata.destinations', '');
      set(clonedApplication, 'data.relationships.schemes.data', [
        { type: 'products/schemes', id: this.props.schemeId },
      ]);

      this.setState({ isSaving: true });

      Promise.resolve(
        dispatch(
          patchQuotes(clonedApplication, (values) => {
            this.setState({
              Napplication: values,
            });
          }),
        ),
      ).then(() => {
        dispatch(isSaving(false));
        const premiums = getIncludedResource(
          this.state.Napplication.data,
          this.state.Napplication.included,
          'premiums',
        );
        const cloneRes = cloneDeep(this.state.Napplication);
        const getPremiumId = this.state.Napplication.data.relationships.premiums.data[0]
          .id;
        set(cloneRes, 'data.relationships.premium.data.id', getPremiumId);
        set(cloneRes, 'meta', values.meta);
        // WIP: consider to send the values.meta instead.
        if (premiums.length) {
          if (premiums.length === 1) {
            selectedPremium = premiums[0];
            set(cloneRes, 'meta.amount', selectedPremium.attributes.gross);
          }
        }
        return this.props
          .dispatch(issuePolicy(this.state.Napplication.data.id, cloneRes))
          .then(() => {
            this.setState({
              isSaving: false,
            });
          });
      });
    } else {
      if (!this.state.application || !this.state.application.data.id) {
        return false;
      }
      return this.props.dispatch(issuePolicy(this.state.application.data.id, values));
    }
  }

  handleOpen() {
    const {
      dispatch,
      reset,
      saveApplication,
      product,
      mta,
      policyId,
      application,
      change,
    } = this.props;
    this.setState({ isSaving: false });
    dispatch(setPremiumIndication([]));
    dispatch(reset());

    if (this.isUpSell()) {
      dispatch(change('meta.cache.isUpsell', true));
    } else {
      dispatch(change('meta.cache.isUpsell', false));
    }

    if (saveApplication) {
      this.setState({ loading: true, premium: null, previousCards: [], errors: [] });
      const applicationId = this.state.application
        ? this.state.application.data.id
        : null;

      return dispatch(
        saveApplication((values) => {
          if (mta && policyId && !get(values, 'data.relationships.policy.data.id')) {
            set(values, 'data.relationships.policy.data.id', policyId);
          }

          if (applicationId) {
            set(values, 'data.id', applicationId);
          }

          dispatch(
            applicationId
              ? patchQuotes(values, this.setPremium)
              : saveQuotes(values, product, this.setPremium),
          );
        }),
      );
    } else {
      this.setPremium(application);
    }
  }

  transformDocLinks(url) {
    const {
      schemeId,
      productState: { quotes },
      application,
    } = this.props;

    let documents = {};
    if (application) {
      const applicationDocuments = getIncludedResource(
        application.data,
        application.included,
        'documents',
      );

      applicationDocuments.forEach((document) => {
        documents[document.attributes.short_name] = document.attributes.file_path;
      });
    } else {
      const currentQuoteId = Object.keys(quotes).filter((quoteId) => {
        return get(quotes[quoteId], 'meta.scheme.id') === schemeId;
      });

      documents = get(quotes[currentQuoteId], 'meta.scheme.documents');
    }

    if (url === 'policy_wording') {
      return get(documents, 'Policy Wording', url);
    } else if (url === 'policy_summary') {
      return get(documents, 'Policy Summary', url);
    } else if (url === 'tobca') {
      return get(documents, 'Terms of Business Customer Agreement', url);
    }

    return url;
  }

  componentDidMount() {
    if (process.env.BROWSER) {
      window.addEventListener('message', this.handleWindowMessage);
    }
  }

  componentWillUnmount() {
    const { dispatch, product } = this.props;

    if (process.env.BROWSER) {
      window.removeEventListener('message', this.handleWindowMessage);
    }

    if (product.isCompletingTransaction) {
      dispatch(productActions.transactionEmergencyBrakes());
    }
  }

  handleWindowMessage(event) {
    const { dispatch } = this.props;

    // For Chrome, the origin property is in the event.originalEvent object.
    const origin = event.origin || event.originalEvent.origin;

    if (origin !== axios.defaults.baseURL) {
      return;
    }

    const data = event.data;

    if (data.status !== 'complete') {
      dispatch(productActions.failTransaction(data.message));
      return;
    }

    dispatch(productActions.completeTransaction(data.policies[0].policy_id));
  }

  renderMarketingBox() {
    return (
      <div className="issue-policy-button-section">
        {/*<div className='issue-policy-title'>*/}
        {/*Marketing Preferences*/}
        {/*</div>*/}
        <Marketing
          label="Marketing"
          name="data.attributes.metadata.adults[0].marketing_options"
          formValues={this.props.formValues}
          component={Marketing}
          dispatch={this.props.dispatch}
          change={this.props.change}
          showText
        />
      </div>
    );
  }

  renderCommunicationBox() {
    const { formValues } = this.props;
    return (
      <div className="issue-policy-button-section">
        <Communications name={'data.attributes.metadata'}/>
        {get(formValues, 'data.attributes.metadata.communications.sms') && (
          <Field
            name={'meta.sms_number'}
            label="Phone number"
            labelSize={2}
            component={PhoneFormControl}
          />
        )}
      </div>
    );
  }

  renderDeclaration() {
    const { declaration } = this.props;
    return (
      <div className="issue-policy-button-section">
        <div className="issue-policy-button-title">Declaration</div>
        {declaration ? (
          <div>
            {Object.keys(declaration).map((declarationSection, i) => (
              <div className="declaration" key={i}>
                <Markdown
                  source={declaration[declarationSection]}
                  linkTarget="_blank"
                  transformLinkUri={this.transformDocLinks}
                />
              </div>
            ))}
          </div>
        ) : (
          <ul>
            <li>The Insured is resident in the United Kingdom.</li>
            <li>Formal instructions have been received to bind cover.</li>
          </ul>
        )}
        <div className="confirm-declaration">
          <Field
            name={'meta.purchase_declaration'}
            inline
            options={[
              {
                label: 'Please tick to confirm you agree to the above declaration',
                value: true,
              },
            ]}
            component={CheckboxGroup}
          />
        </div>
      </div>
    );
  }

  render() {
    const {
      schemeId,
      block,
      bsStyle,
      disabled,
      submitting,
      handleSubmit,
      productState,
    } = this.props;
    const { isSaving } = this.state;
    const title = 'Issue Policy';

    let isValid = false;
    let submitButton = <div/>;

    if (get(this.state, 'application.data.attributes.valid')) {
      isValid = true;
      submitButton = (
        <Button
          type="submit"
          bsStyle="primary"
          disabled={isSaving}
          isLoading={submitting || isSaving}
          handleClick={handleSubmit(this.handleSubmit)}
          label="Issue Policy"
        />
      );
    }

    return (
      <div className="issue-btn-wrapper">
        <Button
          className={this.props.className}
          block={block}
          rightIcon="check-square-o"
          label={title}
          bsStyle={bsStyle || 'primary'}
          disabled={this.state.loading ? this.state.loading : disabled}
          isLoading={this.state.loading}
          handleClick={this.handleOpen}
        />

        <Modal
          name={FORM_NAME + schemeId}
          title={title}
          close={!productState.isCompletingTransaction}
          footer={productState.isCompletingTransaction ? null : submitButton}
          onHide={this.handleHide}
          bsSize="lg"
        >
          {productState.isCompletingTransaction
            ? this.renderCompleteTransactionFrame()
            : this.renderForm(isValid)}
        </Modal>
      </div>
    );
  }

  renderCompleteTransactionFrame() {
    const { productState } = this.props;

    const url =
      axios.defaults.baseURL +
      '/public/transactions/' +
      productState.transactionId +
      ':pay';

    return (
      <div className="payment__container">
        <iframe src={url} className="payment__iframe"/>
      </div>
    );
  }

  renderForm(isValid) {
    const { formValues, handleSubmit, product, premiumIndication } = this.props;
    const taxType = product.data.attributes.metadata.tax_type;
    let selectedPremium = this.state.premium;
    const recalculatedPremium =
      !isEmpty(premiumIndication) && get(premiumIndication, 'meta.premium');

    const currency = this.state.currency;

    const paymentInfo =
      '**Broker Account** \n\nWill place the premium on your account with PJH, with settlement due to PJH within 30 days. \n\n**Card** \n\nAllows your client to settle premium directly to PJH by credit/debit card. Your commissions will be paid by PJH on a monthly basis.';
    const cardPayments = get(product, 'data.attributes.metadata.card_payments', true);

    const paymentOptions = [];

    if (this.props.isBrokerCheck) {
      paymentOptions.push({ label: 'Broker Account', value: 'account' });
    }

    if (cardPayments) {
      if (this.state.previousCards.length) {
        paymentOptions.push({ label: 'Previous Card', value: 'recharge' });
      }
      if (selectedPremium && selectedPremium.attributes.gross > 0) {
        paymentOptions.unshift({
          label: this.state.previousCards.length ? 'New Card' : 'Card',
          value: 'card',
        });
      }
    }

    return (
      <Form onSubmit={handleSubmit(this.handleSubmit)}>
        {this.renderCommunicationBox()}
        <div className="hr no-margin"/>
        {this.renderMarketingBox()}
        <div className="hr no-margin"/>
        <div>
          {!isValid && (
            <div className="issue-policy-button-section">
              <h4>Not ready for issuing</h4>
              {this.state.applicationErrors.length > 0 && (
                <div>
                  <p>Please resolve the following issues:</p>
                  <ul>
                    {this.state.applicationErrors.map((errorItem, i) => (
                      <li key={i}>{errorItem}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}
          {isValid && this.renderDeclaration()}
          {isValid && this.renderUpsellOptions()}
          {isValid && selectedPremium && (
            <div>
              <div className="hr no-margin"/>
              {selectedPremium.attributes.gross !== 0.0 && (
                <div className="issue-policy-button-section">
                  <Row>
                    <Col sm={12} className="payment-container">
                      <div className="payment-details-title">Payment Details</div>
                      <div className="payment-method">
                        Please select a payment method:{' '}
                        <InfoButton content={paymentInfo}/>
                      </div>
                      <span className="total-to-pay-title">
                        Total To Pay:{' '}
                        <span className="total-to-pay-value">
                          {recalculatedPremium
                            ? displayCurrency(recalculatedPremium.gross, currency)
                            : displayCurrency(selectedPremium.attributes.gross, currency)}
                        </span>{' '}
                        Inc. {get(selectedPremium, 'attributes.rates.tax_rate')}%{' '}
                        {taxType}
                      </span>
                    </Col>
                  </Row>
                  <Field
                    name={'meta.payment_type'}
                    inline
                    options={paymentOptions}
                    component={PayMethodButtons}
                  />
                  {get(formValues, 'meta.payment_type') === 'card' &&
                  this.renderCard()}

                  {get(formValues, 'meta.payment_type') === 'recharge' && (
                    <div className="card-details form-horizontal">
                      <Field
                        name={'meta.transaction_id'}
                        label="Previous card"
                        labelSize={3}
                        options={this.state.previousCards.map((previous) => {
                          return {
                            label: 'XXXX-XXXX-XXXX-' + previous.card,
                            value: previous.transaction_id,
                          };
                        })}
                        component={SelectField}
                      />
                      <Field
                        name={'meta.cvv'}
                        label="CVC/CV2"
                        labelSize={3}
                        component={HorizontalFormControl}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </Form>
    );
  }
}

const validate = (values) => {
  const errors = {};

  if (get(values, 'meta.cache.isUpsell') === true) {
    if (!get(values, 'meta.cache.region', false)) {
      set(errors, 'meta.cache.region', 'Required');
    }
    if (!get(values, 'meta.cache.max_trip_duration', false)) {
      set(errors, 'meta.cache.max_trip_duration', 'Required');
    }
  }

  if (!get(values, 'meta.purchase_declaration', false)) {
    set(errors, 'meta.purchase_declaration', 'Required');
  }

  if (
    parseFloat(get(values, 'meta.amount')) !== 0.0 &&
    !get(values, 'meta.payment_type', false)
  ) {
    set(errors, 'meta.payment_type', 'Required');
  }

  if (get(values, 'meta.payment_type', false) === 'card') {
    if (!get(values, 'meta.card_number', false)) {
      set(errors, 'meta.card_number', 'Required');
    }
    if (!get(values, 'meta.cvv', false)) {
      set(errors, 'meta.cvv', 'Required');
    }
    if (!get(values, 'meta.expiry_month', false)) {
      set(errors, 'meta.expiry_month', 'Required');
    }
    if (!get(values, 'meta.expiry_year', false)) {
      set(errors, 'meta.expiry_year', 'Required');
    }
  }

  if (get(values, 'meta.payment_type', false) === 'recharge') {
    if (!get(values, 'meta.transaction_id', false)) {
      set(errors, 'meta.transaction_id', 'Required');
    }
    if (!get(values, 'meta.cvv', false)) {
      set(errors, 'meta.cvv', 'Required');
    }
  }

  return errors;
};

const form = reduxForm({ form: FORM_NAME, validate })(IssueButton);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state, props) => {
  const values = selector(
    state,
    'meta',
    'meta.product_code',
    'meta.payment_type',
    'meta.sms_send',
    'meta.amount',
    'meta.start_date',
    'data.relationships',
  );

  return {
    initialValues: {
      relationships: {
        premium: {
          data: {
            type: 'premium',
            id: null,
          },
        },
      },
      meta: {
        product_code: get(props.product, 'data.attributes.product_code', ''),
        cache: {
          max_trip_duration: get(props.product, 'data.attributes.max_trip_duration', ''),
          region: null,
          start_date: null,
        },
        sms_send: false,
        start_date: '',
      },
    },
    formValues: values,
    productState: state.product,
    premiumIndication: get(state, 'product.premiumIndication'),
  };
};

const definitions = {
  cvc: 'content required',
};

export default connect(mapStateToProps)(form);
