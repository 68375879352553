import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, DataTable, Button, InfoLabel, Modal } from '../../../../common/components';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, Field } from 'redux-form';
import { refreshData } from '../../../../common/components/DataTable/redux/dataTableActions';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { openModal, closeModal } from '../../../../common/components/Modal/ModalRedux';
import { updateInsurer } from '../../redux/organisationActions';
import UploadCropComponent from '../../../../common/components/Upload/UploadCrop';
import { triggerFileUpload } from '../../../../common/components/Upload/UploadRedux';
import { TextField } from '../../../../common/components/ReduxFormField';
import { FormattedDate, FormattedTime } from 'react-intl';
import { get } from 'lodash';
import { Row, Col, ControlLabel } from 'react-bootstrap';

const FORM_NAME = 'insurerForm';

class OrganisationInsurers extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
    formValues: PropTypes.object.isRequired,
  };

  formatDate(cell) {
    return (
      <span>
        <FormattedDate value={new Date(cell)} /> <FormattedTime value={new Date(cell)} />
      </span>
    );
  }

  componentWillUpdate(newProps) {
    const { dispatch, submitSucceeded } = this.props;

    if (newProps.submitSucceeded && !submitSucceeded) {
      dispatch(refreshData('insurers'));
      dispatch(closeModal(FORM_NAME));
    }
  }

  handleSubmit(values) {
    return this.props.dispatch(updateInsurer(values, this.props.resource));
  }

  handleUpload() {
    const { dispatch } = this.props;

    dispatch(triggerFileUpload());
  }

  handleUploadComplete(data) {
    const { dispatch, change } = this.props;

    dispatch(change('data.relationships.logo_file.data', data.data));
    dispatch(change('data.attributes.logo', data.data.attributes.url));
  }

  handleRowClick(row) {
    const { dispatch, reset, change } = this.props;

    dispatch(reset());
    dispatch(change('data.id', row.id));
    dispatch(change('data.attributes.logo', row.logo));
    dispatch(
      change(
        'data.attributes.metadata.description',
        get(row, 'metadata.description', ''),
      ),
    );
    dispatch(openModal(FORM_NAME));
  }

  render() {
    const { resource, submitting, handleSubmit, formValues } = this.props;

    const title = 'Edit Insurer';
    const submitButton = (
      <div>
        <Button
          type="button"
          bsStyle="primary"
          className="pad-right"
          disabled={submitting}
          handleClick={::this.handleUpload}
        >
          {submitting ? (
            <i className="fa fa-cog fa-spin" />
          ) : (
            <i className="fa fa-image" />
          )}{' '}
          upload logo
        </Button>
        <Button
          type="submit"
          bsStyle="primary"
          disabled={submitting}
          isLoading={submitting}
          label="Save Changes"
        />
      </div>
    );

    return (
      <div>
        <Box>
          <DataTable
            source="/organisations/insurers"
            name="insurers"
            showTotal
            autoFilters={[{ type: 'filter[organisation]', value: resource.data.id }]}
            onRowSelect={this.handleRowClick.bind(this)}
          >
            <TableHeaderColumn dataField="id" isKey>
              Name
            </TableHeaderColumn>
          </DataTable>

          <Modal
            name={FORM_NAME}
            title={title}
            close
            handleSubmit={handleSubmit(::this.handleSubmit)}
            footer={submitButton}
          >
            <div className="form-horizontal">
              <InfoLabel
                label="Insurer Code"
                labelSize={3}
                value={get(formValues, 'data.id')}
              />

              <Field
                name="data.attributes.metadata.description"
                label="Description (for brochure website)"
                labelSize={3}
                type="textarea"
                component={TextField}
              />

              <Row>
                <Col sm={12} componentClass={ControlLabel}>
                  Insurer logo
                </Col>
                <Col sm={9}>
                  <img src={get(formValues, 'data.attributes.logo')} alt={'Insurer Logo'} />
                </Col>
              </Row>
            </div>

            <UploadCropComponent
              onComplete={::this.handleUploadComplete}
              width={355}
              height={238}
              bsSize="md"
              circle={false}
              minScale={30}
            />
          </Modal>
        </Box>
      </div>
    );
  }
}

const form = reduxForm({ form: FORM_NAME })(OrganisationInsurers);

const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state) => {
  const values = selector(state, 'data.id', 'data.attributes.logo');

  return {
    formValues: values,
  };
};

export default connect(mapStateToProps)(form);
