import React from 'react';

export const travelDispute = (
  <svg id="travel_dispute_layer_1" data-name="Layer 1" viewBox="0 0 95 95">
    <path className="cls-1" d="M95,47.5A47.5,47.5,0,1,1,47.5,0,47.5,47.5,0,0,1,95,47.5" />
    <path
      style={{ fill: '#fff' }}
      d="M44,69.26H14a1.82,1.82,0,0,1-1.82-1.82V65.23A1.82,1.82,0,0,1,14,63.42H44a1.82,1.82,0,0,1,1.82,1.81v2.21A1.82,1.82,0,0,1,44,69.26"
    />
    <path
      style={{ fill: '#fff' }}
      d="M39.94,62.5H18.14a1.56,1.56,0,0,1-1.56-1.56v-.81a1.56,1.56,0,0,1,1.56-1.56h21.8a1.56,1.56,0,0,1,1.56,1.56v.81a1.56,1.56,0,0,1-1.56,1.56"
    />
    <path
      style={{ fill: '#fff' }}
      d="M37.59,54.27,20.2,45.17a2.65,2.65,0,0,1-1.12-3.58l.26-.49A2.65,2.65,0,0,1,22.91,40l17.39,9.1a2.65,2.65,0,0,1,1.12,3.58l-.26.49a2.65,2.65,0,0,1-3.58,1.12"
    />
    <path
      style={{ fill: '#fff' }}
      d="M50,30.46l-17.39-9.1a2.65,2.65,0,0,1-1.12-3.58l.26-.49a2.65,2.65,0,0,1,3.58-1.12l17.39,9.1a2.65,2.65,0,0,1,1.12,3.58l-.26.49A2.65,2.65,0,0,1,50,30.46"
    />
    <path
      style={{ fill: '#fff' }}
      d="M58.84,49c6.4,2.58,23.74,14.09,23.74,14.09l.45.23a2.62,2.62,0,0,0,3.54-1.11l.71-1.36a2.62,2.62,0,0,0-1.11-3.54l-.45-.23S66.39,49.42,60.62,45.63a50.65,50.65,0,0,1-4.41-4,3.55,3.55,0,0,0-4,1.75,3.4,3.4,0,0,0,.83,4.26A50.59,50.59,0,0,1,58.84,49"
    />
    <path
      style={{ fill: '#fff' }}
      d="M52.49,41.77l-8.42-4.41c-.27.44-.53.9-.77,1.36s-.46.93-.67,1.41l8.4,4.4A4.43,4.43,0,0,1,51.5,43a4.23,4.23,0,0,1,1-1.25"
    />
    <path
      style={{ fill: '#fff' }}
      d="M39.38,47.58,24.63,39.86A38,38,0,0,0,30.41,32a43.79,43.79,0,0,0,3.17-9.24l14.75,7.72a43.89,43.89,0,0,0-5.82,8,37.76,37.76,0,0,0-3.13,9.16"
    />
  </svg>
);

export default travelDispute;
