import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, FieldArray, formValueSelector, reduxForm } from 'redux-form';
import { Col, ControlLabel, FormGroup, Form, Row } from 'react-bootstrap';
import { AddressFields } from '../../../../common/components/ReduxFormField';
import PostcodeList from '../../../../common/components/Postcode/PostcodeList';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import LlpPartner from '../../../brochure/components/BrokerApplicationForm/LlpPartner';
import {
  Box,
  Button,
  HorizontalFormControl,
  SelectLegalEntityType,
  SelectOrganisationType,
} from '../../../../common/components';
import { get } from 'lodash';
import './OrganisationForm.scss';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';

const FORM_NAME = 'organisationForm';

class OrganisationForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    resource: PropTypes.object.isRequired,
    formValues: PropTypes.object,
  };

  render() {
    const {
      formValues,
      handleSubmit,
      submitting,
      resource,
      location: {
        query: { organisationTypeName },
      },
    } = this.props;
    let organisationType = getIncludedResource(
      resource.data,
      resource.included,
      'organisation_type',
    );

    const insurers = getIncludedResource(
      resource.data,
      resource.included,
      'insurers',
    );

    if (insurers.length === 0) {
      insurers.push({
        attributes: {
          code: '',
        },
      });
    }



    return (
      <Box>
        <h2 className="resource-name">{resource.data.attributes.name || 'New ' + organisationTypeName}</h2>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col sm={6}>
              <Field
                name="data.attributes.name"
                label="Name"
                labelSize={4}
                component={HorizontalFormControl}
              />

              <Field
                name="data.relationships.organisation_type.data"
                label="Organisation Type"
                isObject
                labelSize={4}
                component={SelectOrganisationType}
              />
              <Field
                name="data.attributes.phone1"
                label="Phone 1"
                labelSize={4}
                component={HorizontalFormControl}
              />
              <Field
                name="data.attributes.regulatory_authority_number"
                label="FCA Number"
                labelSize={4}
                component={HorizontalFormControl}
              />
              <Field
                name="data.attributes.phone2"
                label="Phone 2"
                labelSize={4}
                component={HorizontalFormControl}
              />
            </Col>
            <Col sm={6}>
              <Field
                name="data.relationships.legal_entity_type.data"
                label="Legal Entity Type"
                labelSize={4}
                isObject
                component={SelectLegalEntityType}
              />
              <Field
                name="data.attributes.metadata.website"
                label="Website"
                labelSize={4}
                component={HorizontalFormControl}
              />
              {(get(
                formValues,
                'data.relationships.legal_entity_type.data.attributes.code',
                ) === 'lp' ||
                get(
                  formValues,
                  'data.relationships.legal_entity_type.data.attributes.code',
                ) === 'llp') && (
                <FormGroup>
                  <Col sm={4}>
                    <ControlLabel>Partners</ControlLabel>
                  </Col>
                  <Col sm={8}>
                    <FieldArray
                      name="data.attributes.metadata.partners"
                      component={LlpPartner}
                      fieldComponent={Field}
                    />
                  </Col>
                </FormGroup>
              )}
              {get(
                formValues,
                'data.relationships.legal_entity_type.data.attributes.code',
              ) === 'soletrader' && (
                <Field
                  name="data.attributes.metadata.proprietor"
                  label="Proprietor"
                  labelSize={4}
                  component={HorizontalFormControl}
                />
              )}
              <Field
                name="data.attributes.metadata.parent_company"
                label="Parent company"
                labelSize={4}
                component={HorizontalFormControl}
              />
              <Field
                name="data.attributes.legal_entity_number"
                label="Company Number"
                labelSize={4}
                object
                component={HorizontalFormControl}
              />
              {get(
                formValues,
                'data.relationships.organisation_type.data.attributes.name',
                get(organisationType, 'data.attributes.name'),
              ) === 'Broker' && (
                <Field
                  name="data.attributes.metadata.principal_company"
                  label="Principal company"
                  labelSize={4}
                  component={HorizontalFormControl}
                />

              )}
            </Col>
          </Row>

          <div className="hr"/>
          <Row>
            <Col lg={6}>
              <PostcodeList
                name="data.attributes.address.postcode"
                label="Postcode"
                context="customer"
                formName={FORM_NAME}
                labelSizes={{ sm: 2, md: 2, lg: 4 }}
                fieldSizes={{ sm: 10, md: 10, lg: 8 }}
                postcodeFieldSizes={{ sm: 6, md: 5, lg: 4 }}
                searchBtnSizes={{ sm: 4, md: 5, lg: 4 }}
                value={get(formValues, 'data.attributes.address.postcode', '')}
              />
              <AddressFields
                baseName="data.attributes.address"
                label="Address"
                labelSizes={{ sm: 2, md: 2, lg: 4 }}
                fieldSizes={{ sm: 10, md: 10, lg: 8 }}
              />
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <InternalButtonBar
                buttons={[
                  {
                    permissions: ['organisation.edit'],
                    button: (
                      <Button
                        className="pull-right"
                        type="submit"
                        bsStyle="primary"
                        label={
                          'Save ' +
                          (organisationTypeName || 'Organisation')
                        }
                        isLoading={submitting}
                      />
                    ),
                  },
                ]}
              />
            </Col>
          </Row>
        </Form>
      </Box>
    );
  }
}

const form = reduxForm({ form: FORM_NAME })(OrganisationForm);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state, props) => {
  if (
    Array.isArray(props.resource.data.attributes.metadata) &&
    !props.resource.data.attributes.metadata.length
  ) {
    props.resource.data.attributes.metadata = {};
  }

  const initialValues = props.resource;
  const values = selector(
    state,
    'data.id',
    'data.attributes.address.postcode',
    'data.relationships.organisation_type.data',
    'data.relationships.legal_entity_type.data',
    'data.relationships.organisation_type.data',
    'data.relationships.primary_contacts.data',
    'data.relationships.accounts_contacts.data',
    'data.attributes.metadata.software_house',
    'data.attributes.metadata.partners',
    'data.attributes.metadata.proprietor',
  );

  // get included resource
  initialValues.data.relationships.insurers.data = getIncludedResource(
    props.resource.data,
    props.resource.included,
    'insurers',
  );

  if (!props.resource.data.id && props.location.query.organisationType) {
    initialValues.data.relationships.organisation_type.data.id =
      props.location.query.organisationType;
  }

  return {
    initialValues,
    formValues: values,
  };
};

export default connect(mapStateToProps)(form);
