import React from 'react';
import { Row, Col } from 'react-bootstrap';
import displayName from '../../../../../../helpers/displayName';
import { NoLabelFormControl, InfoLabel } from '../../../../../../common/components';
import { Field } from 'redux-form';
import './style.scss';
import { map, get } from 'lodash';

class OptionRatesTable extends React.Component {
  componentWillMount() {
    const { dispatch, change, rates, optionKey } = this.props;

    map(rates, (rate, key) => {
      return dispatch(change(`meta.patch.options[${optionKey}].rates.${key}`, rate));
    });
  }

  render() {
    const { name, rates, rateType, optionKey, schemeType, viewOnly } = this.props;
    const mapAges = schemeType === 'single' ? get(rates, 'uk') : get(rates, 'europe');
    return (
      <div>
        <h2>{name}</h2>
        <h6>Rate Type: {rateType}</h6>
        <div className="section-title join">
          <Row>
            <Col xs={2}>Ages</Col>
            <Col xs={10}>
              <Row>
                {map(rates, (rate, key) => {
                  return (
                    <Col key={key} xs={schemeType === 'single' ? 2 : 4}>
                      {displayName(key)}
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </div>
        <div className="product-container-section join">
          <Row className="section-item">
            <Col xs={2}>
              <div>
                {map(mapAges, (rate, key) => {
                  return (
                    <div className="option-label" key={key}>
                      {key}
                    </div>
                  );
                })}
              </div>
            </Col>
            <Col xs={10}>
              <Row className="option-row">
                {map(rates, (rate, key) => {
                  return (
                    <Col
                      key={key}
                      xs={schemeType === 'single' ? 2 : 4}
                      className="options-col"
                    >
                      {map(rate, (item, index) => {
                        return (
                          <div key={index} className="option-field">
                            <Field
                              name={`meta.patch.options[${optionKey}].rates[${key}].${index}`}
                              component={viewOnly ? InfoLabel : NoLabelFormControl}
                            />
                          </div>
                        );
                      })}
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default OptionRatesTable;
