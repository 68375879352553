import React from 'react';
import { map } from 'lodash';
import { Col, Row } from 'react-bootstrap';
import { Form, reduxForm, Field } from 'redux-form';
import { DatePicker } from '../../../../../common/components';
import TextArea from '../../../../content/components/TextArea';
import { Button } from '../../../../../common/components/Button/Button';

const isRequired = (value) => {
  if (value === false) {
    return null;
  }

  return !value || value.length === 0 ? 'This field is required.' : null;
};

class UpdateDynamicDocumentForm extends React.Component {
  componentWillMount() {
    const { document, dispatch, change, productId } = this.props;
    dispatch(change('data', document));
    dispatch(change('data.attributes.from_date', ''));
    dispatch(change('data.relationships.product.data.id', productId));
  }

  render() {
    const { handleSubmit, document, isLoading } = this.props;
    const sections = document.attributes.sections;
    return (
      <Form onSubmit={handleSubmit}>
        <Row className="mt-15">
          <Col sm={6} className="mb-15">
            <strong>Effective date:</strong>
          </Col>
          <Col sm={6}>
            <div className="pull-right">
              <Field
                validate={isRequired}
                component={DatePicker}
                shouldCloseOnSelect
                name={'data.attributes.from_date'}
              />
            </div>
          </Col>
        </Row>
        {map(sections, (item, key) => {
          return (
            <Row className="mt-15" key={key}>
              <Col sm={12} className="mb-15">
                <strong>{key}:</strong>
              </Col>
              <Col sm={12}>
                <Field component={TextArea} name={`data.attributes.sections.${key}`} />
              </Col>
            </Row>
          );
        })}
        <Row>
          <Col sm={12}>
            <Button handleClick={this.handleCancel} bsStyle="default">
              cancel
            </Button>
            <Button
              type="submit"
              bsStyle="primary"
              className="pull-right"
              isLoading={isLoading}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }

  handleCancel = () => {
    const { onCancel } = this.props;
    onCancel();
  };
}

export default reduxForm({
  form: 'UpdateDynamicDocument',
  destroyOnUnmount: false,
})(UpdateDynamicDocumentForm);
