import React from 'react';
import PropTypes from 'prop-types';
import createFragment from 'react-addons-create-fragment';
import './ButtonBar.scss';

const ButtonBar = (children, simple) => {
  children = createFragment(children);
  if (simple) {
    return <div className="button-bar">{children}</div>;
  }
  return <div className="button-bar">{children}</div>;
};

ButtonBar.propTypes = {
  children: PropTypes.node,
};

export default ButtonBar;
