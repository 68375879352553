import * as actions from './screeningReducer';
import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { get } from 'lodash';
import confirm from 'react-confirm2';
const INCLUDED =
  '?include=conditions,conditions.questions.options,conditions.questions.selected';

export function setIndex(index) {
  return {
    type: actions.SET_INDEX,
    index,
  };
}

export function isSubmitting(status) {
  return {
    type: actions.IS_SUBMITTING,
    status,
  };
}

export function isRecalculatingScore(status) {
  return {
    type: actions.IS_RECALCULATING_SCORE,
    status,
  };
}

export function isEditing(status) {
  return {
    type: actions.IS_EDITING,
    status,
  };
}

export function isDeleting(status) {
  return {
    type: actions.IS_DELETING,
    status,
  };
}

export function isSearching(status) {
  return {
    type: actions.IS_SEARCHING,
    status,
  };
}

function startHealixSession(region, destinations, id) {
  return Axios.post('/public/medical/healix:start', {
    meta: {
      region,
      destinations,
      id,
    },
  });
}

function startCegaSession(id) {
  return Axios.post('/public/medical/cega:start', {
    meta: {
      id,
    },
  });
}

function finishHealixSession(id) {
  return Axios.post('/public/medical/healix:finish', {
    meta: {
      id,
    },
  });
}

function finishCegaSession(id) {
  return Axios.post('/public/medical/cega:finish', {
    meta: {
      id,
    },
  });
}

export function startScreening(
  travellerIndex,
  screeningId,
  region,
  destinations = [],
  service = 'healix',
) {
  return (dispatch) => {
    dispatch(setIndex(travellerIndex));
    dispatch(isSubmitting(true));

    let promise = null;

    if (service === 'healix') {
      promise = startHealixSession(region, destinations, screeningId);
    }

    if (service === 'cega') {
      promise = startCegaSession(screeningId);
    }

    return promise
      .then((sessionResponse) => {
        if (service === 'healix' && screeningId) {
          return Axios.get('/public/medical/healix/' + sessionResponse.data.meta.id + INCLUDED)
            .then((res) => {
              dispatch({
                type: actions.START_SCREENING,
                token: sessionResponse.data.meta.id,
                conditions: res.data,
                service,
              });
              dispatch(isSubmitting(false));

              return true;
            })
            .catch((err) => {
              dispatch(isSubmitting(false));
              console.error(err);
            });
        }

        dispatch({
          type: actions.START_SCREENING,
          token: sessionResponse.data.meta.id,
          conditions: {},
          service,
        });
        dispatch(isSubmitting(false));

        return true;
      })
      .catch((err) => {
        dispatch(isSubmitting(false));
        console.error(err);
      });
  };
}

export function recalculateScore(callback, screeningId, region, destinations = [], index) {
  return (dispatch, getState) => {
    if (getState().screening.service === 'cega') {
      return;
    }

    dispatch(isRecalculatingScore(true));

    return Axios.post('/public/medical/healix:recalculate', {
      meta: {
        region,
        destinations,
        id: screeningId,
      },
    })
      .then((res) => {
        if (callback) {
          callback(
            res.data.meta.conditions.length ? res.data : '', // screening payload
            res.data.meta.conditions.length ? screeningId : '', // screening id
            index
          );
        }
        dispatch(isRecalculatingScore(false));
      })
      .catch((err) => {
        dispatch(isRecalculatingScore(false));
        toastr.error(
          'Error',
          'There was an error recalculating medical - Please rescreen',
        );
        console.error(err);
      });
  };
}

export function finishScreening(callback, path) {
  return (dispatch, getState) => {
    dispatch(isSubmitting(true));
    const state = getState().screening;

    let promise = null;

    if (state.service === 'healix') {
      promise = finishHealixSession(state.token);
    }

    if (state.service === 'cega') {
      promise = finishCegaSession(state.token);
    }

    return promise
      .then((res) => {
        dispatch({
          type: actions.FINISH_SCREENING,
          data: res.data,
        });
        callback(
          res.data.meta.conditions.length ? res.data : '',
          res.data.meta.conditions.length ? state.token : '',
          path
        );

        dispatch(isSubmitting(false));
        return true;
      })
      .catch((err) => {
        dispatch(isSubmitting(false));
        console.error(err);
      });
  };
}

export function findCondition(search) {
  return (dispatch, getState) => {
    const state = getState().screening;

    if (!search.length || search.length < 2) {
      return null;
    }

    dispatch(isSearching(true));

    Axios.get(
      '/public/medical/healix/' +
        state.token +
        '/conditions?filter[name]=' +
        encodeURIComponent(search),
    )
      .then((res) => {
        const options = res.data.data.attributes.conditions
          .map((condition) => {
            return {
              value: condition.id,
              label: condition.name,
            };
          })
          .filter(Boolean);

        dispatch(isSearching(false));

        dispatch({
          type: actions.FIND_CONDITION,
          data: options,
          hasMisspelledConditions: res.data.data.attributes.has_misspelled_conditions,
        });
        return true;
      })
      .catch((err) => {
        dispatch(isSearching(false));
        console.error(err);
      });
  };
}

export function startDeclaration(condition) {
  return (dispatch, getState) => {
    const state = getState().screening;
    dispatch(isSubmitting(true));

    return Axios.post('/public/medical/healix/' + state.token + '/declaration' + INCLUDED, {
      id: condition.value,
      name: condition.label,
    })
      .then((res) => {
        dispatch({
          type: actions.START_DECLARATION,
          index: 0,
          declaration: res.data,
        });

        dispatch(isSubmitting(false));
        return true;
      })
      .catch((err) => {
        const message = get(err.response, 'data.errors[0].detail');

        toastr.error('Error', message);

        dispatch(isSubmitting(false));
        console.error(err);
      });
  };
}

export function editDeclaration(declarationId) {
  return (dispatch, getState) => {
    const state = getState().screening;
    dispatch(isEditing(declarationId));

    return Axios.post('/public/medical/healix/' + state.token + '/declaration' + INCLUDED, {
      id: declarationId,
    })
      .then((res) => {
        dispatch({
          type: actions.START_DECLARATION,
          index: 0,
          declaration: res.data,
        });

        dispatch(isEditing(false));
        return true;
      })
      .catch((err) => {
        dispatch(isEditing(false));
        console.error(err);
      });
  };
}

export function cancelDeclaration() {
  return (dispatch, getState) => {
    const state = getState().screening;
    dispatch(isSubmitting(true));

    return Axios.delete('/public/medical/healix/' + state.token + '/declaration', {
      finish: true,
    })
      .then(() => {
        return Axios.get('/public/medical/healix/' + state.token)
          .then((res) => {
            dispatch({
              type: actions.FINISH_DECLARATION,
              conditions: res.data,
            });
            dispatch(isSubmitting(false));
            return true;
          })
          .catch((err) => {
            dispatch(isSubmitting(false));
            console.error(err);
          });
      })
      .catch((err) => {
        dispatch(isSubmitting(false));
        console.error(err);
      });
  };
}

export function finishDeclaration() {
  return (dispatch, getState) => {
    const state = getState().screening;
    dispatch(isSubmitting(true));

    return Axios.patch('/public/medical/healix/' + state.token + '/declaration' + INCLUDED, {
      finish: true,
    })
      .then(() => {
        return Axios.get('/public/medical/healix/' + state.token  + INCLUDED)
          .then((res) => {
            dispatch({
              type: actions.FINISH_DECLARATION,
              conditions: res.data,
            });
            dispatch(isSubmitting(false));
            return true;
          })
          .catch((err) => {
            dispatch(isSubmitting(false));
            console.error(err);
          });
      })
      .catch((err) => {
        dispatch(isSubmitting(false));
        console.error(err);
      });
  };
}

export function deleteDeclaration(declarationId, name) {
  return (dispatch, getState) => {
    const state = getState().screening;
    dispatch(isDeleting(declarationId));

    return new Promise((resolve, reject) => {
      confirm(
        `You have requested to remove ${name} from your medical declaration; are you sure you wish to proceed?`,
        {
          close: () => {
            dispatch(isDeleting(false));

            // Resolve
            resolve();
          },
          done: () => {
            Axios.delete(
              '/public/medical/healix/' + state.token + '/conditions/' + declarationId,
              {
                id: declarationId,
              },
            )
              .then(() => {
                Axios.get('/public/medical/healix/' + state.token)
                  .then((res) => {
                    dispatch({
                      type: actions.DELETE_DECLARATION,
                      conditions: res.data,
                    });
                    dispatch(isDeleting(false));

                    // Resolve
                    resolve(res);
                  })
                  .catch((err) => {
                    dispatch(isDeleting(false));
                    console.error(err);

                    // Reject
                    reject(err);
                  });
              })
              .catch((err) => {
                dispatch(isDeleting(false));
                console.error(err);

                //Reject
                reject(err);
              });
          },
        },
      );
    });
  };
}

export function setAnswer(condition, question, answer, sendAnswerAsObject = false) {
  return (dispatch, getState) => {
    const state = getState().screening;

    dispatch(isSubmitting(true));

    return Axios.patch(
      '/public/medical/healix/' +
        state.token +
        '/declaration/conditions/' +
        condition.attributes.number +
        '/questions/' +
        question.attributes.number +
       INCLUDED,
      sendAnswerAsObject
        ? answer
        : {
            id: answer.value,
          },
    )
      .then((res) => {
        dispatch({
          type: actions.SET_ANSWER,
          declaration: res.data,
        });

        dispatch(isSubmitting(false));
        return true;
      })
      .catch((err) => {
        dispatch(isSubmitting(false));
        console.error(err);
      });
  };
}
