import React from 'react';

export const doubleExcess = (
  <svg
    id="excess_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 95 95"
  >
    <circle className="cls-1" cx="47.5" cy="47.5" r="47.5" />
    <path
      style={{ fill: '#fff' }}
      d="M10.75,39.23h8.79v4.06H16v3.84h3.29V51H16v4.46h3.87V59.5H10.75Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M32.72,39.23l-1.85,9,2.79,11.3H28.78a66.65,66.65,0,0,1-1.6-7.38Q27,54,26.62,56.19l-.5,3.3H21l1.91-11.3-1.91-9h5.09q.14.84.55,2.79.33,1.49.54,2.74l1.13-5.53Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M46.71,48.06H41.44V44.54a6.31,6.31,0,0,0-.17-1.92.73.73,0,0,0-.74-.38.78.78,0,0,0-.83.46,7,7,0,0,0-.18,2v9.41A6.56,6.56,0,0,0,39.7,56a.75.75,0,0,0,.79.45.74.74,0,0,0,.77-.45,7.38,7.38,0,0,0,.18-2.12V51.37h5.27v.79a15.58,15.58,0,0,1-.44,4.46,4.56,4.56,0,0,1-2,2.31,6.73,6.73,0,0,1-3.75,1,7.77,7.77,0,0,1-3.82-.84,4.08,4.08,0,0,1-2-2.32,15.24,15.24,0,0,1-.49-4.46V46.38a26.33,26.33,0,0,1,.15-3.29A4.6,4.6,0,0,1,35.3,41a5,5,0,0,1,2.07-1.6,7.46,7.46,0,0,1,3-.58,7.43,7.43,0,0,1,3.84.9,4.32,4.32,0,0,1,2,2.24,13.49,13.49,0,0,1,.48,4.18Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M48.6,39.23h8.79v4.06H53.87v3.84h3.29V51H53.87v4.46h3.87V59.5H48.6Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M70.41,45.37H65.52v-1.5a3,3,0,0,0-.19-1.34.69.69,0,0,0-.63-.29.8.8,0,0,0-.72.39,2.25,2.25,0,0,0-.24,1.18A3.34,3.34,0,0,0,64,45.33a4,4,0,0,0,1.49,1.24A15,15,0,0,1,69.94,50a7.86,7.86,0,0,1,.91,4.32,7.57,7.57,0,0,1-.51,3.19,4,4,0,0,1-2,1.72,7.75,7.75,0,0,1-3.38.69,7.56,7.56,0,0,1-3.61-.8,3.93,3.93,0,0,1-2-2A10.46,10.46,0,0,1,59,53.56V52.24h4.89V54.7a3.25,3.25,0,0,0,.21,1.46.8.8,0,0,0,.73.33.86.86,0,0,0,.78-.41,2.36,2.36,0,0,0,.26-1.23,3.8,3.8,0,0,0-.49-2.34,15.59,15.59,0,0,0-2.47-1.84,24.2,24.2,0,0,1-2.6-1.89,4.42,4.42,0,0,1-1.06-1.63,7.16,7.16,0,0,1-.42-2.65,7.36,7.36,0,0,1,.59-3.4,4,4,0,0,1,1.92-1.68,7.66,7.66,0,0,1,3.2-.61,8.35,8.35,0,0,1,3.5.66,3.66,3.66,0,0,1,1.91,1.67,8.85,8.85,0,0,1,.47,3.42Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M83.67,45.37H78.77v-1.5a3,3,0,0,0-.19-1.34.69.69,0,0,0-.63-.29.8.8,0,0,0-.72.39A2.25,2.25,0,0,0,77,43.8a3.34,3.34,0,0,0,.27,1.53,4,4,0,0,0,1.49,1.24A15,15,0,0,1,83.19,50a7.86,7.86,0,0,1,.91,4.32,7.57,7.57,0,0,1-.51,3.19,4,4,0,0,1-2,1.72,7.75,7.75,0,0,1-3.38.69,7.56,7.56,0,0,1-3.61-.8,3.93,3.93,0,0,1-2-2,10.46,10.46,0,0,1-.46-3.52V52.24h4.89V54.7a3.25,3.25,0,0,0,.21,1.46.8.8,0,0,0,.73.33.86.86,0,0,0,.78-.41,2.36,2.36,0,0,0,.26-1.23,3.8,3.8,0,0,0-.49-2.34,15.59,15.59,0,0,0-2.47-1.84,24.2,24.2,0,0,1-2.6-1.89,4.42,4.42,0,0,1-1.06-1.63,7.16,7.16,0,0,1-.42-2.65,7.36,7.36,0,0,1,.59-3.4,4,4,0,0,1,1.92-1.68,7.66,7.66,0,0,1,3.2-.61,8.35,8.35,0,0,1,3.5.66,3.66,3.66,0,0,1,1.91,1.67,8.85,8.85,0,0,1,.47,3.42Z"
    />
    <path
      style={{ fill: '#fff', opacity: 0.3 }}
      d="M67.13,70.5V82.93H27.08V72.52q17.8-29.11,21.15-36T51.6,25.71a7.84,7.84,0,0,0-1-4.44,3.51,3.51,0,0,0-3.09-1.46,3.4,3.4,0,0,0-3.09,1.62q-1,1.62-1,6.44v6.94H27.08V32.15a57.79,57.79,0,0,1,.63-9.66,17,17,0,0,1,3.11-7,15.6,15.6,0,0,1,6.44-5.18,23.36,23.36,0,0,1,9.51-1.76q10.86,0,16.42,5.38A18.16,18.16,0,0,1,68.76,27.6a32.42,32.42,0,0,1-3.13,13.25q-3.13,7-18.45,29.65Z"
    />
    <g className="cls-4" style={{ opacity: 0.3 }}>
      <path
        style={{ fill: '#fff' }}
        d="M72.89,30.49l-3.37-5.42h2.86l1.27,2.15.52,1,.52,1h.09l.44-1,.44-1,1.08-2.15h2.75l-3.35,5.72,3.6,5.61H76.88L75.5,34.16l-.57-1q-.29-.51-.59-1h-.09q-.25.48-.5,1l-.5,1-1.2,2.24H69.29Z"
      />
    </g>
  </svg>
);

export default doubleExcess;
