import { Tab, Tabs, Row, Col } from 'react-bootstrap';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import changesToContent from '../../../../helpers/changesToContent';
import moment from 'moment';
import {
  Field,
  formValueSelector,
  getFormError,
  getFormSubmitErrors,
  reduxForm
} from 'redux-form';
import {
  get,
  isEmpty,
  debounce,
  isEqual,
  size,
  head,
  isNil,
  map,
  isBoolean,
} from 'lodash';
import {
  calculateAdjustment,
  getQuotes,
  getQuotesAndSignposting,
  // getSource,
  isSaving,
  saveQuotesOnly,
  saveQuotesPatch,
  saveReferral,
  submitSucceeded,
  setSource,
  getPremiumIndication,
  setPremiumIndication,
  calculateAdjustmentAndSignpost,
} from '../../redux/productActions';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import { toastr } from 'react-redux-toastr';
import { canCommissionAdjust, isBroker, isOwner } from '../../../auth/redux/authActions';
import PremiumAdjust from '../PremiumAdjust';
import AdjustmentHintBox from '../AdjustmentHintBox';
import flatten from 'flat';
import {
  Box,
  Button,
  SelectUser,
  ButtonBar,
  SelectSource,
  InfoLabel
} from '../../../../common/components';
import AvailableQuotes from '../AvailableQuotes';
import SelectedQuotes from '../SelectedQuotes/index';
import AdjustmentForm from '../AdjustmentForm/index';
import TripDetailsTab01 from './Components/TabContent/TripDetailsTab01';
import TravellersTab02 from './Components/TabContent/TravellersTab02';
import MedicalTab03 from './Components/TabContent/MedicalTab03';
import OptionsTab05 from './Components/TabContent/OptionsTab05';
import PaymentTab06 from './Components/TabContent/PaymentTab06';
import CSPDetailsTab from './Components/TabContent/CSPDetailsTab';
import SideBar from './Components/SideBar/SideBar';
import validate from './ReduxForm/validate';
import './styles/LeisureTravelForm.scss';
import { openMenu } from '../../../layout/redux/sideNavAction';
import Signpost from '../../../product/components/Signpost/Signpost';
import Endorsements from './Components/Endorsements/Endorsements';
import { DeclarationTab } from './Components/TabContent/DeclarationTab';

const META = 'data.attributes.metadata';
const FORM_NAME = 'LeisureTravelForm';

const QUOTE_JOURNEY_A = {
  TRIP_DETAILS: 1,
  TRAVELLERS: 2,
  MEDICAL: 3,
  QUOTES: 4,
  OPTIONS: 5,
  PAY: 6
};
const QUOTE_JOURNEY_A_MTA = {
  TRIP_DETAILS: 1,
  TRAVELLERS: 2,
  MEDICAL: 3,
  OPTIONS: 4,
  QUOTES: 5,
  PAY: 6
};
const QUOTE_JOURNEY_B_MTA = {
  TRIP_DETAILS: 1,
  TRAVELLERS: 2,
  OPTIONS: 3,
  QUOTES: 4,
  PAY: 5
};
const QUOTE_JOURNEY_C_MTA = {
  TRIP_DETAILS: 1,
  DECLARATION: 2,
  QUOTES: 3,
  PERSONAL_DETAILS: 4,
  PAY: 5
};
const QUOTE_JOURNEY_B = {
  TRIP_DETAILS: 1,
  TRAVELLERS: 2,
  QUOTES: 3,
  PAY: 4
};
const QUOTE_JOURNEY_C = {
  TRIP_DETAILS: 1,
  TRAVELLERS: 2,
  QUOTES: 3,
  OPTIONS: 4,
  PAY: 5
};
const QUOTE_JOURNEY_D = {
  TRIP_DETAILS: 1,
  DECLARATION: 2,
  QUOTES: 3,
  PERSONAL_DETAILS: 4,
  PAY: 5
};

const QUOTE_JOURNEY_E = {
  TRIP_DETAILS: 1,
  OPTIONS: 2,
  QUOTES: 3,
  PAY: 4
};

const TABS = {
  NB: {
    ADV: QUOTE_JOURNEY_A,
    BMC: QUOTE_JOURNEY_A,
    DIA: QUOTE_JOURNEY_A,
    FST: QUOTE_JOURNEY_A,
    247: QUOTE_JOURNEY_A,
    VEN: QUOTE_JOURNEY_A,
    LSB: QUOTE_JOURNEY_A,
    PST: QUOTE_JOURNEY_A,
    TUMC: QUOTE_JOURNEY_B,
    EURP: QUOTE_JOURNEY_C,
    CPI: QUOTE_JOURNEY_A,
    TPL: QUOTE_JOURNEY_A,
    CPS: QUOTE_JOURNEY_A,
    FPS: QUOTE_JOURNEY_D,
    FPR: QUOTE_JOURNEY_D,
    NPO: QUOTE_JOURNEY_D,
    NPOR: QUOTE_JOURNEY_D,
    CSPA: QUOTE_JOURNEY_D,
    CSPAR: QUOTE_JOURNEY_D,
    CSS: QUOTE_JOURNEY_D,
    CSSR: QUOTE_JOURNEY_D,
    CSP: QUOTE_JOURNEY_E
  },
  MTA: {
    ADV: QUOTE_JOURNEY_A_MTA,
    BMC: QUOTE_JOURNEY_A_MTA,
    DIA: QUOTE_JOURNEY_A_MTA,
    FST: QUOTE_JOURNEY_A_MTA,
    247: QUOTE_JOURNEY_A_MTA,
    VEN: QUOTE_JOURNEY_A_MTA,
    LSB: QUOTE_JOURNEY_A_MTA,
    PST: QUOTE_JOURNEY_A_MTA,
    TUMC: QUOTE_JOURNEY_B,
    EURP: QUOTE_JOURNEY_B_MTA,
    CPI: QUOTE_JOURNEY_A_MTA,
    TPL: QUOTE_JOURNEY_A_MTA,
    CPS: QUOTE_JOURNEY_A_MTA,
    FPS: QUOTE_JOURNEY_C_MTA,
    FPR: QUOTE_JOURNEY_C_MTA,
    NPO: QUOTE_JOURNEY_C_MTA,
    NPOR: QUOTE_JOURNEY_C_MTA,
    CSPA: QUOTE_JOURNEY_C_MTA,
    CSPAR: QUOTE_JOURNEY_C_MTA,
    CSS: QUOTE_JOURNEY_C_MTA,
    CSSR: QUOTE_JOURNEY_C_MTA,
    CSP: QUOTE_JOURNEY_E
  }
};


export class LeisureTravelForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      showSource: false, // dev Flag
      enableOptionsTab: false,
      openTravellers: false,
      isMultipleSchemeSelected: false,
      clearQuotesFlag: false, // used for front end quotes
      savedQuotesFlag: false, // used to know if quote was saved already
      application: [],
      // applicationErrors: []
    };
    this.singleCalculation = this.singleCalculation.bind(this);
    this.calculateQuotes = this.calculateQuotes.bind(this);
    this.handleTab = this.handleTab.bind(this);
    this.openTravellers = this.openTravellers.bind(this);
    this.reCalculate = this.reCalculate.bind(this);
    this.productReferrals = this.productReferrals.bind(this);
    this.getSelectedScheme = this.getSelectedScheme.bind(this);
    this.productReferralMaxTeamFactor = this.productReferralMaxTeamFactor.bind(this);
    this.reCalculateForAdjustments = this.reCalculateForAdjustments.bind(this);
    this.getQuoteAdjustment = this.getQuoteAdjustment.bind(this);
    this.getPremiumAdjustment = this.getPremiumAdjustment.bind(this);

    this.saveReferralApplication = this.saveReferralApplication.bind(this);
    this.updateApplication = this.updateApplication.bind(this);
    this.saveApplication = this.saveApplication.bind(this);
  }

  componentDidMount() {
    const { mta, change, dispatch, product, currentProduct, resource } = this.props;
    // Do not prevent quote tab direct navigation for mta/edit
    dispatch(openMenu(false));

    if (mta) {
      dispatch(change('data.mta', true));
      if (product.productCode === 'CSP') {
        const policy = resource.data.type !== 'policies'
          ? getIncludedResource(resource.data, resource.included, 'policy')
          : resource;
        const obj = {
          businessOnlyNumPreMta: parseInt(get(policy, 'data.attributes.metadata.business_only_num')),
          businessLeisureNumPreMta: parseInt(get(policy, 'data.attributes.metadata.business_leisure_num')),
          coupleNumPreMta: parseInt(get(policy, 'data.attributes.metadata.couple_num')),
          singleParentNumPreMta: parseInt(get(policy, 'data.attributes.metadata.single_parent_num')),
          familyNumPreMta: parseInt(get(policy, 'data.attributes.metadata.family_num')),
          totalEmployeesPreMTA: parseInt(get(policy, 'data.attributes.metadata.total_employees')),
        };
        this.setState(obj);
      }
    }

    if (!mta) {
      dispatch(change(`${META}.campaign`, null));
    }

    if (product.productId) {
      dispatch(change('data.relationships.product.data.id', product.productId));
      dispatch(
        change(
          'data.attributes.metadata.age_limit',
          currentProduct.data.attributes.metadata.age_limit,
        ),
      );
    }
  }

  componentWillUpdate(nextProps, nextState) {
    const {
      change,
      dispatch,
      formValues,
      mta,
      product
    } = this.props;
    const { clearQuotesFlag, activeTab } = this.state;
    const minNumberOfQuotes = 6;
    const selectedSchemes = get(
      nextProps.formValues,
      'data.relationships.schemes.data',
      [],
    );
    const selectedSchemesOld = get(formValues, 'data.relationships.schemes.data', []);
    if (mta) {
      const selectedSchemeId = get(selectedSchemes[0], 'id');
      const selectedSchemeIdOld = get(selectedSchemesOld[0], 'id');

      const currentProductMta = product.quotes[selectedSchemeIdOld];
      const nextProduct = nextProps.product.quotes[selectedSchemeId];

      const changesCurrent = get(currentProductMta, 'meta.mta.changes');
      const changesNext = get(nextProduct, 'meta.mta.changes');

      if (!isEqual(changesCurrent, changesNext)) {
        dispatch(
          change('data.attributes.metadata.endorsements[0].title', 'Mid-term Adjustment'),
        );
        dispatch(
          change(
            'data.attributes.metadata.endorsements[0].content',
            changesToContent(get(nextProduct, 'meta.mta.changes', [])),
          ),
        );
      }
    }
    if (nextState.activeTab !== activeTab) {
      dispatch(change('data.current_tab', nextState.activeTab));
    }

    // Clear the schemes if all selected
    const schemesSelected = get(formValues, 'data.relationships.schemes.data');
    if (size(schemesSelected) >= minNumberOfQuotes && !clearQuotesFlag) {
      dispatch(change('data.relationships.schemes.data', []));
      this.setState(() => ({
        clearQuotesFlag: true,
      }));
    }
  }

  // i'd like to move the recalculates out of here and into listener
  componentWillReceiveProps(nextProps) {
    const {
      formValues,
      product,
      dispatch,
      change,
      mta,
      resource,
      source,
      productCode
    } = this.props;
    const premium = get(formValues, 'data.relationships.schemes.data', []);
    const premiumNext = get(nextProps.formValues, 'data.relationships.schemes.data', []);

    const sourceId = get(nextProps.formValues, 'data.relationships.source.data.id');
    const sourceIdOld = get(formValues, 'data.relationships.source.data.id');

    // Setting the SourceId to relationships when it is selected in dropdown
    if (!sourceId) {
      const sourceIdFromRelationship = getIncludedResource(
        resource.data,
        resource.included,
        'source',
      );
      if (sourceIdFromRelationship && !mta) {
        dispatch(
          change(
            'data.attributes.metadata.source',
            get(sourceIdFromRelationship, 'data.id'),
          ),
        );
      }
    }
    if (sourceId && sourceId !== sourceIdOld) {
      // dispatch(getSource(sourceId));
      Promise.resolve(
        dispatch(change('data.relationships.source.data.id', sourceId)),
      ).then(() => {
        if (getPremium !== 0 && totalPremium !== 0) {
          this.reCalculate();
        }
      });

      if (get(source, 'attributes.is_broker')) {
        const brokerFromSource = get(source, 'relationships.broker.data');
        dispatch(change('data.relationships.broker.data', brokerFromSource));
      } else if (get(source, 'attributes.is_channel')) {
        dispatch(change('data.relationships.broker', null));
      }
    }

    // Clear overrides in case no Quote is selected
    if (!isEqual(premiumNext, premium) && isEmpty(premiumNext)) {
      dispatch(change(`${META}.overrides`, {}));
    }

    // Clear overrides if there is any change on metadata
    if (productCode !== 'ADV' && productCode !== 'PST') {
      if (!mta && LeisureTravelForm.propsChanged(`${META}`, formValues, nextProps)) {
        if (LeisureTravelForm.propsChanged(`${META}.overrides`, formValues, nextProps)) {
          return;
        } else {
          dispatch(change(`${META}.overrides`, {}));
        }
      }
    }
    // MTA: Clear overrides if there is any changes on metadata
    // if (
    //   mta && this.state.activeTab !== QUOTES_TAB &&
    //   LeisureTravelForm.propsChanged(`${META}`, formValues, nextProps)
    // ) {
    //   if (LeisureTravelForm.propsChanged(`${META}.overrides`, formValues, nextProps)) {
    //     return
    //   } else {
    //     dispatch(change(`${META}.overrides`, {}))
    //   }
    // }

    let totalPremium = 0;
    let getPremium = premium && premium.length === 1 ? premium : 0;
    let selection = {};
    if (getPremium !== 0) {
      selection = get(product.quotes, premium[0].id);
      totalPremium = get(selection, 'meta.premium.gross', 0);
    }

    // reCalculate if residency change
    if (
      LeisureTravelForm.propsChanged(
        `${META}.residency_declaration`,
        formValues,
        nextProps,
      )
    ) {
      this.reCalculate();
    }

    if (LeisureTravelForm.propsChanged(`${META}.activities`, formValues, nextProps)) {
      if (!['ADV', 'PST'].includes(productCode)) {
        Promise.resolve(
          dispatch(
            change(
              `${META}.options.hazardous_activities`,
              get(nextProps.formValues, `${META}.activities`, []).length > 0,
            ),
          ),
        ).then(() => this.reCalculate());
      }
    }

    if (
      LeisureTravelForm.propsChanged(`${META}.referred_activities`, formValues, nextProps)
    ) {
      debounce(this.reCalculate, 500)();
    }

    // Clear Quotes in case of Trip Type change in Tab 01
    if (this.state.activeTab === TABS[nextProps.quoteType][nextProps.productCode].TRIP_DETAILS) {
      if (
        LeisureTravelForm.propsChanged(`${META}.scheme_type`, formValues, nextProps) &&
        LeisureTravelForm.hasPremium(getPremium, totalPremium)
      ) {
        dispatch(change('data.relationships.schemes.data', []));
      }
      if (
        LeisureTravelForm.propsChanged(`${META}.scheme_type`, formValues, nextProps) &&
        this.props.edit &&
        !isEmpty(getPremium)
      ) {
        dispatch(change('data.relationships.schemes.data', []));
      }
    }

    // set campaign code to null for the code below works
    if (nextProps.formValues.data.attributes.metadata.campaign === undefined) {
      dispatch(change(`${META}.campaign`, null));
    }

    // Recalculate if campaign selection changes
    if (
      LeisureTravelForm.propsChanged(`${META}.campaign`, formValues, nextProps) &&
      get(formValues, 'data.current_tab') === TABS[nextProps.quoteType][nextProps.productCode].QUOTES
    ) {
      this.reCalculate();
    }

    // Remove max_trip_duration and region for trip type in case of change. Re-screen
    if (
      LeisureTravelForm.propsChanged(`${META}.scheme_type`, formValues, nextProps) &&
      get(nextProps.formValues, `${META}.scheme_type`, 0) === 'single'
    ) {
      dispatch(change(`${META}.max_trip_duration`, ''));
      dispatch(change(`${META}.region`, ''));
    }

    // Remove Destination if user clicks on Annual Multi-trip
    if (
      LeisureTravelForm.propsChanged(
        'data.attributes.metadata.scheme_type',
        formValues,
        nextProps,
      ) &&
      get(nextProps.formValues, 'data.attributes.metadata.scheme_type', 0) === 'annual'
    ) {
      dispatch(change(`${META}.destinations`, ''));
      //dispatch(change(`${META}.max_trip_duration`, '')) // Triggered by the Confirm Up-sell
      dispatch(change(`${META}.end_date`, ''));
    }
  }

  static hasPremium(getPremium, totalPremium) {
    return getPremium !== 0 && totalPremium !== 0;
  }

  static propsChanged(formField, formValues, nextProps) {
    const oldProps = get(formValues, formField, 0);
    const newProps = get(nextProps.formValues, formField, 0);

    return oldProps !== 0 && newProps !== 0 && !isEqual(oldProps, newProps);
  }

  singleCalculation(scheme, formValues) {
    const { mta, getPremiumIndication, array } = this.props;
    if (isBoolean(mta) && mta) {
      return;
    }

    scheme = get(scheme, 'meta.scheme.id', false) ? get(scheme, 'meta.scheme') : scheme;

    // End Point will trigger upgrade information
    if (!mta) {
      if (!isEmpty(scheme)) {
        Promise.resolve(getPremiumIndication(scheme, formValues)).catch((error) => {
          console.log(error);
          array.removeAll('data.relationships.schemes.data');
        });
      }
    }
  }

  getSelectedScheme() {
    const { formValues, product } = this.props;
    const premium = get(formValues, 'data.relationships.schemes.data', []);
    let getPremium = premium && premium.length === 1 ? premium : 0; // 0 = no scheme selected
    let selection = {};
    if (getPremium !== 0) {
      selection = get(product.quotes, premium[0].id);
    }
    return selection;
  }

  getPolicyId() {
    const { formValues, mta } = this.props;

    if (!mta) {
      return null;
    }

    let policyId = get(formValues, 'data.id');
    if (get(formValues, 'data.type') === 'policies/applications') {
      policyId = get(formValues, 'data.relationships.policy.data.id');
    }

    return policyId;
  }

  saveApplication(application) {
    const { currentProduct, dispatch, change } = this.props;

    dispatch(submitSucceeded(false));
    dispatch(isSaving(true));
    return Promise.resolve(
      dispatch(saveQuotesOnly(application, currentProduct?.data?.id)))
      .then((values) => {
        dispatch(change('data.id', values.data.id));
        this.setState({
          application: values,
        });
        return values;
      }).finally(() => {
        dispatch(submitSucceeded(true));
        dispatch(isSaving(false));
      });
  }

  saveReferralApplication(application) {
    const { currentProduct, dispatch } = this.props;
    dispatch(submitSucceeded(false));
    dispatch(isSaving(true));
    return dispatch(
      saveReferral(application, currentProduct?.data?.id, (values) => {
        this.setState(() => ({
          application: values,
        }));

        dispatch(submitSucceeded(true));
        dispatch(isSaving(false));
      }),
    );
  }

  updateApplication(application) {
    const { currentProduct, dispatch } = this.props;
    dispatch(submitSucceeded(false));
    dispatch(isSaving(true));
    return Promise.resolve(dispatch(saveQuotesPatch(application, currentProduct?.data?.id, this.state.application.data.id)))
      .then((values) => {
        this.setState({
          application: values,
        });
        return values;
      })
      .finally(() => {

        dispatch(submitSucceeded(true));
        dispatch(isSaving(false));
      });

  }

  handleTab(key) {
    this.openSpecificTab(key);
  }

  openTravellers(value) {
    const { dispatch, change } = this.props;
    dispatch(change('data.attributes.metadata.traveller_group', value));
  }

  handlePaymentTab = (values) => {
    const { product, auth, productCode, quoteType, mta } = this.props;
    const availableQuotes = map(product.quotes);

    const referrals = this.productReferrals(availableQuotes);
    const getQuoteMaxTeamFactor = head(
      this.productReferralMaxTeamFactor(availableQuotes),
    );
    const userMaxRefLimit = get(auth, 'user.data.attributes.max_referral_limit');
    const requiresReferral = referrals.length && userMaxRefLimit < getQuoteMaxTeamFactor;
    const applicationId = get(this.state.application, 'data.id');

    if (!mta && !isNil(applicationId)) {
      return Promise.resolve(this.updateApplication(values).finally(() => {
        if (!requiresReferral) {
          this.setState({
            activeTab: TABS[quoteType][productCode].PAY,
          });
        }
      }));
    } else {
      return Promise.resolve(this.saveApplication(values).finally(() => {
        if (!requiresReferral) {
          this.setState({
            activeTab: TABS[quoteType][productCode].PAY,
          });
        }
      }));
    }
  };

  handleChangeTab = (key) => {
    const { mta, change, dispatch, handleSubmit, productCode, quoteType } = this.props;

    // Before change the tab, verify if there is not multiple selection or empty telephone number
    const isEnteringQuotesTab = key === TABS[quoteType][productCode].QUOTES;
    const isEnteringOptionsTab = key === TABS[quoteType][productCode].OPTIONS;
    const isExitingOptionsTab = this.state.activeTab === TABS[quoteType][productCode].OPTIONS;
    if (key === TABS[quoteType][productCode].PAY) {
      const selection = this.getSelectedScheme();
      const getErrorsScheme = get(selection, 'meta.errors');
      const getDeclines = get(selection, 'meta.declines');
      if (!isEmpty(getErrorsScheme)) {
        return toastr.error(getErrorsScheme);
      }
      if (!isEmpty(getDeclines)) {
        return toastr.error(getDeclines[0].reason);
      }
      return dispatch(handleSubmit(this.handlePaymentTab));
    }
    if (!mta && isExitingOptionsTab && isEnteringOptionsTab) {
      // clear overrides and fees
      return Promise.resolve(dispatch(change('data.attributes.metadata.fees', {})))
        .then(dispatch(change('data.attributes.metadata.overrides', {})))
        .then(() => this.reCalculate(isEnteringQuotesTab));
    } else {
      return this.reCalculate(isEnteringQuotesTab);
    }
  };

  openSpecificTab = (key) => {
    const { dispatch, change, handleSubmit, product, mta, edit, isScreening, formValues, productCode, quoteType, screening } = this.props;
    if (screening.index > 0) {
      return toastr.info(
        'Screening session active',
        'Please finish your current screening session before continuing',
      );
    }

    if (key <= this.state.activeTab && key !== TABS[quoteType][productCode].QUOTES) { // going back is fine as long as it's not too the quotes tab
      this.setState({
        activeTab: key,
      });
      return;
    }
    if (key === TABS[quoteType][productCode].OPTIONS) {
      if (productCode === 'CSP') {
        const businessOnlyNum = get(formValues, 'data.attributes.metadata.business_only_num', '0') ? parseInt(get(formValues, 'data.attributes.metadata.business_only_num', '0')) : 0;
        const businessLeisureNum = get(formValues, 'data.attributes.metadata.business_leisure_num', '0') ? parseInt(get(formValues, 'data.attributes.metadata.business_leisure_num', '0')) : 0;
        const coupleNum = get(formValues, 'data.attributes.metadata.couple_num', '0') ? parseInt(get(formValues, 'data.attributes.metadata.couple_num', '0')) : 0;
        const singleParentNum = get(formValues, 'data.attributes.metadata.single_parent_num', '0') ? parseInt(get(formValues, 'data.attributes.metadata.single_parent_num', '0')) : 0;
        const familyNum = get(formValues, 'data.attributes.metadata.family_num', '0') ? parseInt(get(formValues, 'data.attributes.metadata.family_num', '0')) : 0;
        const totalEmployees = businessOnlyNum + businessLeisureNum + coupleNum + singleParentNum + familyNum;

        dispatch(change('data.attributes.metadata.total_employees', totalEmployees));

        if (totalEmployees < 3) {
          return toastr.error(
            'Error',
            'Please add at least 3 members to continue',
          );
        }
      }
    }
    // Don't change the tab if the flag is false
    if (key === TABS[quoteType][productCode].QUOTES) {
      if (!mta && !edit && this.state.enableQuoteTab === false) {
        return;
      }
      //Don't go to quotes if recalculating score
      if (isScreening) {
        return;
      }
    }
    // check scheme is selected
    if (key > TABS[quoteType][productCode].QUOTES) {
      const hasSchemes = !!formValues.data.relationships.schemes.data.length;
      if (!hasSchemes) {
        this.setState({
          isMultipleSchemeSelected: true
        });
        return;
      }
    }
    // Check for Upsell Logic to validate the Upsell SideBar Field
    if (key > TABS[quoteType][productCode].QUOTES) {
      const selectedSchemeId = get(formValues, 'data.relationships.schemes.data.0.id');
      const selectedQuote = product.quotes[selectedSchemeId];
      if (selectedQuote) {
        const isUpsell = selectedQuote.meta.scheme.quote_type === 'upsell';
        if (isUpsell && formValues.data.attributes.metadata.scheme_type !== selectedQuote.meta.scheme.scheme_type) {
          return;
        }
      }
    }
    return Promise.resolve(dispatch(handleSubmit(() => this.handleChangeTab(key)))).then((errors) => {
      const formErrors = errors && errors instanceof Object ? flatten(errors) : false;
      if (formErrors) {
        return false;
      }
      dispatch(change('data.current_tab', key));
      this.setState({
        activeTab: key,
      });
    });
  }; // end

  calculatePanel = (values, id, target) => {
    const { dispatch, change, doSignposting } = this.props;
    const schemeType = values.data.attributes.metadata.scheme_type;
    const coverLevel = values.data.attributes.metadata.cover_level || null;
    if (doSignposting) {
      return Promise.resolve(dispatch(getQuotesAndSignposting(id, values, target, schemeType))).then((resp) => {
        dispatch(change('data.attributes.metadata.signposted', resp));
        dispatch(change('data.attributes.metadata.signpost_acknowledged', ''));
      });
    }
    dispatch(getQuotes(id, values, target, schemeType, coverLevel));
  };

  reCalculate(calculatePanel = false) {
    const { dispatch, handleSubmit, mta, formValues } = this.props;
    const schemes = get(formValues, 'data.relationships.schemes.data', []);
    const singleSelectedScheme = schemes.length === 1 ? schemes[0] : null;

    if (mta) {
      return dispatch(handleSubmit(this.getPremiumAdjustment));
    } else {
      if (calculatePanel) {
        Promise.resolve(dispatch(handleSubmit(this.calculateQuotes))).then(() => {
          if (singleSelectedScheme) {
            this.singleCalculation(singleSelectedScheme, formValues);
          }
        });
      } else {
        if (singleSelectedScheme) {
          this.singleCalculation(singleSelectedScheme, formValues);
        }
      }
    }
    return true;
  }

  reCalculateForAdjustments() {
    // called as callback by PremiumAdjust component
    const { dispatch, handleSubmit } = this.props;
    dispatch(handleSubmit(this.getQuoteAdjustment));
  }

  getQuoteAdjustment(application) {
    console.log('Calculating adjustments...');
    // This method is invoked by the premium Adjustment Slider
    const { dispatch, change, mta, formValues, productCode } = this.props;
    // called as callback by PremiumAdjust component

    if (mta) {
      dispatch(calculateAdjustment(this.getPolicyId(), application, change));
    } else {
      if (isBroker()) {
        // commented as calculation was called twice when premium was selected
        // dispatch(getQuotes(product.productId, application, 'products'))
      } else {
        const overrides = get(formValues, 'data.attributes.metadata.overrides', null);

        let singleCalc = false;
        if (Object.keys(overrides).length > 0 && ['TUMC', 'ADV', 'PST'].includes(productCode)) {
          singleCalc = true;
        }
        this.reCalculate(singleCalc);
      }
    }
  }

  getPremiumAdjustment(application) {
    const { dispatch, change, doSignposting } = this.props;
    let policyId = get(application, 'data.id');
    if (application.data.type === 'policies/applications') {
      policyId = get(application, 'data.relationships.policy.data.id');
    }
    const schemeType = application.data.attributes.metadata.scheme_type;
    const coverLevel = application.data.attributes.metadata.cover_level || null;
    if (doSignposting) {
      dispatch(calculateAdjustmentAndSignpost(policyId, application, change, schemeType, coverLevel));
    } else {
      dispatch(calculateAdjustment(policyId, application, change, schemeType, coverLevel));
    }
  }

  calculateQuotes(application) {
    const {
      dispatch,
      product,
      mta,
      channel,
      edit
    } = this.props;

    if (mta) {

      this.getPremiumAdjustment();

    } else {
      const calculationSource = this.getCalculationSource(application, channel, product);

      dispatch(setPremiumIndication([]));
      // Save the quote for the first time the QUOTES_TAB is accessed.
      if (!this.state.savedQuotesFlag && !edit) {

        return Promise.resolve(this.saveApplication(application))
          .then((resp) => {
            this.calculatePanel(resp, calculationSource.id, calculationSource.source);
          })
          .then(() => {
            this.setState({
              savedQuotesFlag: true,
            });
          });
      } else {
        this.calculatePanel(application, calculationSource.id, calculationSource.source);

      }
    }
  }

  productReferrals(quotes) {
    const referrals = [];
    Object.keys(quotes).forEach((quotedScheme) => {
      const quote = quotes[quotedScheme];
      if (quote.meta.referrals && quote.meta.referrals.length > 0) {
        quote.meta.referrals.map((referral) => {
          if (referral && referrals.indexOf(referral.reason) === -1) {
            referrals.push(referral.reason);
          }
        });
      }
    });

    return referrals;
  }

  productReferralMaxTeamFactor(quotes) {
    const referrals = [];
    Object.keys(quotes).forEach((quotedScheme) => {
      const quote = quotes[quotedScheme];
      if (quote.meta.referrals && quote.meta.referrals.length > 0) {
        quote.meta.referrals.map((referral) => {
          if (referral && referrals.indexOf(referral.max_team_factor) === -1) {
            referrals.push(referral.max_team_factor);
          }
        });
      }
    });
    return referrals;
  }

  clearPremiumIndication = () => {
    const { dispatch } = this.props;
    dispatch(setPremiumIndication([]));
  };

  getCalculationSource = (application, channel, product) => {
    if (isBroker()) {
      return {
        source: 'products',
        id: product.productId
      };
    }
    const sourceId = get(
      application,
      'data.relationships.source.data.id',
      get(application, 'data.attributes.metadata.source.id'),
    );
    if (sourceId) {
      return {
        source: 'sources',
        id: sourceId
      };
    } else {
      return {
        source: 'channels',
        id: get(channel, 'data.id')
      };

    }
  };

  handleEndorsementChange = (val) => {
    const { dispatch, change, formValues } = this.props;
    const selectedScheme = get(formValues, 'data.relationships.schemes.data', []);
    if (val.attributes) {
      dispatch(change(
        'data.attributes.metadata.endorsements[0].title',
        val.attributes.sections.title,
      ));
      dispatch(change(
        'data.attributes.metadata.endorsements[0].content',
        val.attributes.sections.content,
      ));
    } else {
      dispatch(change(
        'data.attributes.metadata.endorsements[0].title',
        'Mid-term Adjustment',
      ));
      dispatch(change(
        'data.attributes.metadata.endorsements[0].content',
        changesToContent(get(selectedScheme, 'meta.mta.changes', []))),
      );
    }
  };

  handleSourceChange(option) {
    const { change, dispatch } = this.props;

    const source = option.value;
    if (source) {
      dispatch(setSource(source));
      dispatch(change('data.relationships.user.data.id', ''));
      let salesChannel = 'call-centre';

      if (source.attributes.source_type === 'Aggregator') {
        salesChannel = source.attributes.channel_key;
      }

      if (source.attributes.source_type === 'Broker') {
        salesChannel = 'pjh-b2b';
      }

      if (source.attributes.is_broker) {
        const broker = source.relationships.broker.data.id;
        dispatch(change('data.relationships.broker.data.id', broker));
      }

      dispatch(change('data.attributes.metadata.sales_channel', salesChannel));
    } else {
      dispatch(
        change(
          'data.attributes.metadata.sales_channel',
          isBroker() ? 'pjh-b2b' : 'call-centre',
        ),
      );
    }
  }

  getAge = (dob) => {

    if (isEmpty(dob)) {
      return null;
    }

    const convertedDate = dob.split(/\//).reverse().join('/');
    return moment().diff(convertedDate, 'years');
  };

  handleScreeningComplete = (screening, screeningId, path) => {
    const { dispatch, change } = this.props;
    dispatch(
      change(
        `${path}.screening`,
        screening,
      ),
    );
    dispatch(
      change(
        `${path}.screening_id`,
        screeningId,
      ),
    );
  };


  render() {
    const {
      edit,
      currentProduct,
      formValues,
      handleSubmit,
      product,
      resource,
      screening,
      mta,
      change,
      error,
      auth,
      source,
      isExpired,
      productCode,
      quoteType,
      hazMta,
      array
    } = this.props;
    const { activeTab } = this.state;

    const channelName = get(resource, 'data.attributes.channel_name');
    const sourceFromResource =
      resource && getIncludedResource(resource.data, resource.included, 'source');
    const formErrors = error && error instanceof Object ? flatten(error) : false;
    const declaration = get(
      currentProduct,
      'data.attributes.metadata.purchase_declaration.callcenter.main',
      '',
    );

    // Check for Referrals
    const userMaxRefLimit = get(auth, 'user.data.attributes.max_referral_limit');
    const availableQuotes = map(product.quotes);
    const referrals = this.productReferrals(availableQuotes);
    const getQuoteMaxTeamFactor = head(
      this.productReferralMaxTeamFactor(availableQuotes),
    );
    const requiresReferral = referrals.length && userMaxRefLimit < getQuoteMaxTeamFactor;

    const channel = getIncludedResource(resource.data, resource.included, 'channel');


    // Get the Premium selected to display in the SideBar
    const selectedSchemes = get(formValues, 'data.relationships.schemes.data', []);
    const selectedSchemeFromPanel = selectedSchemes && selectedSchemes.length === 1 ? get(product.quotes, selectedSchemes[0].id) : {};
    const premium = !isEmpty(product.premiumIndication) ? product.premiumIndication : selectedSchemeFromPanel;
    const taxRate = get(premium, 'meta.rates.tax_rate', 0);
    const totalPremium = get(premium, 'meta.premium.gross', 0);
    const productIcon = get(currentProduct, 'data.attributes.icon', '');
    const todayDate = moment();
    const endDate = moment(get(formValues, 'data.attributes.metadata.end_date'));
    const durationFromToday = endDate.diff(todayDate, 'days');
    const brokerIdBasedOnSource = get(source, 'relationships.broker.data.id');
    const isSourceTypeBroker = get(source, 'attributes.source_type', 0);
    const isB2cChannel = get(channel, 'data.attributes.channel_type') === 'B2C';
    const isAggChannel = get(channel, 'data.attributes.channel_type') === 'AGG';
    const isValidToRender =
      isSourceTypeBroker !== 0 ||
      isBroker() ||
      mta ||
      isB2cChannel ||
      isAggChannel ||
      edit;

    const tumc = ['TUMC'].includes(productCode);
    const adv = ['ADV'].includes(productCode);
    const pst = ['PST'].includes(productCode);
    const eurp = ['EURP'].includes(productCode);
    const bmc = ['BMC'].includes(productCode);
    const fps = ['FPS', 'FPR', 'NPO', 'NPOR', 'CSPA', 'CSPAR', 'CSS', 'CSSR'].includes(productCode);
    const csp = ['CSP'].includes(productCode);

    let showBlocker = false;
    let tumcDeclines = false;

    if (tumc && availableQuotes. length > 0) {
      tumcDeclines = get(availableQuotes[0], 'meta.declines', 0).length > 0;
    }

    return (
      <div>
        <form autoComplete="off">
          {isOwner() && (
            <Box>
              <Row>
                <Col sm={6}>
                  {!channelName && currentProduct.data ? (
                    <Field
                      name={'data.relationships.source.data'}
                      isObject
                      isClearable={!mta}
                      filter={'?include=broker,channel'}
                      id={'select-source'}
                      component={SelectSource}

                      disabled={
                        mta || edit || activeTab === TABS[quoteType][productCode].PAY
                      }
                      label={'Source'}
                      labelSize={4}
                      product={currentProduct.data.id}
                      onChangeCallback={(value) => this.handleSourceChange(value)}
                    />
                  ) : (
                    <InfoLabel
                      label="Source"
                      value={
                        sourceFromResource
                          ? get(sourceFromResource, 'data.attributes.name')
                          : channelName
                      }
                      labelSize={4}
                    />
                  )}
                </Col>
                {isSourceTypeBroker === 'Broker' && (
                  <Col sm={6}>
                    <Field
                      key={source.id}
                      label="User"
                      labelKeys={['attributes.first_name', 'attributes.last_name']}
                      labelSize={3}
                      name={'data.relationships.user.data.id'}
                      component={SelectUser}
                      byBroker={brokerIdBasedOnSource}
                      disabled={
                        mta || edit || activeTab === TABS[quoteType][productCode].PAY
                      }
                    />
                  </Col>
                )}
              </Row>
            </Box>
          )}
          {isValidToRender && (
            <div className={'quotes-tabs' + (product.isSaving ? ' loading' : '')}>
              <div className="quote-tabs-content">
                <Tabs
                  activeKey={activeTab}
                  id="leisure-travel"
                  unmountOnExit
                  onSelect={this.handleTab}
                >
                  {csp ? (
                    <Tab eventKey={1} title="Details" autoComplete="off">
                      <div className="leisure-tab-content">
                        <CSPDetailsTab
                          currentProduct={currentProduct}
                          openSpecificTab={this.openSpecificTab}
                          nextTab={TABS[quoteType][productCode].TRIP_DETAILS + 1}
                          mta={mta}
                          formState={this.state}
                        />
                      </div>
                    </Tab>
                  ) : (
                    <Tab eventKey={1} title="Trip&#160;Details" autoComplete="off">
                      <div className="leisure-tab-content">
                        <TripDetailsTab01
                          productCode={productCode}
                          mta={mta}
                          currentProduct={currentProduct}
                          nextTab={TABS[quoteType][productCode].TRIP_DETAILS + 1}
                          openSpecificTab={this.openSpecificTab}
                          isExpired={isExpired}
                        />
                        {fps && (
                          <TravellersTab02
                            currentProduct={currentProduct}
                            openSpecificTab={this.openSpecificTab}
                            nextTab={TABS[quoteType][productCode].TRIP_DETAILS + 1}
                          />
                        )}
                      </div>
                    </Tab>
                  )}

                  {!fps && !csp &&  (
                    <Tab eventKey={2} title="Travellers" autoComplete="off">
                      <div className="leisure-tab-content">
                        <TravellersTab02
                          currentProduct={currentProduct}
                          openSpecificTab={this.openSpecificTab}
                          nextTab={TABS[quoteType][productCode].TRAVELLERS + 1}
                        />
                      </div>
                    </Tab>
                  )}
                  {fps && (
                    <Tab eventKey={2} title="Declaration" autoComplete="off">
                      <div className="leisure-tab-content">
                        <DeclarationTab
                          currentProduct={currentProduct}
                          nextTab={TABS[quoteType][productCode].DECLARATION + 1}
                          openSpecificTab={this.openSpecificTab}
                        />
                      </div>
                    </Tab>
                  )}
                  {!tumc && !eurp && !fps && !csp && (
                    <Tab eventKey={TABS[quoteType][productCode].MEDICAL} title="Medical" unmountOnExit={false}>
                      <div className="leisure-tab-content">
                        <MedicalTab03
                          handleScreeningComplete={this.handleScreeningComplete}
                          currentProduct={currentProduct}
                          openSpecificTab={this.openSpecificTab}
                          screening={screening}
                          mta={mta}
                          currentTab={activeTab}
                          channel={channel}
                          nextTab={TABS[quoteType][productCode].MEDICAL + 1}
                        />
                      </div>
                    </Tab>
                  )}
                  {(mta && !tumc) && (
                    /* handling the order of the tabs */
                    <Tab eventKey={TABS[quoteType][productCode].OPTIONS} title="Options">
                      <div className="leisure-tab-content">
                        <OptionsTab05
                          singleCalculation={this.singleCalculation}
                          mta={mta}
                          hazMta={hazMta}
                          selection={selectedSchemeFromPanel}
                          currentProduct={currentProduct}
                          screening={screening}
                          product={product}
                        />
                        <div className="button-top-padding"/>
                        <Row>
                          <Col xs={12}>
                            <ButtonBar>
                              <Button
                                className="pull-left btn-action-back"
                                label="Back"
                                bsStyle="secondary"
                                handleClick={() => this.openSpecificTab(TABS[quoteType][productCode].OPTIONS - 1)}
                              />
                              <Button
                                className={
                                  product.isSaving
                                    ? 'pull-right'
                                    : 'pull-right btn-action'
                                }
                                label="Next"
                                bsStyle="primary"
                                isLoading={product.isSaving}
                                handleClick={() => this.openSpecificTab(TABS[quoteType][productCode].OPTIONS + 1)}
                              />
                            </ButtonBar>
                          </Col>
                        </Row>
                      </div>
                    </Tab>
                  )}
                  {!csp && (
                    <Tab eventKey={TABS[quoteType][productCode].QUOTES} title={mta ? 'Calculate Adjustment' : (fps ? 'Cover Choice' : 'Quotes' ) }>
                      <div className="leisure-tab-content">
                        {mta ? (
                            <div>
                              <AvailableQuotes
                                mta={mta}
                                showBlocker={showBlocker}
                                product={product}
                                requiresReferral={requiresReferral}
                                referrals={referrals}
                                currentProduct={currentProduct}
                                availableQuotes={availableQuotes}
                                selectedSchemes={get(
                                  formValues,
                                  'data.relationships.schemes.data',
                                  [],
                                )}
                                updateSelection={array}
                                change={change}
                                schemeType={get(
                                  formValues,
                                  'data.attributes.metadata.scheme_type',
                                  'annual',
                                )}
                                coverLevel={get(
                                  formValues,
                                  'data.attributes.metadata.cover_level',
                                )}
                                handleSubmit={handleSubmit}
                                formValues={formValues}
                                clearPremiumIndication={this.clearPremiumIndication}
                              />
                              <Signpost
                                signpost={product.signpost}
                              />
                              {canCommissionAdjust() && isOwner() && (
                                <div className={size(availableQuotes) > 0 ? '' : 'hidden'}>
                                  <AdjustmentForm
                                    referrals={referrals}
                                    requiresReferral={requiresReferral}
                                    product={product}
                                    currentProduct={currentProduct}
                                    image={productIcon}
                                    change={change}
                                    selectedSchemes={get(
                                      formValues,
                                      'data.relationships.schemes.data',
                                      [],
                                    )}
                                    handleSubmit={handleSubmit}
                                    policyId={this.getPolicyId()}
                                    formName={FORM_NAME}
                                    reCalculateForAdjustments={this.reCalculateForAdjustments}
                                  />
                                  <h3>Endorsements</h3>
                                  <Endorsements
                                    handleEndorsementChange={this.handleEndorsementChange}
                                    currentProduct={currentProduct}
                                  />
                                </div>
                              )}
                            </div>
                        ) : (
                          <div>
                              <AvailableQuotes
                                auth={auth}
                                product={product}
                                currentProduct={currentProduct}
                                showBlocker={showBlocker}
                                availableQuotes={availableQuotes}
                                requiresReferral={requiresReferral}
                                referrals={referrals}
                                selectedSchemes={get(
                                  formValues,
                                  'data.relationships.schemes.data',
                                  [],
                                )}
                                updateSelection={array}
                                change={change}
                                schemeType={get(
                                  formValues,
                                  'data.attributes.metadata.scheme_type',
                                  'annual',
                                )}
                                coverLevel={get(
                                  formValues,
                                  'data.attributes.metadata.cover_level',
                                )}
                                handleSubmit={handleSubmit}
                                formValues={formValues}
                                clearPremiumIndication={this.clearPremiumIndication}
                              />
                            <Signpost
                              signpost={product.signpost}
                            />
                              <SelectedQuotes
                                product={product}
                                currentProduct={currentProduct}
                                image={productIcon}
                                showBlocker={showBlocker}
                                selectedSchemes={get(
                                  formValues,
                                  'data.relationships.schemes.data',
                                  [],
                                )}
                                handleSubmit={handleSubmit}
                                requiresReferral={requiresReferral}
                              />
                            {(adv || pst) && canCommissionAdjust() && isOwner() && (
                              <div>
                                <hr/>
                                <div className="premium-adjust-box">
                                  <PremiumAdjust
                                    taxType={get(
                                      currentProduct,
                                      'data.attributes.metadata.tax_type',
                                    )}
                                    formValuesParent={formValues}
                                    reCalculateForAdjustments={this.reCalculateForAdjustments}
                                    baseName="data.attributes.metadata.overrides"
                                    formName={FORM_NAME}
                                    scheme={head(get(formValues, 'data.relationships.schemes.data', []),)}
                                  />
                                </div>
                                <div>
                                  <AdjustmentHintBox
                                    quotes={get(product, 'quotes', [])}
                                  />
                                </div>
                              </div>
                            )}
                            <div>
                              {canCommissionAdjust() && isOwner() && tumc && (
                                <div>
                                  <hr/>
                                  <div className="premium-adjust-box">
                                    <PremiumAdjust
                                      taxType={get(
                                        currentProduct,
                                        'data.attributes.metadata.tax_type',
                                      )}
                                      formValuesParent={formValues}
                                      reCalculateForAdjustments={this.reCalculateForAdjustments}
                                      baseName="data.attributes.metadata.overrides"
                                      formName={FORM_NAME}
                                      scheme={head(get(formValues, 'data.relationships.schemes.data', []),)}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      <Row>
                        <Col sm={12}>
                          <ButtonBar>
                            <Button
                              className="pull-left btn-action-back"
                              label="Back"
                              bsStyle="secondary"
                              handleClick={() => this.openSpecificTab(TABS[quoteType][productCode].QUOTES - 1)}
                            />
                            <Button
                              className="pull-right btn-action"
                              label="Next"
                              id="next-payment-mta"
                              disabled={showBlocker || tumcDeclines} // here it goes
                              bsStyle="primary"
                              // Quote to Options
                              handleClick={() => this.openSpecificTab(TABS[quoteType][productCode].QUOTES + 1)}
                            />
                          </ButtonBar>
                        </Col>
                      </Row>
                    </Tab>
                  )}
                  {fps && (
                    <Tab eventKey={4} title="Personal Details" autoComplete="off">
                      <div className="leisure-tab-content">
                        <TravellersTab02
                          currentProduct={currentProduct}
                          openSpecificTab={this.openSpecificTab}
                          nextTab={TABS[quoteType][productCode].PERSONAL_DETAILS + 1}
                        />
                      </div>
                    </Tab>
                  )}
                  {(!mta && !tumc && !bmc && !fps) && (
                    <Tab eventKey={TABS[quoteType][productCode].OPTIONS} title="Options">
                      <div className="leisure-tab-content">
                        <OptionsTab05
                          singleCalculation={this.singleCalculation}
                          mta={mta}
                          hazMta={hazMta}
                          selection={selectedSchemeFromPanel}
                          currentProduct={currentProduct}
                          screening={screening}
                        />
                        {isOwner() && !mta && (
                          <div>
                            <h4 className="title-options">Endorsements</h4>
                            <div
                              className="container-fluid"
                              style={{ marginBottom: '35px', paddingLeft: '0px' }}
                            ><Endorsements
                              handleEndorsementChange={this.handleEndorsementChange}
                              currentProduct={currentProduct}
                            />
                            </div>
                          </div>
                        )}
                        {!mta &&
                        !error &&
                        Object.keys(formErrors).length === 0 &&
                        get(formValues, 'data.relationships.schemes.data', []).length ===
                        1 &&
                        canCommissionAdjust() &&
                        !['ADV', 'PST'].includes(productCode) ? (
                          // don't display if schemes.data is undefined
                          <div>
                            <hr/>
                            <div className="premium-adjust-box">
                              <PremiumAdjust
                                taxType={get(
                                  currentProduct,
                                  'data.attributes.metadata.tax_type',
                                )}
                                formValuesParent={formValues}
                                reCalculateForAdjustments={this.reCalculateForAdjustments}
                                baseName="data.attributes.metadata.overrides"
                                formName={FORM_NAME}
                                scheme={head(
                                  get(formValues, 'data.relationships.schemes.data', []),
                                )}
                              />
                            </div>
                          </div>
                        ) : (
                          <div/>
                        )}
                        <Row>
                          <Col xs={12}>
                            <ButtonBar>
                              <Button
                                className="pull-left btn-action-back"
                                label="Back"
                                bsStyle="secondary"
                                handleClick={() => this.openSpecificTab(TABS[quoteType][productCode].OPTIONS - 1)}
                                //handleClick={handleSubmit(this.calculateQuotes)}
                              />
                              <Button
                                className={
                                  product.isSaving
                                    ? 'pull-right'
                                    : 'pull-right btn-action'
                                }
                                label="Next"
                                id="next-payment"
                                bsStyle="primary"
                                disabled={product.isCalculating}
                                isLoading={product.isSaving}
                                handleClick={() => this.openSpecificTab(TABS[quoteType][productCode].OPTIONS + 1)}
                              />
                            </ButtonBar>
                          </Col>
                        </Row>
                      </div>
                    </Tab>
                  )}
                  {!mta && bmc && isOwner() && (
                    <Tab eventKey={TABS[quoteType][productCode].OPTIONS} title="Endorsements">
                      <div className="leisure-tab-content">
                        <div>
                          <h4 className="title-options">Endorsements</h4>
                          <div
                            className="container-fluid"
                            style={{ marginBottom: '35px', paddingLeft: '0px' }}
                          >
                            <Endorsements
                              handleEndorsementChange={this.handleEndorsementChange}
                              currentProduct={currentProduct}
                            />
                          </div>
                        </div>
                        <Row>
                          <Col xs={12}>
                            <ButtonBar>
                              <Button
                                className="pull-left btn-action-back"
                                label="Back"
                                bsStyle="secondary"
                                handleClick={() => this.openSpecificTab(TABS[quoteType][productCode].OPTIONS - 1)}
                                //handleClick={handleSubmit(this.calculateQuotes)}
                              />
                              <Button
                                className={
                                  product.isSaving
                                    ? 'pull-right'
                                    : 'pull-right btn-action'
                                }
                                label="Next"
                                id="next-payment"
                                bsStyle="primary"
                                disabled={product.isCalculating}
                                isLoading={product.isSaving}
                                handleClick={() => this.openSpecificTab(TABS[quoteType][productCode].OPTIONS + 1)}/>
                            </ButtonBar>
                          </Col>
                        </Row>
                      </div>
                    </Tab>
                  )}
                  {csp && (
                    <Tab eventKey={TABS[quoteType][productCode].QUOTES} title={mta ? 'Calculate Adjustment' : (fps ? 'Cover Choice' : 'Quotes' ) }>
                      <div className="leisure-tab-content">
                        {mta ? (
                          <div>
                            <AvailableQuotes
                              mta={mta}
                              showBlocker={showBlocker}
                              product={product}
                              requiresReferral={requiresReferral}
                              referrals={referrals}
                              currentProduct={currentProduct}
                              availableQuotes={availableQuotes}
                              selectedSchemes={get(
                                formValues,
                                'data.relationships.schemes.data',
                                [],
                              )}
                              updateSelection={array}
                              change={change}
                              schemeType={get(
                                formValues,
                                'data.attributes.metadata.scheme_type',
                                'annual',
                              )}
                              handleSubmit={handleSubmit}
                              formValues={formValues}
                              clearPremiumIndication={this.clearPremiumIndication}
                            />
                            <Signpost
                              signpost={product.signpost}
                            />
                            {canCommissionAdjust() && isOwner() && (
                              <div className={size(availableQuotes) > 0 ? '' : 'hidden'}>
                                <AdjustmentForm
                                  referrals={referrals}
                                  requiresReferral={requiresReferral}
                                  product={product}
                                  currentProduct={currentProduct}
                                  image={productIcon}
                                  change={change}
                                  selectedSchemes={get(
                                    formValues,
                                    'data.relationships.schemes.data',
                                    [],
                                  )}
                                  handleSubmit={handleSubmit}
                                  policyId={this.getPolicyId()}
                                  formName={FORM_NAME}
                                  reCalculateForAdjustments={this.reCalculateForAdjustments}
                                />
                                <h3>Endorsements</h3>
                                <Endorsements
                                  handleEndorsementChange={this.handleEndorsementChange}
                                  currentProduct={currentProduct}
                                />
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                            <AvailableQuotes
                              auth={auth}
                              product={product}
                              currentProduct={currentProduct}
                              showBlocker={showBlocker}
                              availableQuotes={availableQuotes}
                              requiresReferral={requiresReferral}
                              referrals={referrals}
                              selectedSchemes={get(
                                formValues,
                                'data.relationships.schemes.data',
                                [],
                              )}
                              updateSelection={array}
                              change={change}
                              schemeType={get(
                                formValues,
                                'data.attributes.metadata.scheme_type',
                                'annual',
                              )}
                              handleSubmit={handleSubmit}
                              formValues={formValues}
                              clearPremiumIndication={this.clearPremiumIndication}
                            />
                            <Signpost
                              signpost={product.signpost}
                            />
                            <SelectedQuotes
                              product={product}
                              currentProduct={currentProduct}
                              image={productIcon}
                              showBlocker={showBlocker}
                              selectedSchemes={get(
                                formValues,
                                'data.relationships.schemes.data',
                                [],
                              )}
                              handleSubmit={handleSubmit}
                              requiresReferral={requiresReferral}
                            />
                          </div>
                        )}
                      </div>
                      <Row>
                        <Col sm={12}>
                          <ButtonBar>
                            <Button
                              className="pull-left btn-action-back"
                              label="Back"
                              bsStyle="secondary"
                              handleClick={() => this.openSpecificTab(TABS[quoteType][productCode].QUOTES - 1)}
                            />
                            <Button
                              className="pull-right btn-action"
                              label="Next"
                              id="next-payment-mta"
                              disabled={showBlocker}
                              bsStyle="primary"
                              // Quote to Options
                              handleClick={() => this.openSpecificTab(TABS[quoteType][productCode].QUOTES + 1)}
                            />
                          </ButtonBar>
                        </Col>
                      </Row>
                    </Tab>
                  )}
                  <Tab eventKey={TABS[quoteType][productCode].PAY} title={requiresReferral ? 'Refer' : 'Payment'}>
                    <div className="leisure-tab-content">
                      {this.state.application && !requiresReferral && !product.isCalculating ? (
                        <PaymentTab06
                          // premiumIndicationGross={premiumIndicationGross} // after singleCalculation
                          declaration={declaration}
                          durationFromToday={durationFromToday}
                          premium={totalPremium}
                          getPremium={premium}
                          selectedPremium={selectedSchemeFromPanel}
                          formValues={formValues}
                          product={product}
                          currentProduct={currentProduct}
                          parentChange={change}
                          taxRate={taxRate}
                          selectedSchemes={get(
                            formValues,
                            'data.relationships.schemes.data',
                            [],
                          )}
                          // application={this.state.application}
                          application={this.state.application}
                          hasSignpostedPremiums={get(this.state.application, 'data.attributes.metadata.signposted', '') &&
                          get(this.state.application, 'data.attributes.metadata.signposted', '').includes('medical_premiums')}
                          mta={mta}
                          formState={this.state}
                        />
                      ) : (
                        !product.isCalculating && (
                          <div>
                            {requiresReferral && (
                              <div>
                                <AvailableQuotes
                                  product={product}
                                  currentProduct={currentProduct}
                                  requiresReferral={requiresReferral}
                                  referrals={referrals}
                                  availableQuotes={availableQuotes}
                                  showBlocker={showBlocker}
                                  selectedSchemes={get(
                                    formValues,
                                    'data.relationships.schemes.data',
                                    [],
                                  )}
                                  updateSelection={array}
                                  change={change}
                                  schemeType={get(
                                    formValues,
                                    'data.attributes.metadata.scheme_type',
                                    'annual',
                                  )}
                                  handleSubmit={handleSubmit}
                                  formValues={formValues}
                                  clearPremiumIndication={this.clearPremiumIndication}
                                />
                                <div>
                                  <Signpost
                                    signpost={product.signpost}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  </Tab>
                </Tabs>
              </div>
              {get(resource, 'data.attributes.policy_number') && (
                <Box>
                  <h2>Policy number: {resource.data.attributes.policy_number}</h2>
                </Box>
              )}
            </div>
          )}
        </form>

        {isValidToRender && (
          <SideBar
            saveQuotesPatch={this.updateApplication}
            mta={mta}
            calculateQuotes={this.calculateQuotes}
            auth={auth}
            reCalculate={this.reCalculate}
            requiresReferral={requiresReferral}
            isMultipleSchemeSelected={this.state.isMultipleSchemeSelected}
            isPhone={this.state.isPhone}
            isEmail={this.state.isEmail}
            currentTab={this.state.activeTab}
            taxRate={taxRate}
            formValues={formValues}
            premium={totalPremium}
            resource={resource}
            currentProduct={currentProduct}
            availableQuotes={availableQuotes}
            quotesQty={availableQuotes.length || 0}
            saveQuoteOnly={this.saveApplication}
            referQuoteOnly={this.saveReferralApplication}
            hasReferrals={referrals.length > 0}
            brokerId={brokerIdBasedOnSource}
            // isSignpostAcknowledged={
            //   get(formValues, 'data.attributes.metadata.signposted', false) ?
            //     get(formValues, 'data.attributes.metadata.signpost_acknowledged', false) : true
            // }
            showBlocker={showBlocker}
          />
        )}
      </div>
    );
  }
}

const form = reduxForm({ form: FORM_NAME, validate })(LeisureTravelForm);
const selector = formValueSelector(FORM_NAME);

const mapStateToProps = (state, props) => {
  const values = selector(
    state,
    'data.mta',
    'data.id',
    'data.current_tab',
    'data.attributes.metadata',
    'data.relationships',
    'meta'
  );

  return {
    initialValues: props.resource,
    formValues: values,
    customer: state.customer,
    auth: state.auth,
    source: state.product.source,
    isScreening: state.screening.isScreening,
    submitErrors: getFormSubmitErrors(FORM_NAME)(state),
    formError: getFormError(FORM_NAME)(state),


    // product: state.product,
  };
};

export default connect(
  mapStateToProps,
  { getPremiumIndication },
)(form);
