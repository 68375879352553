import React from 'react';

export const twentyfourHour = (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 95 95"
  >
    <path className="cls-1" d="M95,47.5A47.5,47.5,0,1,1,47.5,0,47.5,47.5,0,0,1,95,47.5" />
    <g style={{ fill: '#fff' }}>
      <path
        className="cls-1"
        d="M38.27,53.75v4.52H23.7V54.48q6.47-10.59,7.69-13.1a9.9,9.9,0,0,0,1.22-3.93,2.86,2.86,0,0,0-.37-1.61,1.28,1.28,0,0,0-1.12-.53A1.24,1.24,0,0,0,30,35.9a5,5,0,0,0-.37,2.34v2.52H23.7v-1a21,21,0,0,1,.23-3.52,6.17,6.17,0,0,1,1.13-2.53,5.68,5.68,0,0,1,2.34-1.88,8.49,8.49,0,0,1,3.46-.64q3.95,0,6,2a6.6,6.6,0,0,1,2,5A11.79,11.79,0,0,1,37.72,43,99,99,0,0,1,31,53.75Z"
      />
      <path
        className="cls-1"
        d="M53.67,31.74V49.07h1.88V53.6H53.67v4.67H47.05V53.6H39.18V49.07L44.9,31.74ZM47.05,49.07V37.78L44.13,49.07Z"
      />
      <path
        className="cls-1"
        d="M60.28,42.86v3.47a3.45,3.45,0,0,1,1.07-.69,3.3,3.3,0,0,1,1.23-.23,2.85,2.85,0,0,1,1.72.51,2.06,2.06,0,0,1,.87,1.18,12.64,12.64,0,0,1,.16,2.45v8.71H61.48V49.38A5.52,5.52,0,0,0,61.37,48a.46.46,0,0,0-.49-.31.47.47,0,0,0-.48.32,4.28,4.28,0,0,0-.12,1.28v9H56.44V42.86Z"
      />
      <path
        className="cls-1"
        d="M70.5,45.64l-.15,1.66a2.86,2.86,0,0,1,2.43-1.89v4.44a3.22,3.22,0,0,0-1.55.29,1.2,1.2,0,0,0-.61.8,14.27,14.27,0,0,0-.11,2.35v5H66.65V45.64Z"
      />
    </g>
    <path
      style={{ fill: '#fff' }}
      d="M47.5,17.77a1.42,1.42,0,0,1-1.42-1.42v-9a1.42,1.42,0,1,1,2.83,0v9A1.42,1.42,0,0,1,47.5,17.77Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M47.5,89.81A1.47,1.47,0,0,1,46,88.34V78.65a1.47,1.47,0,0,1,2.94,0v9.69A1.47,1.47,0,0,1,47.5,89.81Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M16.35,49.28H7A1.44,1.44,0,1,1,7,46.4h9.35a1.44,1.44,0,1,1,0,2.89Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M88,49.28H78.65a1.44,1.44,0,1,1,0-2.89H88a1.44,1.44,0,1,1,0,2.89Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M30,17.93a.44.44,0,0,1-.38-.22L26.87,13a.44.44,0,0,1,.76-.44l2.72,4.72a.44.44,0,0,1-.38.66Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M67.75,83.38a.46.46,0,0,1-.4-.23l-3-5.23a.46.46,0,1,1,.8-.46l3,5.23a.46.46,0,0,1-.4.7Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M12.43,68.55a.46.46,0,0,1-.23-.86l5.12-3a.46.46,0,0,1,.46.79l-5.12,3A.45.45,0,0,1,12.43,68.55Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M77.75,30.82a.45.45,0,0,1-.22-.83l4.83-2.79a.45.45,0,1,1,.45.77L78,30.76A.44.44,0,0,1,77.75,30.82Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M17.25,30.82a.44.44,0,0,1-.22-.06L12.2,28a.45.45,0,0,1,.45-.77L17.48,30a.45.45,0,0,1-.22.83Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M27.25,83.38a.46.46,0,0,1-.4-.7l3-5.23a.46.46,0,1,1,.8.46l-3,5.23A.46.46,0,0,1,27.25,83.38Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M65,17.93a.44.44,0,0,1-.38-.66l2.72-4.72a.44.44,0,0,1,.76.44l-2.72,4.72A.44.44,0,0,1,65,17.93Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M82.57,68.55a.46.46,0,0,1-.23-.06l-5.13-3a.46.46,0,1,1,.46-.79l5.13,3a.46.46,0,0,1-.23.86Z"
    />
  </svg>
);

export default twentyfourHour;
