import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InternalContainer from '../../layout/containers/Internal';
import DocumentsList from '../components/DocumentsList';

class DocumentListContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  render() {
    return (
      <InternalContainer title="Documents">
        <DocumentsList {...this.props} />
      </InternalContainer>
    );
  }
}

export default connect()(DocumentListContainer);
