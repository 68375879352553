import React from 'react';

export const scheduledAirlineFailure = (
  <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 95 95">
    <path className="cls-1" d="M95,47.5A47.5,47.5,0,1,1,47.5,0,47.5,47.5,0,0,1,95,47.5" />
    <path
      style={{ fill: '#fff' }}
      d="M77,32.12c-2.09,3.37-9,9.4-17.37,15.27C44.4,58.06,22.87,69.89,20.56,66.6S36.73,47.12,52,36.44c8.38-5.87,16.41-10.29,20.29-11.1,3.17-.67,4.73-.47,5.77,1s.68,3-1,5.77"
    />
    <polygon
      style={{ fill: '#fff' }}
      points="26.53 57.71 14.39 54.6 12.15 56.17 22.02 65.31 22.26 65.66 27.48 78.07 29.73 76.49 30.95 64.04 26.53 57.71"
    />
    <polygon
      style={{ fill: '#fff' }}
      points="55.69 34.3 19.47 22.83 16.77 24.72 19.47 25.67 43.44 42.88 55.69 34.3"
    />
    <polygon
      style={{ fill: '#fff' }}
      points="62.92 44.63 61.31 82.59 58.61 84.48 58.65 81.62 50.67 53.21 62.92 44.63"
    />
    <path
      className="cls-3"
      d="M75.33,63.85,15.87,53.21a4.34,4.34,0,0,1-3.51-5l2.28-12.72a4.34,4.34,0,0,1,5-3.51L79.13,42.58a4.34,4.34,0,0,1,3.51,5L80.37,60.34a4.34,4.34,0,0,1-5,3.51"
    />
    <path
      style={{ fill: '#fff' }}
      d="M76.1,64.42a4.83,4.83,0,0,1-.86-.08L15.78,53.71a4.85,4.85,0,0,1-3.91-5.62l2.28-12.72a4.84,4.84,0,0,1,5.62-3.91L79.22,42.09a4.85,4.85,0,0,1,3.91,5.62L80.86,60.43A4.85,4.85,0,0,1,76.1,64.42Zm-57.19-32a3.84,3.84,0,0,0-3.78,3.16L12.85,48.27A3.84,3.84,0,0,0,16,52.72L75.42,63.36a3.85,3.85,0,0,0,4.45-3.1l2.28-12.72A3.84,3.84,0,0,0,79,43.08L19.58,32.44A3.88,3.88,0,0,0,18.91,32.38Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M21.18,35.54l-2.54,14.2-2.58-.46-.15,0a.4.4,0,0,1-.3-.2.63.63,0,0,1,0-.42q.08-.43.33-.52l.25-.1a.3.3,0,0,0,.09-.11,1.52,1.52,0,0,0,.16-.52L18.27,37a1.52,1.52,0,0,0,0-.54.31.31,0,0,0,0-.13l-.2-.18a.6.6,0,0,1-.13-.6.63.63,0,0,1,.18-.38.4.4,0,0,1,.35-.08l.15,0Zm.58,7.17a4.31,4.31,0,0,1,.74.51,2.2,2.2,0,0,1,.43.54A3.05,3.05,0,0,1,23.31,45a6.14,6.14,0,0,1-.09,1.68A4.7,4.7,0,0,1,22,49.36a2.22,2.22,0,0,1-1,.58,2.87,2.87,0,0,1-1.23,0l-.69-.12.2-1.09a.89.89,0,0,0,.39-.05.77.77,0,0,0,.29-.25,2.88,2.88,0,0,0,.4-1.16l.42-2.36a5.12,5.12,0,0,0,.1-.82,1.65,1.65,0,0,0-.09-.56.78.78,0,0,0-.16-.31,1,1,0,0,0-.31-.19l.19-1.08a.9.9,0,0,0,.35,0,.68.68,0,0,0,.26-.2,1.58,1.58,0,0,0,.23-.38,2.13,2.13,0,0,0,.15-.44l.1-.57.32-1.8a3.51,3.51,0,0,0,.05-.6,2.25,2.25,0,0,0,0-.51,1,1,0,0,0-.18-.4.79.79,0,0,0-.34-.22l.2-1.14.67.12a2.36,2.36,0,0,1,1.84,1.34,3.33,3.33,0,0,1,.29,1.17A5.74,5.74,0,0,1,24,41a3.28,3.28,0,0,1-.67,1,2.1,2.1,0,0,1-.62.45,4.4,4.4,0,0,1-.91.28"
    />
    <path
      style={{ fill: '#fff' }}
      d="M27.41,39.38l-.09,2.21L25.1,48.13a3.53,3.53,0,0,0-.14.53,1.16,1.16,0,0,0,.17,1l.17.2a.65.65,0,0,1,.1.55c-.06.35-.23.5-.52.44l-1.47-.26a.42.42,0,0,1-.31-.22.72.72,0,0,1,0-.46.61.61,0,0,1,.34-.45,2,2,0,0,0,.41-.28,1.92,1.92,0,0,0,.29-.37,5.74,5.74,0,0,0,.47-1.09Zm.87-2.57,2.34.42L30,49.74q0,.33,0,.59a.57.57,0,0,0,.17.36.73.73,0,0,1,.17.27.72.72,0,0,1,0,.32c-.06.36-.25.51-.55.46L27,51.23a.33.33,0,0,1-.25-.2.7.7,0,0,1,0-.42.81.81,0,0,1,.1-.3,1,1,0,0,1,.26-.22.6.6,0,0,0,.2-.2.89.89,0,0,0,.1-.3,2.17,2.17,0,0,0,0-.31l.09-1.54-1.62-.29.38-1.21,1.3.23L28,37.8Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M33.51,41.51l.29,1.94L32.8,49a4.43,4.43,0,0,0-.08,1.22,1.82,1.82,0,0,0,.29.85l.14.21a.66.66,0,0,1,.07.5.69.69,0,0,1-.18.38.45.45,0,0,1-.41.07L31.3,52a.48.48,0,0,1-.43-.64.67.67,0,0,1,.11-.29,1.35,1.35,0,0,1,.29-.25,2.86,2.86,0,0,0,.91-1.91Zm3.08-3.22L38,48.46,37.14,53l-1.58-.28L33.8,39.89a4,4,0,0,0-.11-.59.55.55,0,0,0-.17-.27l-.19-.18a.36.36,0,0,1-.11-.21.87.87,0,0,1,0-.31,1,1,0,0,1,.11-.32.43.43,0,0,1,.18-.19,1.16,1.16,0,0,1,.55,0Zm1.66,8.5-.28-2,.51-2.88a4.9,4.9,0,0,0,.1-1.36,1.81,1.81,0,0,0-.36-.88.59.59,0,0,1-.13-.25.89.89,0,0,1,0-.32.67.67,0,0,1,.17-.39.39.39,0,0,1,.35-.07l1.45.26c.2,0,.32.11.36.23a.77.77,0,0,1,0,.41.75.75,0,0,1-.11.32,1.21,1.21,0,0,1-.32.25,1.79,1.79,0,0,0-.62.78,4.9,4.9,0,0,0-.32,1.14Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M42,53.92l-2.59-.46-.15,0a.4.4,0,0,1-.3-.2.63.63,0,0,1,0-.42c.05-.28.16-.46.33-.52l.25-.1a.31.31,0,0,0,.09-.11,1.52,1.52,0,0,0,.16-.52l1.86-10.41a1.52,1.52,0,0,0,0-.54.31.31,0,0,0,0-.13l-.2-.18a.61.61,0,0,1-.13-.6.63.63,0,0,1,.18-.38.4.4,0,0,1,.35-.08l.15,0,2.59.46.15,0a.39.39,0,0,1,.3.2.63.63,0,0,1,0,.42c-.05.28-.16.46-.33.52l-.25.1a.3.3,0,0,0-.09.11,1.5,1.5,0,0,0-.16.52L42.37,52c-.07.39-.05.63,0,.72l.16.14c.13.11.17.31.12.6s-.23.52-.51.47Zm4.1-9.67.28,8.53a2.75,2.75,0,0,0,.05.53.65.65,0,0,0,.15.3,1.09,1.09,0,0,1,.13.18.79.79,0,0,1,0,.4c-.07.37-.24.53-.53.47l-2.92-.52a.39.39,0,0,1-.29-.21.77.77,0,0,1,0-.47.66.66,0,0,1,.31-.48.94.94,0,0,0,.21-.16.48.48,0,0,0,.09-.2,2.47,2.47,0,0,0,0-.34l0-4.11a5.16,5.16,0,0,1,.06-1,2.19,2.19,0,0,1,.31-.76l2-3.26a2.72,2.72,0,0,0,.38-1,1.66,1.66,0,0,0,0-.56.72.72,0,0,0-.17-.4L46,41.07a.57.57,0,0,1-.11-.52.8.8,0,0,1,.2-.44.31.31,0,0,1,.29-.07l2.06.37a.37.37,0,0,1,.29.22.78.78,0,0,1,0,.47.72.72,0,0,1-.38.58l-.35.16a2.18,2.18,0,0,0-.59.48,7.25,7.25,0,0,0-.74,1Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M53.16,41.26,51,53.48a1.42,1.42,0,0,0,0,.6.31.31,0,0,0,.06.14l.24.18q.26.16.17.62a.63.63,0,0,1-.23.41.48.48,0,0,1-.39.06l-.2,0L48,55l-.15,0a.39.39,0,0,1-.3-.2.64.64,0,0,1,0-.42c.05-.28.16-.46.33-.52l.25-.1a.33.33,0,0,0,.09-.11,1.52,1.52,0,0,0,.16-.52l1.86-10.41a1.54,1.54,0,0,0,0-.54.3.3,0,0,0,0-.13l-.2-.18a.61.61,0,0,1-.13-.6.63.63,0,0,1,.18-.38.39.39,0,0,1,.35-.08l.15,0Zm.48,7.56a3.28,3.28,0,0,1,.76.47,2,2,0,0,1,.43.57,2.42,2.42,0,0,1,.24.84,3.42,3.42,0,0,1,0,1l-.38,2.15c0,.22,0,.34.06.36a.1.1,0,0,0,.09,0,1.24,1.24,0,0,0,.12-.17c.14-.22.26-.32.36-.3s.12.09.16.22a.81.81,0,0,1,0,.39,2.39,2.39,0,0,1-.31.75,3.66,3.66,0,0,1-.57.75,1.57,1.57,0,0,1-.6.4,1.37,1.37,0,0,1-.68,0,1.34,1.34,0,0,1-1.05-1,4.7,4.7,0,0,1,0-2.27l.31-1.75q.11-.58.12-.68a2.14,2.14,0,0,0,0-.75.69.69,0,0,0-.4-.51l.2-1.11a.73.73,0,0,0,.5-.21,1.22,1.22,0,0,0,.24-.4,4.54,4.54,0,0,0,.17-.7l.49-2.76a2.61,2.61,0,0,0,0-1,1.07,1.07,0,0,0-.42-.6l.21-1.15.15,0a6.3,6.3,0,0,1,1.08.28,2,2,0,0,1,1.06,1,4.45,4.45,0,0,1,.27,2.89,5.08,5.08,0,0,1-.94,2.31,2.36,2.36,0,0,1-.62.55,4.92,4.92,0,0,1-1,.41"
    />
    <path
      style={{ fill: '#fff' }}
      d="M57,52.32l1.46-8.16a1.52,1.52,0,0,0,0-.54.32.32,0,0,0,0-.13l-.2-.18a.59.59,0,0,1-.13-.59.66.66,0,0,1,.19-.39.4.4,0,0,1,.35-.08l.15,0,2.59.46.15,0,.2.08a.41.41,0,0,1,.13.22.76.76,0,0,1,0,.33.6.6,0,0,1-.33.51l-.25.1a.3.3,0,0,0-.09.11,1.5,1.5,0,0,0-.16.52l-1.56,8.73q-.16.88-.19,1.24a3.1,3.1,0,0,0,0,.65,1.09,1.09,0,0,0,.1.4,1.28,1.28,0,0,0,.31.32l-.24,1.36-.13,0a3,3,0,0,1-1.21-.46,3,3,0,0,1-.88-1,3.42,3.42,0,0,1-.41-1.35A8.76,8.76,0,0,1,57,52.32m3,5.1.24-1.36a1.56,1.56,0,0,0,1.12-.68,5.17,5.17,0,0,0,.86-2.28l1.21-6.78a2.67,2.67,0,0,0-.25-2.1,1,1,0,0,1-.18-.33.91.91,0,0,1,0-.36.56.56,0,0,1,.18-.37.49.49,0,0,1,.38-.05l1.42.25.24.06a.27.27,0,0,1,.11.1.59.59,0,0,1,.07.45.74.74,0,0,1-.16.37,2.19,2.19,0,0,1-.3.24,1.73,1.73,0,0,0-.54.69A4.41,4.41,0,0,0,64,46.42L62.8,53.22A8.32,8.32,0,0,1,61.86,56a3.24,3.24,0,0,1-1.14,1.17,1.83,1.83,0,0,1-.76.24"
    />
    <path
      style={{ fill: '#fff' }}
      d="M69.49,44.18,67.31,56.4a1.47,1.47,0,0,0,0,.6.31.31,0,0,0,.06.14l.24.18c.17.11.23.32.18.62a.63.63,0,0,1-.23.41.47.47,0,0,1-.39.06l-.2,0-2.59-.46-.15,0a.39.39,0,0,1-.3-.2.63.63,0,0,1,0-.42c.05-.28.16-.46.33-.52l.25-.1a.29.29,0,0,0,.09-.11,1.5,1.5,0,0,0,.16-.52l1.86-10.41a1.51,1.51,0,0,0,0-.54.31.31,0,0,0,0-.13l-.2-.18a.61.61,0,0,1-.13-.6.64.64,0,0,1,.19-.38.4.4,0,0,1,.35-.08l.15,0ZM68.42,53l.2-1.11a.75.75,0,0,0,.63-.48,4,4,0,0,0,.32-1.07L70.16,47a4.89,4.89,0,0,0,.09-.74,1.25,1.25,0,0,0-.08-.47.74.74,0,0,0-.39-.45l.2-1.12a3.11,3.11,0,0,1,1.69.72,3.17,3.17,0,0,1,.78,1.62,6.76,6.76,0,0,1,0,2.49,7.43,7.43,0,0,1-.86,2.55A2.62,2.62,0,0,1,68.42,53"
    />
    <path
      style={{ fill: '#fff' }}
      d="M73.64,44.92l1.53.27-.22,1.26a1.92,1.92,0,0,0-.55.64,7,7,0,0,0-.64,1.61l-.17.63a1.25,1.25,0,0,1-.21.45.29.29,0,0,1-.27.13c-.1,0-.17-.1-.21-.23a1.1,1.1,0,0,1,0-.51Zm4.57.82L76,58a1.46,1.46,0,0,0,0,.6.32.32,0,0,0,.06.14l.24.18c.17.11.23.32.17.62a.63.63,0,0,1-.23.41.49.49,0,0,1-.39.06l-.19,0-2.59-.46-.19,0a.51.51,0,0,1-.36-.21.61.61,0,0,1-.05-.44c.05-.31.18-.48.38-.52l.29-.08a.28.28,0,0,0,.1-.11,1.42,1.42,0,0,0,.19-.57l2.19-12.22Zm2,.36-.76,4.26a1.08,1.08,0,0,1-.18.47A.29.29,0,0,1,79,51a.28.28,0,0,1-.21-.21,1.24,1.24,0,0,1,0-.5l.06-.65a7,7,0,0,0,0-1.74,2,2,0,0,0-.29-.79l.23-1.26Z"
    />
  </svg>
);

export default scheduledAirlineFailure;
