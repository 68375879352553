import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ButtonGroup, Col, ControlLabel, Label, Row } from 'react-bootstrap';
import { getAccount, manageBulkMatching, runBulkMatching } from '../redux/accountActions';
import {
  Box,
  Button,
  DatePickerDob,
  HorizontalFormControl,
  Loader,
  SelectAccount,
  SelectBroker,
  SelectCurrency,
} from '../../../common/components';
import InternalContainer from '../../layout/containers/Internal';
import AccountMatching from '../components/AccountMatching';
import { openMenu } from '../../layout/redux/sideNavAction';

class AccountMatchingContainer extends Component {
  state = {
    accountId: '',
    brokerId: '',
    currency: 'GBP',
    accountType: 'debtor',
    transaction: {
      dateFrom: '',
      dateTo: '',
      amountFrom: '',
      amountTo: '',
    },
    premium: {
      dateFrom: '',
      dateTo: '',
      amountFrom: '',
      amountTo: '',
      type: 'net-premiums',
    },
  };

  componentWillMount() {
    const { params, actions } = this.props;

    if (params) {
      if (params.id) {
        this.setState({ accountId: params.id });
        actions.getAccount(params.id);
      }

      if (params.accountType) {
        this.setState({ accountId: params.accountType });
      }
    }
  }

  componentDidMount() {
    const { location, actions } = this.props;
    actions.openMenu(false);
    if (location && location.state) {
      this.setState(() => ({
        accountId: location.state.account || '',
        brokerId: location.state.broker || '',
      }));
    }
  }

  componentWillUpdate(nextProps, nextState) {
    const { accountId, accountType } = this.state;
    const { actions } = this.props;

    if (nextState.accountId && nextState.accountId !== accountId) {
      actions.getAccount(nextState.accountId);
    }

    const resource = nextProps.account.resources[accountId];
    if (resource) {
      const resourceType = resource.data.attributes.account_type;
      if (resourceType !== accountType) {
        this.setState({
          accountType: resourceType,
          premium: {
            ...nextState.premium,
            type: resourceType === 'debtor' ? 'net-premiums' : 'premiums',
          },
        });
      }
    }
  }

  componentWillUnmount() {
    const { actions } = this.props;
    actions.openMenu(true);
  }

  render() {
    const {
      accountId,
      brokerId,
      premium,
      transaction,
      currency,
    } = this.state;
    const { account } = this.props;
    const resource = account.resources[accountId];

    const labelStyle = (account) => {
      if (account.data.attributes.suspended) {
        return 'danger';
      } else if (account.data.attributes.limit < account.data.attributes.balance) {
        return 'warning';
      } else {
        return 'success';
      }
    };

    const accountLabel = (account) => {
      return (
        <Label className="pull-right" bsStyle={labelStyle(account)}>
          {account.data.attributes.formatted_balance}
        </Label>
      );
    };

    return (
      <InternalContainer title="Matching">
        <Box className="clearfix">
          <Row className="pre-matching-form">
            <Col md={3}>
              <ControlLabel className="filter-label">Account</ControlLabel>
              <SelectAccount
                async
                key={'select-account'}
                sort={'name'}
                onChange={(option) => this.setState({ accountId: option.value })}
                input={{ value: accountId }}
              />
            </Col>
            <Col md={3}>
              <ControlLabel className="filter-label">Broker</ControlLabel>
              <SelectBroker
                async
                key={'select-broker' + accountId}
                filters={{ account: accountId }}
                onChange={(option) =>
                  this.setState({ brokerId: option ? option.value : '' })
                }
                input={{ value: brokerId }}
                isClearable
              />
            </Col>
            <Col md={3}>
              <ControlLabel>Currency</ControlLabel>
              <SelectCurrency
                onChange={(option) => this.setState({ currency: option.value })}
                input={{ value: currency }}
                defaultValue={{ value: 'GBP', label: 'Pound sterling' }}
              />
            </Col>
            <Col md={3}>
              <ControlLabel>Premium Type</ControlLabel>
              <br />
              <ButtonGroup className="net-gross-filter" bsSize="md">
                <Button
                  bsStyle={premium.type === 'net-premiums' ? 'primary' : 'default'}
                  handleClick={() =>
                    this.setState({
                      premium: {
                        ...premium,
                        type: 'net-premiums',
                      },
                    })
                  }
                  label="Netted"
                />
                <Button
                  bsStyle={premium.type === 'premiums' ? 'primary' : 'default'}
                  handleClick={() =>
                    this.setState({
                      premium: {
                        ...premium,
                        type: 'premiums',
                      },
                    })
                  }
                  label="Split"
                />
              </ButtonGroup>
            </Col>
          </Row>

          <Row className="pre-matching-form">
            <Col sm={6}>
              <Col md={4}>
                <h4>Receipt Filters:</h4>
              </Col>
              <Col md={4}>
                <ControlLabel>Date From</ControlLabel>
                <DatePickerDob
                  input={{
                    value: transaction.dateFrom,
                    onChange: (value) =>
                      this.setState({
                        transaction: {
                          ...transaction,
                          dateFrom: value,
                        },
                      }),
                  }}
                />
                <ControlLabel>Date To</ControlLabel>
                <DatePickerDob
                  minDate={transaction.dateTo}
                  input={{
                    value: transaction.dateTo,
                    onChange: (value) =>
                      this.setState({
                        transaction: {
                          ...transaction,
                          dateTo: value,
                        },
                      }),
                  }}
                />
              </Col>
              <Col md={4}>
                <ControlLabel>Amount From</ControlLabel>
                <HorizontalFormControl
                  input={{
                    value: transaction.amountFrom,
                    onChange: (event) =>
                      this.setState({
                        transaction: {
                          ...transaction,
                          amountFrom: event.target.value,
                        },
                      }),
                  }}
                  currency
                />
                <ControlLabel>Amount To</ControlLabel>
                <HorizontalFormControl
                  input={{
                    value: transaction.amountTo,
                    onChange: (event) =>
                      this.setState({
                        transaction: {
                          ...transaction,
                          amountTo: event.target.value,
                        },
                      }),
                  }}
                  currency
                />
              </Col>
            </Col>
            <Col sm={6}>
              <Col md={4}>
                <h4>Premium Filters:</h4>
              </Col>
              <Col md={4}>
                <ControlLabel>Date From</ControlLabel>
                <DatePickerDob
                  input={{
                    value: premium.dateFrom,
                    onChange: (value) =>
                      this.setState({
                        premium: {
                          ...premium,
                          dateFrom: value,
                        },
                      }),
                  }}
                />
                <ControlLabel>Date To</ControlLabel>
                <DatePickerDob
                  minDate={premium.dateTo}
                  input={{
                    value: premium.dateTo,
                    onChange: (value) =>
                      this.setState({
                        premium: {
                          ...premium,
                          dateTo: value,
                        },
                      }),
                  }}
                />
              </Col>
              <Col md={4}>
                <ControlLabel>Amount From</ControlLabel>
                <HorizontalFormControl
                  input={{
                    value: premium.amountFrom,
                    onChange: (event) =>
                      this.setState({
                        premium: {
                          ...premium,
                          amountFrom: event.target.value,
                        },
                      }),
                  }}
                  currency
                />
                <ControlLabel>Amount To</ControlLabel>
                <HorizontalFormControl
                  input={{
                    value: premium.amountTo,
                    onChange: (event) =>
                      this.setState({
                        premium: {
                          ...premium,
                          amountTo: event.target.value,
                        },
                      }),
                  }}
                  currency
                />
              </Col>
            </Col>
          </Row>

          <div className="hr small-bottom-margin" />
          {resource ? (
            <AccountMatching
              key={'matching' + accountId}
              {...this.props}
              resource={resource}
              filters={this.state}
              accountLabel={accountLabel(resource)}
            />
          ) : (
            <div>
              {accountId ? (
                <Loader noShadow />
              ) : (
                <h1 className="text-center">Select an account to get started</h1>
              )}
            </div>
          )}

          <div className="hr small-bottom-margin" />
        </Box>
      </InternalContainer>
    );
  }
}

AccountMatching.propTypes = {
  dispatch: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    account: state.account,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      { getAccount, manageBulkMatching, runBulkMatching, openMenu },
      dispatch,
    ),
    dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountMatchingContainer);
