import React from 'react';

export const golfCover = (
  <svg id="golf_layer_1" data-name="Layer 1" viewBox="0 0 95 95">
    <path className="cls-1" d="M95,47.5A47.5,47.5,0,1,1,47.5,0,47.5,47.5,0,0,1,95,47.5" />
    <path
      style={{ fill: '#fff' }}
      d="M72.17,30.53a10.16,10.16,0,0,0-1.43-.61,6.75,6.75,0,0,0-7.91,2.84l-5.26,8.47c-0.53-.55-1.11-1.09-1.73-1.61l9.78-16.95a7.54,7.54,0,0,0-1.38-9.29,11.38,11.38,0,0,0-1.35-1.09,5.28,5.28,0,0,0-8.07,3.2A2.05,2.05,0,0,0,55,16.79c1.16,2.52,6.62,4.73,7.32,6.24a0.71,0.71,0,0,1,.07.32,3,3,0,0,1-.26.84L54,38.21a21.25,21.25,0,0,0-2.44-1.51l4.54-7.88a7.54,7.54,0,0,0-1.38-9.29,11.36,11.36,0,0,0-1.35-1.09,5.28,5.28,0,0,0-8.07,3.2A2,2,0,0,0,45.51,23c1.16,2.52,6.62,4.73,7.32,6.24a0.71,0.71,0,0,1,.07.32,3,3,0,0,1-.26.84l-3.17,5.49c-1.11-.29-2-0.2-2.37.46l-1.78,3c-16,10-23.86,18-24.87,25.18-1.84,13,17.73,23.32,22.06,17.43C54.74,65.26,59.87,58.12,56.42,54.62l-9,12.4L59.9,46.23c0.48-.81,0-2-1-3.4l4.16-6.69a2.67,2.67,0,0,1,.55-0.56,0.63,0.63,0,0,1,.28-0.08c1.49-.09,5.53,3.29,8,3.14A1.84,1.84,0,0,0,73,38.24,4.73,4.73,0,0,0,72.17,30.53ZM23.9,68.09C21.66,59.28,33.72,49.83,42.5,44l-14.36,24,6-12.21C29.88,54.28,28.6,58.67,23.9,68.09Z"
    />
  </svg>
);

export default golfCover;
