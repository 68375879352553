import React from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import Icon from '../../../../../common/components/Icon/Icon';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../../../redux/ProductReducer';

class DynamicDocumentDropdownActions extends React.Component {
  handleDropdownAction = () => {
    const { onCreateNewVersion } = this.props;
    onCreateNewVersion();
  };

  render() {
    const { document, showForm } = this.props;

    return (
      <div>
        <DropdownButton
          pullRight
          bsSize="small"
          title={'Actions'}
          bsStyle={'default'}
          id={'dropdown-scheme-actions-' + document.id}
          disabled={document.attributes.status === 'ended' || showForm}
        >
          {document.attributes.status !== 'ended' && !document.attributes.replaced && (
            <MenuItem eventKey="2" onClick={this.handleDropdownAction}>
              <Icon name="edit" />{' '}
              {document.attributes.status === 'pending' ? 'Edit' : 'New Version'}
            </MenuItem>
          )}
        </DropdownButton>
      </div>
    );
  }
}

export default connect(
  null,
  (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
  }),
)(DynamicDocumentDropdownActions);
