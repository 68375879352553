import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, ControlLabel, FormGroup } from 'react-bootstrap';
import MultiSelectMenu from '../SelectMenu/MultiSelectMenu';

export const SELECT_IDENTIFIER = 'channel';

class SelectChannel extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    label: PropTypes.string,
    labelSize: PropTypes.number,
    input: PropTypes.object.isRequired,
    infoButton: PropTypes.element,
    name: PropTypes.string,
    id: PropTypes.string,
    meta: PropTypes.object,
    object: PropTypes.bool,
    multi: PropTypes.bool,
    filters: PropTypes.array,
  };

  render() {
    const { infoButton, name, meta, label, labelSize, smFieldSize } = this.props;
    let source = 'channels';

    const labelSmSize = labelSize ? labelSize : 2;
    const menuSize = smFieldSize ? smFieldSize : !label ? 12 : 12 - labelSmSize;
    return (
      <FormGroup
        controlId={name}
        className={meta && meta.touched && meta.error ? ' has-error' : ''}
      >
        {label && (
          <Col sm={labelSmSize}>
            <ControlLabel>
              {label} {infoButton}
            </ControlLabel>
          </Col>
        )}
        <Col sm={menuSize}>
          <MultiSelectMenu
            source={source}
            sourceKey={'MultiChannels'}
            isClearable
            isObject
            {...this.props}
          />

          {meta && meta.touched && meta.error && (
            <span className="input-error">{meta.error}</span>
          )}
        </Col>
      </FormGroup>
    );
  }
}

export default connect()(SelectChannel);
