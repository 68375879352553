import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveQuotes, patchQuotes, saveReferral } from '../../redux/productActions';
import { Button } from '../../../../common/components';
import { get } from 'lodash';
import './styles.scss';

class QuoteSaveAndExit extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    currentProduct: PropTypes.object.isRequired,
    selectedSchemes: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    declaration: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.approveReferral = this.approveReferral.bind(this);
    this.saveQuotes = this.saveQuotes.bind(this);
  }

  saveQuotes(application) {
    const { hasReferrals } = this.props;
    if (hasReferrals) {
      return this.props.dispatch(saveReferral(application, this.props.currentProduct));
    }

    return this.props.dispatch(saveQuotes(application, this.props.currentProduct));
  }

  approveReferral(application) {
    if (application.data.id) {
      return this.props.dispatch(patchQuotes(application));
    }

    return this.props.dispatch(saveQuotes(application, this.props.currentProduct));
  }

  render() {
    const { className, product, selectedSchemes, handleSubmit } = this.props;
    const selectedScheme =
      selectedSchemes && selectedSchemes.length === 1 && selectedSchemes[0].id
        ? product.quotes[selectedSchemes[0].id]
        : false;
    const isReferral = selectedScheme && get(selectedScheme, 'meta.referrals', []).length;

    return (
      <div className={className ? className : 'save-quote-btn-sidebar'}>
        <Button
          label={isReferral ? 'Approve Quote' : 'Save and Exit'}
          bsStyle={isReferral ? 'primary' : 'primary'}
          block
          isLoading={product.isSaving}
          handleClick={handleSubmit(isReferral ? this.approveReferral : this.saveQuotes)}
        />
      </div>
    );
  }
}

export default connect()(QuoteSaveAndExit);
