import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import {
  Button,
  Modal,
  DatePickerDob,
  HorizontalFormControl,
} from '../../../../common/components';
import { openModal, closeModal } from '../../../../common/components/Modal/ModalRedux';
import { runBulkMatching, generateAccountingReference } from '../../redux/accountActions';
import { get, set } from 'lodash';
import moment from 'moment/moment';
import InfoLabel from '../../../../common/components/InfoLabel/InfoLabel';
import SelectCashAccount from '../../../../common/components/Selects/SelectCashAccount';
import { SelectField } from '../../../../common/components/ReduxFormField';
import { Row, Col } from 'react-bootstrap';
import './styles.scss';

const FORM_NAME = 'makeBulkPaymentForm';

const validate = (values) => {
  const errors = {};

  if (!get(values, 'meta.date')) {
    set(errors, 'meta.date', 'Date is required');
  }

  return errors;
};

class BulkPaymentButton extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    accountId: PropTypes.string,
    matchingDate: PropTypes.string,
    accountingReference: PropTypes.string,
  };

  componentWillUpdate(newProps) {
    const { dispatch, submitSucceeded, callback } = this.props;
    if (newProps.submitSucceeded && !submitSucceeded) {
      if (callback) {
        callback('');
      }
      dispatch(closeModal(FORM_NAME));
    }
  }

  handleSubmit(values) {
    const { accountingReference } = this.props;

    return this.props.dispatch(
      runBulkMatching(this.props.accountId, values.matchingDate, {
        cash_account: values.cashAccountId,
        description: values.description,
        payment_type: values.paymentType,
        payment_reference: accountingReference,
      }),
    );
  }

  handleOpen() {
    const { dispatch, change, reset, matchingDate, accountingReference } = this.props;
    dispatch(reset());
    if (!accountingReference) {
      dispatch(generateAccountingReference(true));
    }
    dispatch(change('meta.date', matchingDate));
    dispatch(openModal(FORM_NAME));
  }

  render() {
    const { label, handleSubmit, submitting, block, accountingReference } = this.props;
    const title = label || 'Bulk Payment';
    const paymentTypes = [
      { label: 'BACS', value: 'BACS' },
      { label: 'Cheque', value: 'Cheque' },
    ];
    const submitButton = (
      <Button
        type="submit"
        bsStyle="primary"
        isLoading={submitting}
        label="Make Bulk Payment"
        disabled={!accountingReference}
      />
    );

    return (
      <div>
        <Button
          bsStyle="primary"
          className="matching-sidebar-btn"
          label={title}
          handleClick={::this.handleOpen}
          block={block}
        />

        <Modal
          name={FORM_NAME}
          title={title}
          close
          handleSubmit={handleSubmit(::this.handleSubmit)}
          footer={submitButton}
        >
          <Field
            name="cashAccountId"
            label="Cash Account"
            labelSize={3}
            component={SelectCashAccount}
          />

          <Field
            name={'description'}
            label="Description"
            labelSize={3}
            component={HorizontalFormControl}
          />

          <Field
            name={'paymentType'}
            label="Type"
            labelSize={3}
            options={paymentTypes}
            component={SelectField}
          />

          <Field
            name={'matchingDate'}
            maxDate={moment().format()}
            label="Date"
            labelSize={3}
            component={DatePickerDob}
          />

          <Row>
            <Col sm={12}>
              <InfoLabel value={accountingReference} label="Reference" labelSize={3}/>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

const form = reduxForm({ form: FORM_NAME, validate })(BulkPaymentButton);

function mapStateToProps(state) {
  return {
    accountingReference: state.account.accountingReference,
  };
}

export default connect(mapStateToProps)(form);
