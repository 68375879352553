import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, ControlLabel, FormGroup } from 'react-bootstrap';
import SelectMenu from '../SelectMenu/SelectMenu';
import AsyncSelectMenu from '../SelectMenu/AsyncSelectMenu';

export const SELECT_IDENTIFIER = 'brokers';

class SelectBroker extends Component {
  static propTypes = {
    label: PropTypes.string,
    labelSize: PropTypes.number,
    infoButton: PropTypes.element,
  };

  renderSelectMenu() {
    const { isObject, byOrganisation, sourceKey, async, byUser, filters = {}, key } = this.props;

    if (async) {
      let menuFilters = filters;
      if (byUser) {
        menuFilters['users'] = byUser;
      }
      if (byOrganisation) {
        menuFilters['organisation'] = byOrganisation;
      }

      return (
        <AsyncSelectMenu
          key={key || 'select-broker'}
          url={SELECT_IDENTIFIER}
          filters={menuFilters}
          {...this.props}
        />
      );
    }

    if (isObject) {
      return (
        <SelectMenu
          source={SELECT_IDENTIFIER}
          sourceKey={`Object${sourceKey}`}
          filter={byUser ? `?filter[users]=${byUser}` : ''}
          isObject
          {...this.props}
        />
      );
    }

    return (
      <SelectMenu
        source={SELECT_IDENTIFIER}
        optionValuePath={'id'}
        sourceKey={sourceKey}
        filter={byUser ? `?filter[users]=${byUser}` : ''}
        {...this.props}
      />
    );
  }

  render() {
    const { infoButton, label, labelSize, meta } = this.props;

    if (label) {
      const labelSmSize = labelSize || 2;
      const menuSize = 12 - labelSmSize;

      return (
        <FormGroup className={meta && meta.touched && meta.error ? ' has-error' : ''}>
          <Col sm={labelSmSize}>
            <ControlLabel>
              {label} {infoButton}
            </ControlLabel>
          </Col>

          <Col sm={menuSize}>
            {this.renderSelectMenu()}
            {meta && meta.touched && meta.error && (
              <span className="input-error">{meta.error}</span>
            )}
          </Col>
        </FormGroup>
      );
    }

    return this.renderSelectMenu();
  }
}

export default connect()(SelectBroker);
