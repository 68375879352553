import React from 'react';

export const categoryA = (
  <svg id="category_a_layer_1" data-name="Layer 1" viewBox="0 0 95 95">
    <path className="cls-1" d="M95,47.5A47.5,47.5,0,1,1,47.5,0,47.5,47.5,0,0,1,95,47.5" />
    <text style={{ fill: '#fff' }} transform="translate(45.52 63.96)" />
    <path
      style={{ fill: '#fff' }}
      d="M58.61,17.74l8.56,57.59H51.87L51.07,65H45.71l-0.9,10.35H29.33L37,17.74H58.61Zm-7.94,37Q49.54,45,48.39,30.59q-2.29,16.54-2.87,24.19h5.16Z"
    />
  </svg>
);

export default categoryA;
