import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  InfoLabel,
  Box,
  Button,
  YesNoFormat,
} from '../../../../common/components';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import displayAddress from '../../../../helpers/displayAddress';
import {
  reactivateOrganisation,
  deactivateOrganisation,
} from '../../redux/organisationActions';
import displayName from '../../../../helpers/displayName';
import { EditNetworkButton } from '../EditNetworkButton';
import { get, reduce } from 'lodash';
import { Col, Label, Row } from 'react-bootstrap';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';

export default class OrganisationDetails extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
    organisation: PropTypes.object.isRequired,
  };

  reactivateOrganisation() {
    this.props.dispatch(reactivateOrganisation(this.props.resource.data.id));
  }

  deactivateOrganisation() {
    this.props.dispatch(deactivateOrganisation(this.props.resource.data.id));
  }

  convertToUrl(link) {
    if (/^(?:https?|link):\/\//.test(link)) {
      return link;
    }

    if (/^(?:http?|link):\/\//.test(link)) {
      return link;
    }

    return 'http://' + link;
  }

  buttons() {
    const { organisation } = this.props;
    const resource = this.props.resource.data.attributes;
    const organisationId = this.props.resource.data.id;
    const network = getIncludedResource(
      this.props.resource.data,
      this.props.resource.included,
      'network',
    );

    const actionButtons = [
      {
        permissions: ['organisation.edit'],
        orgTypes: ['network'],
        button: (
          <EditNetworkButton networkId={network.data.id} />
        ),
      },
      {
        permissions: ['organisation.edit'],
        button: (
          <Button
            label="Edit Organisation"
            link={true}
            to={'/admin/organisations/' + organisationId + '/edit'}
            className="pull-right"
            bsStyle="primary"
            rightIcon="pencil-square-o"
          />
        ),
      },
      {
        statuses: ['inactive'],
        permissions: ['organisation.activate'],
        button: (
          <Button
            className="pull-right"
            label="Reactivate Organisation"
            bsStyle="primary"
            rightIcon="check"
            isLoading={organisation.isReactivating}
            handleClick={::this.reactivateOrganisation}
          />
        ),
      },
      {
        statuses: ['active'],
        permissions: ['organisation.deactivate'],
        button: (
          <Button
            className="pull-right"
            label="Deactivate Organisation"
            bsStyle="primary"
            rightIcon="ban"
            isLoading={organisation.isDeactivating}
            handleClick={::this.deactivateOrganisation}
          />
        ),
      },
    ];

    return actionButtons.filter((button) => {
      return !(button.statuses && button.statuses.indexOf(resource.status) === -1);
    });
  }

  render() {
    const resource = get(this.props.resource, 'data.attributes');
    const insurers = getIncludedResource(
      this.props.resource.data,
      this.props.resource.included,
      'insurers',
    );
    const network = getIncludedResource(
      this.props.resource.data,
      this.props.resource.included,
      'network',
    );
    //const primaryContacts = getIncludedResource(this.props.resource.data, this.props.resource.included, 'primary_contacts')
    //const accountsContacts = getIncludedResource(this.props.resource.data, this.props.resource.included, 'accounts_contacts')
    const organisationType = getIncludedResource(
      this.props.resource.data,
      this.props.resource.included,
      'organisation_type',
    );
    const legalEntity = getIncludedResource(
      this.props.resource.data,
      this.props.resource.included,
      'legal_entity_type',
    );
    const standardCommission = getIncludedResource(
      network.data,
      this.props.resource.included,
      'standard_commission',
    );
    const address = displayAddress(this.props.resource.data.attributes.address);

    return (
      <Box>
        <h2 className="resource-name">
          {resource.name}
          {get(resource, 'metadata.principal_company') && (
            <Label className="pull-right" bsStyle="warning">
              Appointed Representative
            </Label>
          )}
        </h2>

        <Row>
          <Col sm={6}>
            <div className="form-horizontal">
              <InfoLabel label="Name" value={resource.name} labelSize={4} />
              <InfoLabel label="Address" value={address} labelSize={4} />
              <InfoLabel label="Phone Number" value={resource.phone1} labelSize={4} />
              <InfoLabel label="Phone Number 2" value={resource.phone2} labelSize={4} />
              <InfoLabel
                label="FCA Number"
                value={resource.regulatory_authority_number}
                labelSize={4}
              />
              <InfoLabel
                label="Legal Entity Type"
                value={
                  legalEntity.data.attributes ? legalEntity.data.attributes.name : ''
                }
                labelSize={4}
              />
              {legalEntity.data.attributes &&
                (legalEntity.data.attributes.code === 'lp' ||
                  legalEntity.data.attributes.code === 'llp') && (
                  <InfoLabel
                    label="Partners"
                    value={
                      resource.metadata &&
                      get(resource, 'metadata.partners', []).map((partner, i) => {
                        return i > 0 ? ', ' + partner : partner;
                      })
                    }
                    labelSize={4}
                  />
                )}
              {legalEntity.data.attributes &&
                legalEntity.data.attributes.code === 'soletrader' && (
                  <InfoLabel
                    label="Proprietor"
                    value={resource.metadata.proprietor}
                    labelSize={4}
                  />
                )}
              <InfoLabel
                label="Company Number"
                value={resource.legal_entity_number}
                labelSize={4}
              />
            </div>
          </Col>

          <Col sm={6}>
            <div className="form-horizontal">
              <InfoLabel
                label="Organisation Type"
                value={
                  organisationType.data.attributes
                    ? organisationType.data.attributes.name
                    : ''
                }
                labelSize={4}
              />
              {resource.organisation_type_name === 'Network' ? (
                <InfoLabel
                  label="Network Name"
                  value={network.data.attributes ? network.data.attributes.name : ''}
                  labelSize={4}
                />
              ) : (
                <InfoLabel
                  label="Parent Company"
                  value={get(resource, 'metadata.parent_company')}
                  labelSize={4}
                />
              )}
              {resource.organisation_type_name === 'Network' && (
                <InfoLabel
                  label="Commission Rate"
                  value={
                    standardCommission.data.attributes
                      ? standardCommission.data.attributes.rate + '%'
                      : ''
                  }
                  labelSize={4}
                />
              )}
              {resource.organisation_type_name === 'Broker' && (
                <InfoLabel
                  label="Principal Company"
                  value={get(resource, 'metadata.principal_company')}
                  labelSize={4}
                />
              )}

              <InfoLabel
                label="Website"
                value={
                  resource.metadata ? (
                    <a
                      href={this.convertToUrl(resource.metadata.website)}
                      target="__blank"
                    >
                      {resource.metadata.website}
                    </a>
                  ) : (
                    ''
                  )
                }
                labelSize={4}
              />
              <InfoLabel
                label="Status"
                value={displayName(resource.status)}
                labelSize={4}
              />
              <InfoLabel
                label="Created At"
                date
                value={resource.created_at}
                labelSize={4}
              />
              <InfoLabel
                label="Updated At"
                date
                value={resource.updated_at}
                labelSize={4}
              />
            </div>
          </Col>
        </Row>

        {/*{primaryContacts && primaryContacts.length > 0 && (
          <div>
            <div className="hr"/>
            <h2>Primary contacts</h2>
          </div>
        )}

        {Array.isArray(primaryContacts) && primaryContacts.map((contact) => (
          <div key={contact.id} className="form-horizontal">
            <div className="row">
              <div className="col-sm-6">
                <InfoLabel label="Name" value={ contact.attributes.first_name + ' ' + contact.attributes.last_name }
                           labelSize={4}/>
              </div>
              <div className="col-sm-6">
                <InfoLabel label="Phone" value={ contact.attributes.phone1 } labelSize={4}/>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <InfoLabel label="Email" value={ contact.attributes.email } labelSize={4}/>
              </div>
            </div>

            <div className="hr transparent"/>
          </div>
        ))}*/}

        {/* {accountsContacts && accountsContacts.length > 0 && (
          <div>
            <div className="hr"/>
            <h2>Accounts contacts</h2>
          </div>
        )}

        {Array.isArray(accountsContacts) && accountsContacts.map((contact) => (
          <div key={contact.id} className="form-horizontal">
            <div className="row">
              <div className="col-sm-6">
                <InfoLabel label="Name" value={ contact.attributes.first_name + ' ' + contact.attributes.last_name }
                           labelSize={4}/>
              </div>
              <div className="col-sm-6">
                <InfoLabel label="Phone" value={ contact.attributes.phone1 } labelSize={4}/>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <InfoLabel label="Email" value={ contact.attributes.email } labelSize={4}/>
              </div>
            </div>

            <div className="hr transparent"/>
          </div>
        ))}*/}

        {insurers && insurers.length > 0 && (
          <div>
            <div className="hr" />
            <h2>Insurer Brands</h2>
          </div>
        )}

        {resource.organisation_type_name === 'Insurer' &&
          (Array.isArray(insurers) &&
            insurers.map((insurer) => {
              let authorisedUsers = '';
              const insurerAuthorisedUsers = getIncludedResource(
                insurer,
                this.props.resource.included,
                'authorised_users',
                'users',
              );

              if (insurerAuthorisedUsers.length > 0) {
                authorisedUsers = reduce(
                  insurerAuthorisedUsers,
                  function(authorisedUsers, user, i) {
                    if (i > 0) {
                      authorisedUsers += ', ';
                    }
                    authorisedUsers +=
                      user.attributes.first_name + ' ' + user.attributes.last_name;
                    return authorisedUsers;
                  },
                  '',
                );
              }

              return (
                <div key={insurer.id} className="form-horizontal">
                  <Row>
                    <Col sm={6}>
                      <InfoLabel label="Code" value={insurer.id} labelSize={4} />
                      <InfoLabel
                        label="Agreement Number"
                        value={insurer.attributes.agreement_number}
                        labelSize={4}
                      />
                      <InfoLabel
                        label="Authorised Users"
                        value={authorisedUsers}
                        labelSize={4}
                      />
                      <InfoLabel
                        label="Carry Broker Risk"
                        value={
                          <YesNoFormat data={insurer.attributes.carry_broker_risk} />
                        }
                        labelSize={4}
                      />
                      <InfoLabel
                        label="Max Brokerage"
                        value={get(insurer.attributes, 'max_commission')}
                        labelSize={4}
                      />
                      <InfoLabel
                        label="Credit Terms (days)"
                        value={get(insurer.attributes, 'credit_terms')}
                        labelSize={4}
                      />
                    </Col>
                  </Row>
                </div>
              );
            }))}

        <Row>
          <Col sm={12}>
            <InternalButtonBar buttons={this.buttons()} />
          </Col>
        </Row>
      </Box>
    );
  }
}
