import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Upload.scss';
import { DropTarget } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

const droppableTarget = {
  drop(props, monitor) {
    // TODO: fix to always pass accept by props (Policy Details)
    const fileTypes = props.accept
      ? props.accept.split(',')
      : 'application/pdf'.split(',');

    const item = monitor.getItem();

    item.files = item.files.filter((file) => {
      const type = file.type;
      const wildCardType = file.type.split('/')[0] + '/*';
      let okay = true;

      fileTypes.forEach((fileType) => {
        if (!okay) {
          return;
        }

        okay = fileType === type || fileType === wildCardType;
      });

      return okay;
    });

    props.onDrop(item);
  },
  canDrop(props, monitor) {
    return monitor.getItem() && !props.isUploading;
  },
};

class Upload extends Component {
  static propTypes = {
    connectDropTarget: PropTypes.func.isRequired,
    isOver: PropTypes.bool.isRequired,
    canDrop: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    onDrop: PropTypes.func.isRequired,
    style: PropTypes.object,
  };

  render() {
    const {
      canDrop,
      isOver,
      connectDropTarget,
      onClick,
      style,
      isUploading,
    } = this.props;
    const isActive = canDrop && isOver;

    let classNames = ['upload-area'];

    if (isUploading) {
      classNames.push('disabled');
    }

    if (isActive) {
      classNames.push('active');
    } else if (canDrop) {
      classNames.push('droppable');
    }

    return connectDropTarget(
      <div className={classNames.join(' ')} onClick={onClick} style={style}>
        {isActive && false ? (
          <p>Release to upload</p>
        ) : (
          <p>
            Drag and Drop files here, or <u>click here</u> to select files to upload.
          </p>
        )}
      </div>,
    );
  }
}

export default DropTarget(NativeTypes.FILE, droppableTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
}))(Upload);
