import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { setAnswer } from '../../redux/screeningActions';
import getIncludedResponse from '../../../../helpers/getIncludedResource';
import { Button } from '../../../../common/components';
import { intersection } from 'lodash';
import './styles.scss';

export default class MultipleChoiceQuestion extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    screening: PropTypes.object.isRequired,
    condition: PropTypes.object.isRequired,
    question: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      answers: [],
      radio: [], // Elements that will cause the question to submit automatically and clear other choices
    };
  }

  componentWillReceiveProps({ question, screening }) {
    const answers = [];
    const radio = [];
    const options = getIncludedResponse(
      question,
      screening.declaration.included,
      'options',
    );

    if (screening.isSubmitting) {
      return;
    }

    options.map((option) => {
      // const option = options[id];

      if (option.attributes.isSelected) {
        answers.push(option.attributes.number);
      }

      if (option.attributes.isForward === false) {
        radio.push(option.attributes.number);
      }

      if (option.attributes.isDisabled && option.attributes.isForward) {
        answers.push(option.attributes.number);
      }
    });

    this.setState({
      answers,
      radio,
    });
  }

  handleCheckboxTick(e) {
    const { answers, radio } = this.state;
    const answer = Number.parseInt(e.target.value);

    const position = answers.indexOf(answer);

    if (position > -1) {
      this.setState({
        answers: answers.filter((val) => {
          return val !== answer;
        }),
      });
    } else {
      if (radio.indexOf(Number.parseInt(answer)) > -1) {
        this.setState(
          {
            answers: [answer],
          },
          ::this.handleSubmit,
        );

        return;
      }

      let newAnswers = [...answers, answer];

      if (intersection(answers, radio).length > 0) {
        newAnswers = [answer];
      }

      this.setState({
        answers: newAnswers,
      });
    }
  }

  handleSubmit() {
    const { dispatch, condition, question } = this.props;
    const { answers } = this.state;

    dispatch(
      setAnswer(
        condition,
        question,
        {
          id: answers.join(';'),
        },
        true,
      ),
    );
  }

  render() {
    const { question, screening } = this.props;
    const optionsResource = getIncludedResponse(
      question,
      screening.declaration.included,
      'options',
    );
    const { answers, radio } = this.state;
    let disableRadios = false;

    return (
      <div className="row question">
        <div className="col-xs-12">
          <label className="control-label">{question.attributes.text}</label>
          {question.info ? (
            <p className="text-info text-justify" style={{ fontSize: '14px' }}>
              {question.info}
              <br />
            </p>
          ) : (
            ''
          )}
          <div className="multi-select-container">
            {optionsResource.map((option, i) => {
              const inputId = option.id;
              const isForwardConditionSelected =
                option.attributes.isDisabled && option.attributes.isForward;
              let disabled = option.attributes.isDisabled;

              if (isForwardConditionSelected) {
                disableRadios = true;
              }

              if (
                disableRadios &&
                radio.indexOf(Number.parseInt(option.attributes.number)) > -1
              ) {
                disabled = true;
              }

              return (
                <div
                  key={i}
                  className={
                    'radio-container' + (disabled ? ' radio-container--disabled' : '')
                  }
                >
                  <div className={'form-radio'}>
                    <input
                      type="checkbox"
                      id={inputId}
                      value={option.attributes.number}
                      checked={
                        answers.indexOf(option.attributes.number) > -1 ||
                        isForwardConditionSelected
                      }
                      onChange={disabled ? () => {} : ::this.handleCheckboxTick}
                      disabled={disabled}
                    />
                    <label htmlFor={inputId} />
                  </div>
                  <label className="radio-label" htmlFor={inputId}>
                    {option.attributes.text}
                  </label>
                </div>
              );
            })}
          </div>

          <Button
            bsStyle="primary"
            isLoading={screening.isSubmitting}
            label="Submit"
            handleClick={::this.handleSubmit}
          />
        </div>
      </div>
    );
  }
}
