import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { MedicalScreening } from 'react-cega-medical-screening';
import './styles.scss';

export default class CEGASession extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    screening: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    finishScreening: PropTypes.func.isRequired,
  };

  render() {
   // const { screening, finishScreening } = this.props;

    return (
      <div className="screening-session-cega">
        {/*<MedicalScreening*/}
        {/*  url={'https://cega-medical-api.socrates.support'}*/}
        {/*  sessionId={screening.token}*/}
        {/*  onFinish={finishScreening}*/}
        {/*/>*/}
      </div>
    );
  }
}
