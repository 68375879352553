import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  DataTable,
  Button,
  SelectBroker,
  SelectAccount,
} from '../../../../common/components';
import { applyFilter } from '../../../../common/components/DataTable/redux/dataTableActions';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { Row, Col, FormControl, ControlLabel } from 'react-bootstrap';
import { FormattedDate } from 'react-intl';
import { openModal } from '../../../../common/components/Modal/ModalRedux';
import ReceiptModal from '../ReceiptModal';
import selectTheme, { styles } from '../../../../helpers/selectTheme';
import Select from 'react-select';
import './styles.scss';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';

export const TABLE_NAME = 'payments-and-receipts';

export default class AccountPaymentList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      account: '',
      broker: '',
      matched: 'matched',
      receipt: {},
    };
  }

  formatDate(cell) {
    return (
      <span>
        <FormattedDate value={new Date(cell)} />
      </span>
    );
  }

  handleRowClick(row) {
    this.setState({ receipt: row });
    this.props.dispatch(openModal(ReceiptModal.MODAL_IDENTIFIER));
  }

  handleSearchUpdate(event) {
    this.props.dispatch(
      applyFilter(TABLE_NAME, 'filter[reference]', event.target.value),
    );
  }

  handleAccountUpdate(option) {
    const { dispatch } = this.props;
    const value = option ? option.value : '';

    this.setState({ account: value });
    dispatch(applyFilter(TABLE_NAME, 'filter[account]', value));
  }

  handleBrokerUpdate(option) {
    const { dispatch } = this.props;
    const value = option ? option.value : '';

    this.setState({ broker: value });
    dispatch(applyFilter(TABLE_NAME, 'filter[broker]', value));
  }

  handleMatchedUpdate(option) {
    const { dispatch } = this.props;
    const value = option ? option.value : '';

    dispatch(applyFilter(TABLE_NAME, 'filter[batched]', value),
    );
  }

  render() {
    const { account, broker } = this.state;

    return (
      <Box>
        <h2>Payments & Receipts</h2>
        <Row>
          <Col sm={2}>
            <ControlLabel className="filter-label">Reference</ControlLabel>
            <FormControl onChange={::this.handleSearchUpdate} />
          </Col>
          <Col sm={4}>
            <ControlLabel className="filter-label">Account</ControlLabel>
            <SelectAccount
              async
              isClearable
              key={'select-account'}
              sort={'name'}
              filters={{ type: 'debtor,creditor' }}
              onChange={::this.handleAccountUpdate}
              input={{ value: account }}
            />
          </Col>
          <Col sm={4}>
            <ControlLabel className="filter-label">Sub Account (Broker)</ControlLabel>
            <SelectBroker
              async
              isClearable
              key={'select-broker' + account}
              filters={{ account: account }}
              onChange={::this.handleBrokerUpdate}
              input={{ value: broker }}
            />
          </Col>
          <Col sm={2}>
            <ControlLabel className="filter-label">Matched</ControlLabel>
            <Select
              theme={(theme) => selectTheme(theme)}
              styles={styles}
              onChange={(option) => this.handleMatchedUpdate(option)}
              options={[
                { label: 'Unmatched', value: 'unmatched' },
                { label: 'Matched', value: 'matched' },
                { label: 'Paid', value: 'paid' },
                { label: 'All', value: 'all' },
              ]}
              defaultValue={{ label: 'Unmatched', value: 'unmatched' }}
            />
          </Col>
        </Row>

        <Row className="data-table-row">
          <Col xs={12}>
            <DataTable
              source="/accounts/payments-and-receipts"
              autoFilter="debtor,creditor"
              autoFilterType="filter[account_type]"
              name={TABLE_NAME}
              showTotal
              onRowSelect={this.handleRowClick.bind(this)}
            >
              <TableHeaderColumn dataField="id" isKey hidden />

              <TableHeaderColumn
                dataField="date"
                dataFormat={this.formatDate}
                width={'100px'}
                dataSort
              >
                Date
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="accounting_reference"
                width={'120px'}
                dataSort
              >
                Accounting Reference
              </TableHeaderColumn>

              <TableHeaderColumn dataField="batch_reference" width={'120px'} dataSort>
                Payment Reference
              </TableHeaderColumn>

              <TableHeaderColumn dataField="account_name" width={'250px'}>
                Account
              </TableHeaderColumn>

              <TableHeaderColumn dataField="broker_name" width={'250px'}>
                Broker/Branch
              </TableHeaderColumn>

              <TableHeaderColumn dataField="description" width={'150px'} dataSort>
                Description
              </TableHeaderColumn>

              <TableHeaderColumn dataField="currency" width={'50px'}>
                Currency
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="formatted_amount"
                dataAlign="right"
                width={'100px'}
              >
                Amount
              </TableHeaderColumn>

              <TableHeaderColumn dataField="matched" dataAlign="right" width={'80px'}>
                Matched
              </TableHeaderColumn>
            </DataTable>
          </Col>
          <ReceiptModal dispatch={this.props.dispatch} receipt={this.state.receipt} />
        </Row>
        <Row>
          <Col sm={12}>
            <InternalButtonBar buttons={[
              {
                permissions: ['account.edit'],
                button: (
                  <Button
                    className="pull-right"
                    link
                    to="/accounts/payments-and-receipts/new"
                    label="New Payment/Receipt"
                    bsStyle="primary"
                  />
                )
              },
              {
                permissions: ['account.edit'],
                button: (
                  <Button
                    className="pull-right"
                    link
                    label="Import Remittances"
                    bsStyle="primary"
                  />
                )
              },
            ]}/>
          </Col>
        </Row>
      </Box>
    );
  }
}
