import React from 'react';
import {
  HorizontalFormControl,
  InfoLabel,
  Icon,
  Button,
} from '../../../../../../common/components';
import { Row, Col } from 'react-bootstrap';
import SubBenefitsArray from '../SubBenefits/SubBenefitsArray';
import { Field } from 'redux-form';
import { map } from 'lodash';

class BenefitsFields extends React.Component {
  state = {
    showMoreInfo: false,
  };

  componentWillMount() {
    const { dispatch, change, benefit, benefitKey, itsNewBenefit } = this.props;
    dispatch(change(`meta.config.benefits[${benefitKey}]`, benefit));

    if (itsNewBenefit) {
      this.setState({
        showMoreInfo: true,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { benefit, benefitKey, dispatch, change } = this.props;
    if (benefit !== nextProps.benefit) {
      dispatch(change(`meta.config.benefits[${benefitKey}]`, nextProps.benefit));
    }

    if (nextProps.showAll) {
      this.setState({
        showMoreInfo: true,
      });
    }

    if (!nextProps.showAll) {
      this.setState({
        showMoreInfo: false,
      });
    }
  }

  toggleComponent = () => {
    this.setState((prevState) => ({
      showMoreInfo: !prevState.showMoreInfo,
    }));
  };

  render() {
    const { benefit, benefitKey, onRemove, viewOnly } = this.props;
    const { showMoreInfo } = this.state;
    return (
      <Col xs={12}>
        <div className="section-title join" onClick={this.toggleComponent}>
          {benefit.name ? benefit.name : 'New Benefit'}
          <span className={'remove-benefit pull-right'} title={'remove benefit'}>
            <Icon name={showMoreInfo ? 'minus' : 'plus'} />
          </span>
        </div>
        <div className="product-container-section join no-margin">
          {showMoreInfo && (
            <div className="section-item">
              <Row>
                <Col sm={12}>
                  <h4>Benefit Details:</h4>
                </Col>
                <Col sm={6}>
                  <Field
                    name={`meta.config.benefits[${benefitKey}].name`}
                    label="Name:"
                    labelSize={4}
                    component={viewOnly ? InfoLabel : HorizontalFormControl}
                  />
                </Col>
                <Col sm={6}>
                  <Field
                    name={`meta.config.benefits[${benefitKey}].display_name`}
                    label="Short Name:"
                    labelSize={4}
                    component={viewOnly ? InfoLabel : HorizontalFormControl}
                  />
                </Col>
                <Col sm={6}>
                  <Field
                    name={`meta.config.benefits[${benefitKey}].text`}
                    label="Display Text:"
                    labelSize={4}
                    component={viewOnly ? InfoLabel : HorizontalFormControl}
                  />
                </Col>
                <Col sm={12}>
                  <Field
                    name={`meta.config.benefits[${benefitKey}].description`}
                    label="Description:"
                    labelSize={2}
                    componentClass="textarea"
                    component={viewOnly ? InfoLabel : HorizontalFormControl}
                  />
                </Col>
                <Col sm={12}>
                  <h4>Cover Level:</h4>
                </Col>
                {map(benefit.cover_level, (item, key) => {
                  return (
                    <Col key={key} sm={12}>
                      <Row>
                        <Col sm={6}>
                          <Field
                            name={`meta.config.benefits[${benefitKey}].cover_level.${key}`}
                            label={`${key}:`}
                            labelSize={4}
                            mdFieldSize={8}
                            component={viewOnly ? InfoLabel : HorizontalFormControl}
                          />
                        </Col>
                      </Row>
                    </Col>
                  );
                })}
                <Col sm={6}>
                  <Field
                    name={`meta.config.benefits[${benefitKey}].excess`}
                    label="Excess"
                    labelSize={4}
                    mdFieldSize={8}
                    component={viewOnly ? InfoLabel : HorizontalFormControl}
                  />
                </Col>
              </Row>
              <h4>Sub Benefits:</h4>
              <Row className="sub-benefits">
                <Col sm={10} smOffset={2}>
                  <SubBenefitsArray
                    viewOnly={viewOnly}
                    subBenefits={benefit.sub_benefits ? benefit.sub_benefits : []}
                    benefitKey={benefitKey}
                    benefit={benefit}
                    {...this.props}
                  />
                </Col>
              </Row>
              {!viewOnly && (
                <Row>
                  <Col sm={12}>
                    <Button handleClick={onRemove} className="pull-right">
                      Remove <Icon name={'trash'} />
                    </Button>
                  </Col>
                </Row>
              )}
            </div>
          )}
        </div>
      </Col>
    );
  }
}

export default BenefitsFields;
