import * as actions from './productReducer';
import Axios from 'axios';
import queryString from '../../../helpers/queryString';
import { push } from 'react-router-redux';
import { toastr } from 'react-redux-toastr';
import { each } from 'lodash';
import handleErrorResponse from '../../../helpers/handleErrorResponse';
import errorResponseToArray from '../../../helpers/errorResponseToArray';
import { isOwner } from '../../auth/redux/authActions';
import { formValueSelector } from 'redux-form';
import { getFormValues } from 'redux-form';
import { get, isEmpty, map, set, cloneDeep } from 'lodash';

const defaultIncludes = [];
const CancelToken = Axios.CancelToken;
let cancel;

export function isCalculating(status) {
  return {
    type: actions.IS_CALCULATING,
    status,
  };
}

export function isPremiumAdjusting(status) {
  return {
    type: actions.IS_PREMIUM_ADJUSTING,
    status,
  };
}

export function clearData() {
  return {
    type: actions.CLEAR_DATA,
  };
}

export function isUpdatingPremiumIndication(status) {
  return {
    type: actions.IS_UPDATING_PREMIUM_INDICATION,
    status,
  };
}

export function submitSucceeded(status) {
  return {
    type: actions.SUBMIT_SUCCEEDED,
    status,
  };
}

export function isSaving(status) {
  return {
    type: actions.IS_SAVING,
    status,
  };
}

export function isSearching(status) {
  return {
    type: actions.IS_SEARCHING,
    status,
  };
}

export function isSavingForExit(status) {
  return {
    type: actions.IS_SAVING_FOR_EXIT,
    status,
  };
}

export function isIssuing(status) {
  return {
    type: actions.IS_ISSUING,
    status,
  };
}

export function isProcessingUploadedEmployees(status) {
  return {
    type: actions.IS_PROCESSING_UPLOADED_EMPLOYEES,
    status,
  };
}

export function processingUploadedEmployeesSucceded(status) {
  return {
    type: actions.PROCESSING_UPLOADED_EMPLOYEES_SUCCEDED,
    status,
  };
}

export function processingUploadedEmployeesFailed(status) {
  return {
    type: actions.PROCESSING_UPLOADED_EMPLOYEES_FAILED,
    status,
  };
}

export function setRegions(data) {
  return {
    type: actions.SET_REGIONS,
    data,
  };
}

export function setSchemes(data) {
  return {
    type: actions.SET_SCHEMES,
    data,
  };
}

export function setSignpost(data) {
  return {
    type: actions.SET_SIGNPOST,
    data,
  };
}

export function setProduct(data) {
  return {
    type: actions.SET_PRODUCT,
    data,
  };
}

export function setPremiumOverrideCalculation(data) {
  return {
    type: actions.SET_PREMIUM_OVERRIDE_CALCULATION,
    data,
  };
}

export function clearPremiumOverrideCalculation() {
  return {
    type: actions.SET_PREMIUM_OVERRIDE_CALCULATION,
    data: {},
  };
}

export function setPremiumIndication(premium) {
  return {
    type: actions.SET_PREMIUM_INDICATION,
    premium,
  };
}

export function clearNextQuoteReference() {
  return {
    type: actions.SET_NEXT_QUOTE_REFERENCE,
    data: '',
  };
}

export function setNextQuoteReference(data) {
  return {
    type: actions.SET_NEXT_QUOTE_REFERENCE,
    data,
  };
}

export function setPolicyForAdjustment(data) {
  return {
    type: actions.SET_POLICY_FOR_ADJUSTMENT,
    data,
  };
}

export function setQuote(data) {
  return {
    type: actions.SET_QUOTE,
    data,
  };
}

export function setQuotes(data) {
  return {
    type: actions.SET_QUOTES,
    data,
  };
}

export function clearQuotes() {
  return {
    type: actions.CLEAR_QUOTES,
  };
}

export function getRegions() {
  return (dispatch) => {
    return Axios.get('products/regions')
      .then((res) => {
        dispatch(setRegions(res.data.data));
        return true;
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

// Issue Button Refactoring
export function getPremiumIndicationFromApplication(
  schemeId,
  application,
  region,
  startDate,
  maxTripDuration,
) {
  const clonedApplication = cloneDeep(application);
  set(clonedApplication, 'data.attributes.metadata.region', region);
  set(clonedApplication, 'data.attributes.metadata.start_date', startDate);
  set(clonedApplication, 'data.attributes.metadata.max_trip_duration', maxTripDuration);
  set(clonedApplication, 'data.attributes.metadata.scheme_type', 'annual');
  set(clonedApplication, 'data.attributes.metadata.destinations', []);

  return (dispatch) => {
    dispatch(isUpdatingPremiumIndication(true));
    return Axios.post('products/schemes/' + schemeId + ':calculate', clonedApplication)
      .then((res) => {
        dispatch(setPremiumIndication(res.data));
        dispatch(isUpdatingPremiumIndication(false));
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error);
      });
  };
}

export function getNextQuoteReference() {
  return (dispatch) => {
    dispatch(clearNextQuoteReference());
    return Axios.get('/policies/applications/quote-reference')
      .then((res) => {
        dispatch(setNextQuoteReference(res.data.meta.quote_reference));
        return true;
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function getSchemes(productId, filter) {
  return (dispatch) => {
    const endpoint =
      'products/schemes?filter[product]=' +
      productId +
      (filter ? '&filter[status]=' + filter : '');

    return Axios.get(endpoint)
      .then((res) => {
        dispatch(setSchemes(res.data.data));
        return true;
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

const getSignpost = (policyApplicationId, signpost) => {
  return () => {
    return Axios.post('public/contents/signpost', {
      meta: {
        name: signpost,
        policy_application_id: policyApplicationId
      }
    }).catch((error) => {
      console.error(error);
    });
  };
};

const processQuotes = (quotes, showQuotesWithErrors, schemeType) => {
  if (quotes.length === 0) {
    return {
      'quotes': [],
      'signposted': false
    };
  }

  const sortedPremiums = quotes.reduce((carry, quote) => {
    const hasErrors = !!quote.errors && !!quote.errors.length;
    const hasDeclines = !!quote.declines && !!quote.declines.length;
    if (!hasDeclines && !hasErrors && quote.premium && quote.premium.is_signposted && quote.premium.gross === 0) {
      carry.non_medical_premiums.push(quote);
      return carry;
    }
    if (!hasDeclines && !hasErrors && quote.premium && !quote.premium.is_signposted) {
      carry.non_medical_premiums.push(quote);
      return carry;
    }
    if (!hasDeclines && !hasErrors && quote.premium && quote.premium.is_signposted) {
      carry.medical_premiums.push(quote);
      return carry;
    }
    if (hasDeclines || hasErrors) {
      const errors = quote.errors && quote.errors.length ? quote.errors : [];
      // if on ST with an AMT exclusion then treat the screening error as a medical decline so that the medical decline logic will not fail
      const hasAnnualExclusion = !!errors.find((error) => (error.includes('Medical Condition has annual exclusion')));
      const isAnnualQuote = schemeType === 'annual';
      if (!isAnnualQuote && hasAnnualExclusion) {
        carry.medical_declines.push(quote);
        return carry;
      }
      const hasScreeningError = errors.find((error) => (error.includes('Medical Condition has winter sports exclusion') || error.includes('Medical Condition has annual exclusion')));
      if (hasScreeningError) {
        carry.screening_errors.push(quote);
        return carry;
      }
      const hasMedicalError = errors.reduce((carry, error) => {
        if (error.includes('Exceeds maximum medical score')
          || error.includes('Maximum premium')
          || error.includes('Medical Declaration Question \'is-terminal\' is not covered')) {
          return true;
        }
        return carry;
      }, false);
      const declines = hasDeclines ? quote.declines : [];
      const hasMedicalDecline = declines.reduce((carry, decline) => {
        if (decline.is_medical) {
          return true;
        }
        return carry;

      }, false);
      if (hasMedicalError || hasMedicalDecline) {
        carry.medical_declines.push(quote);
        return carry;
      }
      carry.non_medical_declines.push(quote);
    }
    return carry;
  }, {
    non_medical_premiums: [],
    medical_premiums: [],
    medical_declines: [],
    screening_errors: [],
    non_medical_declines: []
  });

  const displayQuotes = quotes.reduce((carry, quote) => {
    const hasErrors = quote.errors && quote.errors.length;
    const hasDeclines = quote.declines && quote.declines.length;
    if (showQuotesWithErrors || (!hasErrors && !hasDeclines)) {
      carry[quote.scheme.id] = { meta: quote };
    }
    return carry;
  }, {});

  if (!!sortedPremiums.medical_premiums.length && (!sortedPremiums.non_medical_premiums.length || quotes[0].scheme.cover_level === 'Cps_full_cover')) {
    return {
      'quotes': displayQuotes,
      'signposted': 'medical_premiums_bo'
    };
  }

  if (sortedPremiums.screening_errors.length === quotes.length) {
    return {
      'quotes': displayQuotes,
      'signposted': 'screening_errors_bo'
    };
  }
  if (sortedPremiums.medical_declines.length === quotes.length) {
    return {
      'quotes': displayQuotes,
      'signposted': 'medical_declines_bo'
    };
  }
  return {
    'quotes': displayQuotes,
    'signposted': false
  };


};

export function getQuotesAndSignposting(sourceId, application, target = 'sources', schemeType) {
  // wip: put the overrides code back for MTA
  const annualFilter = schemeType === 'annual' ? '?filter[scheme_type]=annual' : '';
  let endpoint = `${target}/${sourceId}:calculate${annualFilter}`;
  const showQuotesWithErrors = isOwner();

  return (dispatch, getState) => {
    if (getState().screening.isRecalculatingScore > 0) {
      toastr.error('Re-score in progress', 'Please try again in a few moments');
      return false;
    }

    dispatch(isCalculating(true));
    dispatch({ type: actions.CLEAR_QUOTES });

    return Axios.post(endpoint, application)
      .then((res) => {
        if (Array.isArray(res.data.meta)) {
          const processedQuotes = processQuotes(res.data.meta, isOwner(), application.data.attributes.metadata.scheme_type);
          const processQuotesSignpost = processedQuotes.signposted;

          if (processQuotesSignpost) {
            return Promise.resolve(dispatch(getSignpost(application.data.id, processQuotesSignpost)))
              .then((resp) => {
                dispatch(setSignpost(resp.data.data.attributes.content));
                return processedQuotes.signposted;
              })
              .finally(() => {
                const quoteArray = Object.values(processedQuotes.quotes);
                quoteArray.map((quote) => {
                  const errors = get(quote, 'errors', []);
                  if (!errors.length || (errors.length > 0 && showQuotesWithErrors)) {
                    dispatch(setQuote({ meta: quote.meta }));
                  }
                });
                dispatch(isCalculating(false));
                return processedQuotes.signposted;
              });
          } else {
            res.data.meta.map((quote) => {
              const errors = get(quote, 'errors', []);
              if (!errors.length || (errors.length > 0 && showQuotesWithErrors)) {
                dispatch(setQuote({ meta: quote }));
              }
            });
            dispatch(setSignpost(null));
          }
          return processQuotesSignpost;
        }
        return false;
      })
      .catch(() => {
        return false;
      })
      .finally(() => {
        dispatch(isCalculating(false));
      });
  };
}

export function getQuotes(sourceId, application, target = 'sources', schemeType, coverLevel = null) {
  const filters = [];
  if (schemeType === 'annual') {
    filters.push('filter[scheme_type]=annual');
  }
  if (coverLevel) {
    filters.push('filter[cover_level]=' + coverLevel);
  }

  let endpoint = `${target}/${sourceId}:calculate`;
  if (filters.length) {
    endpoint += '?' + filters.join('&');
  }
  const showQuotesWithErrors = isOwner();

  return (dispatch, getState) => {
    if (getState().screening.isRecalculatingScore > 0) {
      toastr.error('Re-score in progress', 'Please try again in a few moments');
      return false;
    }

    dispatch(isCalculating(true));
    dispatch({ type: actions.CLEAR_QUOTES });

    return Axios.post(endpoint, application)
      .then((res) => {
        if (Array.isArray(res.data.meta)) {
          res.data.meta.map((quote) => {
            const errors = get(quote, 'errors', []);
            if (!errors.length || (errors.length > 0 && showQuotesWithErrors)) {
              dispatch(setQuote({ meta: quote }));
            }
          });
        }

        dispatch(isCalculating(false));
        return true;
      })
      .catch(() => {
        dispatch(isCalculating(false));
      });
  };
}
export function getPremiumIndication(scheme, formValues) {
  return (dispatch) => {
    dispatch(isUpdatingPremiumIndication(true));

    const application = { data: get(formValues, 'data') };
    return Axios.post('products/schemes/' + scheme.id + ':calculate', application)
      .then((res) => {
        dispatch(setPremiumIndication(res.data));
        dispatch(isUpdatingPremiumIndication(false));
        return true;
      })
      .catch(() => {
        dispatch(isUpdatingPremiumIndication(false));
        return false;
        // handleErrorResponse(error);
      });
  };
}

export function getPremiumOverrideCalculation(scheme, formName) {
  if (cancel) {
    cancel();
  }

  return (dispatch, getState) => {
    const application = { data: formValueSelector(formName)(getState(), 'data') };
    dispatch(isPremiumAdjusting(true));
    return Axios.post('products/schemes/' + scheme.id + ':calculate', application, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    })
      .then((response) => {
        if (response) {
          dispatch(setPremiumOverrideCalculation(response.data));
        }
        dispatch(isPremiumAdjusting(false));
        return true;
      })
      .catch((error) => {
        dispatch(isPremiumAdjusting(false));
        handleErrorResponse(error);
      });
  };
}

export function calculateAdjustment(policyId, application, change, schemeType, coverLevel) {
  const META_ENDT = 'data.attributes.metadata.endorsements';
  let URL = '/premiums/mid-term-adjustments';

  const filters = [];
  if (schemeType === 'annual') {
    filters.push('filter[scheme_type]=annual');
  }
  if (coverLevel) {
    filters.push('filter[cover_level]=' + coverLevel);
  }

  if (filters.length) {
    URL += '?' + filters.join('&');
  }

  const showQuotesWithErrors = isOwner();

  return (dispatch) => {
    dispatch(isCalculating(true));
    dispatch({ type: actions.CLEAR_QUOTES });
    Axios.post('policies/' + policyId + URL, application)
      .then((res) => {
        const resEndorsements = get(res.data, 'meta.mta.endorsements');
        if (change) {
          // dispatch(change('data.relationships.schemes.data', [ { ...res.data.meta.scheme } ]))
          if (!isEmpty(resEndorsements)) {
            map(resEndorsements, (endorsement, key) => {
              dispatch(change(`${META_ENDT}[${key}].title`, endorsement.title));
              dispatch(change(`${META_ENDT}[${key}].content`, endorsement.content));
            });
          }
        }
        if (Array.isArray(res.data.meta)) {
          res.data.meta.map((quote) => {
            const errors = get(quote, 'errors', []);
            if (!errors.length || (errors.length > 0 && showQuotesWithErrors)) {
              dispatch(setQuote({ meta: quote }));
            }
          });
        }
        dispatch(isCalculating(false));
        return true;
      })
      .catch((error) => {
        dispatch(isCalculating(false));
        handleErrorResponse(error);
      });
  };
}


export function calculateAdjustmentAndSignpost(policyId, application, change, schemeType, coverLevel) {
  const META_ENDT = 'data.attributes.metadata.endorsements';
  let URL = '/premiums/mid-term-adjustments';

  const filters = [];
  if (schemeType === 'annual') {
    filters.push('filter[scheme_type]=annual');
  }
  if (coverLevel) {
    filters.push('filter[cover_level]=' + coverLevel);
  }

  if (filters.length) {
    URL += '?' + filters.join('&');
  }

  const localApplication = cloneDeep(application);
  const showQuotesWithErrors = isOwner();

  return (dispatch) => {
    dispatch(isCalculating(true));
    dispatch({ type: actions.CLEAR_QUOTES });
    Axios.post('policies/' + policyId + URL, application)
      .then((res) => {
        const resEndorsements = get(res.data, 'meta.mta.endorsements');
        if (change) {
          // dispatch(change('data.relationships.schemes.data', [ { ...res.data.meta.scheme } ]))
          if (!isEmpty(resEndorsements)) {
            map(resEndorsements, (endorsement, key) => {
              dispatch(change(`${META_ENDT}[${key}].title`, endorsement.title));
              dispatch(change(`${META_ENDT}[${key}].content`, endorsement.content));
            });
          }
        }
        if (Array.isArray(res.data.meta)) {
          const processedQuotes = processQuotes(res.data.meta, isOwner(), localApplication.data.attributes.metadata.scheme_type);
          const processQuotesSignpost = processedQuotes.signposted;
          const policySignpost = get(application, 'data.attributes.metadata.storage.policy_signpost');
          const shouldSignpost = ['medical_declines_bo', 'screening_errors_bo'].includes(processQuotesSignpost) && processQuotesSignpost !== policySignpost;
          const existingMedical = localApplication.data.attributes.metadata.storage.policy_medical || 0;
          const allQuotesMedicalIncreased = processQuotesSignpost === 'medical_premiums_bo' && Object.values(processedQuotes.quotes).reduce((carry, quote) => {
            const newPremium = quote.meta.mta.new_premium;
            if (newPremium && newPremium.medical) {
              return carry && newPremium.medical > existingMedical;
            }
            return carry;
          }, true);

          if (shouldSignpost || allQuotesMedicalIncreased) {
            return Promise.resolve(dispatch(getSignpost(application.data.attributes.id, processQuotesSignpost)))
              .then((resp) => {
                dispatch(setSignpost(resp.data.data.attributes.content));
                return processedQuotes.signposted;
              })
              .finally(() => {
                const quoteArray = Object.values(processedQuotes.quotes);
                quoteArray.map((quote) => {
                  const errors = get(quote, 'errors', []);
                  if (!errors.length || (errors.length > 0 && showQuotesWithErrors)) {
                    dispatch(setQuote({ meta: quote.meta }));
                  }
                });
                dispatch(isCalculating(false));
                return processedQuotes.signposted;
              });
          } else {
            res.data.meta.map((quote) => {
              const errors = get(quote, 'errors', []);
              if (!errors.length || (errors.length > 0 && showQuotesWithErrors)) {
                dispatch(setQuote({ meta: quote }));
                dispatch(change('data.attributes.metadata.signposted', false));
              }
            });
          }
        }
        dispatch(isCalculating(false));
        return true;
      })
      .catch((error) => {
        dispatch(isCalculating(false));
        handleErrorResponse(error);
      });
  };
}

export function getMTAPremiumOverrideCalculation(policyId, formName) {
  if (cancel) {
    cancel();
  }

  return (dispatch, getState) => {
    const application = { data: formValueSelector(formName)(getState(), 'data') };
    dispatch(isPremiumAdjusting(true));
    return Axios.post(
      'policies/' + policyId + '/premiums/mid-term-adjustment',
      application,
      {
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      },
    ).then((response) => {
        if (response) {
          dispatch(setPremiumOverrideCalculation(response.data));
        }
        dispatch(isPremiumAdjusting(false));
        return true;
      })
      .catch((error) => {
        dispatch(isPremiumAdjusting(false));
        handleErrorResponse(error);
      });
  };
}

export function getProduct(id, includes = defaultIncludes) {
  return (dispatch) => {
    const endpoint = 'products/' + id + queryString(includes);

    return Axios.get(endpoint)
      .then((res) => {
        dispatch(setProduct(res.data));
        return true;
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function saveAndExitQuote(formName, product) {
  return (dispatch, getState) => {
    dispatch(isSavingForExit(true));
    dispatch(submitSucceeded(false));
    const application = getFormValues(formName)(getState());

    if (product) {
      set(application, 'data.relationships.product.data.id', product.data.id);
    }

    if (
      isOwner() &&
      (!get(application, 'data.relationships.broker.data.id') ||
        !get(application, 'data.relationships.user.data.id'))
    ) {
      dispatch(dispatch(isSavingForExit(false)));
      return toastr.error('Error', 'A valid broker is required');
    }

    return Axios.post(
      'policies/applications?include=customer,schemes,premiums,policy,transactions,channel',
      application,
    )
      .then((res) => {
        dispatch(submitSucceeded(true));
        dispatch(push('/policies/applications/' + res.data.data.id));
        dispatch(clearQuotes());
        dispatch(isSavingForExit(false));
        return true;
      })
      .catch((error) => {
        dispatch(dispatch(isSavingForExit(false)));
        handleErrorResponse(error, 'There was an error saving your quote(s)');
      });
  };
}

export function setSource(data) {
  return {
    type: actions.SET_SOURCE,
    data,
  };
}

export function setSourceResults(data) {
  return {
    type: actions.SET_SOURCES,
    data,
  };
}

export function searchSource(searchTerm, product) {
  const finalUrl = `/sources?include=broker&filter[status]=active&filter[product]=${product}&page[limit]=10&filter[name]=${searchTerm}`;
  return (dispatch) => {
    dispatch(isSearching(true));
    return Axios.get(finalUrl)
      .then((res) => {
        dispatch(setSourceResults(res.data.data));
        dispatch(isSearching(false));
        return true;
      })
      .catch((error) => {
        dispatch(isSearching(false));
        console.error(error);
      });
  };
}

export function getSources(product) {
  const finalUrl = `/sources?include=broker&filter[status]=active&filter[product]=${product}&page[limit]=10`;
  return (dispatch) => {
    dispatch(isSearching(true));
    return Axios.get(finalUrl)
      .then((res) => {
        dispatch(setSourceResults(res.data.data));
        dispatch(isSearching(false));
        return true;
      })
      .catch((error) => {
        dispatch(isSearching(false));
        console.error(error);
      });
  };
}

export function getSource(id) {
  return (dispatch) => {
    return Axios.get('sources/' + id + '?include=broker,channel')
      .then((res) => {
        dispatch(setSource(res.data.data));
        return true;
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function saveQuotes(application, product, cb) {
  return (dispatch) => {
    if (!cb) {
      dispatch(submitSucceeded(false));
      dispatch(isSaving(true));
    }

    if (product) {
      set(application, 'data.relationships.product.data.id', product.data.id);
    }

    return Axios.post(
      'policies/applications?include=customer,schemes,premiums,policy,transactions,channel',
      application,
    )
      .then((res) => {
        if (cb) {
          cb(res.data);
        } else {
          dispatch(submitSucceeded(true));
          dispatch(push('/policies/applications/' + res.data.data.id));
          dispatch(clearQuotes());
        }

        dispatch(isSaving(false));
        return true;
      })
      .catch((error) => {
        dispatch(isSaving(false));

        if (cb) {
          cb(null, true, errorResponseToArray(error));
        } else {
          handleErrorResponse(error, 'There was an error saving your quote(s)');
        }
      });
  };
}

export function saveQuotesPatch(application, product, applicationId, cb) {
  return () => {
    if (product) {
      set(application, 'data.relationships.product.data.id', product.data.id);
    }
    const END_POINT =
      'policies/applications/' +
      applicationId +
      '?include=customer,schemes,premiums,policy,transactions,channel';
    return Axios.put(END_POINT, application)
      .then((res) => {
        if (cb) {
          cb(res.data);
        }
        return res.data;
      })
      .catch((error) => {
        if (cb) {
          cb(null, true, errorResponseToArray(error));
        } else {
          handleErrorResponse(error, 'There was an error saving your quote(s)');
        }
      });
  };
}

export function saveQuotesOnly(application, product, cb) {
  return () => {
    if (product) {
      set(application, 'data.relationships.product.data.id', product.data.id);
    }
    return Axios.post(
      'policies/applications?include=transactions,schemes,product,premiums,policy,customer,broker,channel&calculate=true',
      application,
    )
      .then((res) => {
        if (cb) {
          cb(res.data);
        }
        return res.data;
      })
      .catch((error) => {
        if (cb) {
          cb(null, true, errorResponseToArray(error));
        } else {
          handleErrorResponse(error, 'There was an error saving your quote(s)');
        }
      });
  };
}

export function saveReferral(application, product, cb) {
  return (dispatch) => {
    if (!cb) {
      dispatch(isSaving(true));
      dispatch(submitSucceeded(false));
    }

    if (product) {
      set(application, 'data.relationships.product.data.id', product.data.id);
    }

    return Axios.post(
      'policies/applications?include=customer,schemes,premiums,policy',
      application,
    )
      .then((res) => {
        return Axios.post(
          'policies/applications/' +
            res.data.data.id +
            ':refer?include=customer,schemes,premiums,policy,channel',
          application,
        )
          .then((referral) => {
            if (cb) {
              cb(referral.data);
            } else {
              dispatch(submitSucceeded(true));
              dispatch(push('/policies/applications/' + referral.data.data.id));
              dispatch(clearQuotes());
            }

            dispatch(isSaving(false));
            return true;
          })
          .catch((error) => {
            if (cb) {
              cb(null);
            }
            dispatch(isSaving(false));
            handleErrorResponse(
              error,
              'There was an error sending your quote for approval',
            );
          });
      })
      .catch((error) => {
        if (cb) {
          cb(null);
        }
        dispatch(isSaving(false));
        handleErrorResponse(error, 'There was an error saving your quote');
      });
  };
}

export function patchQuotes(application, cb) {
  return (dispatch) => {
    if (!cb) {
      dispatch(isSaving(true));
      dispatch(submitSucceeded(false));
    }

    return Axios.put(
      'policies/applications/' +
        application.data.id +
        '?include=customer,schemes,premiums,policy,channel',
      application,
    )
      .then((res) => {
        if (cb) {
          cb(res.data);
        } else {
          dispatch(submitSucceeded(true));
          dispatch(push('/policies/applications/' + res.data.data.id));
          dispatch(clearQuotes());
        }

        dispatch(isSaving(false));
        return true;
      })
      .catch((error) => {
        dispatch(isSaving(false));

        if (cb) {
          cb(null, true, errorResponseToArray(error));
        } else {
          handleErrorResponse(error, 'There was an error saving your quote(s)');
        }
      });
  };
}

export function setTransactionId(transactionId) {
  transactionId = transactionId ? transactionId : null;

  return {
    type: actions.SET_TRANSACTION_ID,
    transactionId,
  };
}

export function transactionEmergencyBrakes() {
  return (dispatch) => {
    dispatch(setTransactionId(null));
    dispatch(isIssuing(false));
  };
}

export function completeTransaction(policyId) {
  return (dispatch) => {
    dispatch(setTransactionId(null));
    dispatch(submitSucceeded(true));
    dispatch(push('/policies/issued/' + policyId));
    dispatch(isIssuing(false));
  };
}

export function failTransaction(message) {
  return (dispatch) => {
    dispatch(setTransactionId(null));
    dispatch(submitSucceeded(false));
    dispatch(isIssuing(false));

    toastr.error('Error', 'Transaction failed: ' + message);
  };
}

export function issuePolicy(applicationId, values) {
  return (dispatch) => {
    dispatch(isIssuing(true));
    dispatch(submitSucceeded(false));

    if (values) {
      set(values, 'meta.send_email', true);
    }

    return Axios.post('policies/applications/' + applicationId + ':issue', values)
      .then((res) => {
        dispatch(submitSucceeded(true));
        dispatch(push('/policies/issued/' + res.data.data.id));
        dispatch(isIssuing(false));
        return true;
      })
      .catch((error) => {
        let stopIssuing = true;

        each(error.response.data.errors, (e) => {
          if (e.id !== 'redirect-to-provider') {
            return;
          }

          dispatch(setTransactionId(e.data.transactionId));
          stopIssuing = false;
        });

        if (stopIssuing) {
          dispatch(isIssuing(false));
          handleErrorResponse(error, 'There was an error when issuing your policy');
        }
      });
  };
}

export function issuePolicyMta(applicationId, values) {
  return (dispatch) => {
    dispatch(isIssuing(true));
    dispatch(submitSucceeded(false));

    return Axios.post('policies/applications/' + applicationId + ':issue', values)
      .then((res) => {
        dispatch(submitSucceeded(true));
        dispatch(push('/policies/issued/' + res.data.data.id));
        dispatch(isIssuing(false));
        return true;
      })
      .catch((error) => {
        dispatch(isIssuing(false));
        handleErrorResponse(error, 'There was an error when issuing your policy');
      });
  };
}

export function processUploadedEmployees(applicationId, fileId) {
  return (dispatch) => {
    dispatch(isProcessingUploadedEmployees(true));
    const data = {
      meta: { file_id: fileId }
    };
    return Axios.post('policies/applications/' + applicationId + ':process-uploaded-employees', data)
      .then((res) => {
        dispatch(processingUploadedEmployeesSucceded(true));
        dispatch(isProcessingUploadedEmployees(false));
        return true;
      })
      .catch((error) => {
        dispatch(isProcessingUploadedEmployees(false));
        dispatch(processingUploadedEmployeesFailed(true));
        handleErrorResponse(error, 'There was an error processing uploaded employees');
      });
  };
}
