import React from 'react';
import PropTypes from 'prop-types';
import AutoCapsInput from '../AutoCapsInput/AutoCapsInput';
import { Field } from 'redux-form';
import { SelectField } from '../../../common/components/ReduxFormField';
import { Col, ControlLabel, FormGroup } from 'react-bootstrap';
import { size, join, split, capitalize, map } from 'lodash';
import './NameFields.scss';

const NameFields = ({
  baseName,
  label,
  labelSizes,
  fieldSizes,
  hideTitle,
  infoButton,
  child = false,
  disableOnBlur = false,
  clearable = false,
}) => {
  let titles = [];

  if (child) {
    titles = [
      { label: 'Mr', value: 'Mr' },
      { label: 'Mrs', value: 'Mrs' },
      { label: 'Miss', value: 'Miss' },
      { label: 'Ms', value: 'Ms' },
      { label: 'Master', value: 'Master' },
      { label: 'Mx', value: 'Mx' },
      { label: 'Dr', value: 'Dr' },
      { label: 'Prof', value: 'Prof' },
      { label: 'Rev', value: 'Rev' },
      { label: 'Cpt', value: 'Cpt' },
      { label: 'Sir', value: 'Sir' },
      { label: 'Dame', value: 'Dame' },
      { label: 'Lord', value: 'Lord' },
      { label: 'Lady', value: 'Lady' },
    ];
  } else {
    titles = [
      { label: 'Mr', value: 'Mr' },
      { label: 'Mrs', value: 'Mrs' },
      { label: 'Miss', value: 'Miss' },
      { label: 'Ms', value: 'Ms' },
      { label: 'Master', value: 'Master' },
      { label: 'Mx', value: 'Mx' },
      { label: 'Dr', value: 'Dr' },
      { label: 'Prof', value: 'Prof' },
      { label: 'Rev', value: 'Rev' },
      { label: 'Cpt', value: 'Cpt' },
      { label: 'Sir', value: 'Sir' },
      { label: 'Dame', value: 'Dame' },
      { label: 'Lord', value: 'Lord' },
      { label: 'Lady', value: 'Lady' },
    ];
  }

  const upper = (value) => {
    if (!size(value)) {
      return value;
    }
    return join(
      map(split(value, ' '), (val) => {
        return capitalize(val);
      }),
      ' ',
    );
  };

  const titleField = (
    <Field
      clearable={clearable}
      name={baseName + '.title'}
      placeholder="Title"
      options={titles}
      component={SelectField}
    />
  );

  const firstNameField = (
    <Field
      name={baseName + '.first_name'}
      placeholder="First Name"
      normalize={disableOnBlur ? upper : null}
      disableOnBlur={disableOnBlur}
      component={AutoCapsInput}
    />
  );

  const lastNameField = (
    <Field
      name={baseName + '.last_name'}
      placeholder="Surname"
      normalize={disableOnBlur ? upper : null}
      component={AutoCapsInput}
    />
  );

  const nameFields = (
    <div>
      {!hideTitle && (
        <Col xs={2} className="name-fields">
          {titleField}
        </Col>
      )}
      <Col xs={hideTitle ? 6 : 5} className="name-fields">
        {firstNameField}
      </Col>
      <Col xs={hideTitle ? 6 : 5} className="name-fields">
        {lastNameField}
      </Col>
    </div>
  );

  if (label && labelSizes && fieldSizes) {
    return (
      <FormGroup>
        <Col
          sm={labelSizes.sm || 2}
          md={labelSizes.md || 2}
          lg={labelSizes.lg || 2}
          componentClass={ControlLabel}
        >
          {label} {infoButton}
        </Col>
        <Col
          sm={fieldSizes.sm || 10}
          md={fieldSizes.md || 10}
          lg={fieldSizes.lg || 10}
        >
          {nameFields}
        </Col>
      </FormGroup>
    );
  }

  return nameFields;
};

NameFields.propTypes = {
  baseName: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelSize: PropTypes.number,
  mdFieldSize: PropTypes.number,
  hideTitle: PropTypes.bool,
};

export default NameFields;
