import React from 'react';
import { Form, reduxForm } from 'redux-form';
import OptionRatesTable from './components/OptionRatesTable/OptionRatesTable';
import MultiRiskOptionRatesTable from './components/MultiRiskOptionRatesTable/MultiRiskOptionRatesTable';
import OptionBooleanController from './components/OptionBooleanController/OptionBooleanController';
import OptionSingleRate from './components/OptionSingleRate';
import { Row, Col } from 'react-bootstrap';
import { Button } from '../../../../common/components';
import { map, get } from 'lodash';
import ProductContainer from '../ProductContainer/ProductContainer';

class OptionsForm extends React.Component {
  render() {
    const { handleSubmit, scheme, viewOnly } = this.props;
    const schemeType = get(scheme.schemes, '[0].attributes.scheme_type');

    return (
      <ProductContainer>
        <Form onSubmit={handleSubmit}>
          {map(scheme.options, (option, key) => {
            if (!option.arguments) {
              return (
                <OptionSingleRate
                  viewOnly={viewOnly}
                  rateType={option.rate_type}
                  name={option.name}
                  key={key}
                  optionKey={option.key}
                  rate={option.rate}
                  {...this.props}
                />
              );
            }

            if (option.arguments.length === 1) {
              return (
                <OptionBooleanController
                  viewOnly={viewOnly}
                  rateType={option.rate_type}
                  name={option.name}
                  key={key}
                  optionKey={option.key}
                  rates={option.rates}
                  {...this.props}
                />
              );
            }

            if (option.arguments.length === 2) {
              return (
                <OptionRatesTable
                  viewOnly={viewOnly}
                  rateType={option.rate_type}
                  name={option.name}
                  optionKey={option.key}
                  key={key}
                  rates={option.rates}
                  schemeType={schemeType}
                  {...this.props}
                />
              );
            }

            if (option.arguments.length === 3) {
              return (
                <MultiRiskOptionRatesTable
                  viewOnly={viewOnly}
                  rateType={option.rate_type}
                  name={option.name}
                  optionKey={option.key}
                  key={key}
                  rates={option.rates}
                  schemeType={schemeType}
                  {...this.props}
                />
              );
            }
          })}
          {!viewOnly && (
            <Row>
              <Col sm={12}>
                <Button bsStyle="primary" className="pull-right" type="submit">
                  Save Changes
                </Button>
              </Col>
            </Row>
          )}
        </Form>
      </ProductContainer>
    );
  }
}

export default reduxForm({
  form: 'OptionsForm',
})(OptionsForm);
