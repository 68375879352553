import React from 'react';

export const levelWorldPlus = (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 95 95"
  >
    <path className="cls-1" d="M95,47.5A47.5,47.5,0,1,1,47.5,0,47.5,47.5,0,0,1,95,47.5" />
    <path
      style={{ fill: '#fff' }}
      d="M35.16,55.32a3.12,3.12,0,0,0,2.37-1.09l19.2-17.68a13.21,13.21,0,0,1-8.68-6.95L32.89,49.79A3.87,3.87,0,0,0,32,52.16a3.16,3.16,0,0,0,3.16,3.16Zm0,0"
    />
    <path
      style={{ fill: '#fff' }}
      d="M25.94,72.32c6.38,4.9,16.19-4.38,16.56-4.74.1-.1,10.45-9.21,15.27-3.81a5,5,0,0,1,1.29,4c-.5,5.75-8.49,13.2-11.52,15.58a1.75,1.75,0,0,0,1.08,3.12,1.72,1.72,0,0,0,1.07-.37c.5-.39,12.1-9.59,12.85-18a8.42,8.42,0,0,0-2.17-6.61c-2.13-2.38-6.14-4.5-13.19-1.12A33.51,33.51,0,0,0,40.12,65C38,67,31.38,72.1,28.06,69.55a2.82,2.82,0,0,1-1.17-2.11c-.26-3,3-7.45,4.57-9.29l3.7-1.49a4,4,0,0,1-3.7-.83,3.31,3.31,0,0,1-.82-3.47L29,55.63c-1.14,1.28-6,7.06-5.62,12.1a6.31,6.31,0,0,0,2.52,4.59Zm0,0"
    />
    <path
      style={{ fill: '#fff' }}
      d="M69.79,29.36a11.4,11.4,0,0,0-18.42-13.2C53.42,20.35,58.75,28.32,69.79,29.36Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M50.14,17.82a11.4,11.4,0,0,0,18.43,13.3C58.16,29.68,52.61,22.35,50.14,17.82Z"
    />
    <rect
      className="cls-1"
      x="22.23"
      y="65.49"
      width="50.55"
      height="20.2"
      rx="5"
      ry="5"
    />
    <path
      style={{ fill: '#fff' }}
      d="M67.77,86.26H27.23a5.57,5.57,0,0,1-5.56-5.56V70.49a5.57,5.57,0,0,1,5.56-5.56H67.77a5.57,5.57,0,0,1,5.56,5.56v10.2A5.57,5.57,0,0,1,67.77,86.26ZM27.23,66.05a4.44,4.44,0,0,0-4.44,4.44v10.2a4.44,4.44,0,0,0,4.44,4.44H67.77a4.44,4.44,0,0,0,4.44-4.44V70.49a4.44,4.44,0,0,0-4.44-4.44Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M36.23,70.49,35.16,79.8h-3q-.42-2.15-.73-4.89-.15,1.17-.68,4.89h-3l-1.08-9.32H29l.26,3.25.25,3.14q.13-2.44.67-6.39h2.52q.05.41.26,3.07l.27,3.54q.21-3.38.68-6.62Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M42.25,75.94q0,1.4-.07,2a2.23,2.23,0,0,1-1.36,1.81,3.42,3.42,0,0,1-1.38.26,3.51,3.51,0,0,1-1.34-.24A2.24,2.24,0,0,1,36.71,78a19.68,19.68,0,0,1-.07-2V74.35q0-1.4.07-2a2.22,2.22,0,0,1,1.36-1.81,3.42,3.42,0,0,1,1.38-.26,3.51,3.51,0,0,1,1.34.24,2.24,2.24,0,0,1,1.39,1.8,19.76,19.76,0,0,1,.07,2Zm-2.42-3.06a2.89,2.89,0,0,0-.07-.83.29.29,0,0,0-.3-.18.33.33,0,0,0-.29.15,2.07,2.07,0,0,0-.1.87v4.35a4.23,4.23,0,0,0,.07,1,.29.29,0,0,0,.31.19.3.3,0,0,0,.32-.22,4.61,4.61,0,0,0,.07-1Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M43.15,70.49h1.71a13.09,13.09,0,0,1,2.32.13,1.6,1.6,0,0,1,1,.68A3.07,3.07,0,0,1,48.56,73a2.72,2.72,0,0,1-.27,1.46,1.42,1.42,0,0,1-1.06.45,1.82,1.82,0,0,1,1,.48,1.44,1.44,0,0,1,.31.55,8.7,8.7,0,0,1,.06,1.38V79.8H46.31v-3.1a2.14,2.14,0,0,0-.12-.93q-.12-.18-.62-.18v4.2H43.15Zm2.42,1.59v2.07a1.07,1.07,0,0,0,.57-.11q.16-.11.16-.73V72.8a.78.78,0,0,0-.16-.58A.9.9,0,0,0,45.57,72.08Z"
    />
    <path style={{ fill: '#fff' }} d="M51.92,70.49v7.45h1.47V79.8H49.5V70.49Z" />
    <path
      style={{ fill: '#fff' }}
      d="M54,70.49h1.81a11.28,11.28,0,0,1,2.37.16,1.82,1.82,0,0,1,.94.53,1.68,1.68,0,0,1,.4.82,12.37,12.37,0,0,1,.08,1.78V77a7.55,7.55,0,0,1-.12,1.68,1.26,1.26,0,0,1-.41.66,1.72,1.72,0,0,1-.73.33,6.57,6.57,0,0,1-1.3.09H54Zm2.42,1.59v6.13q.52,0,.64-.21a3.1,3.1,0,0,0,.12-1.14V73.24a4.93,4.93,0,0,0,0-.81.38.38,0,0,0-.18-.26A1.19,1.19,0,0,0,56.4,72.08Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M62.46,74.45h2.07V72.38h1.4v2.07H68v1.4H65.92v2.06h-1.4V75.86H62.46Z"
    />
  </svg>
);

export default levelWorldPlus;
