import React from 'react';
import { get, map } from 'lodash';
import { connect } from 'react-redux';
// import '../SchemeManagement/style.scss'
import { bindActionCreators } from 'redux';
import { actions } from '../../redux/ProductReducer';
import { Loader } from '../../../../common/components';
import InternalContainer from '../../../layout/containers/Internal';
import ProductListItem from './components/ProductListItem/ProductListItem';

export class ProductList extends React.Component {
  componentWillMount() {
    const { actions } = this.props;
    actions.getProducts();
  }

  componentWillUnmount() {
    const { actions } = this.props;
    actions.clearData();
  }

  renderSelectProduct = (products) => {
    if (products && products.length > 0) {
      return map(products, (product, key) => {
        return <ProductListItem key={key} product={product} />;
      });
    }
  };

  render() {
    const { productManagement } = this.props;
    const products = get(productManagement, 'products.data');

    return (
      <div className="admin-area product-container">
        <InternalContainer title="Product management">
          {products ? (
            <div className="animated fadeIn">{this.renderSelectProduct(products)}</div>
          ) : (
            <Loader />
          )}
        </InternalContainer>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    productManagement: state.productManagement,
  }),
  (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
  }),
)(ProductList);
