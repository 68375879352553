import React from 'react';
import InfoLabel from '../../../../../../common/components/InfoLabel/InfoLabel';
import HorizontalFormControl from '../../../../../../common/components/HorizontalFormControl/HorizontalFormControl';
import { Col, Row } from 'react-bootstrap';
import { Field } from 'redux-form';
import { map } from 'lodash';

class ScreeningFee extends React.Component {
  render() {
    const { fee, viewOnly, index } = this.props;
    return (
      <div className="margin-bottom">
        <h2>{fee.name}</h2>
        <div className="form-horizontal">
          <div className="section-item">
            <Row>
              <Col sm={12}>
                <Row>
                  <Col sm={12}>
                    <div className="section-title join">Rates</div>
                    <div className="product-container-section join">
                      <Row className="section-item">
                        <Col sm={6}>
                          <Field
                            name={`meta.patch.fees.${index}.rate_type`}
                            label={'Rate type:'}
                            labelSize={4}
                            mdFieldSize={4}
                            component={viewOnly ? InfoLabel : HorizontalFormControl}
                          />
                        </Col>
                        <Col sm={12}>
                          <h4>Channels</h4>
                        </Col>
                        <Col sm={6}>
                          {map(fee.rates, (rate, key) => {
                            return (
                              <Field
                                key={key}
                                name={`meta.patch.fees.${index}.rates.${key}`}
                                label={`${key}:`}
                                labelSize={4}
                                mdFieldSize={4}
                                component={viewOnly ? InfoLabel : HorizontalFormControl}
                              />
                            );
                          })}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default ScreeningFee;
