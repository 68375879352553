import React from 'react';
import { HorizontalFormControl, InfoLabel } from '../../../../common/components';
import { Form, Field, reduxForm } from 'redux-form';
import ClaimsHandlers from './components/Handlers/ClaimsHandler';
import AssistanceHandlers from './components/Handlers/AssistanceHandler';
import { Col, ControlLabel, Button, FormGroup, Row } from 'react-bootstrap';
import { map, get, filter } from 'lodash';
import SelectInsurer from '../../../../common/components/Selects/SelectInsurer';
import ProductContainer from '../ProductContainer/ProductContainer';

class MainDetailsForm extends React.Component {
  componentWillMount() {
    const { dispatch, change, scheme } = this.props;

    if (scheme) {
      map(scheme.schemes, (scheme, key) => {
        return (
          dispatch(
            change(`data.attributes.scheme_details[${key}].name`, scheme.attributes.name),
          ),
          dispatch(
            change(
              `data.attributes.scheme_details[${key}].cover_level`,
              get(scheme,'attributes.cover_level'),
            ),
          ),
          dispatch(
            change(
              `data.attributes.scheme_details[${key}].prefix`,
              get(scheme,'attributes.prefix'),
            ),
          )
        );
      });

      dispatch(
        change('data.relationships.insurer.data.id', get(scheme,`general.insurer[${0}].id`)),
      );

      // dispatch(change('meta.patch.rates.base.rate', scheme.general.baseRate))
      dispatch(change('meta.patch.parameters.max_age.min', get(scheme,'general.maxAge.min', '0')));
      dispatch(change('meta.patch.parameters.max_age.max', get(scheme, 'general.maxAge.max', '100')));
      dispatch(
        change(
          'meta.patch.parameters.trip_duration.min',
          get(scheme, 'general.tripDuration.min'),
        ),
      );
      dispatch(
        change(
          'meta.patch.parameters.trip_duration.max',
          get(scheme, 'general.tripDuration.max'),
        ),
      );
      const maxTripDurationBands = get(scheme, 'general.maxTripDuration.bands', {});

      for (let i = 0; i < Object.keys(maxTripDurationBands).length; i++) {
        let valueString = '';
        const value = Object.values(maxTripDurationBands)[i];

        if (typeof value === 'object' && value !== null) {
          valueString = JSON.stringify(value).replace(/[{"}]/g, '');
        } else if (Array.isArray(value)) {
          valueString = value.toString();
        } else {
          valueString = value;
        }

        if (valueString.includes(":")) {
          valueString = '(Age: days): ' + valueString;
        }

        dispatch(
          change(
            `meta.patch.parameters.max_trip_duration.bands[${Object.keys(maxTripDurationBands)[i]}]`,
            valueString.replace("[", "").replace("]", "").split(',').join(',  ').split(':').join(': ')
          ),
        );
      }
      dispatch(
        change(
          'meta.patch.parameters.max_trip_duration.min',
          get(scheme, 'general.maxTripDuration.min'),
        ),
      );
      dispatch(
        change(
          'meta.patch.parameters.max_trip_duration.max',
          get(scheme, 'general.maxTripDuration.max'),
        ),
      );
      dispatch(
        change(
          'meta.patch.parameters.children.max-age',
          get(scheme, 'general.childMaxAge.max-age'),
        ),
      );
      dispatch(
        change(
          'meta.patch.parameters.adults.min-age',
          get(scheme, 'general.adultMinAge.min-age'),
        ),
      );
      dispatch(change('meta.patch.rules.max_score', get(scheme,'general.maxScore')));
      dispatch(
        change('meta.patch.rules.quote_valid_for_days', get(scheme,'general.quoteValidForDays')),
      );
      dispatch(
        change('meta.patch.rules.cancellation_period', get(scheme,'general.cancellationPeriod')),
      );
      dispatch(
        change(
          'meta.patch.rules.owner_backdate_cover',
          get(scheme, 'general.ownerBackdateCover'),
        ),
      );
      dispatch(
        change(
          'meta.patch.rules.exclude_postcode_prefixes',
          get(scheme,'general.excludePostcodePrefixes'),
        ),
      );
      dispatch(
        change(
          'data.attributes.metadata.sms_message',
          get(scheme,`schemes[${0}].attributes.metadata.sms_message`),
        ),
      );
      dispatch(
        change(
          'data.attributes.metadata.claims_handlers[0].notes',
          get(scheme,`schemes[${0}].attributes.metadata.claims_handlers[${0}].notes`),
        ),
      );

      map(scheme.schemes[0].attributes.metadata.claims_handlers, (claim, key) => {
        return (
          dispatch(
            change(`data.attributes.metadata.claims_handlers[${key}].notes`, claim.notes),
          ),
          dispatch(
            change(
              `data.attributes.metadata.claims_handlers[${key}].details`,
              claim.details,
            ),
          ),
          dispatch(
            change(`data.attributes.metadata.claims_handlers[${key}].email`, claim.email),
          ),
          dispatch(
            change(`data.attributes.metadata.claims_handlers[${key}].phone`, claim.phone),
          )
        );
      });

      map(
        scheme.schemes[0].attributes.metadata.assistance_handlers,
        (assistance, key) => {
          return (
            dispatch(
              change(
                `data.attributes.metadata.assistance_handlers[${key}].notes`,
                assistance.notes,
              ),
            ),
            dispatch(
              change(
                `data.attributes.metadata.assistance_handlers[${key}].details`,
                assistance.details,
              ),
            ),
            dispatch(
              change(
                `data.attributes.metadata.assistance_handlers[${key}].email`,
                assistance.email,
              ),
            ),
            dispatch(
              change(
                `data.attributes.metadata.assistance_handlers[${key}].phone`,
                assistance.phone,
              ),
            )
          );
        },
      );
    }
  }

  render() {
    const { handleSubmit, scheme, viewOnly } = this.props;
    const schemeType = get(scheme.schemes, '[0].attributes.scheme_type');
    const handlers = scheme.general.handlers;
    const maxTripDurationBands = get(scheme, 'general.maxTripDuration.bands', {});
    const claimsHandler = filter(handlers, [
      'attributes.organisation_type_name',
      'Claims Handler',
    ]);
    const assistanceHandler = filter(handlers, [
      'attributes.organisation_type_name',
      'Assistance Handler',
    ]);

    return (
      <ProductContainer>
        <Form onSubmit={handleSubmit}>
          <h2>Schemes</h2>
          {/*<div className="hr"/>*/}
          {map(scheme.schemes, (scheme, key) => {
            return (
              <div key={key}>
                <div className="section-title join">
                  {scheme.attributes.name}
                  <div className={`color-box ${scheme.attributes.cover_level}`} />
                </div>
                <div className="product-container-section join">
                  <Row className="form-horizontal section-item">
                    <Col sm={6}>
                      <Field
                        name={`data.attributes.scheme_details[${key}].name`}
                        label="Scheme Name:"
                        labelSize={4}
                        component={viewOnly ? InfoLabel : HorizontalFormControl}
                      />
                      <Field
                        name={`data.attributes.scheme_details[${key}].cover_level`}
                        label="Cover Level:"
                        labelSize={4}
                        component={viewOnly ? InfoLabel : HorizontalFormControl}
                        readOnly
                      />
                      <Field
                        name={`data.attributes.scheme_details[${key}].prefix`}
                        label="Prefix:"
                        labelSize={4}
                        disabled={viewOnly}
                        component={viewOnly ? InfoLabel : HorizontalFormControl}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            );
          })}

          {!viewOnly && (
            <Row className="margin-bottom">
              <Col sm={12}>
                <Button bsStyle="primary" className="pull-right" type="submit">
                  Save Changes
                </Button>
              </Col>
            </Row>
          )}

          <h2>General</h2>
          {/*<div className="hr"/>*/}
          <div className="section-title join">Rates and Rules</div>
          <div className="product-container-section join">
            <Row className="form-horizontal section-item">
              <Col sm={6}>
                <Field
                  name={'data.relationships.insurer.data.id'}
                  label="Insurer / Brand"
                  labelSize={4}
                  mdFieldSize={4}
                  component={SelectInsurer}
                />
                <FormGroup controlId="age-range">
                  <Col sm={4}>
                    <ControlLabel>Age Range (years):</ControlLabel>
                  </Col>
                  <Col sm={8}>
                    <Row>
                      <Col xs={6}>
                        <Field
                          name="meta.patch.parameters.max_age.min"
                          label="Min:"
                          labelSize={4}
                          component={viewOnly ? InfoLabel : HorizontalFormControl}
                        />
                      </Col>
                      <Col xs={6}>
                        <Field
                          name="meta.patch.parameters.max_age.max"
                          label="Max:"
                          labelSize={4}
                          component={viewOnly ? InfoLabel : HorizontalFormControl}
                        />
                      </Col>
                    </Row>
                  </Col>
                </FormGroup>
                {schemeType === 'single' && (
                  <FormGroup controlId="trip-range">
                    <Col sm={4}>
                      <ControlLabel>Trip Duration (days):</ControlLabel>
                    </Col>
                    <Col sm={8}>
                      <Row>
                        <Col xs={6}>
                          <Field
                            name="meta.patch.parameters.trip_duration.min"
                            label="Min:"
                            labelSize={4}
                            component={viewOnly ? InfoLabel : HorizontalFormControl}
                          />
                        </Col>
                        <Col xs={6}>
                          <Field
                            name="meta.patch.parameters.trip_duration.max"
                            label="Max:"
                            labelSize={4}
                            component={viewOnly ? InfoLabel : HorizontalFormControl}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>
                )}
                {schemeType === 'annual' && (
                  <FormGroup controlId="trip-range">
                    <Col sm={4}>
                      <ControlLabel>Max Trip Duration (days):</ControlLabel>
                    </Col>
                    <Col sm={8}>
                      <Row>
                        <Col xs={6}>
                          <Field
                            name="meta.patch.parameters.max_trip_duration.min"
                            label="Min:"
                            labelSize={4}
                            component={viewOnly ? InfoLabel : HorizontalFormControl}
                          />
                        </Col>
                        <Col xs={6}>
                          <Field
                            name="meta.patch.parameters.max_trip_duration.max"
                            label="Max:"
                            labelSize={4}
                            component={viewOnly ? InfoLabel : HorizontalFormControl}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>
                )}
                {schemeType === 'annual' && Object.keys(maxTripDurationBands).map((band) => {
                  return (
                    <Field
                      name={`meta.patch.parameters.max_trip_duration.bands[${band}]`}
                      label={`Trip duration Bands Days ${band.replace('_', ' ')}:`}
                      labelSize={4}
                      mdFieldSize={4}
                      component={viewOnly ? InfoLabel : HorizontalFormControl}
                    />
                  );
                })}
                <Field
                  name="meta.patch.parameters.adults.min-age"
                  label="Adult Min Age:"
                  labelSize={4}
                  mdFieldSize={4}
                  component={viewOnly ? InfoLabel : HorizontalFormControl}
                />
                <Field
                  name="meta.patch.parameters.children.max-age"
                  label="Child Max Age:"
                  labelSize={4}
                  mdFieldSize={4}
                  component={viewOnly ? InfoLabel : HorizontalFormControl}
                />
              </Col>

              <Col sm={6}>
                <Field
                  name="meta.patch.rules.max_score"
                  label="Max Medical Score:"
                  labelSize={4}
                  mdFieldSize={4}
                  component={viewOnly ? InfoLabel : HorizontalFormControl}
                />
                <Field
                  name="meta.patch.rules.quote_valid_for_days"
                  label="Quote Validity (days):"
                  labelSize={4}
                  mdFieldSize={4}
                  component={viewOnly ? InfoLabel : HorizontalFormControl}
                />
                <Field
                  name="meta.patch.rules.cancellation_period"
                  label="Cancellation Period:"
                  labelSize={4}
                  mdFieldSize={4}
                  component={viewOnly ? InfoLabel : HorizontalFormControl}
                />
                <Field
                  name="meta.patch.rules.owner_backdate_cover"
                  label="Back Date Cover Days:"
                  labelSize={4}
                  mdFieldSize={4}
                  component={viewOnly ? InfoLabel : HorizontalFormControl}
                />
                <Field
                  name="meta.patch.rules.exclude_postcode_prefixes"
                  label="Exclude Postcode Prefix:"
                  labelSize={4}
                  mdFieldSize={4}
                  component={viewOnly ? InfoLabel : HorizontalFormControl}
                />
              </Col>
            </Row>
          </div>

          <div className="section-title join">Assistance Details</div>
          <div className="product-container-section join">
            <Row className="form-horizontal section-item">
              <Col xs={12}>
                <Field
                  name="data.attributes.metadata.sms_message"
                  label="SMS Message Text:"
                  labelSize={2}
                  type="textarea"
                  component={viewOnly ? InfoLabel : HorizontalFormControl}
                />
              </Col>
              <div className="claims-handlers">
                <Col sm={4} md={2}>
                  <label className="control-label">Claims handlers:</label>
                </Col>
                <Col sm={8} md={10}>
                  <div className="assign-handler-container">
                    <Row>
                      {!viewOnly && (
                        <Col xs={5}>
                          <ClaimsHandlers resource={scheme} />
                        </Col>
                      )}
                      <Col xs={7}>
                        {claimsHandler && (
                          <InfoLabel
                            value={
                              claimsHandler.length > 0
                                ? claimsHandler[0].attributes.name
                                : 'Claims Handler not assigned'
                            }
                          />
                        )}
                      </Col>
                    </Row>
                  </div>

                  {map(
                    scheme.schemes[0].attributes.metadata.claims_handlers,
                    (claims, key) => {
                      return (
                        <div key={key}>
                          <div className="section-title join">Version {key + 1}</div>

                          <div className="product-container-section join">
                            <div className="row section-item">
                              <div className="col-sm-12">
                                <Field
                                  name={`data.attributes.metadata.claims_handlers[${key}].details`}
                                  label="Details:"
                                  labelSize={2}
                                  component={viewOnly ? InfoLabel : HorizontalFormControl}
                                />
                              </div>
                              <div className="col-sm-6">
                                <Field
                                  name={`data.attributes.metadata.claims_handlers[${key}].phone`}
                                  label="Phone:"
                                  labelSize={4}
                                  component={viewOnly ? InfoLabel : HorizontalFormControl}
                                />
                              </div>
                              <div className="col-sm-6">
                                <Field
                                  name={`data.attributes.metadata.claims_handlers[${key}].email`}
                                  label="Email:"
                                  labelSize={2}
                                  component={viewOnly ? InfoLabel : HorizontalFormControl}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    },
                  )}
                </Col>
              </div>

              <div className="claims-handlers">
                <Col sm={4} md={2}>
                  <label className="control-label">Assistance handlers:</label>
                </Col>
                <Col sm={8} md={10}>
                  <div className="assign-handler-container">
                    <Row>
                      {!viewOnly && (
                        <Col xs={5}>
                          <AssistanceHandlers resource={scheme} />
                        </Col>
                      )}
                      <Col xs={7}>
                        {assistanceHandler && (
                          <InfoLabel
                            value={
                              assistanceHandler.length > 0
                                ? assistanceHandler[0].attributes.name
                                : 'Assistance Handler not assigned'
                            }
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                  {map(
                    scheme.schemes[0].attributes.metadata.assistance_handlers,
                    (assistance, key) => {
                      return (
                        <div key={key}>
                          <div className="section-title join">Version {key + 1}</div>
                          <div className="product-container-section join">
                            <div className="row section-item">
                              <div className="col-sm-12">
                                <Field
                                  name={`data.attributes.metadata.assistance_handlers[${key}].details`}
                                  label="Details:"
                                  labelSize={2}
                                  component={viewOnly ? InfoLabel : HorizontalFormControl}
                                />
                              </div>
                              <div className="col-sm-6">
                                <Field
                                  name={`data.attributes.metadata.assistance_handlers[${key}].phone`}
                                  label="Phone:"
                                  labelSize={4}
                                  component={viewOnly ? InfoLabel : HorizontalFormControl}
                                />
                              </div>
                              <div className="col-sm-6">
                                <Field
                                  name={`data.attributes.metadata.assistance_handlers[${key}].email`}
                                  label="Email:"
                                  labelSize={2}
                                  component={viewOnly ? InfoLabel : HorizontalFormControl}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    },
                  )}
                </Col>
              </div>
            </Row>
          </div>
          {!viewOnly && (
            <Row>
              <Col sm={12}>
                <Button bsStyle="primary" className="pull-right" type="submit">
                  Save Changes
                </Button>
              </Col>
            </Row>
          )}
        </Form>
      </ProductContainer>
    );
  }
}

export default reduxForm({
  form: 'MainDetailsForm',
})(MainDetailsForm);
