import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field } from 'redux-form';
import InfoLabel from '../../../../../../common/components/InfoLabel/InfoLabel';
import HorizontalFormControl from '../../../../../../common/components/HorizontalFormControl/HorizontalFormControl';

class AdminFee extends React.Component {
  render() {
    const { fee, viewOnly } = this.props;
    return (
      <div className="margin-bottom">
        <h2>{fee.name}</h2>
        <div className="form-horizontal">
          <div className="section-item">
            <Row>
              <Col sm={12}>
                <Row>
                  <Col sm={12}>
                    <div className="section-title join">Rates</div>
                    <div className="product-container-section join">
                      <Row className="section-item">
                        <Col sm={6}>
                          <Field
                            name={`meta.patch.fees.${fee.key}.rate_type`}
                            label={'Rate type:'}
                            labelSize={4}
                            mdFieldSize={4}
                            component={viewOnly ? InfoLabel : HorizontalFormControl}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminFee;
