import React from 'react';
import SchemeHeader from './components/SchemeHeader/SchemeHeader';

class SchemeContainer extends React.Component {
  render() {
    const {
      activeScheme,
      schemeGroupList,
      product,
      router,
      children,
      params,
    } = this.props;
    return (
      <div>
        <div className="box product-container margin-bottom">
          {schemeGroupList && (
            <SchemeHeader
              activeScheme={activeScheme}
              schemeGroupList={schemeGroupList}
              product={product}
              router={router}
              params={params}
            />
          )}
          <div className="hr" />
          {children}
        </div>
      </div>
    );
  }
}

export default SchemeContainer;
