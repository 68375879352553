import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedTime, FormattedRelative } from 'react-intl';
import getIncludedResponse from '../../../helpers/getIncludedResource';
import { get } from 'lodash';
import './NotesList.scss';

export default class NotesList extends Component {
  render() {
    const { data, included, isFetching } = this.props;

    return (
      <div className="notes-list">
        {isFetching ? (
          <div className="note-item">
            <p className="note-text">Loading...</p>
          </div>
        ) : null}

        {!isFetching && data.length === 0 ? (
          <div className="note-item">
            <p className="note-text">No notes to display.</p>
          </div>
        ) : null}

        {!isFetching &&
          data.map((row, i) => {
            const classes = ['note-item'];
            const attachments = getIncludedResponse(row, included, 'attachments') || [];

            return (
              <div className={classes} key={i}>
                <p className="notes-text">
                  {row.attributes.content.split('\n').map((item, key) => {
                    return (
                      <span key={key}>
                        {item}
                        <br />
                      </span>
                    );
                  })}
                </p>
                {get(row, 'attributes.reminder') && (
                  <p className="reminder">
                    Reminder set for{' '}
                    <FormattedDate value={new Date(row.attributes.reminder_at)} />
                  </p>
                )}
                <p className="author">
                  {row.attributes.created_by_name}{' '}
                  <span className="date">
                    <FormattedDate value={new Date(row.attributes.noted_at)} />{' '}
                    <FormattedTime value={new Date(row.attributes.noted_at)} /> ({' '}
                    <FormattedRelative value={new Date(row.attributes.noted_at)} /> )
                  </span>
                </p>

                {attachments.length > 0 ? (
                  <p className="note-attachments">
                    <strong>Attachments: </strong>

                    {attachments.map((data, i) => {
                      return (
                        <span className="attachment-link" key={i}>
                          <a href={data.attributes.url} target="_blank">
                            {data.attributes.name}
                          </a>
                          {attachments.length - 1 !== i ? <span>, &nbsp;</span> : null}
                        </span>
                      );
                    })}
                  </p>
                ) : null}
              </div>
            );
          })}
      </div>
    );
  }
}

NotesList.propTypes = {
  onRowSelect: PropTypes.func,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  included: PropTypes.array,
  isFetching: PropTypes.bool,
  dataTable: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};
