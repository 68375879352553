import React from 'react';

export const manualBusiness = (
  <svg id="man_bus_layer_1" data-name="Layer 1" viewBox="0 0 95 95">
    <circle className="cls-1" cx="47.5" cy="47.5" r="47.5" />
    <path
      style={{ fill: '#fff' }}
      d="M37.34,29.22h0a1.34,1.34,0,0,0,.9,1.11l2.07.74,2.15-4-.64-.17c-2.57-.62-4.65.73-4.48,2.33Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M41.28,31.44c2,.74,4.24,1.59,5.35,2.14l.66.32,1.43.71,4-7.42A10.73,10.73,0,0,0,62.94,10.62l-1,1.87L58,19.75,52.1,20l-3-5.07,3.95-7.25,1-1.87a10.73,10.73,0,0,0-8.39,17.55Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M34.56,60.62c-.17-.1-.33-.21-.48-.32-1.28-1-4-2.88-6.15-4.34L27,57.61A10.73,10.73,0,0,0,16.85,74.18l1-1.87L21.82,65l5.88-.25,3,5.07-3.95,7.25-1,1.87A10.73,10.73,0,0,0,34.1,61.45Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M62.56,47.33l-.35-.17A6.43,6.43,0,0,1,59,42.86c-.92-3.93-5.07-7.51-7.85-10.37l-1.54,2.89a8.23,8.23,0,0,1,2.78,2.94c-2-1.86-2.43-2-6.15-3.84-2.31-1.14-9.66-3.66-9.66-3.66-2.19-1.05-4.62-.55-5.42,1.11a3.43,3.43,0,0,0,1,3.84,3.43,3.43,0,0,0-3.62,1.63,3.43,3.43,0,0,0,1,3.84,3.43,3.43,0,0,0-3.62,1.63,3.43,3.43,0,0,0,1,3.84,3.43,3.43,0,0,0-3.62,1.63c-.8,1.67.33,3.87,2.52,4.92,0,0,6.68,4.56,8.92,6.25,2.53,1.89,10.54,3.37,14.11,2.73a6.34,6.34,0,0,1,3.81.45l19,11,7.58-16.35Z"
    />
  </svg>
);

export default manualBusiness;
