import React from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import Icon from '../../../../../common/components/Icon/Icon';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../../../redux/ProductReducer';

class DocumentDropdownActions extends React.Component {
  handleDropdownAction = (action) => () => {
    const { actions, document } = this.props;
    actions.viewOrEditDocument(action, document);
  };

  render() {
    const { document } = this.props;

    return (
      <div>
        <DropdownButton
          pullRight
          bsSize="small"
          title={'Actions'}
          bsStyle={'default'}
          id={'dropdown-scheme-actions-' + document.id}
          // disabled={schemeStatus === 'inactive'}
        >
          <MenuItem eventKey="1" onClick={this.handleDropdownAction('view')}>
            <Icon name="eye" /> View
          </MenuItem>
          {document.attributes.status !== 'ended' && !document.attributes.replaced && (
            <MenuItem eventKey="2" onClick={this.handleDropdownAction('edit')}>
              <Icon name="pencil" />{' '}
              {document.attributes.status === 'pending' ? 'Edit' : 'New Version'}
            </MenuItem>
          )}
          {document.attributes.status !== 'ended' && !document.attributes.replaced && (
            <MenuItem eventKey="3" onClick={this.handleDropdownAction('add-superseding')}>
              <Icon name="plus" /> Add Superseding Document
            </MenuItem>
          )}
        </DropdownButton>
      </div>
    );
  }
}

export default connect(
  null,
  (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
  }),
)(DocumentDropdownActions);
