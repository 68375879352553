import React, { Component, createElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BootstrapTable } from 'react-bootstrap-table';
import {
  initTable,
  fetchData,
  setSource,
  setFilters,
  applyFilter,
  clearData,
} from './redux/dataTableActions';
import Icon from '../Icon/Icon';
import Loader from '../Loader/Loader';
import './DataTable.scss';

const MAX_ATTEMPTS = 30;
let timeout = null;

class DataTable extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    dataTables: PropTypes.object,
    source: PropTypes.string,
    autoFilterType: PropTypes.string,
    autoFilter: PropTypes.string,
    autoFilters: PropTypes.array,
    onRowSelect: PropTypes.func,
    waitForItems: PropTypes.number,
    waitForItemsMessage: PropTypes.string,
    perPage: PropTypes.number,
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  };

  state = {
    attempts: 0,
  };

  /**
   * Component will mount
   * Called just before the component mounts on the page
   */
  componentWillMount() {
    const { name, source, dispatch, perPage } = this.props;

    dispatch(initTable(name, source, perPage));
  }

  componentDidMount() {
    const {
      name,
      source,
      autoFilterType,
      autoFilter,
      autoFilters,
      dispatch,
      refreshData,
    } = this.props;

    if (source) {
      if (autoFilter) {
        dispatch(setSource(name, source));
        dispatch(setFilters(name, autoFilterType, autoFilter));
      } else if (autoFilters) {
        autoFilters.map((filter) => {
          dispatch(setFilters(name, filter.type, filter.value));
        });
      }

      if (refreshData) {
        dispatch(setFilters(name, 'refresh', true));
      }

      dispatch(fetchData(name));
    }
  }

  /**
   * Clean out the data when the component unmounts
   */
  componentWillUnmount() {
    this.props.dispatch(clearData(this.props.name));
  }

  componentWillReceiveProps(nextProps) {
    const { waitForItems, dataTables, dispatch, name } = nextProps;
    const table = dataTables.tables[name];

    if (table) {
      if (timeout && table.data.length >= waitForItems) {
        return clearTimeout(timeout);
      }

      if (
        !table.isFetching &&
        waitForItems &&
        this.state.attempts < MAX_ATTEMPTS &&
        table.data.length < waitForItems
      ) {
        timeout = setTimeout(() => {
          this.setState({ attempts: this.state.attempts + 1 }, () =>
            dispatch(fetchData(name)),
          );
        }, 2000);
      }
    }
  }

  renderTotal(from, to, total) {
    if (!this.props.showTotal) return;

    return (
      <div>
        Showing {from} to {to} of {total} entries
      </div>
    );
  }

  formatData(jsonApiData = []) {
    return jsonApiData.map((data) => {
      return {
        id: data.id,
        relationships: data.relationships,
        ...data.attributes,
      };
    });
  }

  onSortChange(sortName, sortOrder) {
    const { name, dispatch } = this.props;
    const sortBy = sortOrder === 'asc' ? '-' + sortName : sortName;
    dispatch(applyFilter(name, 'sort', sortBy));
  }

  onPageChange(page, sizePerPage) {
    const { name, dispatch } = this.props;
    const nextPage = page ? page : 1;
    const currentIndex = (nextPage - 1) * sizePerPage;
    const offset = currentIndex;

    dispatch(setFilters(name, 'page[offset]', offset ? offset : 0));
    dispatch(setFilters(name, 'page[limit]', sizePerPage));
    dispatch(fetchData(name));
  }

  onSizePerPageList(sizePerPage) {
    const {
      name,
      dispatch,
      dataTables: {
        tables: {
          [name]: { currentPage },
        },
      },
    } = this.props;
    const currentIndex = (currentPage - 1) * sizePerPage;
    const offset = currentIndex;

    dispatch(setFilters(name, 'page[offset]', offset || 0));
    dispatch(setFilters(name, 'page[limit]', sizePerPage));

    return sizePerPage;
  }

  /**
   * Render
   * Renders the markup for this component
   *
   * @returns {XML}
   */
  render() {
    if (!this.props.dataTables.tables[this.props.name]) {
      return null;
    }

    const {
      waitForItems,
      pageOff,
      customHeight,
      waitForItemsMessage,
      component,
      name,
      onSelect,
      dataTables: {
        tables: {
          [name]: { data, included, currentPage, total, filters, isFetching },
        },
      },
    } = this.props;
    const limit = filters['page[limit]'] || 10;

    const selectRowProps = this.props.onRowSelect
      ? {
          mode: 'radio',
          className: 'selected',
          clickToSelect: true,
          hideSelectColumn: true,
          onSelect: this.props.onRowSelect,
        }
      : {};

    const tableOptionProps = {
      noDataText: isFetching ? (
        <div className="text-center bs-table-placeholder">
          <Icon name="spinner" spin size="fa-1x" /> Loading results...
        </div>
      ) : (
        <div className="text-center bs-table-placeholder">No results found</div>
      ),
      sizePerPage: limit,
      onPageChange: ::this.onPageChange,
      sizePerPageList: [10, 20, 30, 40, 50],
      pageStartIndex: 1,
      page: currentPage,
      onSizePerPageList: ::this.onSizePerPageList,
      onSortChange: ::this.onSortChange,
    };

    return (
      <div>
        {!waitForItems ||
        data.length >= waitForItems ||
        this.state.attempts >= MAX_ATTEMPTS ? (
          <div
            className={
              'data-table' +
              (this.props.onRowSelect ? ' select-enabled' : ' select-disabled')
            }
          >
            {component ? (
              createElement(component, {
                data,
                included,
                isFetching,
                onSelect,
                dataTable: this,
              })
            ) : (
              <BootstrapTable
                ref={this.props.name}
                data={data ? this.formatData(data) : []}
                remote
                fetchInfo={{ dataTotalSize: total }}
                options={tableOptionProps}
                striped
                hover
                bordered={false}
                selectRow={selectRowProps}
                height={customHeight ? customHeight : ''}
                pagination={!pageOff}
              >
                {this.props.children}
              </BootstrapTable>
            )}
          </div>
        ) : (
          <Loader message={waitForItemsMessage} noShadow />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dataTables: state.dataTables,
  };
}

export default connect(mapStateToProps)(DataTable);
