import React from 'react';

export const reports = (
  <svg
    id="reports_1"
    data-name="reports 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 150.49 150.49"
  >
    <circle cx="75.25" cy="75.25" r="75.25" />
    <path style={{ fill: '#fff' }} d="M72.22,64.18a14,14,0,0,0,4.2-10.05H62.18Z" />
    <path
      style={{ fill: '#fff' }}
      d="M61.49,36.83v14.3H75.24C75.24,43.13,68.49,36.83,61.49,36.83Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M114.56,45.79,96.93,28.59c-2.09-2.09-6.31-3.46-9.29-3.46H37.14c-3,0-4.64,1.67-4.64,4.66V120c0,3,1.66,6.17,4.64,6.17h75.75c3,0,5.61-3.18,5.61-6.17V55A15.22,15.22,0,0,0,114.56,45.79ZM89.49,32a6.58,6.58,0,0,1,2.28,1.24l17.63,18.09a10.1,10.1,0,0,1,1.23,2.76H89.49Zm22,86.09h-73v-86h44V55c0,3,2.16,6.09,5.14,6.09h23.86Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M69.17,63.27,59.49,53.22v-14A14.34,14.34,0,0,0,45,53.33a14.14,14.14,0,0,0,24.19,9.94Z"
    />
    <rect style={{ fill: '#fff' }} x="97.49" y="72.13" width="10" height="43" />
    <rect style={{ fill: '#fff' }} x="82.49" y="87.13" width="9" height="28" />
    <rect style={{ fill: '#fff' }} x="67.49" y="77.13" width="10" height="38" />
    <rect style={{ fill: '#fff' }} x="53.49" y="96.13" width="9" height="19" />
  </svg>
);

export default reports;
