import React from 'react';
import { Field } from 'redux-form';
import { Row, Col } from 'react-bootstrap';
import {
  HorizontalFormControl,
  InfoLabel,
} from '../../../../../../common/components';
import { map, startCase } from 'lodash';

class OptionBooleanController extends React.Component {
  componentWillMount() {
    const { rates, optionKey, dispatch, change } = this.props;

    map(rates, (rate, key) => {
      return dispatch(change(`meta.patch.options[${optionKey}].rates.${key}`, rate));
    });
  }

  render() {
    const { name, optionKey, rates, rateType, viewOnly } = this.props;
    return (
      <div className="margin-bottom">
        <h2>{name}</h2>
        <h6>Rate Type: {rateType}</h6>
        <div className="form-horizontal">
          <div className="section-item">
            <Row>
              <Col sm={12}>
                <Row>
                  <Col sm={12}>
                    <div className="section-title join">Cover Level</div>
                    <div className="product-container-section join">
                      <Row className="section-item">
                        <Col sm={6}>
                          {map(rates, (rate, key) => {
                            return (
                              <Field
                                key={key}
                                name={`meta.patch.options[${optionKey}].rates.${key}`}
                                label={`${startCase(key)}:`}
                                labelSize={4}
                                mdFieldSize={4}
                                component={viewOnly ? InfoLabel : HorizontalFormControl}
                              />
                            );
                          })}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default OptionBooleanController;
