import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Box, Button, Icon } from '../../../../common/components';
import PropTypes from 'prop-types';
import './ProductTemplateBox.scss'
class ProductTemplateBox extends Component {
  render() {
    const { product, title, productIcon, backTo, handleBack } = this.props;

    if (!product) {
      return null;
    }

    return (
      <Box className="animated fadeIn active-product-container">
        <Row>
          <Col sm={9}>
            <h2 className={productIcon ? '' : 'title-with-icon'}>
              {productIcon ? (
                <span className="pull-left">{productIcon}</span>
              ) : (
                <img src={product.attributes.icon} alt={product.attributes.name} />
              )}
              {product.attributes.name} - {title}
            </h2>
          </Col>
          <Col sm={3}>
            <Button
              className="pull-right"
              bsStyle="link"
              link={!handleBack}
              handleClick={handleBack}
              to={!handleBack && backTo ? backTo : '/admin/products'}
            >
              <Icon name="arrow-left" />
              Back
            </Button>
          </Col>
        </Row>
        {this.props.children}
      </Box>
    );
  }
}

ProductTemplateBox.propTypes = {
  handleBack: PropTypes.func,
  title: PropTypes.string.isRequired,
};

export default ProductTemplateBox;
