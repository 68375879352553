import React from 'react';
import { Field } from 'redux-form';
import FieldFormControl from '../../../../../common/components/FieldFormControl/FieldFormControl';

class SchemeCheckboxList extends React.Component {
  render() {
    const { scheme } = this.props;

    return (
      <div className="scheme-checkbox-list">
        <div className={'checkbox-toggle-container'}>
          <Field
            value
            type="checkbox"
            component={FieldFormControl}
            name={`data.schemes.${scheme.name}.${scheme.id}`}
          >
            <div className="activate-scheme-item pull-left">
              <span>{scheme.name}</span>
            </div>
          </Field>
        </div>
      </div>
    );
  }
}

export default SchemeCheckboxList;
