import React from 'react';
import BenefitsArray from './components/Benefits/BenefitsArray';
import { Form, formValueSelector, reduxForm } from 'redux-form';
import { Row, Col, Button } from 'react-bootstrap';
import { flow } from 'lodash';
import { connect } from 'react-redux';
import ProductContainer from '../ProductContainer/ProductContainer';

class BenefitsForm extends React.Component {
  render() {
    const { handleSubmit, scheme, viewOnly } = this.props;
    return (
      <ProductContainer>
        <Form onSubmit={handleSubmit}>
          <BenefitsArray viewOnly={viewOnly} scheme={scheme} {...this.props} />
          {!viewOnly && (
            <Row>
              <Col sm={12}>
                <Button bsStyle="primary" className="pull-right" type="submit">
                  Save Changes
                </Button>
              </Col>
            </Row>
          )}
        </Form>
      </ProductContainer>
    );
  }
}

let selector;

export default flow(
  reduxForm({
    form: 'BenefitsForm',
  }),
  (component) => {
    selector = formValueSelector('BenefitsForm');

    return component;
  },
  connect((state) => {
    const benefits = selector(state, 'benefits');

    return {
      benefits,
    };
  }),
)(BenefitsForm);
