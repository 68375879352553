import React from 'react';
import { Button, DropdownButton, MenuItem, Modal } from 'react-bootstrap';
import { Field, Form, reduxForm } from 'redux-form';
import { Icon, DatePicker } from '../../../../../../../../common/components';
import confirm from 'react-confirm2';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../../../../../../redux/ProductReducer';
import { flow } from 'lodash';

class SchemeDropdownActions extends React.Component {
  state = {
    showModal: false,
  };

  handleActivateScheme = (id) => (formValues) => {
    const { actions, product } = this.props;

    actions
      .activateSchemeGroup(id, formValues)
      .then(
        () => (
          actions.clearData(),
          actions.getProducts(product.id),
          actions.getSchemeGroupList(product.id),
          actions.getProductsStats(),
          actions.getDestinations()
        ),
      );
  };

  handleDeactivateScheme = (id) => () => {
    const { actions, activeScheme } = this.props;
    const schemeStatus = activeScheme && activeScheme.attributes.status;
    const end = schemeStatus === 'active' || schemeStatus === 'pending';

    confirm('Deactivate Scheme', {
      description: 'Are you sure you want to deactivate this scheme?',
      done: () => actions.endSchemeGroup(id, end).then(() => actions.clearData()),
    });
  };

  handleEditScheme = (schemeId) => () => {
    const { router, params } = this.props;
    const productId = params.productId;
    router.push(`/admin/products/${productId}/scheme-management/${schemeId}/edit`);
  };

  handleViewScheme = (schemeId) => () => {
    const { router, params } = this.props;
    const productId = params.productId;
    router.push(`/admin/products/${productId}/scheme-management/${schemeId}/view`);
  };

  handleCreateNewVersion = (scheme) => () => {
    const { actions, params } = this.props;
    actions.createNewSchemeGroupVersion(scheme, params.productId);
  };

  handleRates = (scheme, type) => () => {
    const { actions } = this.props;

    if (type === 'view') {
      actions.editRates(scheme);
    }

    if (type === 'lock') {
      actions.lockRates(scheme);
    }

    if (type === 'import') {
      actions.importRates(scheme);
    }
  };

  handleAggregatorMapping = (scheme, type) => () => {
    const { actions } = this.props;

    if (type === 'view') {
      actions.editAggregatorMapping(scheme);
    }

    if (type === 'import') {
      actions.importAggregatorMapping(scheme);
    }
  };

  openModal = () => {
    this.setState({
      showModal: true,
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
    });
  };

  render() {
    const { activeScheme, handleSubmit } = this.props;
    let displayActiveScheme = null;

    if (activeScheme) {
      displayActiveScheme = activeScheme;
    }

    const schemeStatus = displayActiveScheme && displayActiveScheme.attributes.status;

    return (
      <DropdownButton
        bsStyle={'default'}
        pullRight
        bsSize="small"
        title={'Actions'}
        id={'dropdown-scheme-actions'}
        disabled={schemeStatus === 'inactive'}
      >
        {displayActiveScheme && schemeStatus === 'test' && (
          <MenuItem eventKey="1" onClick={this.openModal}>
            <Icon name="check-circle" /> Activate
          </MenuItem>
        )}
        {displayActiveScheme &&
          (schemeStatus !== 'ended' && schemeStatus !== 'inactive') && (
            <MenuItem
              eventKey="2"
              onClick={this.handleDeactivateScheme(displayActiveScheme.id)}
            >
              <Icon name="ban" /> {schemeStatus === 'test' ? 'Deactivate' : 'End'}
            </MenuItem>
          )}
        {displayActiveScheme && schemeStatus === 'test' && (
          <MenuItem eventKey="2" onClick={this.handleEditScheme(displayActiveScheme.id)}>
            <Icon name="pencil" /> Edit
          </MenuItem>
        )}
        {displayActiveScheme && schemeStatus === 'active' && (
          <MenuItem eventKey="2" onClick={this.handleViewScheme(displayActiveScheme.id)}>
            <Icon name="eye" /> View
          </MenuItem>
        )}
        {displayActiveScheme &&
          schemeStatus !== 'test' &&
          schemeStatus !== 'inactive' && <MenuItem divider />}
        {displayActiveScheme && schemeStatus !== 'test' && schemeStatus !== 'inactive' && (
          <MenuItem
            eventKey="3"
            onClick={this.handleCreateNewVersion(displayActiveScheme)}
          >
            <Icon name="plus-circle" /> Create New Version
          </MenuItem>
        )}
        <MenuItem divider />
        <MenuItem eventKey="3" onClick={this.handleRates(displayActiveScheme, 'view')}>
          <Icon name="table" />{' '}
          {schemeStatus === 'test' ? 'View/Edit rates' : 'View rates'}
        </MenuItem>
        <MenuItem eventKey="3" onClick={this.handleRates(displayActiveScheme, 'lock')}>
          <Icon name="lock" /> Lock Rates
        </MenuItem>
        {displayActiveScheme && schemeStatus === 'test' && (
          <MenuItem
            eventKey="3"
            onClick={this.handleRates(displayActiveScheme, 'import')}
          >
            <Icon name="upload" /> Import Rates
          </MenuItem>
        )}
        <MenuItem
          eventKey="3"
          onClick={this.handleAggregatorMapping(displayActiveScheme, 'view')}
        >
          <Icon name="table" />{' '}
          {schemeStatus === 'test'
            ? 'View/Edit aggregator mapping'
            : 'View aggregator mapping'}
        </MenuItem>
        {displayActiveScheme && schemeStatus === 'test' && (
          <MenuItem
            eventKey="3"
            onClick={this.handleAggregatorMapping(displayActiveScheme, 'import')}
          >
            <Icon name="upload" /> Import aggregator mapping
          </MenuItem>
        )}
        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Activate Scheme</Modal.Title>
          </Modal.Header>
          {displayActiveScheme && (
            <Form
              onSubmit={handleSubmit(this.handleActivateScheme(displayActiveScheme.id))}
            >
              <Modal.Body>
                <p>
                  Please note: Once the scheme has been made active it will no longer be
                  available for editing.
                </p>
                <p>Select a start date:</p>
                <Field
                  name="meta.date"
                  label="Start date"
                  labelSize={4}
                  showTime
                  component={DatePicker}
                  shouldCloseOnSelect={false}
                  timeFormat="HH:mm"
                  dateFormat
                />
              </Modal.Body>
              <Modal.Footer>
                <Button type="submit">Activate</Button>
              </Modal.Footer>
            </Form>
          )}
        </Modal>
      </DropdownButton>
    );
  }
}

export default flow(
  reduxForm({
    form: 'schemeActions',
  }),
  connect(
    null,
    (dispatch) => ({
      actions: bindActionCreators(actions, dispatch),
    }),
  ),
)(SchemeDropdownActions);
