import React  from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, getFormValues, FormSection, Form } from 'redux-form';
import { Row, Col } from 'react-bootstrap';
import { compose } from 'redux';
import isObject from 'lodash/isObject';
import startCase from 'lodash/startCase';
import { Button, HorizontalFormControl, InfoButton } from '../../../../common/components';
import TextArea from '../../../content/components/TextArea';

const Definition = ({ value, definitionKey }) => {

  if (!isObject(value)) {
    return (<div>
      <h2><InfoButton content={value}/>{startCase(definitionKey)}</h2>
      <Field
        name={definitionKey}
        component={TextArea}
      />
    </div>);
  }

  if (definitionKey  === 'hazardous_activities') {
    const packs = Object.keys(value).filter((key) => !['content', 'title'].includes(key));

    return (<div>
      <h2><InfoButton
        large
        content={value.content}
        title={value.title}
      />
      {startCase(definitionKey)}
      </h2>
      <Field
        name={'hazardous_activities.title'}
        component={HorizontalFormControl}
      />
      <Field
        name={'hazardous_activities.content'}
        component={TextArea}
      />
      {packs.map((pack)=> (
        <div key={pack}>
          <h2><InfoButton
            content={value[pack].content}
            title={value[pack].title}
          />
            {startCase(pack)}
          </h2>
          <Field
            name={`hazardous_activities.${pack}.title`}
            component={HorizontalFormControl}
          />
          <Field
            name={`hazardous_activities.${pack}.content`}
            component={TextArea}
          />
        </div>
      ))}
    </div>);
  }
  return (<div>
    <h2><InfoButton
      large
      content={value.content}
      title={value.title}
    />
      {startCase(definitionKey)}
    </h2>
    <Field
      name={`${definitionKey}.title`}
      component={HorizontalFormControl}
    />
    <Field
      name={`${definitionKey}.content`}
      component={TextArea}
    />
  </div>);
};


const ProductDefinitionsForm = ({ formValues, handleSubmit, submitting, updateDefinitions }) => {
  console.log('here');
  if (!formValues){
    return null;
  }
  const definitionKeys = Object.keys(formValues.meta.definitions);
  const definitionValues =formValues.meta.definitions;
  return (
    <div className={'product-definitions-form'}>
      <Form onSubmit={handleSubmit(updateDefinitions)}>
        <h2 className="resource-name">Definitions</h2>
        <FormSection name={'meta.definitions'}>
          {definitionKeys.map((definitionKey) =>
            <Definition
              key={definitionKey}
              value={definitionValues[definitionKey]}
              definitionKey={definitionKey}
            />
            )}
        </FormSection>
        <Row>
          <Col sm={12} >
            <Button
              type="submit"
              label="Save Changes"
              bsStyle="primary"
              className="pull-right"
              isLoading={submitting}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};


const FORM_NAME = 'ProductDefinitionsForm';
export default compose(
  connect(
    (state,props) => ({
      initialValues: {
        meta: {
          definitions: props.definitions
        }
      },
      formValues: getFormValues(FORM_NAME)(state),
    }),
  ),
  reduxForm({ form: FORM_NAME })
)(ProductDefinitionsForm);
