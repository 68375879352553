import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { setAnswer } from '../../redux/screeningActions';
import getIncludedResponse from '../../../../helpers/getIncludedResource';

export default class BooleanQuestion extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    screening: PropTypes.object.isRequired,
    condition: PropTypes.object.isRequired,
    question: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      answer: '',
    };
  }

  componentWillReceiveProps(props) {
    const options = getIncludedResponse(
      props.question,
      props.screening.declaration.included,
      'options',
    );

    Object.keys(options).map((id) => {
      const option = options[id];

      if (
        option.attributes.isSelected &&
        this.state.answer !== option.attributes.number
      ) {
        this.setState({ answer: option.attributes.number });
      }
    });
  }

  handleRadioChange(e) {
    const { condition, question, dispatch } = this.props;
    if (e.target === undefined) {
      return;
    }
    const answer = e.target.value;
    this.setState({ answer });
    dispatch(setAnswer(condition, question, { value: answer }));
  }

  render() {
    const { question, screening } = this.props;
    const optionsResource = getIncludedResponse(
      question,
      screening.declaration.included,
      'options',
    );
    const { answer } = this.state;

    return (
      <div className="row question">
        <div className="col-xs-12">
          <label className="control-label">{question.attributes.text}</label>
          {question.attributes.info ? (
            <p className="text-info text-justify" style={{ fontSize: '14px' }}>
              {question.attributes.info}
              <br />
            </p>
          ) : (
            ''
          )}
          <div className="radio-container">
            {optionsResource.map((option, i) => {
              const inputId = option.id;
              return (
                <div
                  key={i}
                  className={'radio-container radio-inline'}
                  style={{ marginTop: '10px' }}
                >
                  <div className={'form-radio ' + option.attributes.text.toLowerCase()}>
                    <input
                      type="radio"
                      id={inputId}
                      value={option.attributes.number}
                      onChange={::this.handleRadioChange}
                      checked={answer === option.attributes.number}
                    />
                    <label htmlFor={inputId} />
                  </div>
                  <label
                    className="radio-label radio-label-condition-question"
                    htmlFor={inputId}
                  >
                    {option.attributes.text}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
