import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '../../../../common/components';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import { FormattedDate, FormattedTime } from 'react-intl';
import { Col, Row } from 'react-bootstrap';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';

export default class BrokerProducts extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
    commissions: PropTypes.array.isRequired,
    broker: PropTypes.object.isRequired,
    brokerLabel: PropTypes.object.isRequired,
  };

  render() {
    const resource = this.props.resource.data.attributes;
    // const products = this.props.broker.allProducts;
    const commissions = this.props.commissions;

    // const getCommission = (productId) => {
    //   let commission = null;
    //   commissions.map(item => {
    //     if (item.relationships.product.data.id === productId) {
    //       commission = item
    //     }
    //   });
    //   return commission
    // };

    return (
      <Box>
        <h2 className="resource-name">
          {resource.name} {this.props.brokerLabel}
        </h2>

        <table className="table">
          <thead>
          <tr>
            <td>Product</td>
            <td>Active date</td>
            <td>Commission</td>
          </tr>
          </thead>
          <tbody>
          {commissions &&
          commissions.map((commission, i) => {
            const product = getIncludedResource(
              commission,
              this.props.resource.included,
              'product',
            );
            if (product.data.attributes) {
              return (
                <tr key={i}>
                  <td>{product.data.attributes.name}</td>
                  <td>
                    <FormattedDate value={commission.attributes.from_date}/>{' '}
                    <FormattedTime value={commission.attributes.from_date}/>
                  </td>
                  <td>{commission.attributes.rate + '%'}</td>
                </tr>
              );
            }
          })}
          {commissions.length === 0 && (
            <tr>
              <td colSpan={3}>No active products</td>
            </tr>
          )}
          </tbody>
        </table>

        <Row>
          <Col sm={12}>
            <InternalButtonBar
              buttons={[
                {
                  permissions: ['user.edit', 'broker.edit'],
                  button: (
                    <Button
                      link={true}
                      to={
                        '/brokers/' +
                        this.props.resource.data.id +
                        '/commissions/edit'
                      }
                      className="pull-right"
                      label="Edit"
                      bsStyle="primary"
                      rightIcon="percent"
                    />
                  ),
                },
              ]}
            />
          </Col>
        </Row>
      </Box>
    );
  }
}
