import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { SelectField } from '../../../../common/components/ReduxFormField';
import {
  Icon,
  Button,
  SelectAccount,
  SelectBroker,
  SelectProduct,
  SelectCurrency,
  HorizontalFormControl,
} from '../../../../common/components';
import { Row, Col } from 'react-bootstrap';
import './styles.scss';

const journalDefaults = {
  account: '',
  description: '',
  currency: 'GBP',
  debit: 0,
  credit: 0,
  broker: '',
  product: '',
  premium_element_type: '',
};

class Journal extends Component {
  componentWillMount() {
    this.props.fields.push(journalDefaults);
    this.props.fields.push(journalDefaults);
  }

  render() {
    const { fields, entryType } = this.props;

    const premiumElementTypes = [
      { label: 'Net', value: 'net' },
      { label: 'Additional', value: 'additional' },
      { label: 'Medical', value: 'medical' },
      { label: 'Fees', value: 'fees' },
      { label: 'Discount', value: 'discount' },
      { label: 'Commission', value: 'commission' },
      { label: 'Tax', value: 'tax' },
      { label: 'Tax Exclusive', value: 'ex_tax' },
    ];

    return (
      <div>
        {fields.map((entry, index) => {
          return (
            <div key={index} className="journal-ledger-entry">
              <div className="journal-ledger-entry-header">
                {index > 1 && (
                  <span className="remove-entry" onClick={() => fields.remove(index)}>
                    <Icon name="times" />
                  </span>
                )}
                <Row>
                  <Col xs={4}>
                    <Field
                      async
                      key={'select-account' + index}
                      name={entry + '.account'}
                      sort={'name'}
                      label="Account"
                      id={index}
                      labelSize={4}
                      component={SelectAccount}
                    />
                  </Col>
                  <Col xs={4}>
                    <Field
                      async
                      key={'select-broker' + index}
                      name={entry + '.broker'}
                      label="Broker"
                      labelSize={3}
                      isClearable
                      component={SelectBroker}
                    />
                  </Col>
                  <Col xs={4}>
                    <Field
                      name={entry + '.product'}
                      label="Product"
                      labelSize={3}
                      filters={[
                        { filter: 'sort', value: 'name' },
                      ]}
                      isClearable
                      component={SelectProduct}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8}>
                    <Field
                      name={entry + '.description'}
                      label="Description"
                      labelSize={2}
                      component={HorizontalFormControl}
                    />
                  </Col>
                  {entryType === 'premium' && (
                    <Col xs={4}>
                      <Field
                        name={entry + '.premium_element_type'}
                        label="Premium Element"
                        labelSize={3}
                        options={premiumElementTypes}
                        component={SelectField}
                      />
                    </Col>
                  )}
                </Row>
              </div>
              <div className="journal-ledger-entry-amounts">
                <Row>
                  <Col xs={4}>
                    <Field
                      name={entry + '.currency'}
                      label="Currency"
                      labelSize={3}
                      defaultValue={{ value: 'GBP', label: 'Pound sterling' }}
                      component={SelectCurrency}
                    />
                  </Col>
                  <Col xs={4}>
                    <Field
                      name={entry + '.debit'}
                      label="Debit"
                      labelSize={3}
                      currency
                      component={HorizontalFormControl}
                    />
                  </Col>
                  <Col xs={4}>
                    <Field
                      name={entry + '.credit'}
                      label="Credit"
                      labelSize={3}
                      currency
                      component={HorizontalFormControl}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          );
        })}
        <div className="actions">
          <Button
            bsStyle="primary"
            size="xs"
            className="pull-right"
            label="Add a new line"
            handleClick={() => fields.push(journalDefaults)}
          />
        </div>
      </div>
    );
  }
}

Journal.propTypes = {
  fields: PropTypes.object.isRequired,
  entryType: PropTypes.string.isRequired,
  formValues: PropTypes.object,
};

export default Journal;
