import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap';
import { Button, Loader, Box } from '../../../common/components';
import InternalContainer from '../../layout/containers/Internal';
import ProductDocumentPacksFormContainer from './ProductDocumentPacksFormContainer';
import { getProduct, clearData } from '../redux/schemeActions';
import DocumentsList from '../../document/components/DocumentsList';
import './styles.scss';

class SchemeProductEditContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      activeTab: 1,
    };
  }

  componentWillMount() {
    this.props.dispatch(
      getProduct(this.props.params.id, ['document_packs.scheme_documents']),
    );
  }

  /**
   * Clean out the data when the component unmounts
   */
  componentWillUnmount() {
    this.props.dispatch(clearData());
  }

  handleTab(key) {
    this.setState({ activeTab: key });
  }

  render() {
    const resource = this.props.scheme.product;
    const title =
      resource.data && resource.data.id === this.props.params.id
        ? resource.data.attributes.name
        : 'Documents';
    const buttons = (
      <Button bsStyle="default" label="back" isBackButton leftIcon="caret-left" />
    );

    return (
      <InternalContainer title={title} buttons={buttons}>
        {resource.data && resource.data.id == this.props.params.id ? (
          <div className="scheme-product-tabs">
            <Tabs
              activeKey={this.state.activeTab}
              id="scheme-tabs"
              unmountOnExit
              onSelect={::this.handleTab}
            >
              <Tab eventKey={1} title="Documents">
                <Box>
                  <DocumentsList product={resource} />
                </Box>
              </Tab>
              <Tab eventKey={2} title="Document Packs">
                <Box>
                  <ProductDocumentPacksFormContainer product={resource} />
                </Box>
              </Tab>
            </Tabs>
          </div>
        ) : (
          <Loader />
        )}
      </InternalContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    scheme: state.scheme,
  };
}

SchemeProductEditContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  scheme: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(SchemeProductEditContainer);
