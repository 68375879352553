import React from 'react';
import { map, forEach, flow, get } from 'lodash';
import { connect } from 'react-redux';
import { FormattedDate } from 'react-intl';
import { Col, ControlLabel, FormGroup, Modal, Row } from 'react-bootstrap';
import { Form, Field, reduxForm, getFormValues } from 'redux-form';
import {
  DatePicker,
  Button,
  HorizontalFormControl,
} from '../../../../../common/components';
import FieldFormControl from '../../../../../common/components/FieldFormControl/FieldFormControl';
import UploadModalComponent, {
  UploadModal,
} from '../../../../../common/components/Upload/UploadModal';
import { openModal } from '../../../../../common/components/Modal/ModalRedux';
import { clearFiles } from '../../../../../common/components/Upload/UploadRedux';
import { bindActionCreators } from 'redux';
import { actions as schemeActions } from '../../../redux/ProductReducer';
import SelectMenu from '../../../../../common/components/SelectMenu/SelectMenu';

class ViewEditDocumentModal extends React.Component {
  handleSubmit = (formValues) => {
    const { actions, document, params, actionType } = this.props;

    // Requires Validation

    if (actionType === 'add-superseding') {
      const values = {
        data: {
          attributes: {
            name:
              document.attributes.name +
              ' (' +
              formValues.data.relationships.channel.data.attributes.name +
              ')',
            display_name: formValues.data.attributes.display_name,
          },
          relationships: {
            supersedes: {
              data: {
                id: document.id,
              },
            },
            channel: {
              data: {
                id: formValues.data.relationships.channel.data.id,
              },
            },
            products: {
              data: [
                { id: params.productId }
              ]
            },
            file: {
              data: {
                id: formValues.data.relationships.file.data.id,
              },
            },
          },
        },
      };

      return actions.scheme.createNewDocument(values, params.productId);

    }

    // if there are no schemes attached - assign to the product - there are better ways to do this through the form but I'm hacking it here to get it done
    if (!formValues.data.relationships.schemes) {
      formValues.data.relationships.products = {

        data: [
          { id: params.productId }
        ]

      };
    }


    return actions.scheme.updateDocument(formValues, document, params.productId);
  };

  closeModal = () => {
    const { actions } = this.props;
    actions.scheme.closeDocumentModal();
  };

  handleUploadComplete() {
    const { dispatch, change, files } = this.props;

    dispatch(change('data.relationships.file.data', files[0]));
    dispatch(change('data.attributes.file_name', files[0].attributes.name));
    dispatch(change('data.attributes.file_url', files[0].attributes.url));
  }

  resetState() {
    const { actions } = this.props;
    actions.upload.clearFiles();
  }

  handleUpload() {
    const { actions } = this.props;
    actions.upload.openModal(UploadModal.MODAL_IDENTIFIER);
    this.resetState();
  }

  handleSchemeCheckboxChange = (schemeId) => () => {
    const { dispatch, change, formValues } = this.props;

    if (get(formValues, `scheme[${schemeId}]`)) {
      dispatch(change('data.relationships.schemes.data', {}));

      map(formValues.scheme, (status, id) => {
        if (status === true && id !== schemeId) {
          dispatch(change(`data.relationships.schemes.data[${id}].id`, id));
        }
      });
    }

    if (!get(formValues, `scheme[${schemeId}]`)) {
      dispatch(change(`data.relationships.schemes.data[${schemeId}].id`, schemeId));
    }
  };

  getSchemeInfo = (scheme, key) => {
    const { schemeList, actionType } = this.props;
    let schemeName = '';
    let schemeId = '';
    let schemeVersion = '';

    forEach(schemeList, (schemeToMap) => {
      if (schemeToMap.id === scheme.id) {
        schemeName = schemeToMap.name;
        schemeId = schemeToMap.id;
        schemeVersion = schemeToMap.version;
      }
    });

    if (actionType === 'edit') {
      return (
        <Field
          key={key}
          type="checkbox"
          value={schemeId}
          component={FieldFormControl}
          onChange={this.handleSchemeCheckboxChange(schemeId, key)}
          name={`scheme.${schemeId}`}
        >
          <div className="activate-scheme-item">
            <span>
              {schemeName} - V{schemeVersion}
            </span>
          </div>
        </Field>
      );
    }

    return <li key={key}>{schemeName}</li>;
  };

  renderDocumentModalBody = () => {
    const { actionType, document, formValues } = this.props;

    const renderField = (label, content) => {
      return (
        <Row className="document-modal-row">
          <Col sm={4}>
            <strong>{label}:</strong>
          </Col>
          <Col sm={8}>{content}</Col>
        </Row>
      );
    };

    if (actionType === 'view') {
      return (
        <Row>
          <Col sm={12}>{renderField('Status', document.attributes.status)}</Col>
          <Col sm={12}>
            {renderField(
              'Created',
              <FormattedDate value={document.attributes.created_at} />,
            )}
          </Col>
          <Col sm={12}>
            {renderField(
              'Updated',
              <FormattedDate value={document.attributes.created_at} />,
            )}
          </Col>
          <Col sm={12}>
            {renderField(
              'File',
              <a
                target="_blank"
                className="btn btn-primary btn-xs"
                href={document.attributes.file_path}
              >
                <i className="fa fa-file" /> {document.attributes.short_name}
              </a>,
            )}
          </Col>
          {get(document, 'relationships.schemes.data') && (
            <Col sm={12}>
              {renderField(
                'Schemes',
                <ul>{map(document.relationships.schemes.data, this.getSchemeInfo)}</ul>,
              )}
            </Col>
          )}
        </Row>
      );
    }

    return (
      <div>
        <Row>
          {actionType === 'edit' && (
            <Col sm={12}>
              {renderField(
                'Active date',
                <Field
                  showTime
                  dateFormat
                  labelSize={4}
                  name="data.attributes.from_date"
                  label="Start date"
                  timeFormat="HH:mm"
                  component={DatePicker}
                  shouldCloseOnSelect={false}
                />,
              )}
            </Col>
          )}
        </Row>
        {actionType === 'add-superseding' && (
          <Field
            name="data.attributes.display_name"
            label="Display Name"
            labelSize={4}
            object
            component={HorizontalFormControl}
          />
        )}
        {actionType === 'add-superseding' && (
          <FormGroup>
            <Col sm={4}>
              <ControlLabel>
                Channel:
              </ControlLabel>
            </Col>
            <Col sm={8}>
              <Field
                name="data.relationships.channel.data"

                labelSize={4}
                isObject={true}
                source={'channels'}
                component={SelectMenu}
              />
            </Col>
          </FormGroup>
        )}
        <Row>
          <Col sm={12}>
            {renderField(
              'File',
              <div className="pad-form-row">
                <Button
                  bsSize="xs"
                  type="button"
                  bsStyle="primary"
                  className="pad-right btn-xs"
                  handleClick={::this.handleUpload}
                >
                  <i className="fa fa-file" /> upload document
                </Button>
                {get(formValues, 'data.attributes.file_url') && (
                  <a
                    href={get(formValues, 'data.attributes.file_url')}
                    target="_blank"
                    className="btn btn-link btn-xs"
                  >
                    {get(formValues, 'data.attributes.file_name')}
                  </a>
                )}
              </div>,
            )}
          </Col>
          {actionType === 'edit' && get(document, 'relationships.schemes.data') && (
            <Col sm={12}>
              {renderField(
                'Schemes',
                <ul className="schemes-checkbox-container">
                  {map(document.relationships.schemes.data, this.getSchemeInfo)}
                </ul>,
              )}
            </Col>
          )}
        </Row>
      </div>
    );
  };

  render() {
    const {
      actionType,
      document,
      showDocumentModal,
      handleSubmit,
      isSubmitting,
    } = this.props;
    return (
      <div>
        {/* EDIT DOCUMENT MODAL */}
        <Modal show={showDocumentModal} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              {actionType === 'view' ? 'View ' : 'Edit '}
              {document.attributes.name}
            </Modal.Title>
          </Modal.Header>
          <Form className="form-horizontal" onSubmit={handleSubmit(this.handleSubmit)}>
            <Modal.Body>{this.renderDocumentModalBody()}</Modal.Body>
            <Modal.Footer>
              {actionType !== 'view' && (
                <Button type="submit" className="btn-blue" isLoading={isSubmitting}>
                  Save
                </Button>
              )}
              <Button className="pull-left" handleClick={this.closeModal}>
                Cancel
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        {/* UPDLOAD DOCUMENT MODAL */}
        <UploadModalComponent
          onComplete={::this.handleUploadComplete}
          accept="application/pdf"
          maxFiles={1}
        />
      </div>
    );
  }
}

const FORM_NAME = 'UpdateSchemeDocuments';

export default flow([
  reduxForm({
    form: FORM_NAME,
  }),
  connect(
    (state) => {
     // const formValues = selector(state, 'data', 'scheme');

      return {
        formValues: getFormValues(state)(FORM_NAME),
        files: state.upload.files,
      };
    },
    (dispatch) => ({
      dispatch,
      actions: {
        upload: bindActionCreators({ openModal, clearFiles }, dispatch),
        scheme: bindActionCreators(schemeActions, dispatch),
      },
    }),
  ),
])(ViewEditDocumentModal);
