import React from 'react';

export const cat = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95 95">
    <path className="cls-1" d="M0,47.5A47.5,47.5,0,1,0,47.5,0,47.5,47.5,0,0,0,0,47.5" />
    <path
      style={{ fill: '#fff' }}
      d="M69.61,49.41a30.3,30.3,0,0,0-4.44-7.23c-5.7-6.95-4.8-7.18-10.35-10.28s-7.55-4.36-12.57-4.29a7.12,7.12,0,0,0-3-3.12c-.57-.27-1-.46-1.41-.66s-.7-.68-.93-.93a1.53,1.53,0,0,1-.11-.44,5.44,5.44,0,0,0,.35-1.65,3.07,3.07,0,0,1,.07-1.56,33.84,33.84,0,0,0,.66-3.54c0-.33.18-.9-.75-1.19s-3,2.42-3.43,2.88-.71.26-.92.44-.92,0-1.15.17-.51-.09-.84.05-.28-.09-.75.16-.4,0-.5.07-.49.11-.9.16a3.06,3.06,0,0,0-.57,0,1.25,1.25,0,0,0-.53.09l-.07,0s-.09,0-.11,0-.24,0-.29,0-.88-.11-1-.24-.37,0-.48-.09-.33,0-.62-.13-.4,0-.73,0a30.49,30.49,0,0,0-3.34-2.71c-1.25-.77-1.5-.4-1.87.71s.75,3.72.86,4.38a8.61,8.61,0,0,0,.31,2.2c.35.7-.29.53-.15.6s-.51.7-.33.75-.44,1-.29,1-.4.93-.24,1-.38,1.12-.15,1.34,0,.68.4.88a.79.79,0,0,1,.33.55,12.65,12.65,0,0,0,.4,2.13c.66,2.29,1.41,2.8,2,6.07A23.72,23.72,0,0,0,25,44.67c1.26,2.44,6.36,6.73,5.63,10s-2.07,10-3.56,10.65a5.83,5.83,0,0,0-2.88,2.07c-.37.73.22,2.14,1.85,2.29s4.51.15,5.7-1.7,5.92-9.24,7.17-12.21A11.36,11.36,0,0,0,40,62.12c1.63,3,2.36,4.07,2.51,5.31a3.78,3.78,0,0,0-1.19,2c-.15,1.12.88,1.54,1.85,1.17,0,0,.37.88,2.73.44a14.19,14.19,0,0,1,4.51-.29c1.85.07,4-.82,6.07-.44s4.44,1.48,6.65.88A8.85,8.85,0,0,0,67,69.53c-2.28,3-7.12,7.29-16.34,7.07-9.43-.22-12.73-1.92-13-2.07-.74-.37-5.11-2-3.9,2.09,1.39,4.64,20,4.12,23.15,3.38s10-1.19,18.14-11.18c0,0,0,0,0,0a4.8,4.8,0,0,0,.59-.92C77.4,64.34,72.76,55,69.61,49.41Z"
    />
  </svg>
);

export default cat;
