import React from 'react';
import { map } from 'lodash';
import { DropdownButton, MenuItem } from 'react-bootstrap';

class DynamicDocumentVersionSelector extends React.Component {
  handleSelectDocument = (document) => () => {
    const { onSelectDocument } = this.props;

    onSelectDocument(document);
  };

  render() {
    const { documentList, selectedDocument } = this.props;
    return (
      <DropdownButton
        pullRight
        bsSize={'xs'}
        bsStyle={'default'}
        id={'split-button-basic'}
        title={'v' + selectedDocument.attributes.version}
        className={`version-dropdown-btn ${selectedDocument.attributes.status}`}
      >
        {map(documentList, (document, key) => {
          return (
            <MenuItem
              key={key}
              eventKey={key + 1}
              active={selectedDocument.id === document.id}
              onClick={this.handleSelectDocument(document)}
            >
              <div className="dropdown-item">
                <span>
                  {document.attributes.name} - v{document.attributes.version}
                </span>
              </div>
            </MenuItem>
          );
        })}
      </DropdownButton>
    );
  }
}

export default DynamicDocumentVersionSelector;
