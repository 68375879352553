import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Loader } from '../../../common/components';
import InternalContainer from '../../layout/containers/Internal';
import LeisureTravelFormContainer from './LeisureTravelFormContainer';
import {
  getProduct,
  getRegions,
  getSchemes,
  clearData,
  setPolicyForAdjustment,
} from '../redux/productActions';
import { getPolicy } from '../../policy/redux/policyActions';
import { get, set, map } from 'lodash';
import getIncludedResource from '../../../helpers/getIncludedResource';
import moment from 'moment';

export class ProductMTAContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    policy: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
  };

  componentWillMount() {
    const { dispatch, params } = this.props;
    if (params.applicationId) {
      dispatch(
        getPolicy(params.applicationId, false, [
          'customer',
          'broker',
          'user',
          'schemes',
          'policies',
          'premiums',
          'applications',
          'channel',
          'source',
        ]),
      );
    }

    dispatch(setPolicyForAdjustment(params.applicationId));
    dispatch(getSchemes(params.id));
    dispatch(getRegions());
    dispatch(getProduct(params.id));
  }

  /**
   * Clean out the data when the component unmounts
   */
  componentWillUnmount() {
    this.props.dispatch(clearData());
  }

  render() {
    const { product, policy, params } = this.props;
    const productResource = product.resources[params.id];
    const application = policy.resources[params.applicationId];
    const title = productResource ? productResource.data.attributes.name : '';
    if (!productResource || (params.applicationId && !application)) {
      return (<InternalContainer hideBreadcrumbs={true} title={title}>
        <Loader/>
      </InternalContainer>);
    }

    const isExpired = application.data.attributes.status === 'expired';

    const haz = get(application, 'data.attributes.metadata.activities');
    let hazMta = [];

    map(haz, (act) => {
      hazMta.push(act);
      return act;
    });

    set(application, 'data.attributes.metadata.overrides', {});

    const signpost = get(application, 'data.attributes.metadata.signposted');
    if (signpost) {
      set(application, 'data.attributes.metadata.signposted', false);
      set(application, 'data.attributes.metadata.signpost_acknowledged', false);
      set(application, 'data.attributes.metadata.storage.policy_signpost', signpost);
    }

    const premiums = getIncludedResource(application.data, application.included, 'premiums');

    const lastIssuedPremium = premiums.reduce((carry, premium) => {
      if (!carry) {
        return premium;
      }

      if (!premium.attributes.issued_at) {
        return null;
      }
      const premiumIssuedAt = moment(premium.attributes.issued_at);
      const existingPremiumIssuedAt = moment(carry.attributes.issued_at);
      if (premiumIssuedAt.isAfter(existingPremiumIssuedAt)) {
        return premium;
      }
      return carry;

    }, null);
    const lastIssuedPremiumMedical = get(lastIssuedPremium, 'attributes.mta.new_premium.medical', lastIssuedPremium.attributes.medical);

    set(application, 'data.attributes.metadata.storage.policy_medical', lastIssuedPremiumMedical);


    return (
      <InternalContainer hideBreadcrumbs title={title}>
        <LeisureTravelFormContainer
          {...this.props}
          mta
          hazMta={hazMta}
          application={application}
          currentProduct={productResource}
          isExpired={isExpired}
        />
      </InternalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    product: state.product,
    policy: state.policy,
  };
};

export default connect(mapStateToProps)(ProductMTAContainer);
