import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../redux/ProductReducer';
import { Loader } from '../../../common/components';
import DynamicDocumentList from '../components/DynamicDocumentList/DynamicDocumentList';
import InternalContainer from '../../layout/containers/Internal';
import ProductTemplateBox from '../components/ProductTemplateBox/ProductTemplateBox';

class DynamicDocumentListContainer extends React.Component {
  componentWillMount() {
    const { actions, params } = this.props;
    actions.getProducts(params.productId);
    actions.getDynamicDocuments(params.productId);
  }

  componentWillUnmount() {
    const { actions } = this.props;
    actions.clearData();
  }

  render() {
    const { productManagement } = this.props;
    const product = get(productManagement, 'product');
    const isLoading = get(productManagement, 'isLoading');
    const dynamicDocuments = get(productManagement, 'dynamicDocuments');

    return (
      <InternalContainer title={'Product Management'}>
        {dynamicDocuments.list.length > 0 && product ? (
          <ProductTemplateBox title={'Dynamic Documents'} product={product.data}>
            <DynamicDocumentList
              dynamicDocuments={dynamicDocuments}
              onUpdate={this.handleUpdateContent}
              isLoading={isLoading}
              {...this.props}
            />
          </ProductTemplateBox>
        ) : (
          <Loader />
        )}
      </InternalContainer>
    );
  }

  handleUpdateContent = (values) => {
    const { actions } = this.props;
    actions.updateDynamicDocument(values);
  };
}

export default connect(
  (state) => ({
    productManagement: state.productManagement,
  }),
  (dispatch) => ({
    dispatch,
    actions: bindActionCreators({ ...actions }, dispatch),
  }),
)(DynamicDocumentListContainer);
