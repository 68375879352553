import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change, Field } from 'redux-form';
import { Alert, FormGroup, Col, ControlLabel, Row } from 'react-bootstrap';
import Button from '../Button/Button';
import { PostcodeField } from './components';
import { get, map, startsWith, upperCase } from 'lodash';
import { clearSelection, getAddress, getAddresses } from './redux/PostcodeActions';
import Select from 'react-select';
import './PostcodeList.scss';
import { bindActionCreators } from 'redux';
import selectTheme, { styles } from '../../../helpers/selectTheme';

const DATA = 'data.attributes';
const META = 'data.attributes.metadata';

class PostcodeList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    context: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string.isRequired,
    //change: PropTypes.func.isRequired,
    selections: PropTypes.object,
    addresses: PropTypes.object,
    infoButton: PropTypes.object,
    labelSizes: PropTypes.object.isRequired,
    postcodeFieldSizes: PropTypes.object.isRequired,
    searchBtnSizes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      populated: false,
      showMenu: false,
      alertVisible: true,
      targetPath: '',
      isPostCodeFromIsleOrChannel: false,
    };
    this.handlePostcodeSearch = this.handlePostcodeSearch.bind(this);
    this.handleAddressSelect = this.handleAddressSelect.bind(this);
    this.handleAddressClear = this.handleAddressClear.bind(this);
    this.handleAlertDismiss = this.handleAlertDismiss.bind(this);
    this.handleCantFind = this.handleCantFind.bind(this);
  }

  handleAlertDismiss() {
    this.setState({
      alertVisible: false,
    });
  }

  handleCantFind() {
    this.props.handleCantFind();
  }

  componentWillMount() {
    const { targetField, context } = this.props;
    if (!context && !targetField) {
      this.setState({
        targetPath: `${META}.adults[0].address`,
      });
    }

    if (targetField) {
      this.setState({
        targetPath: targetField,
      });
    }

    if (context && (context === 'customer' || context === 'organisation')) {
      this.setState({
        targetPath: `${DATA}.address`,
      });
    }

    if (context && context === 'account') {
      this.setState({
        targetPath: `${DATA}.address`,
      });
    }
  }

  isPostCodeFromIsleOrChannel = (postcode) => {
    const postcodeUpper = upperCase(postcode);
    return (
      startsWith(postcodeUpper, 'JE') ||
      startsWith(postcodeUpper, 'GY') ||
      startsWith(postcodeUpper, 'IM') ||
      startsWith(postcodeUpper, 'EV')
    );
  };

  handleAddressClear = () => {
    const { dispatch, change, formName } = this.props;
    const { targetPath } = this.state;
    change(formName, targetPath, {
      line1: '',
      line2: '',
      line3: '',
      town: '',
      county: '',
      postcode: '',
      country: '',
    });
    dispatch(clearSelection());
    this.setState(() => ({ populated: false }));
  };

  handlePostcodeSearch = (postcode) => {
    const { currentProduct } = this.props;

    if (['247'].includes(get(currentProduct, 'data.attributes.product_code', ''))
      && this.isPostCodeFromIsleOrChannel(postcode)) {
      return () => {
        this.setState({
          isPostCodeFromIsleOrChannel: true,
        });
      };
    }

    return () => {
      this.setState({
        alertVisible: true,
        showMenu: true,
        isPostCodeFromIsleOrChannel: false,
      });
      this.props.dispatch(getAddresses(postcode));
    };
  }

  updateFields = () => {
    const { change, selection, formName } = this.props;
    const { targetPath } = this.state;
    const address = selection.attributes;

    if (!get(selection, 'attributes.line1', false)) {
      return;
    }
    if (this.state.populated) {
      return;
    }
    change(formName, targetPath, address);
    this.setState({
      populated: true,
      showMenu:false,
    });
  };

  handleAddressSelect(addressId) {
    const { dispatch } = this.props;
    Promise.resolve(dispatch(getAddress(addressId.value))).then(() => {
      this.updateFields();
    });
  }

  render() {
    const {
      disabled,
      addresses,
      label,
      name,
      value,
      labelSizes,
      postcodeFieldSizes,
      searchBtnSizes,
      fieldSizes,
      infoButton,
      isLoading,
      adjustWidth,
      showCantFindLink,
      showFieldsState,
    } = this.props;
    const { showMenu, alertVisible, isPostCodeFromIsleOrChannel } = this.state;
    const uppercase = (value) => value && value.toUpperCase();

    const postCodeMenu = () => {
      const options = map(addresses.data, (address) => {
        return {
          label: address.attributes.name,
          value: address.id,
        };
      });

      return (
        <Select
          key={this.props.key + '-select'}
          theme={(theme) => selectTheme(theme)}
          styles={styles}
          onChange={this.handleAddressSelect}
          options={options}
          placeholder="Please select your address from the following..."
          clearable={false}
        />
      );
    };

    const style = ['postcode-list', adjustWidth && 'adjustWidth'];
    return (
      <div>
        <FormGroup className={style.join(' ')}>
          <Col
            sm={labelSizes.sm || 2}
            md={labelSizes.md || 2}
            lg={labelSizes.lg || 2}
            componentClass={ControlLabel}
          >
            {label} {infoButton}
          </Col>
          <Col
            sm={postcodeFieldSizes.sm || 3}
            md={postcodeFieldSizes.md || 3}
            lg={postcodeFieldSizes.lg || 3}
            className="address-field"
          >
            <Field
              normalize={uppercase}
              name={name}
              type="text"
              disabled={disabled}
              component={PostcodeField}
            />
          </Col>
          {!disabled && (
            <Col
              sm={searchBtnSizes.sm || (12 - (labelSizes.sm || 2) - (postcodeFieldSizes.sm || 3))}
              md={searchBtnSizes.md || (12 - (labelSizes.md || 2) - (postcodeFieldSizes.md || 3))}
              lg={searchBtnSizes.lg || (12 - (labelSizes.lg || 2) - (postcodeFieldSizes.lg || 3))}
              className="search-btn-container"
            >
              {this.state.populated ? (
                <Button
                  type="button"
                  className="search-btn"
                  handleClick={this.handleAddressClear}
                  label="Clear"
                  isLoading={isLoading}
                />
              ) : (
                <Button
                  type="button"
                  className="search-btn"
                  handleClick={this.handlePostcodeSearch(value)}
                  label="Search"
                  isLoading={isLoading}
                />
              )}
            </Col>
          )}
        </FormGroup>

        {addresses.data && (
          <FormGroup>
            <Col
              sm={fieldSizes.sm || 10}
              smOffset={labelSizes.sm || 2}
              md={fieldSizes.md || 10}
              mdOffset={labelSizes.md || 2}
              lg={fieldSizes.lg || 10}
              lgOffset={labelSizes.lg || 2}
              className="fadein-fields"
            >
              {addresses.data.length > 0 && showMenu && postCodeMenu()}
              {addresses.data.length === 0 && alertVisible && (
                <div className="not-found-alert fade in">
                  <Alert bsStyle="danger" onDismiss={this.handleAlertDismiss}>
                    No addresses were found with the given criteria.
                  </Alert>
                </div>
              )}
            </Col>
          </FormGroup>
        )}
        {showCantFindLink && showCantFindLink() && (
          <Row>
            <Col
              sm={fieldSizes.sm || 10}
              smOffset={labelSizes.sm || 2}
              md={fieldSizes.md || 10}
              mdOffset={labelSizes.md || 2}
              lg={fieldSizes.lg || 10}
              lgOffset={labelSizes.lg || 2}
            >
              <div className={'btn btn-link pull-right'} onClick={this.handleCantFind}>
                {(showFieldsState ? 'Hide Address Fields' : 'Can\'t find address?')}
              </div>
            </Col>
          </Row>
        )}
        {isPostCodeFromIsleOrChannel && (
          <Row>
            <Col
              sm={fieldSizes.sm || 10}
              smOffset={labelSizes.sm || 2}
              md={fieldSizes.md || 10}
              mdOffset={labelSizes.md || 2}
              lg={fieldSizes.lg || 10}
              lgOffset={labelSizes.lg || 2}
            >
              <div className="not-found-alert fade in">
                <Alert bsStyle="danger" onDismiss={this.handleAlertDismiss}>
                  Unfortunately we are unable to offer quotes being purchased
                  from this location.
                </Alert>
              </div>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

// function mapStateToProps(state) {
//   return {
//     addresses: state.postcode.addresses,
//     selections: state.postcode.selections,
//     selection: state.postcode.selection,
//     isLoading: state.postcode.isLoading,
//     menuOpen: state.postcode.menuOpen,
//   };
// }
//
// export default connect(mapStateToProps)(PostcodeList);

export default connect(
  (state) => {
    return {
      addresses: state.postcode.addresses,
      selections: state.postcode.selections,
      selection: state.postcode.selection,
      isLoading: state.postcode.isLoading,
      menuOpen: state.postcode.menuOpen,
    };
  },
  (dispatch) => ({
    change: bindActionCreators(change, dispatch),
    dispatch,
  }),
)(PostcodeList);
