import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormGroup, Col, ControlLabel } from 'react-bootstrap';
import SelectMenu from '../SelectMenu/SelectMenu';
import AsyncSelectMenu from '../SelectMenu/AsyncSelectMenu';
import { forEach } from 'lodash';

export const SELECT_IDENTIFIER = 'accounts';

class SelectAccount extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    label: PropTypes.string,
    labelSize: PropTypes.number,
    input: PropTypes.object.isRequired,
    infoButton: PropTypes.element,
    name: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    meta: PropTypes.object,
    object: PropTypes.bool,
    multi: PropTypes.bool,
  };

  renderSelectMenu() {
    const { id, isObject, filters, async, key } = this.props;

    if (async) {
      return (
        <AsyncSelectMenu
          key={key || 'select-account'}
          url={SELECT_IDENTIFIER}
          {...this.props}
        />
      );
    }

    let filterUrl = '';

    const joinChar = (url) => {
      return url.includes('?') ? '&' : '?';
    };

    if (filters) {
      forEach(filters, (filter, key) => {
        if (filter) {
          filterUrl += `${joinChar(filterUrl)}filter[${key}]=${filter}`;
        }
      });
    }

    if (isObject) {
      return (
        <SelectMenu
          isClearable
          source={SELECT_IDENTIFIER}
          sourceKey={'Object'}
          filterUrl={filterUrl}
          {...this.props}
        />
      );
    }

    return (
      <SelectMenu
        isClearable
        source={SELECT_IDENTIFIER}
        sourceKey={id || 'accounts'}
        optionValuePath={'id'}
        filterUrl={filterUrl}
        {...this.props}
      />
    );
  }

  render() {
    const { infoButton, name, meta, label, labelSize } = this.props;

    if (label) {
      return (
        <FormGroup
          controlId={name}
          className={meta && meta.touched && meta.error ? ' has-error' : ''}
        >
          <Col componentClass={ControlLabel} sm={labelSize}>
            {label} {infoButton}
          </Col>
          <Col sm={12 - labelSize}>{this.renderSelectMenu()}</Col>
        </FormGroup>
      );
    }

    return this.renderSelectMenu();
  }
}

export default connect()(SelectAccount);
