import React from 'react';

export const categoryD = (
  <svg id="category_c_layer_1" data-name="Layer 1" viewBox="0 0 95 95">
    <path className="cls-1" d="M95,47.5A47.5,47.5,0,1,1,47.5,0,47.5,47.5,0,0,1,95,47.5" />
    <text style={{ fill: '#fff' }} transform="translate(45.42 63.95)" />
    <path
      style={{ fill: '#fff' }}
      d="M31,17.74H42.19q10.85,0,14.67,1A11.26,11.26,0,0,1,62.68,22a10.37,10.37,0,0,1,2.49,5.07,76.69,76.69,0,0,1,.5,11V58.23q0,7.76-.73,10.37a7.8,7.8,0,0,1-2.54,4.09,10.57,10.57,0,0,1-4.48,2.06,40.45,40.45,0,0,1-8,.59H31V17.74ZM46,27.6V65.48q3.24,0,4-1.3t0.75-7.06V34.75a30,30,0,0,0-.25-5,2.38,2.38,0,0,0-1.14-1.62A7.4,7.4,0,0,0,46,27.6Z"
    />
  </svg>
);

export default categoryD;
