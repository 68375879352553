import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../../common/components';
import { finishDeclaration } from '../../redux/screeningActions';
import ConditionSearch from '../ConditionSearch';
import ConditionQuestions from '../ConditionQuestions';
import DeclaredConditions from '../DeclaredConditions';
import getIncludedResponse from '../../../../helpers/getIncludedResource';
import { reduce, get, head, isEmpty } from 'lodash';

export default class HealixSession extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    screening: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    finishScreening: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      condition: '',
    };
    this.conditionChange = this.conditionChange.bind(this);
  }

  // Used as callback helper to sync states and hidden 'Finish Screen' button
  // when 'Screen Button' is displayed in HealixSession
  conditionChange(condition) {
    this.setState({ condition });
  }

  render() {
    const { index, screening, dispatch, finishScreening } = this.props;
    const conditions = getIncludedResponse(
      screening.declaration.data,
      screening.declaration.included,
      'conditions',
    );

    const declaredConditionsView = getIncludedResponse(
      screening.declaredConditions.data,
      screening.declaredConditions.included,
      'conditions',
    );

    const conditionsNeedRescreen = reduce(
      declaredConditionsView,
      (carry, declaredConditions) => {
        const declaredCondition = head(get(declaredConditions, 'attributes.conditions'));
        if (
          get(declaredConditions, 'attributes.requiresFullRescreen', false) ||
          get(declaredConditions, 'attributes.requiresPartialRescreen', false)
        ) {
          carry = (carry ? ' ' : '') + declaredCondition;
        }
        return carry;
      },
      '',
    );

    return (
      <div className="screening-session-healix">
        {conditions.length ? (
          conditions.map((condition, i) => {
            return (
              <div key={i} className="row">
                <div className="col-xs-12">
                  <ConditionQuestions
                    condition={condition}
                    dispatch={dispatch}
                    screening={screening}
                  />
                </div>
              </div>
            );
          })
        ) : (
          <div className="row">
            <div className="col-xs-12">
              <ConditionSearch
                conditionProps={this.state.condition}
                conditionChange={this.conditionChange}
                index={index}
                dispatch={dispatch}
                screening={screening}
              />
            </div>
          </div>
        )}

        {!screening.complete && screening.declaration.data.attributes.state === 'ready' && (
          <div className="row declaration-actions">
            <div className="col-xs-12">
              <Button
                bsStyle="primary"
                isLoading={screening.isSubmitting}
                label="Continue"
                className="pull-right"
                handleClick={() => dispatch(finishDeclaration())}
              />
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-xs-12">
            {conditionsNeedRescreen && (
              <span className={'screening-warning-msg'}>
                Warning: {conditionsNeedRescreen} needs re-screening
              </span>
            )}
          </div>
        </div>
        {screening.complete && (
          <div className="row">
            <div className="col-xs-12">
              <DeclaredConditions dispatch={dispatch} screening={screening} />
            </div>
          </div>
        )}
        {screening.complete && isEmpty(this.state.condition) && (
          <div className="row declaration-actions">
            <div className="col-xs-12">
              <Button
                className="pull-right"
                bsStyle="primary"
                label="Finish Screening"
                disabled={!!conditionsNeedRescreen}
                isLoading={screening.isSubmitting && screening.index === index}
                handleClick={finishScreening}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
