import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import {
  Button,
  Modal,
  SelectOrganisation,
} from '../../../../../../common/components';
import {
  openModal,
  closeModal,
} from '../../../../../../common/components/Modal/ModalRedux';
import { get, set, flow } from 'lodash';
import { bindActionCreators } from 'redux';
import { actions } from '../../../../redux/ProductReducer';

const FORM_NAME = 'assignAssistanceHandlerToScheme';

const validate = (values) => {
  const errors = {};

  if (!get(values, 'data.relationships.claims_handler.data.id', false)) {
    set(
      errors,
      'data.relationships.claims_handler.data.id',
      'A claims handler is required',
    );
  }

  return errors;
};

class AssistanceHandler extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
  };

  componentWillUpdate(newProps) {
    const { dispatch, submitSucceeded, callback } = this.props;
    if (newProps.submitSucceeded && !submitSucceeded) {
      if (callback) {
        callback('');
      }
      dispatch(closeModal(FORM_NAME));
    }
  }

  handleSubmit(values) {
    const { resource, actions } = this.props;
    actions.assignHandlerToScheme(resource, 'Assistance Handler', values);
  }

  handleOpen() {
    const { dispatch, reset } = this.props;
    dispatch(reset());
    dispatch(openModal(FORM_NAME));
  }

  render() {
    const { handleSubmit } = this.props;
    const handlerType = 'Assistance Handler';
    const submitButton = (
      <Button
        type="submit"
        bsStyle="primary"
        handleClick={handleSubmit(::this.handleSubmit)}
        label="Assign"
      />
    );

    return (
      <div>
        <Button
          type="button"
          label={'Assign ' + handlerType}
          handleClick={::this.handleOpen}
        />

        <Modal
          name={FORM_NAME}
          title="Assign Assistance Handler"
          close
          footer={submitButton}
        >
          <form onSubmit={handleSubmit(::this.handleSubmit)}>
            <div className="col-xs-12 form-horizontal">
              <div className="col-xs-12">
                <Field
                  name={'data.relationships.assistance_handler.data.id'}
                  label={handlerType}
                  labelSize={4}
                  filters={[{ filter: 'filter[type]', value: handlerType }]}
                  component={SelectOrganisation}
                />
              </div>
            </div>
          </form>
        </Modal>
      </div>
    );
  }
}

export default flow(
  reduxForm({
    form: FORM_NAME,
    validate,
  }),
  connect(
    null,
    (dispatch) => ({
      actions: bindActionCreators(actions, dispatch),
    }),
  ),
)(AssistanceHandler);
