import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, FieldArray, Field, FormSection } from 'redux-form';
import { get } from 'lodash';
import Travellers from '../../../Travellers/index';
import TravellersAddress from '../../../TravellersAddress';
import {
  InfoButton,
  Button,
  ButtonBar,
  HorizontalFormControl
} from '../../../../../../common/components';
import mapReactSelect from '../../../../../../helpers/mapReactSelect';
import SelectField from '../../../../../../common/components/ReduxFormField/SelectField';
import { Row, Col, ControlLabel, FormGroup } from 'react-bootstrap';

const META = 'data.attributes.metadata';
export const COL_WIDTHS = {
  labels: { sm: 2, md: 2, lg: 3 },
  fields: { sm: 10, md: 10, lg: 8 },
};

export class TravellersTab02 extends Component {

  componentWillUpdate(nextProps) {
    const { dispatch, change, formValues } = this.props;
    const travellerGroup = get(
      nextProps.formValues,
      'data.attributes.metadata.traveller_group',
    );
    const leadTraveller = {
      title: '',
      first_name: '',
      last_name: '',
      dob: '',
      email: '',
      phone1: '',
      phone2: '',
      conditions_covered: true,
      screening: '',
      metadata: {
        doctor_form_info: '',
      },
      screening_id: '',
      address: {
        line1: '',
        line2: '',
        line3: '',
        town: '',
        county: '',
        postcode: '',
      },
    };
    if (travellerGroup === 'independent_child') {
      dispatch(change('data.attributes.metadata.adults', []));
      if (get(formValues, 'data.attributes.metadata.children', []).length === 0) {
        dispatch(change('data.attributes.metadata.children', [leadTraveller]));
      }
      return;
    }

    if (get(formValues, 'data.attributes.metadata.adults', []).length === 0) {
      dispatch(change('data.attributes.metadata.adults', [leadTraveller]));
    }

    if (travellerGroup === 'couple') {
      dispatch(change('data.attributes.metadata.children', []));
    }
  }

  render() {
    const { formValues, currentProduct, openSpecificTab, handleSubmit, nextTab, dispatch } = this.props;
    const tripType = () => get(formValues, 'data.attributes.metadata.scheme_type');
    const groupType = get(formValues, 'data.attributes.metadata.traveller_group');
    const groupTypes = mapReactSelect(
      get(currentProduct, `data.attributes.metadata.group_type_trip_${tripType()}`, {}),
    );

    const filteredGroupTypeOptions = (groupTypeOptions) => {
      const order = ['individual', 'couple', 'single_parent_family', 'family', 'independent_child'];
      return groupTypeOptions.sort((a, b) => {
        return order.indexOf(a.value) - order.indexOf(b.value);
      });
    };

    const definitions = get(currentProduct, 'data.attributes.metadata.definitions', {});
    const  ageLimits = get(currentProduct, 'data.attributes.metadata.age_limit');
    const productCode = get(currentProduct, 'data.attributes.product_code');
    const bmc = ['BMC'].includes(productCode);
    const fps = ['FPS', 'FPR', 'NPO', 'NPOR', 'CSPA', 'CSPAR', 'CSS', 'CSSR'].includes(productCode);

    return (
      <div>
        <FormSection name={META}>
          {!bmc && !fps && (
            <FormGroup>
              <Col sm={COL_WIDTHS.labels.sm} md={COL_WIDTHS.labels.md} lg={COL_WIDTHS.labels.lg} componentClass={ControlLabel}>
                <InfoButton content={definitions.group_type}/> Group Type bro
              </Col>
              <Col sm={COL_WIDTHS.fields.sm} md={COL_WIDTHS.fields.md} lg={COL_WIDTHS.fields.lg}>
                <Field
                  name="traveller_group"
                  id="traveller_group"
                  component={SelectField}
                  options={filteredGroupTypeOptions(groupTypes)}
                />
              </Col>
            </FormGroup>
          )}
          {!bmc && !fps && (
            <div className="hr"/>
          )}
          <div className="form-horizontal">
            <FieldArray
              name={'adults'}
              travellerType="adult"
              change={this.props.change}
              dispatch={dispatch}
              component={TravellersAddress}
              leadAddress={groupType !== 'independent_child'}
              formValues={get(formValues, 'data.attributes.metadata.adults')}
              productCode={get(currentProduct, 'data.attributes.product_code')}
              travellerGroup={get(formValues, 'data.attributes.metadata.traveller_group')}
              ageLimits={ageLimits}
              noTravellers={{
                adults: get(formValues, 'data.attributes.metadata.adults', []).length,
                children: get(formValues, 'data.attributes.metadata.children', []).length,
              }}
              currentProduct={currentProduct}
            />
          </div>
          <div className="form-horizontal">
            <FieldArray
              child
              name="children"
              travellerType="children"
              change={this.props.change}
              dispatch={dispatch}
              leadAddress={groupType === 'independent_child'}
              formValues={get(formValues, 'data.attributes.metadata.children')}
              component={
                groupType === 'independent_child' ? TravellersAddress : Travellers
              }
              travellerGroup={get(formValues, 'data.attributes.metadata.traveller_group')}
              ageLimits={ageLimits}
              noTravellers={{
                adults: get(formValues, 'data.attributes.metadata.adults', []).length,
                children: get(formValues, 'data.attributes.metadata.children', []).length,
              }}
              currentProduct={currentProduct}
            />
          </div>
          {fps && (
            <FormGroup>
              <Col sm={3} componentClass={ControlLabel}>
                Membership number
              </Col>
              <Col sm={4}>
                <Field
                  name="membership_number"
                  type="input"
                  component={HorizontalFormControl}
                />
              </Col>
            </FormGroup>
          )}
        </FormSection>
        <hr/>
        <Row>
          <Col sm={12}>
            <ButtonBar>
              <Button
                label="Back"
                id="back"
                className="btn-action-back pull-left"
                bsStyle="primary"
                handleClick={() => openSpecificTab(1)}
              />
              <Button
                label="Next"
                id="next"
                className="pull-right btn-action-blue"
                bsStyle="primary"
                handleClick={() => dispatch(handleSubmit(() => openSpecificTab(nextTab)))}
              />
            </ButtonBar>
          </Col>
        </Row>
      </div>
    );
  }
}

const FORM_NAME = 'LeisureTravelForm';
const form = reduxForm({ form: FORM_NAME })(TravellersTab02);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state) => {
  const values = selector(
    state,
    'data.id',
    'data.current_tab',
    'data.attributes.metadata',
  );

  return {
    auth: state.auth,
    form: 'LeisureTravelForm',
    formValues: values,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    product: state.product,
  };
};

export default connect(mapStateToProps)(form);
