import React, { Component } from 'react';
import { Pie } from 'react-chartjs-2';
import { get } from 'lodash';
import './styles.scss';

export default class PieChart extends Component {
  render() {
    const { range, stats } = this.props;

    let displayStats;
    let displayQuotesStats;

    if (range === 'Today') {
      displayQuotesStats = stats.quotes.day;
    }

    if (range === 'This week') {
      displayQuotesStats = stats.quotes.week;
    }

    if (range === 'This month') {
      displayQuotesStats = stats.quotes.month;
    }

    if (range === 'Yesterday') {
      displayQuotesStats = stats.quotes.yesterday;
    }

    displayStats = displayQuotesStats;

    const chartData = {
      labels: ['Issued', 'Renewals', 'Unconverted'],
      datasets: [
        {
          backgroundColor: ['#243b79', '#41a5f5', '#6d6e70', '#f2f2f2'],
          hoverBackgroundColor: ['#243b79', '#41a5f9', '#949597', '#f8f8f8'],
          data: [
            get(displayStats, 'issued', 0),
            get(displayStats, 'renewals', 0),
            get(displayStats, 'unconverted', 0),
          ],
        },
      ],
    };

    const options = {
      maintainAspectRatio: false,
      responsive: true,
      cutoutPercentage: -1,
      legend: {
        labels: {
          boxWidth: 10,
          fontSize: 10,
        },
      },
    };

    return (
      <div className="chart-wrapper pie">
        <Pie data={chartData} options={options} />
      </div>
    );
  }
}
