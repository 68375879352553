import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { Form, Row, Col, ControlLabel } from 'react-bootstrap';
import { Sticky, StickyContainer } from 'react-sticky';
import { Button } from '../../../../common/components';
import displayAccountAmount from '../../../../helpers/displayAccountAmount';
import BulkPaymentButton from '../../components/BulkPaymentButton/BulkPaymentButton';
import MakeAdjustmentButton from '../MakeAdjustmentButton';
import MakePaymentButton from '../MakePaymentButton';
import MakeReceiptButton from '../MakeReceiptButton';
import LedgerEntry from './LedgerEntry';
import {
  getAccount,
  getPremiums,
  clearAccount,
  cachePremiums,
  getTransactions,
  runBulkMatching,
  cacheTransactions,
  manageBulkMatching,
  saveAccountMatching,
} from '../../redux/accountActions';
import moment from 'moment';
import { forEach, debounce, remove, some, concat } from 'lodash';
import './styles.scss';
import DatePickerDob from '../../../../common/components/DatePicker/DatePickerDob';
import { bindActionCreators } from 'redux';

const FORM_NAME = 'accountMatching';

class AccountMatching extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unmatchedTransactions: [],
      matchedTransactions: [],
      unmatchedPremiums: [],
      matchedPremiums: [],
      matchedTransactionsAmount: 0.00,
      matchedPremiumsAmount: 0.00,
      premiumType: '',
      premiumFilter: '',
      transactionFilter: '',
      resetting: false,
      matchingDate: '',
    };
  }

  componentWillMount() {
    const { filters, account } = this.props;
    this.getData(filters);

    this.setState({
      unmatchedPremiums: account.premiums.data,
      unmatchedTransactions: account.transactions.data,
    });

    this.getData = debounce(this.getData, 500);
  }

  componentWillReceiveProps(nextProps) {
    const { account: { premiums, transactions, cachedPremiums, cachedTransactions } } = this.props;
    const { resetting } = this.props;

    if (!resetting) {
      this.getData(nextProps.filters);
    }

    if (nextProps.account.transactions.accountId !== transactions.accountId) {
      this.setState({
        unmatchedTransactions: nextProps.account.transactions.data.filter((transaction) => {
          return !some(cachedTransactions, transaction);
        }),
        matchedTransactions: cachedTransactions,
      });
    }

    if (nextProps.account.premiums.accountId !== premiums.accountId) {
      this.setState({
        unmatchedPremiums: nextProps.account.premiums.data.filter((premium) => {
          return !some(cachedPremiums, premium);
        }),
        matchedPremiums: cachedPremiums,
      });
    }

    this.setState({ resetting: false });
  }

  componentWillUnmount() {
    const { actions } = this.props;
    actions.clearAccount();
  }

  getData(matchingFilters = {}) {
    const { actions } = this.props;
    const { premiumType, premiumFilter, transactionFilter } = this.state;

    if (!matchingFilters.accountId) {
      return;
    }

    // Premiums
    let premiumFilters = {};
    let transactionFilters = {};

    if (matchingFilters.brokerId) {
      premiumFilters['broker'] = matchingFilters.brokerId;
      transactionFilters['broker'] = matchingFilters.brokerId;
    }

    if (matchingFilters.currency) {
      premiumFilters['currency'] = matchingFilters.currency;
      transactionFilters['currency'] = matchingFilters.currency;
    }

    if (matchingFilters.premium.dateFrom) {
      premiumFilters['date:from'] = moment(matchingFilters.premium.dateFrom).format('YYYY-MM-DD');
    }

    if (matchingFilters.premium.dateTo) {
      premiumFilters['date:to'] = moment(matchingFilters.premium.dateTo).format('YYYY-MM-DD');
    }

    if (matchingFilters.premium.amountFrom !== '') {
      premiumFilters['amount:from'] = matchingFilters.premium.amountFrom;
    }

    if (matchingFilters.premium.amountTo !== '') {
      premiumFilters['amount:to'] = matchingFilters.premium.amountTo;
    }

    let premiumFilterUrl = '';
    if (premiumFilters) {
      forEach(premiumFilters, (filter, key) => {
        premiumFilterUrl += `&filter[${key}]=${filter}`;
      });
    }

    if (premiumFilterUrl !== premiumFilter || matchingFilters.premium.type !== premiumType) {
      actions.cachePremiums([]);
      actions.getPremiums(matchingFilters.accountId, matchingFilters.premium.type, 'sort=created_at' + premiumFilterUrl);
      this.setState({
        premiumType: matchingFilters.premium.type,
        premiumFilter: premiumFilterUrl,
      });
    }

    // Transactions
    if (matchingFilters.transaction.dateFrom) {
      transactionFilters['date:from'] = moment(matchingFilters.transaction.dateFrom).format('YYYY-MM-DD');
    }

    if (matchingFilters.transaction.dateTo) {
      transactionFilters['date:to'] = moment(matchingFilters.transaction.dateTo).format('YYYY-MM-DD');
    }
    if (matchingFilters.transaction.amountFrom !== '') {
      transactionFilters['amount:from'] = parseFloat(matchingFilters.transaction.amountFrom) * -1;
    }

    if (matchingFilters.transaction.amountTo !== '') {
      transactionFilters['amount:to'] = parseFloat(matchingFilters.transaction.amountTo) * -1;
    }

    let transactionFilterUrl = '';
    if (transactionFilters) {
      forEach(transactionFilters, (filter, key) => {
        transactionFilterUrl += `&filter[${key}]=${filter}`;
      });
    }

    if (transactionFilterUrl !== transactionFilter) {
      actions.cacheTransactions([]);
      actions.getTransactions(matchingFilters.accountId, 'sort=created_at' + transactionFilterUrl);
      this.setState({ transactionFilter: transactionFilterUrl });
    }
  }

  getBalance(items, formatted = true) {
    let balance = 0.0;

    if (items.length > 0) {
      items.map((item) => {
        balance += parseFloat(item.attributes.amount);
      });
    }

    if (formatted) {
      return displayAccountAmount(balance);
    }

    return balance;
  }

  setBalance(items, type) {
    const typeMap = this.mapping(type);

    if (typeMap.selected) {
      const amt = type + 'Amount';
      const amount = this.getBalance(items, false);
      this.setState({
        [amt]: amount,
      });
    }
  }

  reset() {
    const { actions, filters, resource } = this.props;

    this.setState({
      unmatchedTransactions: [],
      matchedTransactions: [],
      unmatchedPremiums: [],
      matchedPremiums: [],
      matchedTransactionsAmount: 0.00,
      matchedPremiumsAmount: 0.00,
      transactionFilter: '',
      premiumFilter: '',
      resetting: true,
    });

    this.getData(filters);
    actions.getAccount(resource.data.id);
  }

  moveItem(item, from, to) {
    const fromArray = this.state[from];
    const toArray = this.state[to];

    remove(fromArray, item);
    toArray.push(item);
    this.setState({
      [from]: fromArray,
      [to]: toArray,
    });

    this.setBalance(fromArray, from);
    this.setBalance(toArray, to);
  }

  mapping(type) {
    const { account } = this.props;

    const mapping = {
      unmatchedTransactions: {
        title: 'Unmatched Receipts',
        entries: [],
        onSelectXfrTo: 'matchedTransactions',
        loader: account.isFetchingTransactions,
        selected: false,
      },
      matchedTransactions: {
        title: 'Matched Receipts',
        entries: [],
        onSelectXfrTo: 'unmatchedTransactions',
        loader: account.isFetchingTransactions,
        selected: true,
      },
      unmatchedPremiums: {
        title: 'Unmatched Premiums',
        entries: [],
        onSelectXfrTo: 'matchedPremiums',
        loader: account.isFetchingPremiums,
        selected: false,
      },
      matchedPremiums: {
        title: 'Matched Premiums',
        entries: [],
        onSelectXfrTo: 'unmatchedPremiums',
        loader: account.isFetchingPremiums,
        selected: true,
      },
    };

    let typeMap = mapping[type];
    typeMap.entries = this.state[type];

    return typeMap;
  }

  handleSubmit(form) {
    const { resource, actions } = this.props;
    const { matchedPremiums, matchedTransactions } = this.state;

    if (!this.difference().balancesMatch) {
      return false;
    }

    return actions.saveAccountMatching(
      {
        meta: {
          date: form.date,
          matched_premiums: matchedPremiums,
          matched_transactions: matchedTransactions,
        }
      },
      resource.data.id,
      ::this.reset
    );
  }

  selectAll() {
    const {
      matchedPremiums,
      matchedTransactions,
      unmatchedPremiums,
      unmatchedTransactions
    } = this.state;
    const totalMatchedPremiums = concat(matchedPremiums, unmatchedPremiums);
    const totalMatchedTransactions = concat(matchedTransactions, unmatchedTransactions);

    this.setState({
      matchedPremiums: totalMatchedPremiums,
      unmatchedPremiums: [],
      matchedTransactions: totalMatchedTransactions,
      unmatchedTransactions: [],
    });

    this.setBalance(totalMatchedPremiums, 'matchedPremiums');
    this.setBalance(totalMatchedTransactions, 'matchedTransactions');
  }

  unselectAll() {
    const {
      matchedPremiums,
      matchedTransactions,
      unmatchedPremiums,
      unmatchedTransactions
    } = this.state;
    const totalUnmatchedPremiums = concat(matchedPremiums, unmatchedPremiums);
    const totalUnmatchedTransactions = concat(matchedTransactions, unmatchedTransactions);

    this.setState({
      matchedPremiums: [],
      unmatchedPremiums: totalUnmatchedPremiums,
      matchedTransactions: [],
      unmatchedTransactions: totalUnmatchedTransactions,
    });

    this.setBalance([], 'matchedPremiums');
    this.setBalance([], 'matchedTransactions');
  }

  renderLedgerEntriesContainer(type) {
    const typeMap = this.mapping(type);

    return (
      <div className="sortable-container">
        <h4>
          {typeMap.title}
          <span className="pull-right">
            {this.getBalance(typeMap.loader ? 0 : typeMap.entries)}
          </span>
        </h4>

        {typeMap.loader ? (
          <div className="loading__center-container">
            <span className="loader-dots">
              <i className="fa fa-circle loader-dot"/>
              <i className="fa fa-circle loader-dot"/>
              <i className="fa fa-circle loader-dot"/>
            </span>
          </div>
        ) : (
          <div className="items">
            {typeMap.entries.map((item, i) => (
              <LedgerEntry
                key={i}
                item={item}
                selected={typeMap.selected}
                onItemSelect={() => this.moveItem(item, type, typeMap.onSelectXfrTo)}
              />
            ))}
          </div>
        )}
      </div>
    );
  }

  difference() {
    const {
      matchedTransactions,
      matchedPremiums,
      matchedTransactionsAmount,
      matchedPremiumsAmount,
    } = this.state;

    const difference = matchedPremiumsAmount + matchedTransactionsAmount;
    const balancesMatch =
      matchedTransactions.length + matchedPremiums.length > 0 &&
      Math.abs(difference).toFixed(2) === '0.00';

    return {
      difference: difference,
      balancesMatch: balancesMatch,
    };
  }

  renderSideBar() {
    const {
      matchedTransactions,
      matchedPremiums,
      unmatchedTransactions,
      unmatchedPremiums,
      transactionFilter,
      matchingDate,
    } = this.state;
    const { resource, account, actions } = this.props;
    const submitting = account.isSubmitting;
    const accountType = resource.data.attributes.account_type;
    const difference = this.difference();
    const matchedEntries = matchedTransactions.length + matchedPremiums.length;
    const unmatchedEntries = unmatchedTransactions.length + unmatchedPremiums.length;
    const totalEntries = matchedEntries + unmatchedEntries;

    return (
      <StickyContainer className="matching-sidebar">
        <Sticky>
          <div className="matched-buttons">
            {(unmatchedEntries > 0) && (
              <Button
                label="Select All"
                className="matching-sidebar-btn"
                handleClick={::this.selectAll}
                isLoading={submitting}
                block
              />
            )}
            {(matchedEntries > 0) && (
              <Button
                label="Unselect All"
                className="matching-sidebar-btn"
                handleClick={::this.unselectAll}
                isLoading={submitting}
                block
              />
            )}
          </div>
          <div className="matched-stats">
            <ControlLabel>Payments/Receipts</ControlLabel>
            {matchedTransactions.length || 'None'}
          </div>
          <div className="matched-stats">
            <ControlLabel>Premiums</ControlLabel>
            {matchedPremiums.length || 'None'}
          </div>
          <div className="matched-stats">
            <ControlLabel>Difference</ControlLabel>
            {displayAccountAmount(difference.difference)}
          </div>
          {difference.balancesMatch && (
            <div className="matched-stats">
              <div className="matched-buttons">
                <Field
                  name="date"
                  component={DatePickerDob}
                />
                <Button
                  className="match-btn"
                  type="submit"
                  bsStyle="success"
                  label="Match"
                  isLoading={submitting}
                  block
                />
              </div>
            </div>
          )}
          <div className="matched-buttons">
            {(difference.difference).toFixed(2) < 0.0 && (
              <MakePaymentButton
                label="Pay"
                matchedPremiums={matchedPremiums}
                matchedTransactions={matchedTransactions}
                resource={resource}
                amount={difference.difference * -1}
                callback={::this.reset}
                submitting={submitting}
                block
              />
            )}

            <MakeAdjustmentButton
              label="Adjustment"
              resource={resource}
              amount={difference.difference}
              matchedTransactions={matchedTransactions}
              submitting={submitting}
              block
            />

            <MakeReceiptButton
              label={accountType === 'creditor' ? 'payment' : 'receipt'}
              resource={resource}
              amount={difference.difference}
              matchedTransactions={matchedTransactions}
              filters={transactionFilter}
              submitting={submitting}
              block
            />

            {(matchedEntries > 0) && (
              <Button
                label="Reset"
                className="reset-button matching-sidebar-btn"
                handleClick={::this.reset}
                isLoading={submitting}
                block
              />
            )}

            {(totalEntries > 0 ) && (
              <div className="bulk-matching-btns">
                <ControlLabel>Bulk As At</ControlLabel>
                <DatePickerDob
                  input={{
                    value: matchingDate,
                    onChange: (value) =>
                      this.setState({ matchingDate: value }),
                  }}
                />
                <Button
                  bsStyle="primary"
                  className="matching-sidebar-btn"
                  label={
                    accountType === 'creditor'
                      ? 'Manage Bulk Payments'
                      : 'Manage Bulk Matching'
                  }
                  isLoading={submitting}
                  handleClick={() => {
                    actions.manageBulkMatching(resource.data.id, matchingDate);
                  }}
                  block
                />
                <Button
                  bsStyle="primary"
                  className="matching-sidebar-btn"
                  label={
                    accountType === 'creditor'
                      ? 'Queue Bulk Payments'
                      : 'Queue Bulk Matching'
                  }
                  isLoading={submitting}
                  handleClick={() => {
                    actions.manageBulkMatching(resource.data.id, matchingDate, false, true, true);
                  }}
                  block
                />

                {accountType === 'creditor' ? (
                  <BulkPaymentButton
                    label={'Make Bulk Payment'}
                    accountId={resource.data.id}
                    matchingDate={matchingDate}
                    submitting={submitting}
                    block
                  />
                ) : (
                  <Button
                    bsStyle="primary"
                    className="matching-sidebar-btn"
                    label={'Run Bulk Matching'}
                    isLoading={submitting}
                    handleClick={() => {
                      actions.runBulkMatching(resource.data.id, matchingDate);
                    }}
                    block
                  />
                )}
                <Button
                  className="reset-button matching-sidebar-btn"
                  label={
                    accountType === 'creditor'
                      ? 'Reset Bulk Payments'
                      : 'Reset Bulk Matching'
                  }
                  isLoading={submitting}
                  handleClick={() => {
                    actions.manageBulkMatching(
                      resource.data.id,
                      this.state.matchingDate,
                      true,
                    );
                  }}
                  block
                />
              </div>
            )}
          </div>
        </Sticky>
      </StickyContainer>
    );
  }

  render() {
    const { handleSubmit, resource, accountLabel } = this.props;

    return (
      <Form onSubmit={handleSubmit(::this.handleSubmit)}>
        <h2 className="resource-name">
          {resource.data.attributes.name}
          {accountLabel}
        </h2>
        <Row className="accounts-matching">
          <Col sm={9} md={10}>
            <Row className="transaction-container">
              <Col sm={6}>
                {this.renderLedgerEntriesContainer('unmatchedTransactions')}
              </Col>
              <Col sm={6}>
                {this.renderLedgerEntriesContainer('matchedTransactions')}
              </Col>
            </Row>
            <Row className="premium-container">
              <Col sm={6}>
                {this.renderLedgerEntriesContainer('unmatchedPremiums')}
              </Col>
              <Col sm={6}>
                {this.renderLedgerEntriesContainer('matchedPremiums')}
              </Col>
            </Row>
          </Col>
          <Col md={3} lg={2}>
            {this.renderSideBar()}
          </Col>
        </Row>
      </Form>
    );
  }
}

const form = reduxForm({ form: FORM_NAME })(AccountMatching);

function mapStateToProps(state) {
  return {
    account: state.account,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      { getAccount, clearAccount, getPremiums, cachePremiums, getTransactions, cacheTransactions, saveAccountMatching, manageBulkMatching, runBulkMatching },
      dispatch,
    ),
    dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(form);
