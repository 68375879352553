import React from 'react';
import { Row, Col } from 'react-bootstrap';
import BaseRates from '../BaseRates';
import DisplayListContent from '../DisplayListContent';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../../redux/ProductReducer';
import '../style.scss';
import SchemeContainer from './components/SchemeContainer/SchemeContainer';
import Button from '../../../../common/components/Button/Button';
import './ProductFrontPage.scss';

class ProductFrontPage extends React.Component {
  render() {
    const {
      product,
      stats,
      params,
      destinations,
      router,
      activeSchemeGroupSingle,
      activeSchemeGroupAnnual,
      schemeGroupListSingle,
      schemeGroupListAnnual,
      coverLevels,
      quotationTypes,
      maxTripDurations,
      regions,
      medicalQuestions,
      options,
      definitions,
    } = this.props;

    return (
      <Row>
        {/* PRODUCT GENERAL */}

        <Col sm={12}>
          <div className="box product-container margin-bottom">
            <h2 className={'title-with-icon'}>
              {product.attributes.icon && (
                <img src={product.attributes.icon} alt={product.attributes.name} />
              )}
              {product.attributes.name}
            </h2>
            <div className="status active">{product.attributes.status}</div>
            <div className="hr" />
            <Row>
              <Col sm={12}>{stats && <BaseRates stats={stats.total} />}</Col>
              <Col sm={6}>
                {product && (
                  <DisplayListContent
                    title="Cover Level"
                    customType="cover-level"
                    contentToMap={coverLevels}
                  />
                )}
              </Col>
              <Col sm={6}>
                {product && (
                  <DisplayListContent
                    title="Quotation Types"
                    contentToMap={quotationTypes}
                  />
                )}
              </Col>
            </Row>
          </div>
        </Col>

        {/* SINGLE TRIP SCHEME */}

        {schemeGroupListSingle && schemeGroupListSingle.length > 0 && (
          <Col sm={6}>
            <SchemeContainer
              activeScheme={activeSchemeGroupSingle}
              schemeGroupList={schemeGroupListSingle}
              product={product}
              router={router}
              params={params}
            >
              {stats && <BaseRates display="vertical" stats={stats.single} />}
              {product && (
                <DisplayListContent
                  title="Country List"
                  customType="country-list"
                  contentToMap={destinations}
                />
              )}
            </SchemeContainer>
          </Col>
        )}

        {/* ANNUAL TRIP SCHEME */}

        {schemeGroupListAnnual && schemeGroupListAnnual.length > 0 && (
          <Col sm={6}>
            <SchemeContainer
              activeScheme={activeSchemeGroupAnnual}
              schemeGroupList={schemeGroupListAnnual}
              product={product}
              router={router}
              params={params}
            >
              {stats && <BaseRates display="vertical" stats={stats.annual} />}
              {product && <DisplayListContent title="Regions" contentToMap={regions} />}
              {product && (
                <DisplayListContent
                  title="Max Trip Durations"
                  contentToMap={maxTripDurations}
                />
              )}
            </SchemeContainer>
          </Col>
        )}

        {/* PRODUCT GENERAL EXTRAS */}

        <Col sm={12}>
          <div className="box product-container margin-bottom">
            <Row>
              <Col sm={6}>
                {product && (
                  <DisplayListContent
                    title="Medical Questions"
                    customType="medical-questions"
                    contentToMap={medicalQuestions}
                  />
                )}
                {product && (
                  <DisplayListContent
                    title="Options"
                    customType="options"
                    contentToMap={options}
                  />
                )}
              </Col>
              <Col sm={6}>
                {product && definitions && (
                  <div>
                    <DisplayListContent
                      title="Definitions - (info-button content)"
                      customType="definitions"
                      contentToMap={definitions}
                    />
                    <Button
                      bsStyle="primary"
                      size="xs"
                      className="pull-right"
                      link
                      to={`/admin/products/${product.id}/definitions`}
                    >
                      Edit
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    );
  }
}

export default connect(
  null,
  (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
  }),
)(ProductFrontPage);
