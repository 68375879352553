import React from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import { Col, Row, ControlLabel } from 'react-bootstrap';
import { Button, SelectDocument } from '../../../../common/components';
import { flow } from 'lodash';
import './DocumentPacksForm.scss';
import ProductContainer from '../ProductContainer/ProductContainer';

class DocumentPacksForm extends React.Component {
  componentWillMount() {
    const { dispatch, change, documentPacks } = this.props;

    documentPacks.data.map((pack, index) => {
      dispatch(change(`data[${index}]`, pack));
    });
  }

  render() {
    const { handleSubmit, documentPacks } = this.props;
    return (
      <ProductContainer>
        <Form onSubmit={handleSubmit}>
          {documentPacks.data.map((pack, index) => {
            return (
              <div className="" key={index}>
                <Row>
                  <Col sm={12}>
                    <h3 className="capitalise">{pack.attributes.for} pack</h3>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <ControlLabel>
                      {'Documents to be included in the ' +
                        pack.attributes.for +
                        ' document pack'}
                    </ControlLabel>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Field
                      name={'data[' + index + '].relationships.scheme_documents.data'}
                      component={SelectDocument}
                    />
                  </Col>
                </Row>

                <div className="hr transparent" />
              </div>
            );
          })}
          <div className="divider margin-bottom" />
          <Row>
            <Col sm={12}>
              <Button bsStyle="default" link={true} to={'/admin/products'}>
                Back
              </Button>
              <Button bsStyle="primary" className="pull-right" type="submit">
                Save Changes
              </Button>
            </Col>
          </Row>
        </Form>
      </ProductContainer>
    );
  }
}

export default flow(
  reduxForm({
    form: 'DocumentPacksForm',
  }),
)(DocumentPacksForm);
