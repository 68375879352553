export const SET_PRODUCTS = 'socrates-online/scheme/SET_PRODUCTS';
export const SET_DESTINATIONS = 'socrates-online/scheme/SET_DESTINATIONS';
export const SET_PRODUCT = 'socrates-online/scheme/SET_PRODUCT';
export const SET_PRODUCTS_STATS = 'socrates-online/scheme/SET_PRODUCTS_STATS';
export const SET_SCHEME = 'socrates-online/scheme/SET_SCHEME';
export const SET_SCHEME_OPTIONS = 'socrates-online/scheme/SET_SCHEME_OPTIONS';
export const SET_SCHEME_BENEFITS = 'socrates-online/scheme/SET_SCHEME_BENEFITS';
export const IS_LOADING = 'socrates-online/scheme/IS_LOADING';
export const IS_COPYING = 'socrates-online/scheme/IS_COPYING';
export const IS_EDITING = 'socrates-online/scheme/IS_EDITING';
export const IS_IMPORTING = 'socrates-online/scheme/IS_IMPORTING';
export const IS_EXPORTING = 'socrates-online/scheme/IS_EXPORTING';
export const CLEAR_DATA = 'socrates-online/scheme/CLEAR_DATA';

export const initialState = {
  isLoading: false,
  isCopying: false,
  isEditing: false,
  isImporting: false,
  isExporting: false,
  products: {},
  product: {},
  scheme: {},
  stats: {},
  destinations: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        isLoading: action.status,
      };
    case IS_COPYING:
      return {
        ...state,
        isCopying: action.status,
      };
    case IS_EDITING:
      return {
        ...state,
        isEditing: action.status,
      };
    case IS_IMPORTING:
      return {
        ...state,
        isImporting: action.status,
      };
    case IS_EXPORTING:
      return {
        ...state,
        isExporting: action.status,
      };
    case SET_PRODUCTS:
      return {
        ...state,
        products: action.data,
      };
    case SET_PRODUCTS_STATS:
      return {
        ...state,
        stats: action.data.meta.TRAV,
      };
    case SET_DESTINATIONS:
      return {
        ...state,
        destinations: action.data,
      };
    case SET_PRODUCT:
      return {
        ...state,
        product: action.data,
      };
    case SET_SCHEME:
      return {
        ...state,
        scheme: action.data,
      };
    case SET_SCHEME_OPTIONS:
      return {
        ...state,
        scheme: action.data,
      };
    case SET_SCHEME_BENEFITS:
      return {
        ...state,
        scheme: action.data,
      };
    default:
      return state;
  }
}
