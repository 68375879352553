import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import InternalContainer from '../../layout/containers/Internal';
import { Box, Loader }  from '../../../common/components';
import ProductDefinitionsForm from '../components/ProductDefinitionsForm/ProductDefinitionsForm';
import { actions } from '../redux/ProductReducer';

const ProductDefinitionsFormContainer = ({ actions, params: { productId } }) => {
  const [definitions, setDefinitions] = useState(null);

  useEffect(() => {
    handleSetup();
  }, []);

  const handleSetup = () => {
    Promise.resolve(actions.getDefinitions(productId)).then((response) => {
      setDefinitions(response.definitions);
    });
  };

  const updateDefinitions = (values) => {
    return Promise.resolve(actions.updateDefinitions(productId, values)).then(()=>{
      handleSetup();
    });
  };

  if (!definitions) {
    return (<InternalContainer title="Product management - Definitions">
      <Loader/>
    </InternalContainer>);
  }

  return (
    <InternalContainer title="Product management - Definitions">
      <Box>
        <ProductDefinitionsForm
          definitions={definitions}
          updateDefinitions={updateDefinitions}
        />
      </Box>

    </InternalContainer>
  );
};

export default connect(
  null,
  (dispatch) => ({
    actions: bindActionCreators(actions, dispatch)
  })
)
(ProductDefinitionsFormContainer);
