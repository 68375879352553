import React from 'react';
import './style.scss';
import { map, isObject, startCase, get } from 'lodash';
import displayName from '../../../helpers/displayName';
import InfoButton from '../../../common/components/InfoButton/InfoButton';

const DefinitionSection = ({ definition, name }) => (
  <div className="section-item">
    <h5><InfoButton content={definition}/> {startCase(name)}</h5>
  </div>
);
const Definition = ({ definition, name }) => {
  if (!definition) {
    return null;
  }
  if (!isObject(definition)) {
    return (
     <DefinitionSection definition={definition} name={name}/>
    );
  }
  if (name === 'hazardous_activities') {
    return (<div>
      <DefinitionSection definition={definition} name={'Activity Pack'}/>
      {map(definition, (subDefinition, subKey) => {
        if (!['content', 'title'].includes(subKey)) {
          return (<DefinitionSection
            definition={subDefinition}
            name={subKey}
          />);
        }
      })}
    </div>);
  }
  if (null !== get(definition, 'content', null)) {
    return (
      <DefinitionSection definition={definition} name={name}/>
    );
  }
  return null;
};

class DisplayListContent extends React.Component {
  render() {
    const { title, contentToMap, customType } = this.props;
    return (
      <div>
        <h5 className="section-title join-cover">{title}</h5>
        <div className="product-container-section join">
          <div className="scroll-section">
            {map(contentToMap, (item, key) => {
              if (customType && customType === 'cover-level') {
                return (
                  <div key={key} className="section-item">
                    <p>{displayName(item)}</p>
                    <div className={`color-box ${item}`} />
                  </div>
                );
              }

              if (customType && customType === 'country-list') {
                if (item.attributes.included) {
                  return (
                    <div key={key} className="section-item">
                      <p>{item.attributes.name}</p>
                    </div>
                  );
                }
              }

              if (customType && customType === 'medical-questions') {
                return (
                  <div key={key} className="section-item">
                    <p>
                      <strong>Content: </strong>
                      {item.content}
                    </p>
                    <p>
                      <strong>Display: </strong>
                      {item.display ? 'true' : 'false'}
                    </p>
                    <p>
                      <strong>Negotiable: </strong>
                      {item.negotiable ? 'true' : 'false'}
                    </p>
                  </div>
                );
              }

              if (customType && customType === 'definitions') {
                return (<Definition
                  definition={item}
                  name={key}
                />);
              }

              if (customType && customType === 'options') {
                return (
                  <div key={key} className="section-item">
                    <p>{item.name}</p>
                  </div>
                );
              }

              if (!customType) {
                return (
                  <div key={key} className="section-item">
                    <p>{item}</p>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default DisplayListContent;
