import React from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { map } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../../../../../../redux/ProductReducer';
import SchemeDropdownActions from '../SchemeDropdownActions/SchemeDropdownActions';

class SchemeHeader extends React.Component {
  selectSchemeGroup = (scheme, key) => () => {
    const { actions } = this.props;

    actions.selectActiveScheme(scheme, key);
  };

  render() {
    const { activeScheme, schemeGroupList, product, router, params } = this.props;
    let displayActiveScheme = schemeGroupList[0];

    if (activeScheme) {
      displayActiveScheme = activeScheme;
    }

    return (
      <div className="scheme-header">
        {displayActiveScheme && (
          <div className="product-title">
            <h3>{displayActiveScheme.attributes.name}</h3>
            <div className={`status ${displayActiveScheme.attributes.status}`}>
              {displayActiveScheme.attributes.status}
            </div>
          </div>
        )}
        <div className="old-version-dropdown">
          <DropdownButton
            bsStyle={'default'}
            pullRight
            title={'v' + displayActiveScheme.attributes.version}
            id={'split-button-basic'}
          >
            {map(schemeGroupList, (scheme, key) => {
              return (
                <MenuItem
                  key={key}
                  eventKey={key + 1}
                  active={displayActiveScheme.id === scheme.id}
                >
                  <div className="dropdown-item">
                    <span>
                      {scheme.attributes.name} - v{scheme.attributes.version}
                    </span>
                    <div className={`status ${scheme.attributes.status}`}>
                      {scheme.attributes.status}
                    </div>
                  </div>
                </MenuItem>
              );
            })}
          </DropdownButton>
        </div>
        <div className="scheme-actions-dropdown">
          <SchemeDropdownActions
            params={params}
            router={router}
            product={product}
            activeScheme={displayActiveScheme}
            schemeGroupList={schemeGroupList}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
  }),
)(SchemeHeader);
