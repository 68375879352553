import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { SelectField } from '../../../../common/components/ReduxFormField';
import {
  Box,
  Button,
  DatePickerDob,
  SelectProduct,
  SelectInsurer,
  SelectAccount,
  SelectBroker,
  SelectAccountingPeriod,
} from '../../../../common/components';
import { generateReport } from '../../redux/accountActions';
import { get, indexOf } from 'lodash';
import { Form, Col, Row, ControlLabel, FormGroup } from 'react-bootstrap';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import './styles.scss';

const FORM_NAME = 'bordereauxReportsForm';

const validate = (values, endDateOnlyRequired) => {
  const errors = {};

  if (!get(values, 'report')) {
    errors.report = 'Report is required';
  }

  if (
    indexOf(endDateOnlyRequired, get(values, 'report')) === -1 &&
    !get(values, 'from_date')
  ) {
    errors.from_date = 'From date is required';
  }

  if (!get(values, 'to_date')) {
    errors.to_date = 'To date is required';
  }

  if (get(values, 'report') === 'broker_statement' && !get(values, 'account')) {
    errors.account = 'Account required';
  }

  return errors;
};

class BordereauxReports extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    isGeneratingReport: PropTypes.bool.isRequired,
  };

  constructor(props) {
     super(props);
     this.state = {
        queue: false
     };
  }

  handleSubmit(values) {
    this.props.dispatch(generateReport(values));
  }

  componentWillReceiveProps(nextProps) {
    const { change, dispatch, formValues } = this.props;
    const reportOld = get(formValues, 'report');
    const reportNew = get(nextProps.formValues, 'report');
    if (reportOld !== reportNew) {
      dispatch(change('product', ''));
      dispatch(change('account', ''));
      dispatch(change('broker', ''));
      dispatch(change('insurer', ''));
    }
  }

  handleGenerateOnQueue() {
    const { change, dispatch } = this.props;

    if (this.state.queue) {
      dispatch(change('generator', null));
    } else {
      dispatch(change('generator', 'queue'));
    }

    this.setState({ queue: !this.state.queue });
  }

  render() {
    const {
      formValues,
      handleSubmit,
      isGeneratingReport,
      endDateOnlyRequired,
    } = this.props;

    const reports = [
      { label: 'Accounts Master Report', value: 'accounts_master_report:bespoke' },
      { label: 'Data Export', value: 'data_export:bespoke' },
      { label: 'Aged Premiums Report', value: 'aged_premiums_report:bespoke' },
      { label: 'Aged Payables Report', value: 'aged_payables_report:bespoke' },
      { label: 'Paid Bordereaux', value: 'bordereaux_paid' },
      { label: 'Unpaid Bordereaux', value: 'bordereaux_unpaid' },
      { label: 'Bordereaux', value: 'bordereaux' },
      { label: 'Broker Statement', value: 'broker_statement' },
      { label: 'Receipts/Payments List', value: 'receipts_list' },
      {
        label: 'Unallocated Payments and Receipts List',
        value: 'unallocated_payments_receipts_list:bespoke',
      },
      { label: 'Aged Debtors List', value: 'aged_debt' },
      { label: 'Verisk Report', value: 'verisk_report:bespoke' },
      { label: 'Bastion Report', value: 'bastion_report:bespoke' },
      { label: 'Signposted Quotes', value: 'signposted_quotes:bespoke' },
      { label: 'Signposted Policies', value: 'signposted_policies:bespoke' },
      { label: 'Voyager Report', value: 'voyager_report:bespoke' },
      { label: 'Lloyds Report', value: 'lloyds_report:bespoke' },
      { label: 'Broker/Agency Details Report', value: 'broker_agency_report:bespoke' },
      { label: 'Sanctions Report', value: 'sanctions_report:bespoke' },
      { label: 'Retail Policy Report', value: 'retail_policy_report:bespoke' },
      { label: 'Retail Insured Person Report', value: 'retail_insured_person_report:bespoke' },
    ];

    const bordereauxReports = [
      'bordereaux_paid',
      'bordereaux_unpaid',
      'bordereaux',
      'data_export:bespoke',
      'accounts_master_report:bespoke',
    ];

    const sanctionReports = get(formValues, 'report') === 'sanctions_report:bespoke';
    const account = get(formValues, 'account');

    return (
      <Box>
        <h2 className="resource-name">Bordereaux/Statements</h2>
        <Form onSubmit={handleSubmit(::this.handleSubmit)}>
          <Row>
            <Col sm={6}>
              <Field
                name="report"
                label="Report"
                labelSize={3}
                component={SelectField}
                options={reports}
              />

              {indexOf(bordereauxReports, get(formValues, 'report')) !== -1 && (
                <Field
                  name="insurer"
                  label="Insurer"
                  labelSize={3}
                  isClearable
                  component={SelectInsurer}
                />
              )}

              {sanctionReports && (
                <Field
                  name="insurer"
                  label="Insurer"
                  labelSize={3}
                  isClearable
                  component={SelectInsurer}
                />
              )}

              {indexOf(endDateOnlyRequired, get(formValues, 'report')) !== -1 ? (
                <Field
                  name="to_date"
                  label="As at date"
                  labelSize={3}
                  component={DatePickerDob}
                />
              ) : (
                <div>
                  <Field
                    name="accounting_period"
                    label="Accounting Period"
                    labelSize={3}
                    component={SelectAccountingPeriod}
                  />
                  {!get(formValues, 'accounting_period') && (
                    <div>
                      <Field
                        name="from_date"
                        label="From"
                        labelSize={3}
                        component={DatePickerDob}
                      />
                      <Field
                        name="to_date"
                        label="To"
                        minDate={get(formValues, 'from_date')}
                        labelSize={3}
                        component={DatePickerDob}
                      />
                    </div>
                  )}
                </div>
              )}
              <FormGroup>
                <Col sm={3} componentClass={ControlLabel}>
                  Queue
                </Col>
                <Col sm={9}>
                  <div className="queue-checkbox">
                    <div className="checkbox-container inline">
                      <div className="form-radio">
                        <input
                          id="Queue"
                          type="checkbox"
                          checked={this.state.queue}
                          onChange={() => this.handleGenerateOnQueue()}
                          value="generator"
                        />
                        <label htmlFor="Queue"/>
                      </div>
                    </div>
                  </div>
                </Col>
              </FormGroup>
            </Col>

            <Col sm={6}>
              {indexOf(bordereauxReports, get(formValues, 'report')) !== -1 && (
                <Field
                  name="product"
                  label="Product"
                  labelSize={3}
                  isClearable
                  component={SelectProduct}
                />
              )}
              {sanctionReports && (
                <Field
                  name="product"
                  label="Product"
                  labelSize={3}
                  isClearable
                  component={SelectProduct}
                />
              )}

              {(indexOf(bordereauxReports, get(formValues, 'report')) !== -1 ||
                get(formValues, 'report') === 'broker_statement') && (
                <Field
                  async
                  name="account"
                  label="Broker Account"
                  labelSize={3}
                  filters={{ type: 'debtor' }}
                  isClearable
                  component={SelectAccount}
                />
              )}
              {(indexOf(bordereauxReports, get(formValues, 'report')) !== -1 ||
                get(formValues, 'report') === 'broker_statement') && account && (
                <Field
                  async
                  key={'select-broker-' + account}
                  name="broker"
                  label="Sub Account (Broker/Branch)"
                  filters={{ account: account }}
                  labelSize={3}
                  isClearable
                  component={SelectBroker}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <InternalButtonBar buttons={[
                {
                  button: (
                    <Button
                      className="pull-right"
                      type="submit"
                      label="Generate Report"
                      isLoading={isGeneratingReport}
                      bsStyle="primary"
                    />
                  ),
                },
              ]}/>
            </Col>
          </Row>
        </Form>
      </Box>
    );
  }
}

const form = reduxForm({ form: FORM_NAME, validate })(BordereauxReports);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state) => {
  const values = selector(
    state,
    'from_date',
    'to_date',
    'report',
    'accounting_period',
    'transaction_source',
    'account',
    'broker',
    'product',
    'insurer',
  );
  const endDateOnlyRequired = [
    'broker_statement',
    'aged_debt',
    'bordereaux_unpaid:bespoke',
    'bordereaux_accounts_unpaid:bespoke',
    'aged_premiums_report:bespoke',
    'aged_payables_report:bespoke',
    'unallocated_payments_receipts_list:bespoke',
  ];
  return {
    endDateOnlyRequired: endDateOnlyRequired,
    formValues: values,
    isGeneratingReport: state.account.isGeneratingReport,
  };
};
export default connect(mapStateToProps)(form);
