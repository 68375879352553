import React from 'react';
import Markdown from 'react-remarkable';
import { Col, Row } from 'react-bootstrap';
import { Field, Form, reduxForm } from 'redux-form';
import TextArea from '../../../content/components/TextArea';
import Button from '../../../../common/components/Button/Button';

class BrochureContentForm extends React.Component {
  state = {
    showForm: false,
  };

  componentWillMount() {
    const { product, dispatch, change } = this.props;

    dispatch(change('product.data.id', product.data.id));
    dispatch(
      change(
        'product.data.attributes.metadata.details',
        product.data.attributes.metadata.details,
      ),
    );
  }

  render() {
    const { product, isLoading, handleSubmit, showForm } = this.props;
    const brochureContent = product.data.attributes.metadata.details;
    const defaultMessage = 'No content found. Click "Edit" to add content.';

    return (
      <Form onSubmit={handleSubmit}>
        {showForm ? (
          <Field component={TextArea} name={'product.data.attributes.metadata.details'} />
        ) : (
          <Markdown source={brochureContent ? brochureContent : defaultMessage} />
        )}
        <Row>
          <Col sm={12}>
            {showForm && (
              <Button type="button" bsStyle="default" handleClick={this.handleToggleForm}>
                Cancel
              </Button>
            )}
            {showForm && (
              <Button
                type="submit"
                bsStyle="primary"
                className="pull-right"
                isLoading={isLoading}
              >
                Save
              </Button>
            )}
            {!showForm && (
              <Button
                type="button"
                bsStyle="primary"
                className="pull-right"
                handleClick={this.handleToggleForm}
              >
                Edit
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    );
  }

  handleToggleForm = () => {
    const { onToggleForm } = this.props;

    onToggleForm();
  };
}

export default reduxForm({
  form: 'BrochureContentForm',
})(BrochureContentForm);
