import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, FieldArray, formValueSelector } from 'redux-form';
import { SelectField } from '../../../../common/components/ReduxFormField';
import {
  Box,
  Button,
  DatePickerDob,
  HorizontalFormControl,
} from '../../../../common/components';
import { postJournal } from '../../redux/accountActions';
import { Journal } from '../Journal';
import { get, set } from 'lodash';
import './styles.scss';
import { Form, Col, Row } from 'react-bootstrap';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';

class JournalForm extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    const { change, dispatch } = this.props;

    dispatch(change('data.attributes.entry_type', 'adjustment'));
  }

  handleSubmit(journal) {
    return this.props.dispatch(postJournal(journal));
  }

  render() {
    const { formValues, handleSubmit, submitting } = this.props;

    return (
      <Box>
        <h2 className="resource-name">Add a manual journal</h2>
        <div className="journal-form">
          <Form onSubmit={handleSubmit(this.handleSubmit)}>
            <div className="journal-form-header">
              <Row className="form-horizontal">
                <Col sm={4}>
                  <Field
                    name="data.attributes.date"
                    label="Date"
                    labelSize={3}
                    component={DatePickerDob}
                  />
                </Col>
                <Col sm={4}>
                  <Field
                    name="data.attributes.description"
                    label="Description"
                    labelSize={3}
                    component={HorizontalFormControl}
                  />
                </Col>
                <Col sm={4}>
                  <Field
                    name="data.attributes.entry_type"
                    label="Type"
                    labelSize={3}
                    options={[
                      { label: 'Adjustment', value: 'adjustment' },
                      { label: 'Premium', value: 'premium' },
                      { label: 'Transaction', value: 'transaction' },
                    ]}
                    defaultValue={{ label: 'Adjustment', value: 'adjustment' }}
                    component={SelectField}
                  />
                </Col>
              </Row>
            </div>

            <Row>
              <Col xs={12}>
                <FieldArray
                  name="data.attributes.metadata.ledger_entries"
                  component={Journal}
                  entryType={get(formValues, 'data.attributes.entry_type', 'transaction')}
                />
              </Col>
            </Row>

            <div className="hr transparent" />

            <Row>
              <Col sm={12}>
                <InternalButtonBar buttons={[
                  {
                    button: (
                      <Button
                        label="Cancel"
                        bsStyle="primary"
                        className="pull-left"
                        link
                        to="/accounts/bookkeeping#journals"
                      />
                    )
                  },
                  {
                    permissions: ['account_ledger.journal_create'],
                    button: (
                      <Button
                        label="Post Journal"
                        bsStyle="primary"
                        className="pull-right"
                        type="submit"
                        isLoading={submitting}
                      />
                    )
                  },
                ]}/>
              </Col>
            </Row>
          </Form>
        </div>
      </Box>
    );
  }
}

const validate = (values) => {
  let errors = {};

  if (!get(values, 'data.attributes.date')) {
    set(errors, 'data.attributes.date', 'Date is required');
  }

  if (!get(values, 'data.attributes.description')) {
    set(errors, 'data.attributes.description', 'Description is required');
  }

  if (!get(values, 'data.attributes.entry_type')) {
    set(errors, 'data.attributes.entry_type', 'Entry type is required');
  }

  get(values, 'data.attributes.metadata.ledger_entries', []).map((entry, i) => {
    if (!entry.account) {
      set(
        errors,
        'data.attributes.metadata.ledger_entries[' + i + '].account',
        'Account is required',
      );
    }

    if (!entry.description) {
      set(
        errors,
        'data.attributes.metadata.ledger_entries[' + i + '].description',
        'Description is required',
      );
    }

    if (parseInt(entry.debit) === 0 && parseInt(entry.credit) === 0) {
      set(
        errors,
        'data.attributes.metadata.ledger_entries[' + i + '].debit',
        'Either debit or credit must be set',
      );
      set(
        errors,
        'data.attributes.metadata.ledger_entries[' + i + '].credit',
        'Either debit or credit must be set',
      );
    }

    if (parseFloat(entry.debit) > 0 && parseFloat(entry.credit) > 0) {
      set(
        errors,
        'data.attributes.metadata.ledger_entries[' + i + '].debit',
        'Both debit and credit cant be set',
      );
      set(
        errors,
        'data.attributes.metadata.ledger_entries[' + i + '].credit',
        'Both debit and credit cant be set',
      );
    }
  });

  return errors;
};

JournalForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
};

const FORM_NAME = 'JournalForm';

const form = reduxForm({ form: FORM_NAME, validate })(JournalForm);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state, props) => {
  const values = selector(state, 'data.id', 'data.attributes.entry_type');

  return {
    initialValues: props.resource,
    formValues: values,
  };
};

export default connect(mapStateToProps)(form);
