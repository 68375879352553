import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { Tabs } from 'react-bootstrap';
import { Loader } from '../../../common/components';
import InternalContainer from '../../layout/containers/Internal';
import LeisureTravelFormContainer from './LeisureTravelFormContainer';
import getIncludedResource from '../../../helpers/getIncludedResource';
import { toastr } from 'react-redux-toastr';
import { get, set } from 'lodash';

import {
  getProduct,
  getRegions,
  getSchemes,
  setPremiumIndication,
  getNextQuoteReference,
  clearNextQuoteReference,
  setPremiumOverrideCalculation,
} from '../redux/productActions';
import { getPolicy } from '../../policy/redux/policyActions';
import { getCustomer } from '../../customer/redux/customerActions';
import confirm from 'react-confirm2';

export class ProductQuoteContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    policy: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      nextLocation: null,
      activeTab: 1,
      currentOrganisation: '',
    };
  }

  componentWillMount() {
    const { dispatch, params, auth, location } = this.props;
    const currentOrganisation = getIncludedResource(
      auth.user.data,
      auth.user.included,
      'current_organisation',
    );
    this.setState({
      currentOrganisation: currentOrganisation.data.attributes.name,
    });

    dispatch(getRegions());
    dispatch(setPremiumIndication({}));
    dispatch(setPremiumOverrideCalculation({}));
    dispatch(getNextQuoteReference());
    dispatch(getSchemes(params.id));
    dispatch(getProduct(params.id));

    if (params.applicationId) {
      dispatch(
        getPolicy(params.applicationId, location.query.clone ? '' : 'applications', [
          'customer',
          'broker',
          'user',
          'schemes',
          'channel',
          'source',
        ]),
      );
    }

    if (get(location, 'query.customer')) {
      Promise.resolve(dispatch(getCustomer(location.query.customer, []))).then(() => {
        return dispatch(reset('LeisureTravelForm'));
      });
    }
  }

  componentDidMount() {
    this.props.router.setRouteLeaveHook(this.props.route, ::this.routerWillLeave);
  }

  routerWillLeave(nextLocation) {
    const { auth, screening } = this.props;
    if (!auth.authenticated) {
      return;
    }

    const currentOrganisation = getIncludedResource(
      auth.user.data,
      auth.user.included,
      'current_organisation',
    );

    if (this.state.nextLocation) {
      return this.state.nextLocation;
    } else if (this.props.product.submitSucceeded || nextLocation.pathname === '/login') {
      return true;
    }

    // Do not display the confirm dialog if the user changed the Organisation by Settings -> Organisation Settings
    if (this.state.currentOrganisation === currentOrganisation.data.attributes.name) {
      if (screening.index > 0) {
        toastr.error(
          'Screening session active',
          'Please finish your current screening session before continuing',
        );
        this.setState({
          nextLocation: null,
        });
        return false;
      }
      confirm('Are you sure you want to leave?', {
        confirmLabel: 'Proceed',
        description: 'We recommend you save your quote to ensure no details are lost.',
        close: () => {
          this.setState({
            nextLocation: null,
          });
        },
        done: () => {
          this.setState({ nextLocation }, () => {
            this.props.router.push(nextLocation);
          });
        },
      });
    } else {
      this.setState({ nextLocation }, () => {
        this.props.router.push(nextLocation);
      });
    }

    return false;
  }

  componentWillUpdate(nextProps) {
    const { dispatch, params } = this.props;
    if (nextProps.params.id !== params.id) {
      dispatch(clearNextQuoteReference());
      dispatch(setPremiumIndication({}));
    }
  }

  handleTab(key) {
    this.setState({ activeTab: key });
  }

  render() {
    const { product, policy, params } = this.props;
    const resource = product.resources[params.id];
    const application = policy.resources[params.applicationId];
    // const title = resource ? resource.data.attributes.name : '';
    const mta =
      get(application, 'data.attributes.quote_type', 'new business') ===
      'mid term adjustment';
    const edit = ['new business', 'renewal'].includes(get(application, 'data.attributes.quote_type'));

    // Clear any overrides before editing
    set(application, 'data.attributes.metadata.overrides', {});

    // Reset any policy details if cloning
    if (this.props.location.query.clone) {
      set(application, 'data.id', null);
      set(application, 'data.attributes.policy_number', '');
      set(application, 'data.attributes.quote_reference', '');
      set(application, 'data.relationships.schemes.data', []);
      set(application, 'data.attributes.metadata.max_trip_duration', null);
    }

    return (
      <InternalContainer hideBreadcrumbs title={'Get a Quote'} bottomBar>
        {resource &&
        product.nextQuoteReference &&
        (!params.applicationId || application) ? (
          <Tabs
            activeKey={this.state.activeTab}
            id="broker-tabs"
            unmountOnExit={false}
            onSelect={::this.handleTab}
          >
            {(() => {
              switch (true) {
                default:
                  return (
                    <LeisureTravelFormContainer
                      {...this.props}
                      mta={mta}
                      edit={edit}
                      application={application}
                      currentProduct={resource}
                    />
                  );
              }
            })()}
          </Tabs>
        ) : (
          <Loader />
        )}
      </InternalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    product: state.product,
    policy: state.policy,
    auth: state.auth,
    screening: state.screening,
  };
};

export default connect(mapStateToProps)(ProductQuoteContainer);
