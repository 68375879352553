import React from 'react';

export const businessEquipment = (
  <svg id="bis_quip_layer_1" data-name="Layer 1" viewBox="0 0 95 95">
    <path className="cls-1" d="M95,47.5A47.5,47.5,0,1,1,47.5,0,47.5,47.5,0,0,1,95,47.5" />
    <rect style={{ fill: '#fff' }} x="22.45" y="25.63" width="49.48" height="26.82" />
    <path
      style={{ fill: '#fff' }}
      d="M76.36,56.53H18V20.9H76.36ZM20.09,54.46h54.2V23H20.09Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M80.18,67.88h-66a1,1,0,0,1-1-1.43l4.63-11.34a1,1,0,0,1,1-.64H75.1a1,1,0,0,1,.95.61l5.08,11.34a1,1,0,0,1-.95,1.46ZM15.74,65.81H78.58l-4.15-9.27H19.52Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M80.18,70.85h-66a1,1,0,0,1-1-1v-3a1,1,0,0,1,1-1h66a1,1,0,0,1,1,1v3A1,1,0,0,1,80.18,70.85ZM15.23,68.78H79.14v-.91H15.23Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M55.68,65.42H38.73l.92-4.14H54.53ZM40,64.38H54.32l-.58-2.07H40.48Z"
    />
  </svg>
);

export default businessEquipment;
