import React from 'react';
import { Row, Col, ButtonToolbar, DropdownButton, MenuItem } from 'react-bootstrap';
import StatsSectionTable from '../../dashboard/components/StatsSectionTable/StatsSectionTable';
import StatsSectionConversionLabel from '../../dashboard/components/StatsSectionConversionLabel/StatsSectionConversionLabel';
import PieChart from '../../../common/components/PieChart/PieChart';
import './style.scss';

class BaseRatesPieChart extends React.Component {
  state = {
    range: 'Today',
  };

  setRange(range) {
    this.setState({
      range: range,
    });
  }

  render() {
    const { stats, display } = this.props;
    const { range } = this.state;

    return (
      <div>
        <h5 className="section-title join-cover">
          Activity - {range}
          <ButtonToolbar>
            <DropdownButton
              bsSize="small"
              title="Range"
              className="pull-right"
              id="dropdown-size-large"
              pullRight
              bsStyle="default"
            >
              <MenuItem
                eventKey="1"
                active={range === 'Today'}
                onClick={() => this.setRange('Today')}
              >
                Today
              </MenuItem>
              <MenuItem
                eventKey="1"
                active={range === 'Yesterday'}
                onClick={() => this.setRange('Yesterday')}
              >
                Yesterday
              </MenuItem>
              <MenuItem
                eventKey="2"
                active={range === 'This week'}
                onClick={() => this.setRange('This week')}
              >
                This week
              </MenuItem>
              <MenuItem
                eventKey="3"
                active={range === 'This month'}
                onClick={() => this.setRange('This month')}
              >
                This month
              </MenuItem>
            </DropdownButton>
          </ButtonToolbar>
        </h5>
        <div className="product-container-section join">
          <Row className="base-rates-component-container">
            <Col sm={display === 'vertical' ? 12 : 6}>
              <div className="section-item">
                <div className="section-title join">Quotes</div>
                <div className="product-chart-container ">
                  {stats && <PieChart range={range} stats={stats} />}
                </div>
              </div>
            </Col>
            <Col sm={display === 'vertical' ? 12 : 6}>
              <div className="section-item">
                <div className="section-title join">Policies</div>
                <div className=" ">
                  <div className="section-item">
                    {stats && <StatsSectionTable range={range} stats={stats} />}
                    {stats && <StatsSectionConversionLabel range={range} stats={stats} />}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default BaseRatesPieChart;
