import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import {
  Button,
  Modal,
  DatePickerDob,
  HorizontalFormControl,
  SelectCashAccount,
} from '../../../../common/components';
import { openModal, closeModal } from '../../../../common/components/Modal/ModalRedux';
import { makePayment, generateAccountingReference } from '../../redux/accountActions';
import { get, set } from 'lodash';
import InfoLabel from '../../../../common/components/InfoLabel/InfoLabel';
import { SelectField } from '../../../../common/components/ReduxFormField';
import './styles.scss';
import { bindActionCreators } from 'redux';

const FORM_NAME = 'makePaymentForm';

const validate = (values) => {
  const errors = {};

  if (!get(values, 'meta.date')) {
    set(errors, 'meta.date', 'Date is required');
  }

  if (!get(values, 'meta.amount')) {
    set(errors, 'meta.amount', 'Amount is required');
  }

  return errors;
};

class MakePaymentButton extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
    amount: PropTypes.number,
    matchedPremiums: PropTypes.array.isRequired,
    matchedTransactions: PropTypes.array.isRequired,
    callback: PropTypes.func,
    label: PropTypes.string,
    block: PropTypes.bool,
    accountingReference: PropTypes.string,
  };

  componentWillUpdate(newProps) {
    const { actions, dispatch, change, submitSucceeded, callback } = this.props;
    if (newProps.submitSucceeded && !submitSucceeded) {
      if (callback) {
        callback('');
      }
      actions.closeModal(FORM_NAME);
    }

    dispatch(change('meta.payment_reference', newProps.accountingReference));
  }

  handleSubmit(values) {
    const { actions, resource } = this.props;

    return actions.makePayment(values, resource);
  }

  handleOpen() {
    const {
      dispatch,
      change,
      reset,
      matchedPremiums,
      matchedTransactions,
      accountingReference,
      actions,
    } = this.props;
    dispatch(reset());
    if (!accountingReference) {
      actions.generateAccountingReference(true);
    }
    dispatch(change('meta.matched_premiums', matchedPremiums));
    dispatch(change('meta.matched_transactions', matchedTransactions));
    actions.openModal(FORM_NAME);
  }

  render() {
    const {
      label,
      handleSubmit,
      amount,
      submitting,
      block,
      accountingReference,
    } = this.props;
    const title = label || 'Make Payment';
    const paymentTypes = [
      { label: 'BACS', value: 'BACS' },
      { label: 'Cheque', value: 'Cheque' },
    ];
    const submitButton = (
      <Button
        type="submit"
        bsStyle="primary"
        isLoading={submitting}
        label="Save Payment"
        disabled={!accountingReference}
      />
    );

    return (
      <div>
        <Button
          className="matching-sidebar-btn"
          bsStyle="primary"
          isLoading={submitting}
          label={title}
          handleClick={::this.handleOpen}
          block={block}
        />
        <Modal
          name={FORM_NAME}
          title={title}
          close
          handleSubmit={handleSubmit(::this.handleSubmit)}
          footer={submitButton}
        >
          <Field
            name="meta.cash_account"
            label="Cash Account"
            labelSize={3}
            component={SelectCashAccount}
          />
          <Field
            name={'meta.payment_type'}
            label="Type"
            labelSize={3}
            options={paymentTypes}
            component={SelectField}
          />
          <Field
            name={'meta.description'}
            label="Description"
            labelSize={3}
            component={HorizontalFormControl}
          />
          <Field
            name={'meta.date'}
            label="Date"
            labelSize={3}
            component={DatePickerDob}
          />
          <InfoLabel value={amount.toFixed(2)} label="Amount" labelSize={3}/>
          <InfoLabel value={accountingReference} label="Reference" labelSize={3}/>
        </Modal>
      </div>
    );
  }
}

const form = reduxForm({ form: FORM_NAME, validate })(MakePaymentButton);

function mapStateToProps(state) {
  return {
    accountingReference: state.account.accountingReference,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      { makePayment, generateAccountingReference, openModal, closeModal },
      dispatch,
    ),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(form);
