import e6p from 'es6-promise';
import 'intl';
import 'intl/locale-data/jsonp/en-GB';
import 'babel-polyfill';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Router, browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import axios from 'axios';
import { IntlProvider } from 'react-intl';
import smoothScroll from 'smoothscroll';
import ReduxToastr from 'react-redux-toastr';
import createRoutes from './routes.jsx';
import configureStore from './redux/configureStore';
import AuthInterceptor from './api/authInterceptor';
import { logoutTimer } from './api/timer';

e6p.polyfill();

// Grab the state from a global injected into
// server-generated HTML
const initialState = window.__INITIAL_STATE__;
const apiUrl = window.__API_URL__;

axios.defaults.baseURL = apiUrl;

if (initialState.auth && initialState.auth.accessToken) {
  axios.defaults.headers.common.Authorization = 'Bearer ' + initialState.auth.accessToken;
}

const authInterceptor = new AuthInterceptor();
authInterceptor.registerInterceptor();

axios.interceptors.request.use((request) => {
  // Do not modify requests made to the local node server
  if (request.baseURL === '') {
    return request;
  }

  if (request.data && request.headers['Content-Type'] !== 'application/vnd.api+json') {
    request.headers['Content-Type'] = 'application/vnd.api+json';
  }

  return request;
});

const store = configureStore(initialState, browserHistory);
const history = syncHistoryWithStore(browserHistory, store);
const routes = createRoutes(store);

logoutTimer(store);

const hashLinkScroll = () => {
  const { hash } = window.location;
  if (hash !== '') {
    // Push onto callback queue so it runs after the DOM is updated,
    // this is required when navigating from a different page so that
    // the element is rendered on the page before trying to getElementById.
    setTimeout(() => {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) smoothScroll(element, 1000);
    }, 1000);
  }
};

// Router converts <Route> element hierarchy to a route config:
// Read more https://github.com/rackt/react-router/blob/latest/docs/Glossary.md#routeconfig
render(
  <Provider store={store}>
    <IntlProvider locale="en-GB">
      <div>
        <Router history={history} onUpdate={hashLinkScroll}>
          {routes}
        </Router>
        <ReduxToastr
          timeOut={4000}
          newestOnTop
          progressBar
          preventDuplicates
          position="top-right"
        />
      </div>
    </IntlProvider>
  </Provider>,
  document.getElementById('app'),
);
