import React from 'react';

export const naturalCatastrophe = (
  <svg id="nat_cat_layer_1" data-name="Layer 1" viewBox="0 0 95 95">
    <path className="cls-1" d="M95,47.5A47.5,47.5,0,1,1,47.5,0,47.5,47.5,0,0,1,95,47.5" />
    <path style={{ fill: '#fff' }} d="M42.24,24.17a8,8,0,1,1,8,8,8,8,0,0,1-8-8" />
    <path style={{ fill: '#fff' }} d="M51.53,30.43a5,5,0,1,1,5,5,5,5,0,0,1-5-5" />
    <path style={{ fill: '#fff' }} d="M57,24.24a5,5,0,1,1,5,5,5,5,0,0,1-5-5" />
    <path style={{ fill: '#fff' }} d="M51.44,26.93a8,8,0,1,0-8,8,8,8,0,0,0,8-8" />
    <path style={{ fill: '#fff' }} d="M42.15,33.2a5,5,0,1,0-5,5,5,5,0,0,0,5-5" />
    <path style={{ fill: '#fff' }} d="M36.7,27a5,5,0,1,0-5,5,5,5,0,0,0,5-5" />
    <path
      style={{ fill: '#fff' }}
      d="M38.16,47.65c0.58,0.38,1.42,1.37.44,3.75-1.3,3.14-1.33,4.91-1,5.47a0.45,0.45,0,0,0,.42.21c1.56,0.06,2.45-2.64,3.28-5.23,1-3.2,2.08-6.51,4.89-6.51,3,0,3.07,2.86,3.15,5.38,0,0.69,0,1.39.12,2.07a1.73,1.73,0,0,0,1,1.52,0.77,0.77,0,0,0,.81-0.12c0.47-.42.54-1.45,0.19-2.75-0.7-2.58.55-3.32,0.94-3.48,1.1-.45,2.46.29,3.33,1.8,1.13,2,4,5.88,6.66,6.55A2.73,2.73,0,0,0,64,56.23c-4-4.34-7.65-9-8.83-12-0.58-2.88-2.3-2.65-7.71-2.65s-7.13-.23-7.7,2.65A19,19,0,0,1,38,47.56a1.73,1.73,0,0,1,.19.09"
    />
    <path
      style={{ fill: '#fff' }}
      d="M76.21,67.16C74,66,69.4,61.91,65,57.29a4,4,0,0,1-2.95.35c-3.86-1-7.16-6.57-7.53-7.2-0.57-1-1.29-1.33-1.6-1.21s-0.45.66-.13,1.84c0.68,2.52-.09,3.67-0.6,4.14a2.13,2.13,0,0,1-2.23.39,3.11,3.11,0,0,1-1.87-2.66C48,52.22,48,51.48,48,50.76c-0.09-2.87-.28-4-1.77-4-1.8,0-2.71,2.82-3.58,5.54-1,3-2,6.19-4.55,6.19H38a1.81,1.81,0,0,1-1.54-.83c-1-1.5-.08-4.5.85-6.75,0.42-1,.46-1.82.09-2.05a0.71,0.71,0,0,0-.22-0.09c-5,7.06-14.84,16.58-18.38,18.44-4.38,2.31-2.31,3.92-2.31,3.92h62s2.08-1.61-2.31-3.92"
    />
    <path style={{ fill: '#fff' }} d="M41.65,32.33c1.85,1.85,2.54,7.61,2.54,7.61v-9" />
    <path style={{ fill: '#fff' }} d="M50.53,39.82V29.44l5,1.85s-4.15.92-5,8.54" />
    <polygon
      style={{ fill: '#fff' }}
      points="46.26 31.75 47.42 40.17 48.69 30.94 46.26 31.75"
    />
  </svg>
);

export default naturalCatastrophe;
