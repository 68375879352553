import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, DataTable, SelectOrganisation } from '../../../../common/components';
import { applyFilter } from '../../../../common/components/DataTable/redux/dataTableActions';
import { brokerStatuses } from '../../../../schemas/broker';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { FormControl, ControlLabel, Col, Row } from 'react-bootstrap';
import { push } from 'react-router-redux';
import { FormattedDate, FormattedTime } from 'react-intl';
import Select from 'react-select';
import selectTheme, { styles } from '../../../../helpers/selectTheme';

export const TABLE_NAME = 'brokers';

export default class BrokerList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    broker: PropTypes.object.isRequired,
  };

  constructor() {
    super();

    this.state = {
      values: {
        reference: '',
        search: '',
        organisation: '',
        status: '',
      },
    };
  }

  componentWillMount() {
    this.setState({
      values: {
        ...this.state.values,
        status: this.props.location.query.status ? this.props.location.query.status : '',
      },
    });
  }

  formatDate(cell) {
    return (
      <span>
        <FormattedDate value={new Date(cell)} /> <FormattedTime value={new Date(cell)} />
      </span>
    );
  }

  handleRowClick(row) {
    this.props.dispatch(push('/brokers/' + row.id));
  }

  handleFilterStatus(option) {
    const value = option && option.value;

    this.setState({
      values: {
        ...this.state.values,
        status: value || '',
      },
    });

    this.props.dispatch(applyFilter(TABLE_NAME, 'filter[status]', value));
  }

  handleOrganisationFilterUpdate(option) {
    const value = option && option.value;

    this.setState({
      values: {
        ...this.state.values,
        organisation: value || '',
      },
    });

    this.props.dispatch(applyFilter(TABLE_NAME, 'filter[organisation]', value));
  }

  handleSearchUpdate(event) {
    this.setState({
      values: {
        ...this.state.values,
        search: event.target.value,
      },
    });
    this.props.dispatch(applyFilter(TABLE_NAME, 'filter[name]', event.target.value));
  }

  handleSearchReference(event) {
    this.setState({
      values: {
        ...this.state.values,
        reference: event.target.value,
      },
    });
    this.props.dispatch(applyFilter(TABLE_NAME, 'filter[reference]', event.target.value));
  }

  brokerStatus(status) {
    return brokerStatuses[status];
  }

  render() {
    return (
      <div>
        <Box>
          <Row>
            <Col sm={2}>
              <ControlLabel className="filter-label">Reference</ControlLabel>
              <FormControl
                onChange={::this.handleSearchReference}
                value={this.state.reference}
              />
            </Col>
            <Col sm={4}>
              <ControlLabel className="filter-label">Search</ControlLabel>
              <FormControl
                onChange={::this.handleSearchUpdate}
                value={this.state.search}
              />
            </Col>
            <Col sm={4}>
              <ControlLabel className="filter-label">Organisation</ControlLabel>
              <SelectOrganisation
                id={'organisation-select'}
                async
                isClearable
                filters={{ type: 'Broker' }}
                onChange={::this.handleOrganisationFilterUpdate}
                input={{
                  value: this.state.values.organisation,
                }}
              />
            </Col>
            <Col sm={2}>
              <div className="form-group">
                <ControlLabel className="filter-label">Status</ControlLabel>
                <Select
                  theme={(theme) => selectTheme(theme)}
                  styles={styles}
                  onChange={::this.handleFilterStatus}
                  options={Object.keys(brokerStatuses).map((status) => {
                    return { label: brokerStatuses[status], value: status };
                  })}
                  isClearable
                />
              </div>
            </Col>
          </Row>
        </Box>

        <Box>
          <DataTable
            source="/brokers"
            name="brokers"
            autoFilter={this.state.values.status}
            autoFilterType="filter[status]"
            showTotal
            refreshData
            onRowSelect={this.handleRowClick.bind(this)}
          >
            <TableHeaderColumn dataField="id" isKey hidden />

            <TableHeaderColumn dataField="reference" width={'100px'} dataSort>
              Reference
            </TableHeaderColumn>

            <TableHeaderColumn dataField="name" width={'280px'} dataSort>
              Name
            </TableHeaderColumn>

            <TableHeaderColumn dataField="trading_name" width={'200px'} dataSort>
              Trading Name
            </TableHeaderColumn>

            <TableHeaderColumn dataField="branch_name" width={'200px'} dataSort>
              Branch Name
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="status"
              dataFormat={this.brokerStatus}
              dataSort
              width={'100px'}
            >
              Status
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="created_at"
              dataFormat={this.formatDate}
              width={'180px'}
              dataSort
            >
              Created
            </TableHeaderColumn>
          </DataTable>
        </Box>
      </div>
    );
  }
}
