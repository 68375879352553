import React, { Component } from 'react';
import './StatsSectionConversionLabel.scss';

export default class StatsSectionConversion extends Component {
  render() {
    const { stats, range } = this.props;

    let displayQuotesStats = {};

    if (range === 'Today') {
      displayQuotesStats = stats.quotes.day;
    }

    if (range === 'This week') {
      displayQuotesStats = stats.quotes.week;
    }

    if (range === 'This month') {
      displayQuotesStats = stats.quotes.month;
    }

    if (range === 'Yesterday') {
      displayQuotesStats = stats.quotes.yesterday;
    }

    const conversion = Math.round(
      (displayQuotesStats.issued / displayQuotesStats.count) * 100,
    );

    return (
      <div className="stats-section-Conversion-Label">
        <div>
          <span className="conversion-label">
            Conversion rate:
            <span>{isNaN(conversion) ? 0 : conversion} %</span>
          </span>
        </div>
      </div>
    );
  }
}
