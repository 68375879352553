import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../redux/ProductReducer';
import { Loader } from '../../../common/components';
import InternalContainer from '../../layout/containers/Internal';
import ProductTemplateBox from '../components/ProductTemplateBox/ProductTemplateBox';
import BrochureContentForm from '../components/BrochureContent/BrochureContentForm';

class BrochureContentContainer extends React.Component {
  state = {
    showForm: false,
  };

  componentWillMount() {
    const { actions, params } = this.props;

    actions.getProducts(params.productId);
  }

  componentWillUnmount() {
    const { actions } = this.props;

    actions.clearData();
  }

  render() {
    const { productManagement } = this.props;
    const { showForm } = this.state;
    const isLoading = productManagement.isLoading;
    const product = get(productManagement, 'product');

    return (
      <InternalContainer title={'Product Management'}>
        {product ? (
          <ProductTemplateBox title={'Brochure Content'} product={product.data}>
            <div className="hr mb-15" />
            <BrochureContentForm
              product={product}
              showForm={showForm}
              isLoading={isLoading}
              onSubmit={this.handleSubmit}
              onToggleForm={this.handleToggleForm}
              {...this.props}
            />
          </ProductTemplateBox>
        ) : (
          <Loader />
        )}
      </InternalContainer>
    );
  }

  handleToggleForm = () => {
    this.setState({ showForm: !this.state.showForm });
  };

  handleSubmit = (formValues) => {
    const { actions } = this.props;

    actions.updateProduct(formValues).then(() => {
      this.setState({ showForm: false });
    });
  };
}

export default connect(
  (state) => ({
    productManagement: state.productManagement,
  }),
  (dispatch) => ({
    dispatch,
    actions: bindActionCreators({ ...actions }, dispatch),
  }),
)(BrochureContentContainer);
