import React from 'react';

const documents = (
  <svg
    id="dm"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 150.49 150.49"
  >
    <circle cx="75.25" cy="75.25" r="75.25" />
    <path
      style={{ fill: '#fff' }}
      d="M120,37.43,105.58,23.12a12,12,0,0,0-7.53-3H56.44A4.18,4.18,0,0,0,52,24.24V41.13H34c-2.46,0-4,2.13-4,4.59V120c0,2.46,1.56,5.1,4,5.1H96.41c2.46,0,4.56-2.64,4.56-5.1v-16.9h17.88A4.29,4.29,0,0,0,123,98.55V45A12.28,12.28,0,0,0,120,37.43ZM99,26.1a5.76,5.76,0,0,1,2.19,1l14.68,14.82A5.66,5.66,0,0,1,117,44.13H99ZM83,105.17c0,.84-.59,1-1.42,1H48.86c-.83,0-.88-.13-.88-1v-3c0-.84.05-2.06.88-2.06h32.7c.83,0,1.42,1.23,1.42,2.06Zm0-11.89c0,.84-.59.85-1.42.85H48.86c-.83,0-.88,0-.88-.85v-3c0-.84.05-1.18.88-1.18h32.7c.83,0,1.42.34,1.42,1.18Zm0-11.89a1.65,1.65,0,0,1-1.42,1.74H48.86c-.83,0-.88-.9-.88-1.74v-3c0-.84.05-1.29.88-1.29h32.7A1.26,1.26,0,0,1,83,78.42Zm34,15.74H101v-26H75.61A4.74,4.74,0,0,1,71,66.53V41.13H58v-15H93V45A5.59,5.59,0,0,0,98,50.13H117Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M78.71,44.42A4.35,4.35,0,0,0,77,43.12v22H99a4.31,4.31,0,0,0-1.33-1.72Z"
    />
  </svg>
);

export default documents;
