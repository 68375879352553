import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { map } from 'lodash';

class DynamicDocumentContent extends React.Component {
  render() {
    const { document } = this.props;

    // creates a list of empty arrays with key === document name
    // const documentVersionedList = documentList.reduce((acc, curr) => {
    //   acc[curr.attributes.name] = [];
    //   return acc;
    // }, {});

    // push documents to array above
    // map(documentList, (document) => {
    //   forEach(documentVersionedList, (docArr, docName) => {
    //     if (document.attributes.name === docName) {
    //       documentVersionedList[docName].push(document);
    //     }
    //   });
    // });

    return map(document.attributes.sections, (item, key) => {
      // const docListOrdered = orderBy(
      //   documentList,
      //   ['attributes.version_number'],
      //   ['desc'],
      // );
      return (
        <Row className="my-15" key={key}>
          <Col sm={12}>
            <strong>{key}:</strong>
            <div className="justify-text mt-15">{item}</div>
          </Col>
        </Row>
      );
    });
  }
}

export default DynamicDocumentContent;
