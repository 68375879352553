import React from 'react';

export const cruise = (
  <svg
    id="cruise_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 95 95"
  >
    <circle className="cls-1" cx="47.5" cy="47.5" r="47.5" />
    <polygon
      style={{ fill: '#fff' }}
      points="59.22 49.78 53.73 52.96 7.06 52.96 12.11 65.08 71.88 65.08 90.58 49.78 59.22 49.78"
    />
    <polygon
      style={{ fill: '#fff' }}
      points="14.29 43.26 13.62 45.52 81.25 45.52 80.24 43.26 14.29 43.26"
    />
    <polygon
      style={{ fill: '#fff' }}
      points="15.64 39.56 14.97 41.81 78.56 41.81 77.55 39.56 15.64 39.56"
    />
    <polygon
      style={{ fill: '#fff' }}
      points="60.36 35.69 57.67 37.94 76.37 37.94 75.36 35.69 60.36 35.69"
    />
    <polygon
      style={{ fill: '#fff' }}
      points="56.3 29.32 63.67 34.7 68.67 34.7 58.67 29.32 56.3 29.32"
    />
    <polygon
      style={{ fill: '#fff' }}
      points="47.01 33.57 41.3 33.57 40.3 27.51 35.59 27.51 36.59 33.57 32.28 33.57 31.28 27.51 26.57 27.51 27.57 33.57 22.37 33.57 21.17 37.94 48.82 37.94 47.01 33.57"
    />
    <path
      style={{ fill: '#fff' }}
      d="M82.93,46.89H13.28L11.94,51.4H53.5l5.15-2.93,25-.11Zm-67,3.68a1.43,1.43,0,1,1,1.43-1.43A1.43,1.43,0,0,1,15.94,50.57Zm5.69,0a1.43,1.43,0,1,1,1.43-1.43A1.43,1.43,0,0,1,21.63,50.57Zm5.69,0a1.43,1.43,0,1,1,1.43-1.43A1.43,1.43,0,0,1,27.32,50.57Zm5.69,0a1.43,1.43,0,1,1,1.43-1.43A1.43,1.43,0,0,1,33,50.57Zm5.69,0a1.43,1.43,0,1,1,1.43-1.43A1.43,1.43,0,0,1,38.7,50.57Zm5.69,0a1.43,1.43,0,1,1,1.43-1.43A1.43,1.43,0,0,1,44.39,50.57Zm5.69,0a1.43,1.43,0,1,1,1.43-1.43A1.43,1.43,0,0,1,50.08,50.57Z"
    />
  </svg>
);

export default cruise;
