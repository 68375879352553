import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../components';
import { openModal } from '../Modal/ModalRedux';
import { connect } from 'react-redux';
import UploadModal, { UploadModal as UploadModalClass } from './UploadModal';
import './Upload.scss';

class Upload extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    broker: PropTypes.object.isRequired,
  };

  openModal() {
    this.props.dispatch(openModal(UploadModalClass.MODAL_IDENTIFIER));
  }

  render() {
    const { broker, ...props } = this.props;

    return (
      <div>
        <Button {...props} handleClick={this.openModal.bind(this)} />

        <UploadModal broker={broker} />
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(Upload);
