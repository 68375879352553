import React from 'react';
import { bindActionCreators } from 'redux';
import { flow, forEach, get, map } from 'lodash';
import { Col, Modal, Row } from 'react-bootstrap';
import connect from 'react-redux/es/connect/connect';
import SchemeCheckboxList from './SchemeCheckboxList';
import { DatePicker, Button } from '../../../../../common/components';
import { Form, Field, reduxForm, formValueSelector, destroy } from 'redux-form';
import { actions as schemeActions } from '../../../redux/ProductReducer';
import { openModal } from '../../../../../common/components/Modal/ModalRedux';
import { clearFiles } from '../../../../../common/components/Upload/UploadRedux';
import SelectField from '../../../../../common/components/ReduxFormField/SelectField';
import UploadModalComponent, {
  UploadModal,
} from '../../../../../common/components/Upload/UploadModal';

const isRequired = (value) => {
  if (value === false) {
    return null;
  }
  return !value ? 'This field is required.' : null;
};

class NewDocumentForm extends React.Component {
  state = {
    showModal: false,
  };

  openModal = () => {
    this.setState({
      showModal: true,
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
    });
  };

  resetState() {
    const { actions } = this.props;
    actions.upload.clearFiles();
  }

  handleUpload() {
    const { actions } = this.props;
    actions.upload.openModal(UploadModal.MODAL_IDENTIFIER);
    this.resetState();
  }

  handleUploadComplete() {
    const { dispatch, change, files } = this.props;

    dispatch(change('data.relationships.file.data', files[0]));
    dispatch(change('data.attributes.file_name', files[0].attributes.name));
    dispatch(change('data.attributes.file_url', files[0].attributes.url));
  }

  getSchemeInfo = (scheme, key) => {
    const { dispatch, change, formValues } = this.props;

    return (
      <SchemeCheckboxList
        key={key}
        scheme={scheme}
        dispatch={dispatch}
        change={change}
        formValues={formValues}
      />
    );
  };

  renderDocumentTypeOptions = () => {
    const { documentManagement } = this.props;
    // create a list of document-types
    let documentTypeList = [];

    // push only types that are not been used already
    forEach(documentManagement.docTypeList, (item, name) => {
      if (item.documents.length === 0) {
        documentTypeList.push({ label: name, value: item.id });
      }
    });

    // return an option list
    return documentTypeList;
  };

  handleSubmit = (formValues) => {
    const { documentManagement, actions, params } = this.props;
    let docTypeName = '';
    let docTypeId = '';
    const schemeList = [];

    forEach(documentManagement.docTypeList, (item, name) => {
      if (item.id === formValues.data.relationships.document_type.data.id) {
        docTypeName = name;
        docTypeId = item.id;
      }
    });

    forEach(formValues.data.schemes, (scheme) => {
      forEach(scheme, (value, schemeId) => {
        if (value === true) {
          schemeList.push({ id: schemeId });
        }
      });
    });

    const value = {
      data: {
        id: docTypeId,
        attributes: {
          name: docTypeName,
          from_date: formValues.data.attributes.from_date,
        },
        relationships: {
          document_type: formValues.data.relationships.document_type,
          file: formValues.data.relationships.file,
          schemes: {
            data: schemeList,
          },
        },
      },
    };

    actions.documents.createNewDocument(value, params.productId);

    setTimeout(
      () => (
        actions.destroy('NewDocumentForm'),
        this.setState({
          showModal: false,
        })
      ),
      1000,
    );
  };

  render() {
    const {
      handleSubmit,
      isSubmitting,
      formValues,
      documentManagement: { schemeList },
    } = this.props;
    const { showModal } = this.state;

    const renderField = (label, content) => {
      return (
        <Row className="document-modal-row">
          <Col sm={5}>
            <strong>{label}:</strong>
          </Col>
          <Col sm={7}>{content}</Col>
        </Row>
      );
    };

    return (
      <Col sm={12}>
        <Button bsStyle="primary" className="pull-right" handleClick={this.openModal}>
          Add a new document
        </Button>
        <Modal
          show={showModal}
          bsSize="large"
          onHide={this.closeModal}
          aria-labelledby="contained-modal-title"
        >
          <Form onSubmit={handleSubmit(this.handleSubmit)}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title">New Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col sm={12}>
                  <Row>
                    <Field
                      name={'data.relationships.document_type.data.id'}
                      label={'Document type:'}
                      mdFieldSize={7}
                      labelSize={5}
                      component={SelectField}
                      options={this.renderDocumentTypeOptions()}
                      validtate={[isRequired]}
                    />
                  </Row>
                </Col>
                <Col sm={12} className="margin-top">
                  {renderField(
                    'Active date',
                    <Field
                      showTime
                      dateFormat
                      labelSize={4}
                      name="data.attributes.from_date"
                      label="Start date"
                      timeFormat="HH:mm"
                      component={DatePicker}
                      shouldCloseOnSelect={false}
                      validtate={[isRequired]}
                    />,
                  )}
                </Col>
                <Col sm={12} className="margin-top">
                  {renderField(
                    'File',
                    <div>
                      <Button
                        bsSize="xs"
                        type="button"
                        bsStyle="primary"
                        className="pad-right btn-xs"
                        handleClick={::this.handleUpload}
                      >
                        <i className="fa fa-file" /> upload document
                      </Button>
                      <div className="pdf-note">Note: Document PDF version should not be higher than 1.4</div>
                      {get(formValues, 'attributes.file_url') && (
                        <a
                          href={get(formValues, 'attributes.file_url')}
                          target="_blank"
                          className="btn btn-link btn-xs"
                        >
                          {get(formValues, 'attributes.file_name')}
                        </a>
                      )}
                    </div>,
                  )}
                </Col>
                <Col sm={12} className="margin-top">
                  {renderField(
                    'Schemes',
                    <ul className="schemes-checkbox-container">
                      {map(schemeList, this.getSchemeInfo)}
                    </ul>,
                  )}
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit" className="btn-blue" isLoading={isSubmitting}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <UploadModalComponent
          onComplete={::this.handleUploadComplete}
          accept="application/pdf"
          maxFiles={1}
        />
      </Col>
    );
  }
}

const selector = formValueSelector('NewDocumentForm');
export default flow([
  reduxForm({
    form: 'NewDocumentForm',
  }),
  connect(
    (state) => {
      const formValues = selector(state, 'data');

      return {
        formValues,
        files: state.upload.files,
      };
    },
    (dispatch) => ({
      actions: {
        upload: bindActionCreators({ openModal, clearFiles }, dispatch),
        destroy: bindActionCreators(destroy, dispatch),
        documents: bindActionCreators(schemeActions, dispatch),
      },
    }),
  ),
])(NewDocumentForm);
