import React from 'react';

export const categoryB = (
  <svg id="category_b_layer_1" data-name="Layer 1" viewBox="0 0 95 95">
    <path className="cls-1" d="M95,47.5A47.5,47.5,0,1,1,47.5,0,47.5,47.5,0,0,1,95,47.5" />
    <text style={{ fill: '#fff' }} transform="translate(44.78 63.95)" />
    <path
      style={{ fill: '#fff' }}
      d="M30.37,17.74H45.31A39.4,39.4,0,0,1,56,18.85a10.63,10.63,0,0,1,5.89,4.46q2.24,3.36,2.24,10.83,0,5-1.58,7t-6.24,3.06q5.19,1.17,7,3.9t1.85,8.35v5.34q0,5.84-1.33,8.65a7.2,7.2,0,0,1-4.25,3.84q-2.92,1-12,1H30.37V17.74Zm15,9.85V40.4l1.49,0q2.21,0,2.81-1.09t0.6-6.21a10.05,10.05,0,0,0-.5-3.79,2.39,2.39,0,0,0-1.3-1.37A11.51,11.51,0,0,0,45.35,27.6Zm0,21.77V65.48q3.17-.11,4-1t0.87-4.37V54.74q0-3.7-.78-4.48T45.35,49.37Z"
    />
  </svg>
);

export default categoryB;
