import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hasRole, isOwner, isBroker } from '../../auth/redux/authActions';
import { Box, InfoButton, Loader } from '../../../common/components';
import { push } from 'react-router-redux';
import InternalContainer from '../../layout/containers/Internal';
import StarBrokerStats from '../components/StarBrokerStats';
import { get, sortBy } from 'lodash';
import flatten from 'flat';
import displayCurrency from '../../../helpers/displayCurrency';
import getIncludedResource from '../../../helpers/getIncludedResource';
import { Form, FormControl, FormGroup, Col, Row, Nav } from 'react-bootstrap';
import { fetchStats, fetchBrokerStats } from '../redux/dashboardActions';
import Icon from '../../../common/components/Icon/Icon';
import { Link } from 'react-router';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import moment from 'moment';
import { Button, DataTable } from '../../../common/components';
import DatePickerDobMasked from '../../../common/components/DatePicker/DatePickerDobMasked';
import { TableHeaderColumn } from 'react-bootstrap-table';
import validate from './validate';
import { fetchData } from '../../../common/components/DataTable/redux/dataTableActions';
import { storeCustomer } from './redux/searchActions';
import Alert from 'react-bootstrap/es/Alert';
import { FormattedDate } from 'react-intl';
import { upper } from '../../../helpers/normalizeUpper';
import './Dashboard.scss';
import Marquee from '../../layout/components/InternalSection/Marquee';

class DashboardContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    auth: PropTypes.object.isRequired,
    dashboard: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleSearchButton = this.handleSearchButton.bind(this);
    this.renderField = this.renderField.bind(this);
    this.handleAlertDismiss = this.handleAlertDismiss.bind(this);
    this.handleLastNameUpdate = this.handleLastNameUpdate.bind(this);
    this.handleNameUpdate = this.handleNameUpdate.bind(this);
    this.handleEmailUpdate = this.handleEmailUpdate.bind(this);
    this.handleDOBUpdate = this.handleDOBUpdate.bind(this);
    this.handlePostcodeUpdate = this.handlePostcodeUpdate.bind(this);
    this.state = {
      alertVisible: true,
      enableViewBrokers: true,
      name: '',
      surname: '',
      email: '',
      dob: '',
      postcode: '',
      day: true,
      week: false,
      month: false,
      loginToday: true,
      loginWeek: false,
      brokerToday: true,
      brokerWeek: false,
      brokerMonth: false,
      selection: '',
      loginSelection: '',
      brokerSelection: '',
      range: 'day',
    };
  }

  componentWillMount() {
    this.props.fetchStats();
    this.props.fetchBrokerStats(this.state.range);
  }

  handleAlertDismiss() {
    this.setState({
      alertVisible: false,
    });
  }

  renderField(field) {
    const { input, type, meta } = field;
    const className =
      meta.submitFailed && meta.touched && meta.error && meta.error.length > 0
        ? 'form-control has-error'
        : 'form-control';
    return (
      <div>
        <input {...input} className={className} type={type} />
      </div>
    );
  }

  formatDob(cell) {
    return (
      <span>
        <FormattedDate value={new Date(cell)} />
      </span>
    );
  }

  formatAddress(cell, row) {
    return row.address.postcode;
  }

  handleSearchButton() {
    const { formValues } = this.props;

    let filter1 = '';
    let filter2 = '';
    let filter3 = '';
    let filter4 = '';
    let filter5 = '';

    if (this.state.name != null) {
      filter1 = `&filter[name.first_name]=${this.state.name}`;
    }

    if (this.state.surname != null) {
      filter2 = `&filter[name.last_name]=${this.state.surname}`;
    }

    if (this.state.email != null) {
      filter3 = `&filter[email]=${this.state.email}`;
    }

    if (this.state.dob != null) {
      filter4 = `&filter[dob]=${this.state.dob}`;
    }

    if (this.state.postcode != null) {
      filter5 = `&filter[address.postcode]=${this.state.postcode}`;
    }

    const customFilter = filter1 + filter2 + filter3 + filter4 + filter5;
    this.props.fetchData('customers', customFilter);
    this.props.storeCustomer(formValues);
  }

  handleNameUpdate(event) {
    this.setState(() => ({
      name: event.target.value,
    }));
  }

  handleLastNameUpdate(event) {
    this.setState(() => ({
      surname: event.target.value,
    }));
  }

  handleEmailUpdate(event) {
    this.setState(() => ({
      email: event.target.value,
    }));
  }

  handleDOBUpdate(event) {
    const formattedDate = moment(event.target.value, 'DD/MM/YYYY').utcOffset(0, true);
    if (formattedDate.isValid()) {
      this.setState(() => ({
        dob: formattedDate.format(),
      }));
    } else {
      this.setState(() => ({
        dob: '',
      }));
    }
  }

  handlePostcodeUpdate(event) {
    this.setState(() => ({
      postcode: event.target.value,
    }));
  }

  handleRowClick(row) {
    const { user } = this.props.auth;
    const products = sortBy(
      getIncludedResource(user.data, user.included, 'available_products'),
      'attributes.name',
    );
    const productsId = products.map((product) => product.id);
    this.props.dispatch(push(`/products/${productsId[0]}/quote/?customer=${row.id}`));
  }

  setRange(range) {
    this.setState({ range }, () => {
      this.props.fetchBrokerStats(range);
    });
  }

  handleFilterLogins(event) {
    this.setState({ loginSelection: event.target.value });
    switch (event.target.value) {
      case 'today':
        this.setState({ loginToday: true, loginWeek: false });
        break;
      case 'week':
        this.setState({ loginToday: false, loginWeek: true });
        break;
      default:
        return;
    }
  }

  handleBrokerSelection(event) {
    this.setState({ brokerSelection: event.target.value });
    switch (event.target.value) {
      case 'today':
        this.setRange('day');
        this.setState({ brokerToday: true, brokerWeek: false, brokerMonth: false });
        break;
      case 'week':
        this.setRange('week');
        this.setState({ brokerWeek: true, brokerToday: false, brokerMonth: false });
        break;
      case 'month':
        this.setRange('month');
        this.setState({ brokerMonth: true, brokerToday: false, brokerWeek: false });
        break;
      default:
        return;
    }
  }

  handleFilterUpdate(event) {
    this.setState({ selection: event.target.value });
    switch (event.target.value) {
      case 'today':
        this.setState({ day: true, month: false, week: false });
        break;
      case 'week':
        this.setState({ week: true, day: false, month: false });
        break;
      case 'month':
        this.setState({ month: true, week: false, day: false });
        break;
      default:
        return;
    }
  }

  customSelection() {
    return (
      <div className="select-box">
        <FormControl
          componentClass="select"
          className="select2"
          type="select"
          onChange={::this.handleFilterUpdate}
          value={this.state.selection}
          placeholder="select"
        >
          <option key="0" value="today">
            Today
          </option>
          <option key="1" value="week">
            This week
          </option>
          <option key="2" value="month">
            This month
          </option>
        </FormControl>
      </div>
    );
  }

  customSelectionLogins() {
    return (
      <FormControl
        componentClass="select"
        className="select"
        type="select"
        onChange={::this.handleFilterLogins}
        value={this.state.loginSelection}
        placeholder="select"
      >
        <option key="0" value="today">
          Today
        </option>
        <option key="1" value="week">
          This week
        </option>
      </FormControl>
    );
  }

  calcPercentage(n1, n2) {

    if (n1 !== 0 && n2 !== 0) {
      return (n1 / n2) * 100;
    }
    return null;
  }

  renderBroker() {
    const { dashboard } = this.props;
    const stats = get(dashboard.broker_stats, 'meta[0].broker', null) || '';
    const statistics = stats.statistics || '';
    return (
      <Box minHeight={false} className="middlebar">
        <div className="select-box dashboard-select">
          <FormControl
            componentClass="select"
            className="select"
            type="select"
            onChange={::this.handleBrokerSelection}
            value={this.state.brokerSelection}
            placeholder="select"
          >
            <option key="0" value="today">
              {isBroker() ? 'Today' : 'Top Broker (Today)'}
            </option>
            <option key="1" value="week">
              {isBroker() ? 'Week' : 'Top Broker (Week)'}
            </option>
            <option key="2" value="month">
              {isBroker() ? 'Month' : 'Top Broker (Month)'}
            </option>
          </FormControl>
        </div>
        {stats ? (
          <div className="list-group list-group-horizontal">
            <div className="list-group-item">
              <div className="list-group-item-text">
                <h4>
                  {stats.name}
                  {'\n'}
                </h4>
              </div>
              <br />
            </div>
            <div className="list-group-item">
              <div className="list-group-item-text">
                <small> No. of Quotes</small>
              </div>
              {statistics.no_quoted || null}
            </div>
            <div className="list-group-item">
              <div className="list-group-item-text">
                <small> No. of Policies</small>
              </div>
              {statistics.no_issued || null}
            </div>
            <div className="list-group-item">
              <div className="list-group-item-text">
                <small> Conversion Rate</small>
              </div>
              <span>
                {Math.round(
                  ::this.calcPercentage(statistics.no_issued, statistics.no_quoted),
                )}
                %
              </span>
            </div>
            <div className="list-group-item">
              <div className="list-group-item-text">
                <small> Premium issued</small>
              </div>
              {displayCurrency(statistics.premiums_gross) || ''}
            </div>
          </div>
        ) : (
          <div className="list-group list-group-horizontal">
            <div className="list-group-item">
              <div className="list-group-item-text">
                <h4>No broker activity for the given period{'\n'}</h4>
              </div>
              <br />
            </div>
            <div className="list-group-item">
              <div className="list-group-item-text">
                <small> No. of Quotes</small>
              </div>
            </div>
            <div className="list-group-item">
              <div className="list-group-item-text">
                <small> No. of Policies</small>
              </div>
            </div>
            <div className="list-group-item">
              <div className="list-group-item-text">
                <small> Conversion Rate</small>
              </div>
            </div>
            <div className="list-group-item">
              <div className="list-group-item-text">
                <small> Premium issued</small>
              </div>
            </div>
          </div>
        )}
      </Box>
    );
  }

  renderUniqueDays() {
    const { dashboard } = this.props;
    return (
      <div>
        <span>({get(dashboard.stats, 'meta.day.unique_logins', 0)} unique)</span>
      </div>
    );
  }

  renderWeekDays() {
    const { dashboard } = this.props;
    return (
      <div>
        <span>({get(dashboard.stats, 'meta.week.logins', 0)})</span>
      </div>
    );
  }

  render() {
    const { auth, handleSubmit, error, submitFailed, submitSucceeded } = this.props;
    const isSubmitted = submitFailed || submitSucceeded;
    const customerBoxClass = ['quotes-box', isSubmitted && 'is-searched'].join(' ');

    // const statsPoliciesDay = get(dashboard, 'stats.meta.day.quotes.issued', '')
    // const statsQuotesDay = get(dashboard, 'stats.meta.day.quotes.unconverted', '')

    const formErrors = error && error instanceof Object ? flatten(error) : false;
    const products =  getIncludedResource(auth.user.data, auth.user.included, 'available_products')
      .filter( (product) => product.attributes.status === 'active')
      .sort((a, b) => {
        if (a.attributes.name > b.attributes.name) {
          return 1;
        }
        if (a.attributes.name < b.attributes.name) {
          return -1;
        }
        return 0;
      });


    if (!get(auth.user, 'data.relationships.current_organisation')) {
      return <div>Sort it out</div>;
    }

    if (!get(auth.user, 'data.relationships.current_organisation')) {
      return <div>Sort it out</div>;
    }

    return (
      <InternalContainer
        hideBreadcrumbs
        title={'Welcome Back, ' + auth.user.data.attributes.first_name + '!'}
      >
        {auth.user ? (
          <div>
            <Marquee {...this.props}/>
            <Row className="dashboard-container">
              <Col sm={12}  md={6} className="internal-container">
                <div className="get-quotes-header">
                  <h3> Get a Quote </h3>
                </div>
                <Box className="quotes-box get-quote-box">
                  <div className="tabbable">
                    <Nav  bsStyle="pills" stacked>
                      {products.length === 0 && (
                        <li>
                          <div className="quotes-menu-no-quotes">
                            No Products Available
                          </div>
                        </li>
                      )}
                      {products.map((product, index) => (
                        <div>
                          {!['FPR', 'NPOR', 'CSSR', 'CSPAR'].includes(product.attributes.product_code) && (
                            <li>
                              <div className="product-row">
                                <InfoButton content={product.attributes.description .description} />
                                <div className="quotes-menu-item">
                                  <Link
                                    id={'quote' + index}
                                    to={'products/' + product.id + '/quote'}
                                   onlyActiveOnIndex>
                                    <div>
                                      <img
                                        className="icon-img"
                                        src={product.attributes.icon}
                                        alt=""
                                      />
                                      &nbsp;{product.attributes.name}
                                      <span className="icon-right">
                                        <Icon name="caret-right" />
                                      </span>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </li>
                          )}
                        </div>
                      ))}
                    </Nav>
                  </div>
                </Box>
              </Col>
              <Col sm={12}  md={6} className="internal-container">
                <div className="get-quotes-header">
                  <h3> Existing Customer </h3>
                </div>
                <Box className={customerBoxClass}>
                  <Form
                    onSubmit={handleSubmit(this.handleSearchButton)}
                    className="form-horizontal"
                  >
                    <FormGroup>
                      <Col md={3}>
                        <label className="control-label">First Name</label>
                      </Col>
                      <Col md={8}>
                        <Field
                          name="metadata.first_name"
                          type="text"
                          component={this.renderField}
                          placeholder="First Name"
                          onChange={this.handleNameUpdate}
                          normalize={upper}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup>
                      <Col md={3}>
                        <label className="control-label">Surname</label>
                      </Col>
                      <Col md={8}>
                        <Field
                          name="metadata.last_name"
                          type="text"
                          component={this.renderField}
                          placeholder="surname"
                          normalize={upper}
                          onChange={this.handleLastNameUpdate}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup>
                      <Col md={3}>
                        <label className="control-label">Email</label>
                      </Col>
                      <Col md={8}>
                        <Field
                          name="metadata.email"
                          type="email"
                          component={this.renderField}
                          placeholder="email"
                          onChange={this.handleEmailUpdate}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup>
                      <Col md={3}>
                        <label className="control-label">Postcode</label>
                      </Col>
                      <Col md={8}>
                        <Field
                          name="metadata.address.postcode"
                          type="text"
                          component={this.renderField}
                          placeholder="postcode"
                          onChange={this.handlePostcodeUpdate}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup>
                      <Col md={3}>
                        <label className="control-label">Date of Birth</label>
                      </Col>
                      <Col md={4}>
                        <Field
                          name="metadata.dob"
                          type="text"
                          showYear
                          maxDate={moment().format()}
                          component={DatePickerDobMasked}
                          placeholder="dob"
                          onChange={this.handleDOBUpdate}
                        />
                      </Col>
                    </FormGroup>
                    <Row>
                      <Col md={11}>
                        <Button
                          type="submit"
                          className="pull-right pad-top btn-padding"
                          label={<span className="placeholder"> Search</span>}
                          bsStyle="primary"
                          search
                        />
                      </Col>
                    </Row>
                  </Form>
                  {isSubmitted && formErrors && this.state.alertVisible === true ? (
                    <div className="search-has-error">
                      <Alert bsStyle="danger" onDismiss={this.handleAlertDismiss}>
                        Please complete at least 1 piece of information.
                      </Alert>
                    </div>
                  ) : (
                    <div />
                  )}
                  <div className={!submitSucceeded ? 'table-hidden' : ''}>
                    <DataTable
                      source="/customers/:strict"
                      name="customers"
                      showTotal
                      onRowSelect={this.handleRowClick.bind(this)}
                      pageOff
                      customHeight={!submitSucceeded ? '79px' : ''}
                      style={{ minHeight: '5px' }}
                    >
                      <TableHeaderColumn dataField="id" isKey hidden>
                        ID
                      </TableHeaderColumn>
                      <TableHeaderColumn dataField="first_name" width={'20%'}>
                        First Name
                      </TableHeaderColumn>
                      <TableHeaderColumn dataField="last_name" width={'20%'}>
                        Surname
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField="dob"
                        dataFormat={this.formatDob}
                        width={'25%'}
                      >
                        Dob
                      </TableHeaderColumn>
                      <TableHeaderColumn dataField="email" width={'22%'} dataSort>
                        Email
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField="address"
                        width={'15%'}
                        dataFormat={this.formatAddress}
                      >
                        Postcode
                      </TableHeaderColumn>
                    </DataTable>
                  </div>
                </Box>
              </Col>
            </Row>
            {((isOwner() && !hasRole('Standard (Owner)')) ||
              this.state.enableViewBrokers) && (
              <div className="row">
                <div className="col-md-3 internal-container">
                  <Box minHeight={false} className="middlebar">
                    <div className="select-box dashboard-select">
                      <FormControl
                        componentClass="select"
                        className="select"
                        type="select"
                        onChange={::this.handleFilterLogins}
                        value={this.state.loginSelection}
                        placeholder="select"
                      >
                        <option key="0" value="today">
                          Logins(Today)
                        </option>
                        <option key="1" value="week">
                          Logins(This week)
                        </option>
                      </FormControl>
                    </div>
                    <div className="list-group list-group-horizontal2">
                      <div className="list-group-item">
                        <div className="list-group-item-text">
                          <small> Internal</small>
                        </div>
                        {this.state.loginToday
                          ? this.renderUniqueDays()
                          : this.renderWeekDays()}
                      </div>
                    </div>
                  </Box>
                </div>
                {/*  Middle Bar 2/2 - Broker Info  */}
                <div className="col-md-9 internal-container">
                  {/* Selection Top Broker DropDown */}
                  {this.state.brokerToday && this.renderBroker()}
                  {this.state.brokerWeek && this.renderBroker()}
                  {this.state.brokerMonth && this.renderBroker()}
                </div>
              </div>
            )}
            {/* Stats Box */}
            {((isOwner() && !hasRole('Standard (Owner)')) ||
              this.state.enableViewBrokers) && (
              <div className="row">
                <div className="col-md-12 internal-container broker-stats">
                  <StarBrokerStats />
                </div>
              </div>
            )}
          </div>
        ) : (
          <Loader />
        )}
      </InternalContainer>
    );
  }
}

const FORM_NAME = 'DashboardContainer';
const form = reduxForm({ form: FORM_NAME, validate })(DashboardContainer);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state, props) => {
  const values = selector(state, 'data', 'metadata');

  return {
    auth: state.auth,
    dashboard: state.dashboard,
    initialValues: props.resource,
    formValues: values,
  };
};

export default connect(
  mapStateToProps,
  { fetchStats, fetchData, fetchBrokerStats, storeCustomer },
)(form);
