import React from 'react';

export const rabbit = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95 95">
    <path className="cls-1" d="M0,47.5A47.5,47.5,0,1,0,47.5,0,47.5,47.5,0,0,0,0,47.5" />
    <path
      style={{ fill: '#fff' }}
      d="M28.77,38.79a.94.94,0,0,1-.59.91,4.28,4.28,0,0,0-2,.63c-1.16.72-.74-.16-2.59,1.46s-2,2.13-2,2.94a15.25,15.25,0,0,1-1.6,4.1c-.67,1.32-.58,2-.88,2.53s-.55,1.32-.79,1.6a1.42,1.42,0,0,0-.16,1.32c.14.63.44.6.47.93a3.39,3.39,0,0,0,.49,1.62c.39.53.55,1.27,1.13,1.3s.63.42,1.18.39.67.28,1.79.33,1.48-.05,1.76.39.58,1.3,1.71,3.22,1.79,1.6,2.7,2.7A3.41,3.41,0,0,0,28,66.91c-.35,1.16-.63,1.76-.05,2.13a6.09,6.09,0,0,0,3.17.25,12.09,12.09,0,0,1,2.34,0s.19.53,1.27.55,2.83.16,3.75.19a12.59,12.59,0,0,0,3.24-.16c1.4-.28,1.81.09,2.92-.44A3.18,3.18,0,0,1,46.31,69s-.6,1.32.93,1.35a15.41,15.41,0,0,0,2.29-.09s.28.3,1.13.28a8.54,8.54,0,0,0,3-.33,7.11,7.11,0,0,0,2.92-.05,17.07,17.07,0,0,1,4-.49,31.75,31.75,0,0,0,3.85-.58c.93-.16,1.21-.11,1-.67s-.09-.72-.09-.72,3,.09,4.1-.05a11.81,11.81,0,0,1,3-.28c2,0,3.28.47,4.91,0s4-2.45,3.61-5-2.8-2.84-5.81-1.95a2.54,2.54,0,0,1,.19-1.32c.3-.79.19-1.74.58-2.57a17,17,0,0,0,.63-8,11.42,11.42,0,0,0-3.38-6.46c-1.41-1.25-3-3.27-4.79-4.63A23.6,23.6,0,0,0,58,33.13,17.74,17.74,0,0,0,45.65,35c-3.19,1.69-4,3.14-4.84,3.31s-1.55-.25-2.39,0a1.78,1.78,0,0,1-.16-1.69,9.72,9.72,0,0,0,.58-5A29.42,29.42,0,0,0,38,26.11c-.42-1.71-.91-4.3-2.83-5.21s-2.78-.09-3.17.42a5.6,5.6,0,0,0-1.71,2.59,11.25,11.25,0,0,0,.14,4.4c.33,1.27.55,2.75.55,2.75a24.67,24.67,0,0,0-3.36-4.38c-1.81-1.76-3-2.83-4.1-3.77S22.18,21,21.05,21.47s-.33,1.46,0,2,2,2.15,2.43,3.06,1.92,3.82,2.8,6.16a32.58,32.58,0,0,0,2.23,4.52A8,8,0,0,1,28.77,38.79Z"
    />
  </svg>
);

export default rabbit;
