import React from 'react';
import { Button } from '../../../../../../common/components';
import { flow } from 'lodash';
import SubBenefitsFields from './SubBenefitsFields';
import confirm from 'react-confirm2';

class SubBenefitsArray extends React.Component {
  state = {
    subBenefitsList: [],
    itsNewSubBenefit: false,
    showAllDetails: false,
  };

  componentWillMount() {
    const { subBenefits } = this.props;

    if (subBenefits && subBenefits.length > 0) {
      this.setState({
        subBenefitsList: subBenefits,
      });
    }
  }

  handleRemoveSubBenefit = (index) => () => {
    const { subBenefits } = this.props;

    confirm('Warning', {
      description: 'Are you sure you want to remove this sub benefit?',
      done: () => {
        subBenefits.splice(index, 1);
        this.setState({
          benefitsList: subBenefits,
        });
      },
    });
  };

  handleAddSubBenefit = () => () => {
    const { subBenefits } = this.props;

    subBenefits.push({
      name: '',
      text: '',
      option: '',
      excess: '',
      cover_level: {
        super: '',
        super_duper: '',
      },
    });

    this.setState({
      subBenefitsList: subBenefits,
      itsNewSubBenefit: true,
    });
  };

  handleToggleSubBenefits = () => () => {
    this.setState((prevState) => ({
      showAllDetails: !prevState.showAllDetails,
    }));
  };

  renderSubBenefits = () => {
    const { viewOnly } = this.props;
    const { subBenefitsList, itsNewSubBenefit, showAllDetails } = this.state;

    if (subBenefitsList.length > 0) {
      return subBenefitsList.map((subBenefit, index) => {
        return (
          <div className="row benefit-row" key={index}>
            <SubBenefitsFields
              viewOnly={viewOnly}
              onRemoveSub={this.handleRemoveSubBenefit(index)}
              subBenefit={subBenefit}
              index={index}
              itsNewSubBenefit={itsNewSubBenefit}
              showAllDetails={showAllDetails}
              {...this.props}
            />
          </div>
        );
      });
    }

    if (subBenefitsList.length === 0) {
      return (
        <div>
          <h5>No Sub Benefits found.</h5>
        </div>
      );
    }
  };

  render() {
    const { viewOnly } = this.props;
    const { showAllDetails } = this.state;
    return (
      <div className="form-horizontal">
        <Button
          className="benefit-btn pull-right"
          bsStyle="link"
          label={!showAllDetails ? 'Show more' : 'show less'}
          handleClick={this.handleToggleSubBenefits()}
        />
        {this.renderSubBenefits()}
        {!viewOnly && (
          <Button
            className="benefit-btn pull-left"
            bsStyle="primary"
            size="small"
            label={'Add a Sub Benefit'}
            handleClick={this.handleAddSubBenefit()}
          />
        )}
      </div>
    );
  }
}

export default flow()(SubBenefitsArray);
