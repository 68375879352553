import * as actions from './policyReducer';
import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import queryString from '../../../helpers/queryString';
import handleErrorResponse from '../../../helpers/handleErrorResponse';
import { indexOf } from 'lodash';
import { ADD_RESOURCE } from '../../layout/redux/breadcrumb';

const defaultIncludes = [
  'customer',
  'user',
  'broker',
  'non_scheme_documents',
  'premiums.scheme.product',
  'schemes.product',
  'documents',
  'product',
  'source',
  'mid_term_adjustment_applications',
  'channel',
];

export function clearData() {
  return {
    type: actions.CLEAR_DATA,
  };
}

export function isSaving(status) {
  return {
    type: actions.IS_SAVING,
    status,
  };
}

export function isSubmitting(status) {
  return {
    type: actions.IS_SUBMITTING,
    status,
  };
}

export function isCheckingCancel(status) {
  return {
    type: actions.IS_CHECKING_CANCEL,
    status,
  };
}

export function isGeneratingDocs(status) {
  return {
    type: actions.IS_GENERATING_DOCS,
    status,
  };
}

export function isProcessingPrintJobRequest(status) {
  return {
    type: actions.IS_PROCESSING_PRINT_JOB_REQUEST,
    status,
  };
}

export function addToPrintJobs(application, resourceType, callback) {
  return (dispatch) => {
    dispatch(isProcessingPrintJobRequest(true));
    return Axios.post(resourceType + '/' + application.data.id + ':print-queue', {})
      .then(() => {
        return dispatch(
          getPolicy(application.data.id, resourceType === 'policies/applications'),
        );
      })
      .then(() => {
        toastr.success('Success', 'Successfully added to print queue');
        dispatch(isProcessingPrintJobRequest(false));

        if (callback) {
          callback();
        }

        return true;
      })
      .catch((error) => {
        dispatch(isProcessingPrintJobRequest(false));
        handleErrorResponse(error, 'Failed to add to print queue');
      });
  };
}

export function removeFromPrintJobs(application, resourceType, callback) {
  return (dispatch) => {
    dispatch(isProcessingPrintJobRequest(true));
    return Axios.delete(resourceType + '/' + application.data.id + ':print-queue', {})
      .then(() => {
        return dispatch(
          getPolicy(application.data.id, resourceType === 'policies/applications'),
        );
      })
      .then(() => {
        toastr.success('Success', 'Successfully removed from print queue');
        dispatch(isProcessingPrintJobRequest(false));

        if (callback) {
          callback();
        }

        return true;
      })
      .catch((error) => {
        dispatch(isProcessingPrintJobRequest(false));
        handleErrorResponse(error, 'Failed to remove from print queue');
      });
  };
}

export function sendEndorsementEmail(id) {
  return () => {
    return Axios.post('/emails/policies/' + id + ':send-endorsements-email')
      .then(() => {
        toastr.success('Success', 'Endorsement email sent');
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'Email failed to send');
      });
  };
}

// Used for marketing - not currently required
// export function logEditQuote(application) {
//   return () => {
//     return Axios.post('policies/applications/' + application.data.id + ':log-edit').then(
//       () => {
//         return true;
//       },
//     );
//   };
// }

export function createDoctor(fileId, customerId, fileName) {
  const valuesToApplication = {
    data: {
      attributes: {
        name: fileName,
      },
      relationships: {
        file: {
          data: {
            id: fileId,
          },
        },
        customer: {
          data: { id: customerId },
        },
      },
    },
  };


  return (dispatch) => {
    return Axios.post('/doctors-form', valuesToApplication)
      .then((response) => {
        dispatch({
          type: actions.SET_DOCTORS_FORM,
          data: response.data,
        });
        toastr.success('Success', 'Your file has been saved');
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function getDoctorForm() {
  return () => {
    const END_POINT = 'doctors-form/static-form';
    return Axios.get(END_POINT)
      .then((res) => {
        return res.data.data.attributes.file_path;
      })
      .catch((error) => {
        handleErrorResponse(error, 'There was an error loading the resource');
      });
  };
}

export function getQuotesHistory(quoteReference) {
  if (!quoteReference) {
    return false;
  }

  return (dispatch) => {
    const END_POINT = `/policies/applications?filter[quote.reference]=${quoteReference}&filter[version]=all`;
    return Axios.get(END_POINT)
      .then((res) => {
        dispatch({
          type: actions.QUOTES_HISTORY,
          data: res.data,
        });
        return res.data;
      })
      .catch((error) => {
        handleErrorResponse(error, 'There was an error loading the resource');
      });
  };
}

export function clearQuoteHistory() {
  return (dispatch) => {
    dispatch({
      type: actions.QUOTES_HISTORY,
      data: {},
    });
  };
}

export function createAccount(id) {
  const endpoint = `customers/${id}:create-account`;

  return () => {
    return Axios.post(endpoint)
      .then(() => {
        toastr.success('Success');
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'Failed to create account');
      });
  };
}

export function declineReferral(values, application) {
  return (dispatch) => {
    return Axios.post('policies/applications/' + application.data.id + ':decline', values)
      .then(() => {
        dispatch(getPolicy(application.data.id, true));
        toastr.success('Success', 'Referral has been declined');
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'Failed to decline referral');
      });
  };
}

export function getPolicy(id, isApplication = false, includes = defaultIncludes) {
  return (dispatch) => {
    if (isApplication && indexOf(includes, 'policy') === -1) {
      includes.push('policy');
    } else {
      includes.push('issued_applications');
    }

    let endpoint = 'policies/' + (isApplication ? 'applications/' : '') + id;
    endpoint += queryString(includes);

    return Axios.get(endpoint)
      .then((response) => {
        dispatch({
          type: actions.SET_POLICY,
          data: response.data,
        });
        dispatch({
          type: ADD_RESOURCE,
          id: response.data.data.id,
          name: isApplication
            ? response.data.data.attributes.quote_reference
            : response.data.data.attributes.policy_number,
        });
        return true;
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function generateDocuments(application, resourceType, callback) {
  return (dispatch) => {
    dispatch(isGeneratingDocs(true));
    return Axios.post(
      resourceType + '/' + application.data.id + ':generate-documents',
      {},
    )
      .then(() => {
        toastr.success('Success', 'Documents have been generated');
        dispatch(isGeneratingDocs(false));

        if (callback) {
          callback();
        }

        return true;
      })
      .catch((error) => {
        dispatch(isGeneratingDocs(false));
        handleErrorResponse(error, 'Documents failed to generate');
      });
  };
}

export function emailDocuments(values, application, resourceType) {
  return () => {
    return Axios.post(
      resourceType + '/' + application.data.id + ':email-documents',
      values,
    )
      .then(() => {
        toastr.success(
          'Success',
          'Quote/Policy email has been sent to ' +
            (values.meta.email ? ' to ' + values.meta.email : ''),
        );
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'Email failed to send');
      });
  };
}

export function printDocuments(values, application, resourceType) {
  return () => {
    return Axios.post(
      resourceType + '/' + application.data.id + ':print-documents',
      values,
    )
      .then((response) => {
        window.open(response.data.meta.url, '_blank');
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'Failed to print documents');
      });
  };
}

export function downloadCSPLiveMembershipData(id) {
  return () => {
    return Axios.post(
      'policies/' + id + ':download-live-membership-data',
    )
      .then((response) => {
        window.open(response.data.meta.url, '_blank');
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'Failed to download live membership data');
      });
  };
}

export function sendMedicalScreeningEmail(resourceType, id) {
  return () => {
    return Axios.post('/emails/' + resourceType + '/' + id + ':send-medical-screening-email')
      .then(() => {
        toastr.success('Success', 'Medical Screening email sent');
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'Email failed to send');
      });
  };
}

export function sendCustomerLoginEmail(resourceType, id) {
  return () => {
    return Axios.post('/emails/' + resourceType + '/' + id + ':send-customer-log-in')
      .then(() => {
        toastr.success('Success', 'Customer log in email sent');
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'Email failed to send');
      });
  };
}

export function sendCustomerResetPasswordEmail(id, type = 'policies') {
  const endpoint = '/emails/' + type + '/';
  return () => {
    return Axios.post(endpoint + id + ':send-reset-password')
      .then(() => {
        toastr.success('Success', 'Customer reset password email sent');
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'Email failed to send');
      });
  };
}

export function sendPolicyCancelledEmail(id) {
  return () => {
    return Axios.post('/emails/policies/' + id + ':send-cancellation-email')
      .then(() => {
        toastr.success('Success', 'Policy cancellation email sent');
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'Email failed to send');
      });
  };
}

export function sendEndorsementsEmail(id) {
  return () => {
    return Axios.post('/emails/policies/' + id + ':send-endorsements-email')
      .then(() => {
        toastr.success('Success', 'Endorsements email sent');
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'Email failed to send');
      });
  };
}

export function sendSms(values, application, resourceType) {
  return () => {
    return Axios.post(resourceType + '/' + application.data.id + ':send-sms', values)
      .then(() => {
        toastr.success(
          'Success',
          'SMS has been sent' + (values.meta.number ? ' to ' + values.meta.number : ''),
        );
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'Failed to send SMS');
      });
  };
}

export function transferPolicy(values, application, resourceType) {
  return () => {
    return Axios.post(resourceType + '/' + application.data.id + ':transfer', values)
      .then(() => {
        toastr.success('Success', 'Policy transferred to Broker');
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'Failed to transfer policy to Broker');
      });
  };
}

export function updatePolicyApplication(application, product, applicationId) {
  return (dispatch) => {
    dispatch(isSaving(true));
    const END_POINT =
      'policies/applications/' +
      applicationId +
      '?include=customer,schemes,premiums,policy,transactions,channel';
    return Axios.put(END_POINT, application)
      .then(() => {
        {
          //dispatch(submitSucceeded(true))
          //dispatch(push('/policies/applications/' + res.data.data.id))
          //dispatch(clearQuotes())
        }
        dispatch(isSaving(false));
        return true;
      })
      .catch((error) => {
        dispatch(isSaving(false));
        handleErrorResponse(error, 'There was an error saving your quote(s)');
      });
  };
}

export function cancelPolicy(values, policy) {
  return (dispatch) => {
    return Axios.post('policies/' + policy.data.id + ':cancel', values)
      .then(() => {
        dispatch(getPolicy(policy.data.id));
        toastr.success('Success', 'Policy has been cancelled');
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'Failed to cancel policy');
      });
  };
}

export function uncancelPolicy(policy) {
  return (dispatch) => {
    return Axios.post('policies/' + policy.data.id + ':uncancel',)
      .then(() => {
        dispatch(getPolicy(policy.data.id));
        toastr.success('Success', 'Policy has been uncancelled');
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'Failed to uncancel policy');
      });
  };
}

export function lapseQuote(values, application) {
  return (dispatch) => {
    return Axios.post('policies/applications/' + application.data.id + ':lapse', values)
      .then(() => {
        dispatch(getPolicy(application.data.id, true));
        toastr.success('Success', 'Quote has been lapsed');
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'Failed to lapse quote');
      });
  };
}

export function getDetailsForCancellation(application, cb) {
  return (dispatch) => {
    dispatch(isCheckingCancel(true));
    return Axios.post('policies/' + application.data.id + '/premiums/return-premium')
      .then((response) => {
        cb(response.data);
        dispatch(isCheckingCancel(false));
        return true;
      })
      .catch((error) => {
        dispatch(isCheckingCancel(false));
        handleErrorResponse(error, 'Policy cannot be canceled at this time');
      });
  };
}

export function updateEndorsement(values) {
  return () => {
    return Axios.patch('policies/endorsements/' + values.data.id, values)
      .then(() => {
        toastr.success('Success', 'Endorsement details updated');
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'Failed to update endorsement details');
      });
  };
}

export function lapseQuotesArray(values) {
  return dispatch => {
    return Axios.post('policies/applications:lapse', values ).then(() => {
      toastr.success('Success', 'Lapsed');
      dispatch(clearData());
      return true;
    }).catch(error => {
      handleErrorResponse(error, 'Failed to lapse');
    });
  };
}


export function addNonRenewals(values, cb) {
  return dispatch => {
    return Axios.post('policies/' + null + ':update-from-file', values).then(() => {
      cb(dispatch);
      toastr.success('Success', 'You will receive an email when the upload is complete');
      return true;
    }).catch(error => {
      handleErrorResponse(error, 'Failed to add to non renewal list');
    });
  };
}

export function updateRenewalStatus (application, formValues, status) {
  return dispatch => {
    const values = { data: { 'attributes' : { 'renewal_status' : status } } };
    values.meta = { reason: '' };

    if (formValues.meta) {
      if (formValues.meta.reason) {
        values.meta.reason = formValues.meta.reason;
      }

      if (formValues.meta.note && formValues.meta.note.content) {
        values.meta.reason = formValues.meta.note.content;
      }
    }

    return Axios.post('policies/' + application.data.id + ':update-renewal-status', values).then(() => {
      toastr.success('Success', 'Renewal status changed to ' + status);
      dispatch(getPolicy(application.data.id));
      return true;
    }).catch(error => {
      handleErrorResponse(error, 'Error');
    });
  };
}

// Receives an array of ID
export function inviteRenewalsArray(applications, convertTo) {
  return dispatch => {
    const data = { 'meta': { 'applications' : applications } };
    if (convertTo) {
      data.meta.metadata = { 'convert_referred_renewal_to': convertTo };
    }
    return Axios.post('policies/applications:invite-renewals', data).then(() => {
      toastr.success('Success', 'Renewal has been invited');
      dispatch(clearData());
      return true;
    }).catch(error => {
      handleErrorResponse(error, 'Failed to invite renewal');
    });
  };
}

export function inviteRenewal(application) {
  return dispatch => {
    return Axios.post('policies/applications:invite-renewals', { 'meta': { 'applications' : [application] } }).then(() => {
      dispatch(getPolicy(application, true));
      toastr.success('Success', 'Renewal has been invited');
      return true;
    }).catch(error => {
      handleErrorResponse(error, 'Failed to invite renewal');
    });
  };
}

export function getAccounts(policyId) {

  return dispatch => {
    return Axios.get(`policies/accounts/${policyId}`)
      .then((response) => {
        const premiums = response.data.meta;
        const accountsByPremium = premiums.reduce((carry, premium) => {
          const label = `Premium | ${premium.premium.quote_reference} | £${premium.premium.amount}`;
          premium.title = label;

          carry[label] = premium;
          return carry;
        },{});

        dispatch({
          type: actions.SET_ACCOUNTS,
          accounts: accountsByPremium
        });


      return accountsByPremium;
    }).catch(error => {
      handleErrorResponse(error, 'Failed to retrieve acccounts');
    });
  };
}
