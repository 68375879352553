import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { getOrganisation } from '../redux/organisationActions';
import { Button, Loader } from '../../../common/components';
import InternalContainer from '../../layout/containers/Internal';
import OrganisationDetails from '../components/OrganisationDetails/OrganisationDetails';
import OrganisationBrokers from '../components/OrganisationBrokers/OrganisationBrokers';
import OrganisationInsurers from '../components/OrganisationInsurers';
import OrganisationUsers from '../components/OrganisationUsers/OrganisationUsers';
import OrganisationNotes from '../components/OrganisationNotes/OrganisationNotes';
import OrganisationAudit from '../components/OrganisationAudit';
import getIncludedResource from '../../../helpers/getIncludedResource';
import InternalTabs from '../../layout/components/InternalTabs/InternalTabs';
import { SET_ACTIVE_TAB } from '../../layout/redux/tabs';

const TAB_NAME = 'organisationTabs';

class OrganisationShowContainer extends Component {
  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(getOrganisation(this.props.params.id));
    dispatch({
      type: SET_ACTIVE_TAB,
      name: TAB_NAME,
      number: 1,
    });
  }

  tabs() {
    const resource = this.props.organisation.resources[this.props.params.id];
    const organisationType = getIncludedResource(
      resource.data,
      resource.included,
      'organisation_type',
    );
    const organisationTypeName = organisationType.data && organisationType.data.attributes.name;
    const organisationTabs = [
      {
        permissions: ['organisation.view'],
        title: 'Details',
        content: <OrganisationDetails {...this.props} resource={resource} />,
      },
      {
        type: 'Broker',
        permissions: ['broker.view'],
        title: 'Brokers/Branches',
        content: <OrganisationBrokers {...this.props} resource={resource} />,
      },
      {
        type: 'Insurer',
        permissions: ['organisation.view'],
        title: 'Brands',
        content: <OrganisationInsurers {...this.props} resource={resource} />,
      },
      {
        permissions: ['user.view'],
        title: 'Users/Contacts',
        content: <OrganisationUsers {...this.props} resource={resource} />,
      },
      {
        permissions: ['organisation.notes_view'],
        orgTypes: ['owner'],
        title: 'Notes',
        content: <OrganisationNotes {...this.props} resource={resource} />,
      },
      {
        permissions: ['system.audit_logs_view'],
        orgTypes: ['owner'],
        title: 'Audit',
        content: <OrganisationAudit {...this.props} resource={resource} />,
      },
    ];

    return organisationTabs.filter((tab) => {
      return !(tab.type && tab.type !== organisationTypeName);
    });
  }

  render() {
    const resource = this.props.organisation.resources[this.props.params.id];
    //const organisationId = get(this.props.auth.user, 'data.relationships.current_organisation.data.id', null)
    const organisationType = resource
      ? getIncludedResource(resource.data, resource.included, 'organisation_type')
      : {};

    const buttons = (
      <Button bsStyle="default" label="Back" isBackButton leftIcon="caret-left" />
    );

    return (
      <InternalContainer
        title={
          'Organisation' +
          (organisationType.data ? ' - ' + get(organisationType, 'data.attributes.name') : '')
        }
        buttons={buttons}
      >
        {resource && organisationType.data ? (
          <InternalTabs id={TAB_NAME} tabs={this.tabs()} />
        ) : (
          <Loader />
        )}
      </InternalContainer>
    );
  }
}

OrganisationShowContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  organisation: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    organisation: state.organisation,
  };
}

export default connect(mapStateToProps)(OrganisationShowContainer);
