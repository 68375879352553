import React from 'react';
import Markdown from 'react-remarkable';
import { Col, Row } from 'react-bootstrap';
import { Field, Form, reduxForm } from 'redux-form';
import TextArea from '../../../content/components/TextArea';
import Button from '../../../../common/components/Button/Button';

class CovidMessageContentForm extends React.Component {
  state = {
    showForm: false,
  };

  componentWillMount() {
    const { product, dispatch, change } = this.props;

    dispatch(change('product.data.id', product.data.id));
    if (product.data.attributes.metadata.covid_message &&
      product.data.attributes.metadata.covid_message.travel_detail_bo) {
      dispatch(
        change(
          'product.data.attributes.metadata.covid_message.travel_detail_bo',
          product.data.attributes.metadata.covid_message.travel_detail_bo,
        ),
      );
    }
    if (product.data.attributes.metadata.covid_message &&
      product.data.attributes.metadata.covid_message.travel_detail_b2c) {
      dispatch(
        change(
          'product.data.attributes.metadata.covid_message.travel_detail_b2c',
          product.data.attributes.metadata.covid_message.travel_detail_b2c,
        ),
      );
    }
    if (product.data.attributes.metadata.covid_message &&
      product.data.attributes.metadata.covid_message.payment_bo) {
      dispatch(
        change(
          'product.data.attributes.metadata.covid_message.payment_bo',
          product.data.attributes.metadata.covid_message.payment_bo,
        ),
      );
    }
    if (product.data.attributes.metadata.covid_message &&
      product.data.attributes.metadata.covid_message.payment_b2c) {
      dispatch(
        change(
          'product.data.attributes.metadata.covid_message.payment_b2c',
          product.data.attributes.metadata.covid_message.payment_b2c,
        ),
      );
    }
  }

  render() {
    const { product, isLoading, handleSubmit, showForm } = this.props;
    const covidMessageContentTravelBo = product.data.attributes.metadata.covid_message ? product.data.attributes.metadata.covid_message.travel_detail_bo : null;
    const covidMessageContentTravelB2c = product.data.attributes.metadata.covid_message ? product.data.attributes.metadata.covid_message.travel_detail_b2c : null;
    const covidMessageContentPaymentBo = product.data.attributes.metadata.covid_message ? product.data.attributes.metadata.covid_message.payment_bo : null;
    const covidMessageContentPaymentB2c = product.data.attributes.metadata.covid_message ? product.data.attributes.metadata.covid_message.payment_b2c : null;
    const defaultMessage = 'No content found. Click "Edit" to add content.';

    return (
      <Form onSubmit={handleSubmit}>
        <label className="control-label">Travel Details Back Office</label>
        {showForm ? (
          <Field component={TextArea} name={'product.data.attributes.metadata.covid_message.travel_detail_bo'}/>
        ) : (
          <Markdown
            source={covidMessageContentTravelBo && covidMessageContentTravelBo !== '\u200b' ? covidMessageContentTravelBo : defaultMessage}/>
        )}
        <label className="control-label">Travel Details B2C</label>
        {showForm ? (
          <Field component={TextArea} name={'product.data.attributes.metadata.covid_message.travel_detail_b2c'}/>
        ) : (
          <Markdown
            source={covidMessageContentTravelB2c && covidMessageContentTravelB2c !== '\u200b' ? covidMessageContentTravelB2c : defaultMessage}/>
        )}
        <label className="control-label">Payment Back Office</label>
        {showForm ? (
          <Field component={TextArea} name={'product.data.attributes.metadata.covid_message.payment_bo'}/>
        ) : (
          <Markdown
            source={covidMessageContentPaymentBo && covidMessageContentPaymentBo !== '\u200b' ? covidMessageContentPaymentBo : defaultMessage}/>
        )}
        <label className="control-label">Payment B2C</label>
        {showForm ? (
          <Field component={TextArea} name={'product.data.attributes.metadata.covid_message.payment_b2c'}/>
        ) : (
          <Markdown
            source={covidMessageContentPaymentB2c && covidMessageContentPaymentB2c !== '\u200b' ? covidMessageContentPaymentB2c : defaultMessage}/>
        )}
        <Row>
          <Col sm={12}>
            {showForm && (
              <Button type="button" bsStyle="default" handleClick={this.handleToggleForm}>
                Cancel
              </Button>
            )}
            {showForm && (
              <Button
                type="submit"
                bsStyle="primary"
                className="pull-right"
                isLoading={isLoading}
              >
                Save
              </Button>
            )}
            {!showForm && (
              <Button
                type="button"
                bsStyle="primary"
                className="pull-right"
                handleClick={this.handleToggleForm}
              >
                Edit
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    );
  }

  handleToggleForm = () => {
    const { onToggleForm } = this.props;

    onToggleForm();
  };
}

export default reduxForm({
  form: 'CovidMessageContentForm',
})(CovidMessageContentForm);
