import { getScheme, isEditing, isImporting } from '../../scheme/redux/schemeActions';
import Axios from 'axios/index';
import handleErrorResponse from '../../../helpers/handleErrorResponse';
import queryString from '../../../helpers/queryString';
import { push } from 'react-router-redux';
import { toastr } from 'react-redux-toastr';
import { filter, forEach } from 'lodash';
import getIncludedResource from '../../../helpers/getIncludedResource';
import { ADD_RESOURCE } from '../../layout/redux/breadcrumb';

const SET_SOURCE = 'products/SET_SOURCE';
const SET_PRODUCTS = 'socrates-online/scheme/SET_PRODUCTS';
const SET_DESTINATIONS = 'socrates-online/scheme/SET_DESTINATIONS';
const SET_PRODUCT = 'socrates-online/scheme/SET_PRODUCT';
const SET_PRODUCTS_STATS = 'socrates-online/scheme/SET_PRODUCTS_STATS';
const SET_SCHEME_GROUP_LIST = 'socrates-online/scheme/SET_SCHEME_GROUP_LIST';
const SET_SCHEME = 'socrates-online/scheme/SET_SCHEME';
// const SET_SCHEME_OPTIONS = 'socrates-online/scheme/SET_SCHEME_OPTIONS'
// const SET_SCHEME_BENEFITS = 'socrates-online/scheme/SET_SCHEME_BENEFITS'
const IS_LOADING = 'socrates-online/scheme/IS_LOADING';
const IS_COPYING = 'socrates-online/scheme/IS_COPYING';
const IS_EDITING = 'socrates-online/scheme/IS_EDITING';
const IS_IMPORTING = 'socrates-online/scheme/IS_IMPORTING';
const IS_EXPORTING = 'socrates-online/scheme/IS_EXPORTING';
const IS_SUBMITTING = 'socrates-online/scheme/IS_SUBMITTING';
const CLEAR_DATA = 'socrates-online/scheme/CLEAR_DATA';
const SET_ACTIVE_SCHEME_SINGLE = 'socrates-online/scheme/SET_ACTIVE_SCHEME_SINGLE';
const SET_ACTIVE_SCHEME_ANNUAL = 'socrates-online/scheme/SET_ACTIVE_SCHEME_ANNUAL';
const SET_PRODUCT_DOCUMENT_TYPE_LIST =
  'socrates-online/scheme/SET_PRODUCT_DOCUMENT_TYPE_LIST';
const SET_DOCUMENT_SCHEME_LIST = 'socrates-online/scheme/SET_DOCUMENT_SCHEME_LIST';
const VIEW_OR_EDIT_DOCUMENT = 'socrates-online/scheme/VIEW_OR_EDIT_DOCUMENT';
const CLOSE_DOCUMENT_MODAL = 'socrates-online/scheme/CLOSE_DOCUMENT_MODAL';
const CLEAR_DOCUMENT_MANAGEMENT_DATA =
  'socrates-online/scheme/CLEAR_DOCUMENT_MANAGEMENT_DATA';
const SET_DYNAMIC_DOCUMENTS_LIST = 'socrates-online/scheme/SET_DYNAMIC_DOCUMENTS_LIST';
const CLEAR_DYNAMIC_DOCUMENT_DATA = 'socrates-online/scheme/CLEAR_DYNAMIC_DOCUMENT_DATA';

// const defaultSchemeIncludes = ['product', 'insurer', 'claims_handler', 'assistance_handler']
const defaultSchemeIncludes = [];
// const defaultMeta = ['config', 'options', 'benefits']
const defaultMeta = [];

export const initialState = {
  isLoading: false,
  isCopying: false,
  isEditing: false,
  isImporting: false,
  isExporting: false,
  isSubmitting: false,
  products: {},
  scheme: null,
  schemeGroupList: null,
  activeSchemeGroup: {
    single: null,
    annual: null,
  },
  activeScheme: {},
  stats: {},
  destinations: {},
  documentManagement: {
    docTypeList: [],
    showDocumentModal: false,
    activeDocument: null,
  },
  dynamicDocuments: {
    list: [],
  },
};

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        isLoading: action.status,
      };
    case IS_COPYING:
      return {
        ...state,
        isCopying: action.status,
      };
    case IS_EDITING:
      return {
        ...state,
        isEditing: action.status,
      };
    case IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.data,
      };
    case SET_SOURCE:
      return {
        ...state,
        source: action.data,
      };
    case IS_IMPORTING:
      return {
        ...state,
        isImporting: action.status,
      };
    case IS_EXPORTING:
      return {
        ...state,
        isExporting: action.status,
      };
    case SET_PRODUCTS:
      return {
        ...state,
        products: action.data,
      };
    case SET_PRODUCTS_STATS:
      return {
        ...state,
        stats: action.data,
      };
    case SET_DESTINATIONS:
      return {
        ...state,
        destinations: action.data,
      };
    case SET_PRODUCT:
      return {
        ...state,
        product: action.data,
      };
    case SET_SCHEME_GROUP_LIST:
      return {
        ...state,
        schemeGroupList: action.data,
      };
    case SET_ACTIVE_SCHEME_SINGLE:
      return {
        ...state,
        activeSchemeGroup: {
          ...state.activeSchemeGroup,
          single: action.data,
        },
      };
    case SET_ACTIVE_SCHEME_ANNUAL:
      return {
        ...state,
        activeSchemeGroup: {
          ...state.activeSchemeGroup,
          annual: action.data,
        },
      };
    case SET_SCHEME:
      return {
        ...state,
        scheme: {
          id: action.data.data.id,
          name: action.data.data.attributes.name,
          status: action.data.data.attributes.status,
          version: action.data.data.attributes.version,
          scheme_details: action.data.data.attributes.scheme_details,
          schemes: filter(action.data.included, ['type', 'products/schemes']),
          general: {
            insurer: filter(action.data.included, ['type', 'organisations/insurers']),
            maxAge: action.data.meta.parameters.max_age,
            tripDuration: action.data.meta.parameters.trip_duration,
            maxTripDuration: action.data.meta.parameters.max_trip_duration,
            adultMinAge: action.data.meta.parameters.adults,
            childMaxAge: action.data.meta.parameters.children,
            maxScore: action.data.meta.rules.max_score,
            quoteValidForDays: action.data.meta.rules.quote_valid_for_days,
            cancellationPeriod: action.data.meta.rules.cancellation_period,
            ownerBackdateCover: action.data.meta.rules.owner_backdate_cover,
            excludePostcodePrefixes: action.data.meta.rules.exclude_postcode_prefixes,
            handlers: filter(action.data.included, ['type', 'organisations']),
          },
          excess: {
            standardExcess: action.data.meta.standard_excess,
          },
          options: action.data.meta.options,
          benefits: action.data.meta.benefits,
          fees: action.data.meta.fees,
        },
      };
    case SET_PRODUCT_DOCUMENT_TYPE_LIST:
      return {
        ...state,
        documentManagement: {
          ...state.documentManagement,
          docTypeList: action.data,
        },
      };
    case SET_DOCUMENT_SCHEME_LIST:
      return {
        ...state,
        documentManagement: {
          ...state.documentManagement,
          schemeList: action.data,
        },
      };
    case VIEW_OR_EDIT_DOCUMENT:
      return {
        ...state,
        documentManagement: {
          ...state.documentManagement,
          showDocumentModal: true,
          actionType: action.data.action,
          activeDocument: action.data.document,
        },
      };
    case CLOSE_DOCUMENT_MODAL:
      return {
        ...state,
        documentManagement: {
          ...state.documentManagement,
          showDocumentModal: false,
          activeDocument: null,
        },
      };
    case CLEAR_DOCUMENT_MANAGEMENT_DATA:
      return {
        ...state,
        documentManagement: {
          docTypeList: [],
          showDocumentModal: false,
          activeDocument: null,
          insurerList: [],
        },
      };
    case SET_DYNAMIC_DOCUMENTS_LIST:
      return {
        ...state,
        dynamicDocuments: {
          ...state.dynamicDocuments,
          list: action.data,
        },
      };
    case CLEAR_DYNAMIC_DOCUMENT_DATA:
      return {
        ...state,
        dynamicDocuments: {
          ...state.dynamicDocuments,
          list: [],
        },
      };
    case CLEAR_DATA:
      return initialState;
    default:
      return state;
  }
}

const getProductsStats = () => {
  return (dispatch) => {
    dispatch(isLoading(true));
    return Axios.get('products/statistics')
      .then((response) => {
        dispatch({
          type: SET_PRODUCTS_STATS,
          data: response.data,
        });
        dispatch(isLoading(false));
        return true;
      })
      .catch((error) => {
        dispatch(isLoading(false));
        console.error(error);
      });
  };
};

// const getProducts = () => {
//   return dispatch => {
//     dispatch(isLoading(true))
//     return Axios.get('products?include=scheme_groups.all_schemes.document_packs').then(response => {
//       dispatch({
//         type: SET_PRODUCTS,
//         data: response.data
//       })
//       dispatch(isLoading(false))
//       return true
//     }).catch(error => {
//       dispatch(isLoading(false))
//       console.error(error)
//     })
//   }
// }

const getProducts = (productId, getIncludes = true) => {
  // const includes = getIncludes ? '?include=scheme_groups.all_schemes.document_packs' : '';
  const includes = getIncludes
    ? '?page[limit]=30&include=' +
      'all_scheme_groups,' +
      'scheme_groups.all_schemes,' +
      'document_packs.scheme_documents'
    : '';
  let endpoint = 'products';

  if (productId) {
    endpoint = `products/${productId}`;
  }

  return (dispatch) => {
    dispatch(isLoading(true));
    return Axios.get(endpoint + includes)
      .then((response) => {
        if (productId) {
          dispatch({ type: SET_PRODUCT, data: response.data });
          // dispatch(getProductsStats(response.data.data.attributes.product_code))
          dispatch({
            type: ADD_RESOURCE,
            id: response.data.data.id,
            name: response.data.data.attributes.name,
          });
        }

        if (!productId) {
          dispatch({ type: SET_PRODUCTS, data: response.data });
        }

        dispatch(isLoading(false));
        return true;
      })
      .catch((error) => {
        dispatch(isLoading(false));
        console.error(error);
      });
  };
};

const getDestinations = () => {
  return (dispatch) => {
    dispatch(isLoading(true));
    return Axios.get('products/destinations?page[limit]=500')
      .then((response) => {
        dispatch({
          type: SET_DESTINATIONS,
          data: response.data,
        });
        dispatch(isLoading(false));
        return true;
      })
      .catch((error) => {
        dispatch(isLoading(false));
        console.error(error);
      });
  };
};

const getSchemeGroupList = (productId) => {
  return (dispatch) => {
    dispatch(isLoading(true));
    return Axios.get(
      `products/scheme-groups/?filter[product]=${productId}&sort=-version_number`,
    )
      .then((response) => {
        dispatch({
          type: SET_SCHEME_GROUP_LIST,
          data: response.data,
        });
        dispatch(isLoading(false));
        return true;
      })
      .catch((error) => {
        dispatch(isLoading(false));
        console.error(error);
      });
  };
};

const getSchemeGroupDetails = (schemeGroupId) => {
  return (dispatch) => {
    return Axios.get(
      '/products/scheme-groups/' +
        schemeGroupId +
        '?include=all_schemes.claims_handler,all_schemes.assistance_handler,all_schemes.insurer&meta=config,options,fees,benefits,rates',
    ).then((response) => {
      dispatch({ type: SET_SCHEME, data: response.data });
    });
  };
};

const clearData = () => {
  return {
    type: CLEAR_DATA,
  };
};

const createNewSchemeGroupVersion = (
  scheme,
  productId,
  includes = defaultSchemeIncludes,
  meta = defaultMeta,
) => {
  return (dispatch) => {
    return Axios.patch(
      'products/scheme-groups/' + scheme.id + queryString(includes, meta),
      scheme,
    )
      .then((response) => {
        if (scheme.id !== response.data.data.id) {
          dispatch(
            push(
              '/admin/products/' +
                productId +
                '/scheme-management/' +
                response.data.data.id +
                '/edit',
            ),
          );
          toastr.success('Success', 'New version setup successfully');
        } else {
          toastr.success('Success', 'Your changes have been saved');
        }
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'There was an error updating the scheme');
      });
  };
};

const activateSchemeGroup = (schemeId, values) => {
  return (dispatch) => {
    return Axios.post('products/scheme-groups/' + schemeId + ':activate', values)
      .then(() => {
        dispatch(getProducts());
        toastr.success('Success', 'Scheme has been activated');
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'Failed to activate scheme');
      });
  };
};

const endSchemeGroup = (schemeId, end = false) => {
  const endpoint = end ? 'end' : 'deactivate';
  return (dispatch) => {
    return Axios.post('products/scheme-groups/' + schemeId + ':' + endpoint)
      .then(() => {
        dispatch(getProducts());
        toastr.success('Success', 'Scheme has been deactivated');
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'Failed to deactivate scheme');
      });
  };
};

const assignHandlerToScheme = (scheme, handlerType, values) => {
  let action = 'assign-';

  if (handlerType === 'Claims Handler') {
    action += 'claims-handler';
  }

  if (handlerType === 'Assistance Handler') {
    action += 'assistance-handler';
  }

  return (dispatch) => {
    dispatch(isEditing(true));
    return Axios.post('products/scheme-groups/' + scheme.id + ':' + action, values)
      .then(() => {
        dispatch(getSchemeGroupDetails(scheme.id));
        dispatch(isEditing(false));
        toastr.success('Success', handlerType + ' assigned');
        return true;
      })
      .catch((error) => {
        dispatch(isEditing(false));
        handleErrorResponse(error, 'Failed to assign ' + handlerType);
      });
  };
};

const updateSchemeGroup = (values, id) => {
  return (dispatch) => {
    dispatch(isEditing(true));
    return Axios.patch('products/scheme-groups/' + id, values)
      .then(() => {
        toastr.success('Success', ' Your changes has been saved.');
        dispatch(isEditing(false));
      })
      .catch((error) => {
        dispatch(isEditing(false));
        handleErrorResponse(error, 'Failed to perform this action.');
      });
  };
};

const updateConfiguration = (values, id) => {
  return (dispatch) => {
    dispatch(isEditing(true));
    return Axios.patch('products/scheme-groups/' + id + '/configuration', values)
      .then(() => {
        toastr.success('Success', ' Your changes has been saved.');
        dispatch(isEditing(false));
      })
      .catch((error) => {
        dispatch(isEditing(false));
        handleErrorResponse(error, 'Failed to perform this action.');
      });
  };
};

const updateConfigurationByType = (values, id, type) => {
  return (dispatch) => {
    dispatch(isEditing(true));
    return Axios.patch('products/scheme-groups/' + id + '/configuration/' + type, values)
      .then(() => {
        toastr.success('Success', ' Your changes has been saved.');
        dispatch(isEditing(false));
      })
      .catch((error) => {
        dispatch(isEditing(false));
        handleErrorResponse(error, 'Failed to perform this action.');
      });
  };
};

const editRates = (scheme) => {
  return (dispatch) => {
    dispatch(isEditing(true));
    return Axios.post('products/scheme-groups/' + scheme.id + ':edit-rates', {})
      .then((response) => {
        dispatch(isEditing(false));

        if (window && response.data.meta.url) {
          window.open(response.data.meta.url, '_blank');
        }
        return true;
      })
      .catch((error) => {
        dispatch(isEditing(false));
        handleErrorResponse(error, 'Failed to get scheme document URL');
      });
  };
};

const importRates = (scheme) => {
  return (dispatch) => {
    dispatch(isImporting(true));
    return Axios.post('products/scheme-groups/' + scheme.id + ':import-rates', {})
      .then(() => {
        dispatch(getScheme(scheme.id));
        dispatch(isImporting(false));
        toastr.success('Success', 'Your rates have been imported');
        return true;
      })
      .catch((error) => {
        dispatch(isImporting(false));
        handleErrorResponse(error, 'Failed to get scheme document URL');
      });
  };
};

const lockRates = (scheme) => {
  return (dispatch) => {
    dispatch(isEditing(true));
    return Axios.post('products/scheme-groups/' + scheme.id + ':lock-rates', {})
      .then(() => {
        dispatch(isEditing(false));
        toastr.success('Success', 'Rates have been locked');
        return true;
      })
      .catch((error) => {
        dispatch(isEditing(false));
        handleErrorResponse(error, 'Failed to lock rates');
      });
  };
};

const editAggregatorMapping = (scheme) => {
  return (dispatch) => {
    dispatch(isEditing(true));
    return Axios.post(
      'products/scheme-groups/' + scheme.id + ':edit-aggregator-mappings',
      {},
    )
      .then((response) => {
        dispatch(isEditing(false));

        if (window && response.data.meta.url) {
          window.open(response.data.meta.url, '_blank');
        }
        return true;
      })
      .catch((error) => {
        dispatch(isEditing(false));
        handleErrorResponse(error, 'Failed to get scheme document URL');
      });
  };
};

const importAggregatorMapping = (scheme) => {
  return (dispatch) => {
    dispatch(isImporting(true));
    return Axios.post(
      'products/scheme-groups/' + scheme.id + ':import-aggregator-mappings',
      {},
    )
      .then(() => {
        dispatch(getScheme(scheme.id));
        dispatch(isImporting(false));
        toastr.success('Success', 'Your mappings have been imported');
        return true;
      })
      .catch((error) => {
        dispatch(isImporting(false));
        handleErrorResponse(error, 'Failed to get scheme document URL');
      });
  };
};

const updateProduct = (formValues) => {
  return (dispatch) => {
    dispatch(isLoading(true));
    return Axios.patch('products/' + formValues.product.data.id, formValues.product)
      .then((response) => {
        dispatch({
          type: SET_PRODUCT,
          data: response.data,
        });
        dispatch(isLoading(false));
        toastr.success('Success', 'Your changes have been saved');
        return true;
      })
      .catch((error) => {
        dispatch(isLoading(false));
        handleErrorResponse(error, 'There was an error updating the product');
      });
  };
};

const getDocumentTypes = (productId) => {
  return (dispatch) => {
    dispatch(isLoading(true));
    // OLD WAY OF GETTING THE DATA
    return Axios.get(`/documents/document-types?filter[product]=${productId}`)
      .then((response) => {
        // return Axios.get(`products/${productId}`).then(response => {

        // OLD WAY OF MANAGING THE RESPONSE
        const documentTypes =
          response.data &&
          response.data.data.reduce((acc, curr) => {
            acc[curr.attributes.name] = {
              id: curr.id,
              documents: [],
            };
            return acc;
          }, {});

        // const documentTypes = response.data && response.data.data.attributes.metadata.document_types.reduce((acc, curr) => {
        //   acc[curr] = []
        //   return acc
        // },{})

        dispatch({ type: SET_PRODUCT_DOCUMENT_TYPE_LIST, data: documentTypes });
        dispatch(getDocuments(productId));
        return true;
      })
      .catch((error) => {
        dispatch(isLoading(false));
        console.error(error);
      });
  };
};

const getDocuments = (productId) => {
  return (dispatch, getState) => {
    return Axios.get(
      `/documents/?filter[product]=${productId}&filter[version]=all&filter[base_document]=true&page[limit]=0&include=superseded_by.channel,schemes`,
    )
      .then((response) => {
        const documentManagement = getState().productManagement.documentManagement;
        const docTypeList = documentManagement.docTypeList;
        const schemes = filter(
          response.data.included,
          (item) => item.type === 'products/schemes',
        );

        // map documents to docTypeList
        forEach(response.data.data, (doc) => {
          forEach(docTypeList, (item, key) => {
            if (doc.attributes.document_type === key || doc.attributes.name === key) {
              doc['supersededBy'] = getIncludedResource(
                doc,
                response.data.included,
                'superseded_by',
                'Document',
              );
              docTypeList[key].documents.push(doc);
            }
          });
        });

        const schemeList = [];

        forEach(schemes, (scheme) => {
          schemeList.push({
            id: scheme.id,
            name: scheme.attributes.name,
            version: scheme.attributes.version,
          });
        });

        dispatch({ type: SET_DOCUMENT_SCHEME_LIST, data: schemeList });
        dispatch(isLoading(false));
        return true;
      })
      .catch((error) => {
        dispatch(isLoading(false));
        console.error(error);
      });
  };
};

const createNewDocument = (values, productId) => {
  return (dispatch) => {
    dispatch(isSubmitting(true));
    return Axios.post('documents', values)
      .then(() => {
        toastr.success('Success', 'Document has been saved');

        dispatch(isSubmitting(false));
        dispatch(clearDocumentManagementData());
        dispatch(closeDocumentModal());
        dispatch(getDocumentTypes(productId));
        return true;
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        handleErrorResponse(error, 'There was an error saving this document');
      });
  };
};

const viewOrEditDocument = (action, document) => ({
  type: VIEW_OR_EDIT_DOCUMENT,
  data: { action, document },
});
const closeDocumentModal = () => ({ type: CLOSE_DOCUMENT_MODAL });
const clearDocumentManagementData = () => ({ type: CLEAR_DOCUMENT_MANAGEMENT_DATA });

const isSubmitting = (state) => ({ type: IS_SUBMITTING, data: state });
const isLoading = (state) => ({ type: IS_LOADING, data: state });

const updateDocument = (values, document, productId) => {
  return (dispatch) => {
    dispatch(isSubmitting(true));
    return Axios.patch('documents/' + document.id, values)
      .then(() => {
        toastr.success('Success', 'A New version has been created');

        dispatch(isSubmitting(false));
        dispatch(clearDocumentManagementData());
        dispatch(closeDocumentModal());
        dispatch(getDocumentTypes(productId));
        return true;
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        handleErrorResponse(error, 'There was an error updating this document');
      });
  };
};

const getDynamicDocuments = (productId) => {
  return (dispatch) => {
    dispatch(isLoading(true));
    return Axios.get(
      `/contents/?page[limit]=1000&filter[template.type]=document&filter[status]=active,pending,ended&filter[product]=${productId}`,
    ).then((response) => {
      dispatch(isLoading(false));
      dispatch({ type: SET_DYNAMIC_DOCUMENTS_LIST, data: response.data.data });
    });
  };
};

const clearDynamicDocuments = () => ({ type: CLEAR_DYNAMIC_DOCUMENT_DATA });

const updateDynamicDocument = (resource, includes = ['template', 'files']) => {
  return (dispatch) => {
    dispatch(isLoading(true));
    const endpoint = 'contents/' + resource.data.id + queryString(includes);

    return Axios.patch(endpoint, resource)
      .then(() => {
        dispatch(isLoading(false));
        toastr.success('Success', 'Document has been updated');
        dispatch(clearDynamicDocuments());
        dispatch(getDynamicDocuments(resource.data.relationships.product.data.id));
        return true;
      })
      .catch((error) => {
        dispatch(isLoading(false));
        handleErrorResponse(error, 'There was an error updating your content');
      });
  };
};

const updateDocumentPacks = (packs, productId) => {
  return () => {
    return Axios.patch(
      'products/' +
        productId +
        '/document-packs' +
        queryString(['document_packs.scheme_documents']),
      packs,
    )
      .then(() => {
        toastr.success('Success', 'Your changes have been saved');
        return true;
      })
      .catch((error) => {
        handleErrorResponse(
          error,
          'There was an error updating the product document packs',
        );
      });
  };
};


const getDefinitions = (productId) => {
  return () => {
    return Axios.get(`/product-management/${productId}/definitions`)
      .then((response) => {
        return response.data.meta;
      }).
      catch((error)=>{
        handleErrorResponse(
          error,
          'There was an error updating the product definitions',
        );
      });
  };
};

const updateDefinitions = (productId, values) => {
  return () => {
    return Axios.post(`/product-management/${productId}/definitions`, values)
      .then(() => {
        return true;
      }).
      catch((error)=>{
        handleErrorResponse(
          error,
          'There was an error updating the product definitions',
        );
      });
  };
};


export const actions = {
  getProducts,
  getSchemeGroupList,
  getProductsStats,
  getDestinations,
  clearData,
  createNewSchemeGroupVersion,
  activateSchemeGroup,
  endSchemeGroup,
  getSchemeGroupDetails,
  updateDynamicDocument,
  getDynamicDocuments,
  updateDocumentPacks,
  assignHandlerToScheme,
  updateSchemeGroup,
  updateConfiguration,
  updateConfigurationByType,
  editRates,
  importRates,
  lockRates,
  editAggregatorMapping,
  importAggregatorMapping,
  updateProduct,
  createNewDocument,
  getDocumentTypes,
  viewOrEditDocument,
  closeDocumentModal,
  updateDocument,
  getDefinitions,
  updateDefinitions
};
