import React from 'react';

export const pound = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150.49 150.49">
    <g>
      <g>
        <circle className="cls-1" cx="75.2" cy="75.2" r="74.5" />
      </g>
      <g>
        <path
          style={{ fill: '#fff' }}
          d="M73.3,97.4h31v17.8h-58v-13.1c3.5-1.5,6.5-3.7,9.2-6.7c2.6-3,4-6.7,4-11.1c0-0.3,0-0.7,0-1c0-0.3,0-0.7-0.1-1
			H47V70.2l8.4-0.7h0.2c-0.4-1.4-0.8-2.7-1.1-4c-0.3-1.3-0.4-2.5-0.4-3.7c0-4,0.7-7.7,2.1-11c1.4-3.3,3.3-6.1,5.8-8.4
			c2.5-2.3,5.4-4,8.8-5.3c3.4-1.2,7.1-1.9,11-1.9c4.7,0,8.9,0.9,12.4,2.6c3.6,1.7,6.9,4.3,9.8,7.8L92.8,57c-2.6-3.1-5.5-4.7-8.7-4.7
			c-2.9,0-5.2,0.8-7,2.4c-1.8,1.6-2.7,4.4-2.7,8.2c0,1.1,0.1,2.1,0.2,3.2c0.2,1,0.3,2.1,0.5,3.3h17.8v12.9H77.8
			c0.1,0.4,0.1,0.8,0.1,1.1c0,0.3,0,0.7,0,1.1c0,2.4-0.4,4.5-1.1,6.4c-0.7,1.9-1.9,3.9-3.7,6V97.4z"
        />
      </g>
    </g>
  </svg>
);

export default pound;
