import React from 'react';

export const categoryC = (
  <svg id="category_b_layer_1" data-name="Layer 1" viewBox="0 0 95 95">
    <path className="cls-1" d="M95,47.5A47.5,47.5,0,1,1,47.5,0,47.5,47.5,0,0,1,95,47.5" />
    <text style={{ fill: '#fff' }} transform="translate(44.78 63.95)" />
    <path
      style={{ fill: '#fff' }}
      d="M65.29,42.82h-15v-10q0-4.36-.48-5.45a2.06,2.06,0,0,0-2.12-1.08,2.21,2.21,0,0,0-2.35,1.32,20,20,0,0,0-.5,5.69V60a18.65,18.65,0,0,0,.5,5.48,2.13,2.13,0,0,0,2.24,1.28,2.11,2.11,0,0,0,2.19-1.28q0.51-1.28.52-6V52.25h15v2.24q0,8.94-1.26,12.68a13,13,0,0,1-5.59,6.55,19.12,19.12,0,0,1-10.65,2.81,22.07,22.07,0,0,1-10.85-2.38,11.59,11.59,0,0,1-5.66-6.6q-1.39-4.22-1.39-12.68V38.06a74.7,74.7,0,0,1,.43-9.34,13.07,13.07,0,0,1,2.54-6,14.19,14.19,0,0,1,5.87-4.54,21.21,21.21,0,0,1,8.63-1.65,21.11,21.11,0,0,1,10.92,2.56,12.28,12.28,0,0,1,5.66,6.38q1.35,3.82,1.35,11.88v5.47Z"
    />
  </svg>
);

export default categoryC;
