export const IS_SUBMITTING = 'socrates-online/account/IS_SUBMITTING';
export const IS_FETCHING_PREMIUMS = 'socrates-online/account/IS_FETCHING_PREMIUMS';
export const IS_FETCHING_TRANSACTIONS = 'socrates-online/account/IS_FETCHING_TRANSACTIONS';
export const IS_SUSPENDING = 'socrates-online/account/IS_SUSPENDING';
export const IS_REINSTATING = 'socrates-online/account/IS_REINSTATING';
export const IS_COMMISSION_RECALCULATING =
  'socrates-online/account/IS_COMMISSION_RECALCULATING';
export const SET_ACCOUNT = 'socrates-online/account/SET_ACCOUNT';
export const SET_TRANSACTIONS = 'socrates-online/account/SET_TRANSACTIONS';
export const SET_PREMIUMS = 'socrates-online/account/SET_PREMIUMS';
export const CACHE_MATCHED_TRANSACTIONS = 'socrates-online/account/CACHE_MATCHED_TRANSACTIONS';
export const CACHE_MATCHED_PREMIUMS = 'socrates-online/account/CACHE_MATCHED_PREMIUMS';
export const SET_SUMMARIES = 'socrates-online/account/SET_SUMMARIES';
export const SET_PREMIUM_TYPE = 'socrates-online/account/SET_PREMIUM_TYPE';
export const SET_COMMISSION_REALLOCATION =
  'socrates-online/account/SET_COMMISSION_REALLOCATION';
export const CLEAR_COMMISSION_REALLOCATION =
  'socrates-online/account/CLEAR_COMMISSION_REALLOCATION';
export const SET_PAYMENTS_AND_RECEIPTS =
  'socrates-online/account/SET_PAYMENTS_AND_RECEIPTS';
export const CLEAR_ACCOUNT = 'socrates-online/account/CLEAR_ACCOUNT';
export const CLEAR_PREMIUMS = 'socrates-online/account/CLEAR_PREMIUMS';
export const CLEAR_TRANSACTIONS = 'socrates-online/account/CLEAR_TRANSACTIONS';
export const IS_GENERATING_REPORT = 'socrates-online/account/IS_GENERATING_REPORT ';
export const SET_LEDGER_ENTRY = 'socrates-online/account/SET_LEDGER_ENTRY';
export const SET_ACCOUNTING_REFERENCE =
  'socrates-online/account/SET_ACCOUNTING_REFERENCE';

export const initialState = {
  isSubmitting: false,
  isFetchingPremiums: false,
  isFetchingTransactions: false,
  isGeneratingReport: false,
  isSuspending: false,
  isReinstating: false,
  isCommissionRecalculating: false,
  premiumType: 'net-premiums',
  filters: '',
  resources: {},
  summaries: {},
  transactions: {
    data: [],
  },
  cachedTransactions: [],
  premiums: {
    data: [],
  },
  cachedPremiums: [],
  commissions: {},
  ledgerEntry: {},
  accountingReference: '',
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.status,
      };
    case IS_FETCHING_PREMIUMS:
      return {
        ...state,
        isFetchingPremiums: action.status,
      };
    case IS_FETCHING_TRANSACTIONS:
      return {
        ...state,
        isFetchingTransactions: action.status,
      };
    case IS_GENERATING_REPORT:
      return {
        ...state,
        isGeneratingReport: action.status,
      };
    case IS_SUSPENDING:
      return {
        ...state,
        isSuspending: action.status,
      };
    case IS_REINSTATING:
      return {
        ...state,
        isReinstating: action.status,
      };
    case IS_COMMISSION_RECALCULATING:
      return {
        ...state,
        isCommissionRecalculating: action.status,
      };
    case SET_SUMMARIES:
      return {
        ...state,
        summaries: action.data,
      };
    case SET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.data,
      };
    case CACHE_MATCHED_TRANSACTIONS:
      return {
        ...state,
        cachedTransactions: action.data,
      };
    case SET_PREMIUMS:
      return {
        ...state,
        premiumType: action.premiumType,
        filters: action.filters,
        premiums: action.data,
      };
    case CACHE_MATCHED_PREMIUMS:
      return {
        ...state,
        cachedPremiums: action.data,
      };
    case SET_COMMISSION_REALLOCATION:
      return {
        ...state,
        commissions: action.data,
      };
    case CLEAR_COMMISSION_REALLOCATION:
      return {
        ...state,
        commissions: {},
      };
    case SET_PREMIUM_TYPE:
      return {
        ...state,
        premiumType: action.premiumType,
      };
    case SET_LEDGER_ENTRY:
      return {
        ...state,
        ledgerEntry: action.data,
      };
    case SET_ACCOUNT:
      return {
        ...state,
        resources: {
          ...state.resources,
          [action.data.data.id]: {
            ...action.data,
          },
        },
      };
    case SET_ACCOUNTING_REFERENCE:
      return {
        ...state,
        accountingReference: action.accountingReference,
      };
    case CLEAR_ACCOUNT:
      return initialState;
    case CLEAR_PREMIUMS:
      return {
        ...state,
        premiums: {
          data: [],
        },
      };
    case CLEAR_TRANSACTIONS:
      return {
        ...state,
        transactions: {
          data: [],
        },
      };
    default:
      return state;
  }
}
