import React from 'react';
import InternalContainer from '../../layout/containers/Internal';
import MainDetailsForm from '../components/MainDetails/MainDetailsForm';
import ExcessForm from '../components/Excess/ExcessForm';
import OptionsForm from '../components/Options/OptionsForm';
import BenefitsForm from '../components/Benefits/BenefitsForm';
import NotesForm from '../components/Notes/NotesForm';
import AuditForm from '../components/Audit/AuditForm';
import { Tabs, Tab } from 'react-bootstrap';
import { Button, Box, Loader } from '../../../common/components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../redux/ProductReducer';
import { get } from 'lodash';
import FeesForm from '../components/Fees/FeesForm';
// import { updateProduct } from '../../../scheme/redux/schemeActions';
const NOTES_TAB = 6;

class SchemeEditContainer extends React.Component {
  state = {
    viewOnly: false,
  };

  componentWillUpdate(newProps, newState) {
    const { location } = this.props;
    if (
      location &&
      location.state &&
      location.state === 'tab04' &&
      location !== newProps.location &&
      newState.activeTab !== NOTES_TAB
    ) {
      console.info('Notes');
      this.handleTab(NOTES_TAB);
    }
  }

  componentWillMount() {
    const { actions, params, router, location } = this.props;
    if (location && location.state && location.state === 'tab04') {
      this.handleTab(NOTES_TAB);
    }

    if (router.location.pathname.indexOf('/view') !== -1) {
      this.setState({ viewOnly: true });
    }

    actions.getSchemeGroupDetails(params.schemeId);
    actions.getProducts();
  }

  componentWillReceiveProps(nextProps) {
    const { actions, params } = this.props;

    if (!nextProps.productManagement.scheme) {
      actions.getSchemeGroupDetails(params.schemeId);
    }
  }

  componentWillUnmount() {
    const { actions } = this.props;

    actions.clearData();
  }

  handleSubmit = () => (formValues) => {
    const { actions, params } = this.props;
    actions
      .updateSchemeGroup(formValues, params.schemeId)
      .then(() => actions.clearData());
  };

  handleSubmitConfiguration = () => (formValues) => {
    const { actions, params } = this.props;
    actions
      .updateConfiguration(formValues, params.schemeId)
      .then(() => actions.clearData());
  };

  handleSubmitConfigurationByType = (type) => (formValues) => {
    const { actions, params } = this.props;
    actions
      .updateConfigurationByType(formValues, params.schemeId, type)
      .then(() => actions.clearData());
  };

  handleUpdateProduct = () => (formValues) => {
    const { actions } = this.props;
    actions.updateProduct(formValues);
  };

  render() {
    const { productManagement } = this.props;
    const { viewOnly } = this.state;
    const scheme = get(productManagement, 'scheme');

    return (
      <InternalContainer title={`${viewOnly ? 'View' : 'Edit'} Scheme`}>
        <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
          <Tab eventKey={1} title="Main Details">
            {scheme ? (
              <Box>
                <MainDetailsForm
                  viewOnly={viewOnly}
                  scheme={scheme}
                  onSubmit={this.handleSubmit()}
                />
              </Box>
            ) : (
              <Loader />
            )}
          </Tab>
          <Tab eventKey={2} title="Excess">
            {scheme ? (
              <Box>
                <ExcessForm
                  viewOnly={viewOnly}
                  scheme={scheme}
                  onSubmit={this.handleSubmitConfiguration()}
                />
              </Box>
            ) : (
              <Loader />
            )}
          </Tab>
          <Tab eventKey={3} title="Options">
            {scheme ? (
              <Box>
                <OptionsForm
                  viewOnly={viewOnly}
                  scheme={scheme}
                  onSubmit={this.handleSubmitConfiguration()}
                />
              </Box>
            ) : (
              <Loader />
            )}
          </Tab>
          <Tab eventKey={4} title="Benefits">
            {scheme ? (
              <Box>
                <BenefitsForm
                  viewOnly={viewOnly}
                  scheme={scheme}
                  onSubmit={this.handleSubmitConfigurationByType('benefits')}
                />
              </Box>
            ) : (
              <Loader />
            )}
          </Tab>
          {/*<Tab eventKey={5} title="Documents">*/}
          {/*{product ? (*/}
          {/*<Box>*/}
          {/*<DocumentsPackForm product={product} onSubmit={this.handleSubmit()}/>*/}
          {/*</Box>*/}
          {/*) : (*/}
          {/*<Loader/>*/}
          {/*)}*/}
          {/*</Tab>*/}
          <Tab eventKey={6} title="Notes">
            {scheme ? (
              <Box>
                <NotesForm
                  viewOnly={viewOnly}
                  scheme={scheme}
                  onSubmit={this.handleSubmit()}
                />
              </Box>
            ) : (
              <Loader />
            )}
          </Tab>
          <Tab eventKey={7} title="Audit">
            {scheme ? (
              <Box>
                <AuditForm scheme={scheme} onSubmit={this.handleSubmit()} />
              </Box>
            ) : (
              <Loader />
            )}
          </Tab>
          <Tab eventKey={8} title="Fees">
            {scheme ? (
              <Box>
                <FeesForm
                  viewOnly={viewOnly}
                  scheme={scheme}
                  onSubmit={this.handleSubmit()}
                />
              </Box>
            ) : (
              <Loader />
            )}
          </Tab>
        </Tabs>
        <div className="bottom-bar">
          <Button buttonClass="default" link to="/admin/products">
            Back
          </Button>
        </div>
      </InternalContainer>
    );
  }
}

export default connect(
  (state) => ({
    productManagement: state.productManagement,
  }),
  (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
  }),
)(SchemeEditContainer);
