import React from 'react';

export const excessWaiver = (
  <svg id="excess_layer_1" data-name="Layer 1" viewBox="0 0 95 93.77">
    <path
      className="cls-1"
      d="M95,46.89c0,25.9-21.27,46.89-47.5,46.89S0,72.78,0,46.89,21.27,0,47.5,0,95,21,95,46.89"
    />
    <path
      style={{ fill: '#fff' }}
      d="M9.71,38.08h8.16v3.77H14.61v3.57h3.06V49H14.61v4.14H18.2V56.9H9.71Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M29.53,38.08l-1.72,8.32L30.4,56.9H25.86A61.9,61.9,0,0,1,24.38,50q-.18,1.79-.52,3.79L23.4,56.9H18.63l1.78-10.5-1.78-8.32h4.73q.13.78.51,2.59.31,1.38.5,2.55l1-5.14Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M42.52,46.28H37.62V43a5.83,5.83,0,0,0-.16-1.78.67.67,0,0,0-.69-.35.72.72,0,0,0-.77.43,6.5,6.5,0,0,0-.16,1.86V51.9A6.07,6.07,0,0,0,36,53.69a.83.83,0,0,0,1.45,0,6.86,6.86,0,0,0,.17-2V49.36h4.89v.73a14.5,14.5,0,0,1-.41,4.14,4.24,4.24,0,0,1-1.83,2.14,6.25,6.25,0,0,1-3.48.92,7.21,7.21,0,0,1-3.54-.78,3.79,3.79,0,0,1-1.85-2.16,14.16,14.16,0,0,1-.45-4.14v-5.5a24.5,24.5,0,0,1,.14-3.05,4.27,4.27,0,0,1,.83-2,4.63,4.63,0,0,1,1.92-1.48,6.93,6.93,0,0,1,2.82-.54,6.89,6.89,0,0,1,3.57.84,4,4,0,0,1,1.85,2.08,12.55,12.55,0,0,1,.44,3.88Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M52.66,38.08h8.16v3.77H57.56v3.57h3.06V49H57.56v4.14h3.59V56.9H52.66Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M72.92,43.78H68.38v-1.4a2.74,2.74,0,0,0-.17-1.24.64.64,0,0,0-.58-.27.74.74,0,0,0-.67.36,2.09,2.09,0,0,0-.23,1.09A3.1,3.1,0,0,0,67,43.75a3.68,3.68,0,0,0,1.38,1.15,14,14,0,0,1,4.12,3.18,7.3,7.3,0,0,1,.85,4,7,7,0,0,1-.47,3A3.69,3.69,0,0,1,71,56.65a7.2,7.2,0,0,1-3.14.65,7,7,0,0,1-3.35-.74,3.65,3.65,0,0,1-1.82-1.89,9.73,9.73,0,0,1-.43-3.27V50.16h4.54v2.29A3,3,0,0,0,67,53.81a.74.74,0,0,0,.68.3.8.8,0,0,0,.73-.38,2.2,2.2,0,0,0,.24-1.14,3.53,3.53,0,0,0-.45-2.17,14.45,14.45,0,0,0-2.29-1.71A22.52,22.52,0,0,1,63.52,47a4.1,4.1,0,0,1-1-1.51A6.65,6.65,0,0,1,62.15,43a6.84,6.84,0,0,1,.55-3.16,3.71,3.71,0,0,1,1.78-1.56,7.11,7.11,0,0,1,3-.56,7.75,7.75,0,0,1,3.25.62,3.4,3.4,0,0,1,1.78,1.55A8.22,8.22,0,0,1,72.92,43Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M85.23,43.78H80.69v-1.4a2.74,2.74,0,0,0-.17-1.24.64.64,0,0,0-.58-.27.74.74,0,0,0-.67.36A2.09,2.09,0,0,0,79,42.33a3.1,3.1,0,0,0,.25,1.42,3.68,3.68,0,0,0,1.38,1.15,14,14,0,0,1,4.12,3.18,7.3,7.3,0,0,1,.85,4,7,7,0,0,1-.47,3,3.69,3.69,0,0,1-1.82,1.6,7.2,7.2,0,0,1-3.14.65,7,7,0,0,1-3.35-.74A3.65,3.65,0,0,1,75,54.66a9.73,9.73,0,0,1-.43-3.27V50.16h4.54v2.29a3,3,0,0,0,.19,1.36.74.74,0,0,0,.68.3.8.8,0,0,0,.73-.38A2.2,2.2,0,0,0,81,52.59a3.53,3.53,0,0,0-.45-2.17,14.45,14.45,0,0,0-2.29-1.71A22.52,22.52,0,0,1,75.83,47a4.1,4.1,0,0,1-1-1.51A6.65,6.65,0,0,1,74.46,43,6.84,6.84,0,0,1,75,39.82a3.71,3.71,0,0,1,1.78-1.56,7.11,7.11,0,0,1,3-.56,7.75,7.75,0,0,1,3.25.62,3.4,3.4,0,0,1,1.78,1.55A8.22,8.22,0,0,1,85.23,43Z"
    />
    <path
      className="cls-3"
      d="M57.48,70.12l-5.69-9.85c-2.31-3.77-3.77-6.22-5.16-8.79H46.5c-1.26,2.58-2.51,5-4.83,8.86l-5.36,9.79H29.7L43.33,47.57l-13.09-22h6.68L42.8,36c1.65,2.91,2.91,5.16,4.1,7.54h.2c1.26-2.65,2.38-4.7,4-7.54l6.08-10.45h6.61L50.27,47.24,64.16,70.12Z"
    />
    <path
      className="cls-3"
      d="M56.26,72.24,50,61.33c-1.24-2-2.32-3.8-3.3-5.49-.89,1.67-1.89,3.43-3.16,5.58L37.57,72.24H26L40.86,47.56,26.51,23.43H38.15L44.64,35c.89,1.57,1.64,2.9,2.32,4.14.73-1.37,1.49-2.7,2.34-4.17L56,23.43H67.64L52.76,47.26l15.16,25Z"
    />
    <path
      style={{ fill: '#fff' }}
      d="M65.92,71.12h-9l-6-10.35c-1.75-2.86-3.1-5.09-4.32-7.28-1,2-2.19,4.16-4.08,7.35L36.9,71.12h-9L42.17,47.57l-13.68-23h9l6.17,11c1.38,2.42,2.4,4.25,3.32,6,1-2,2-3.84,3.29-6l6.37-10.94h9L51.44,47.25Z"
    />
    <path
      className="cls-1"
      d="M58.05,69.13h4.34L49.11,47.23,62,26.54H57.78L52,36.5c-1.56,2.68-2.71,4.75-4,7.47l-.27.57H46.28L46,44c-1.12-2.25-2.3-4.37-4.08-7.49l-5.6-9.95H32l12.51,21-13,21.55h4.27l5.07-9.27c2.38-4,3.6-6.35,4.81-8.82l.27-.56h1.35l.28.52c1.44,2.66,3,5.22,5.13,8.75Z"
    />
  </svg>
);

export default excessWaiver;
