import * as actions from './brokerReducer';
import Axios from 'axios';
import { push } from 'react-router-redux';
import { toastr } from 'react-redux-toastr';
import queryString from '../../../helpers/queryString';
import handleErrorResponse from '../../../helpers/handleErrorResponse';
import { ADD_RESOURCE } from '../../layout/redux/breadcrumb';
import getIncludedResource from '../../../helpers/getIncludedResource';

const defaultIncludes = [
  'organisation',
  'users',
  'broker_region',
  'networks',
  'active_commissions',
  'active_commissions.product',
  'primary_contacts',
  'accounts_contacts',
];

export function setPdf(brokerId, title, content) {
  return {
    type: actions.SET_PDF,
    brokerId,
    title,
    content,
  };
}

export function clearBroker() {
  return {
    type: actions.CLEAR_BROKER,
  };
}

export function isApproving(status) {
  return {
    type: actions.IS_APPROVING,
    status,
  };
}

export function isDeclining(status) {
  return {
    type: actions.IS_DECLINING,
    status,
  };
}

export function isDeactivating(status) {
  return {
    type: actions.IS_DEACTIVATING,
    status,
  };
}

export function isReactivating(status) {
  return {
    type: actions.IS_REACTIVATING,
    status,
  };
}

export function isSendingApproval(status) {
  return {
    type: actions.IS_SENDING_APPROVAL,
    status,
  };
}

export function isSubmitting(status) {
  return {
    type: actions.IS_SUBMITTING,
    status,
  };
}

export function getBroker(id, includes = defaultIncludes) {
  return (dispatch) => {
    let endpoint = 'brokers/' + id;
    endpoint += queryString(includes);

    return Axios.get(endpoint)
      .then((response) => {
        dispatch({
          type: actions.SET_BROKER,
          data: response.data,
        });
        dispatch({
          type: ADD_RESOURCE,
          id: response.data.data.id,
          name: response.data.data.attributes.name,
        });

        const org = getIncludedResource(
          response.data.data,
          response.data.included,
          'organisation'
        );

        if (org) {
          dispatch({
            type: ADD_RESOURCE,
            id: org.data.id,
            name: org.data.attributes.name,
          });
        }

        return true;
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function getAllProducts() {
  return (dispatch) => {
    return Axios.get('products?page[limit]=500')
      .then((response) => {
        dispatch({
          type: actions.SET_ALL_PRODUCTS,
          data: response.data,
        });
        return true;
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function createBroker(resource) {
  return (dispatch) => {
    dispatch(isSubmitting(true));
    return Axios.post('brokers', resource)
      .then((response) => {
        dispatch(isSubmitting(false));
        dispatch(push('/brokers/' + response.data.data.id));
        return true;
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        handleErrorResponse(error, 'There was an error creating your broker');
      });
  };
}

export function updateBroker(resource, includes = defaultIncludes) {
  return (dispatch) => {
    dispatch(isSubmitting(true));
    const id = resource.data.id;
    let endpoint = 'brokers/' + id;
    endpoint += queryString(includes);

    return Axios.patch(endpoint, resource)
      .then(() => {
        dispatch(isSubmitting(false));
        dispatch(push('/brokers/' + id));
        return true;
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        handleErrorResponse(error, 'There was an error updating this broker');
      });
  };
}

export function updateBrokerCommissions(commissions, brokerId) {
  return (dispatch) => {
    dispatch(isSubmitting(true));
    let endpoint = 'brokers/' + brokerId + '/commissions';

    return Axios.put(endpoint, commissions)
      .then(() => {
        dispatch(isSubmitting(false));
        dispatch(push('/brokers/' + brokerId));
        return true;
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        handleErrorResponse(error, 'There was an error updating the broker commissions');
      });
  };
}

export function approveBroker(id) {
  return (dispatch) => {
    dispatch(isApproving(true));
    return Axios.post('brokers/' + id + ':approve', {
      data: {
        metadata: {
          send_email: true,
        },
      },
    })
      .then(() => {
        dispatch(getBroker(id)).then(() => {
          dispatch(isApproving(false));
          toastr.success('Approved', 'The broker has now been approved');
          return true;
        });
        return true;
      })
      .catch((error) => {
        dispatch(isApproving(false));
        handleErrorResponse(error, 'There was an error approving this broker');
      });
  };
}

export function declineBroker(values, id) {
  return (dispatch) => {
    dispatch(isDeclining(true));
    return Axios.post('brokers/' + id + ':decline', values)
      .then(() => {
        dispatch(getBroker(id)).then(() => {
          dispatch(isDeclining(false));
          toastr.success('Declined', 'The broker has now been declined');
          return true;
        });
        return true;
      })
      .catch((error) => {
        dispatch(isDeclining(false));
        handleErrorResponse(error, 'There was an error declining this broker');
      });
  };
}

export function deactivateBroker(id) {
  return (dispatch) => {
    dispatch(isDeactivating(true));
    return Axios.post('brokers/' + id + ':deactivate', {
      data: {
        metadata: {
          send_email: false,
        },
      },
    })
      .then(() => {
        dispatch(getBroker(id)).then(() => {
          dispatch(isDeactivating(false));
          toastr.success('Deactivated', 'The broker has now been deactivated');
          return true;
        });
        return true;
      })
      .catch((error) => {
        dispatch(isDeactivating(false));
        handleErrorResponse(error, 'There was an error deactivating this broker');
      });
  };
}

export function reactivateBroker(id) {
  return (dispatch) => {
    dispatch(isReactivating(true));
    return Axios.post('brokers/' + id + ':reactivate', {
      data: {
        metadata: {
          send_email: false,
        },
      },
    })
      .then(() => {
        dispatch(getBroker(id)).then(() => {
          dispatch(isReactivating(false));
          toastr.success('Declined', 'The broker has now been reactivated');
          return true;
        });
        return true;
      })
      .catch((error) => {
        dispatch(isReactivating(false));
        handleErrorResponse(error, 'There was an error reactivating this broker');
      });
  };
}

export function resendApproval(id) {
  return (dispatch) => {
    dispatch(isSendingApproval(true));
    return Axios.post('brokers/' + id + ':send-approval-email', {
      data: {
        metadata: {
          send_email: true,
        },
      },
    })
      .then(() => {
        dispatch(getBroker(id)).then(() => {
          dispatch(isSendingApproval(false));
          toastr.success('Email sent', 'The broker approval email has been resent');
          return true;
        });
        return true;
      })
      .catch((error) => {
        dispatch(isSendingApproval(false));
        handleErrorResponse(
          error,
          'There was an error sending the broker approval email',
        );
      });
  };
}

export function transferBroker(values, application, resourceType) {
  return () => {
    return Axios.post(resourceType + '/' + application.data.id + ':merge', values)
      .then(() => {
        toastr.success('Success', 'Broker has been transferred');
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'Failed to transfer to Broker');
      });
  };
}

export function createContract(values) {
  return () => {
    return Axios.post('brokers/contracts', values)
      .then(() => {
        toastr.success('Success', 'Broker contract has been created');
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'Failed to create Broker contract');
      });
  };
}

export function assignUserToBroker(id, values) {
  return () => {
    return Axios.post('brokers/' + id + ':add-user', values)
      .then(() => {
        toastr.success('Success', 'User has been successfully assigned to Broker');
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'Failed to assign User to Broker');
      });
  };
}

export function viewStatement(id) {
  return () => {
    return Axios.get('brokers/' + id + '/statement')
      .then((response) => {
        if (window && response.data.meta.file_name) {
          window.open(response.data.meta.file_name, '_blank');
        }
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'Error generating statement');
      });
  };
}
