import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Label } from 'react-bootstrap';
import { getBroker, getAllProducts } from '../redux/brokerActions';
import { hasPermission } from '../../auth/redux/authActions';
import { Button, Loader } from '../../../common/components';
import { brokerStatuses } from '../../../schemas/broker';
import InternalContainer from '../../layout/containers/Internal';
import BrokerDetails from '../components/BrokerDetails/BrokerDetails';
import BrokerContracts from '../components/BrokerContracts/BrokerContracts';
import BrokerProducts from '../components/BrokerProducts';
import BrokerUsers from '../components/BrokerUsers/BrokerUsers';
import BrokerNotes from '../components/BrokerNotes/BrokerNotes';
import BrokerAudit from '../components/BrokerAudit';
import getIncludedResource from '../../../helpers/getIncludedResource';
import displayName from '../../../helpers/displayName';
import { get } from 'lodash';
import InternalTabs from '../../layout/components/InternalTabs/InternalTabs';

const TAB_NAME = 'brokerTabs';

class BrokerShowContainer extends Component {
  componentWillMount() {
    const { params, dispatch } = this.props;

    let includes = [
      'organisation',
      'users',
      'broker_region',
      'networks',
      'active_commissions',
      'active_commissions.product',
      'primary_contacts',
      'accounts_contacts',
      'tracking_links',
    ];
    if (hasPermission('account.view')) {
      includes.push('organisation.account');
    }

    dispatch(getBroker(params.id, includes));
    dispatch(getAllProducts());
  }

  render() {
    const { params, broker } = this.props;

    const resource = get(broker, 'resources[' + params.id + ']');
    const buttons = (
      <Button bsStyle="default" label="Back" isBackButton leftIcon="caret-left" />
    );

    const brokerStatus =
      brokerStatuses[get(resource, 'data.attributes.status', 'applied')];
    const labelStyle = () => {
      if (brokerStatus === 'Pending' || brokerStatus === 'Awaiting Approval') {
        return 'warning';
      }
      if (brokerStatus === 'Live') {
        return 'success';
      }
      if (
        brokerStatus === 'Declined' ||
        brokerStatus === 'Inactive' ||
        brokerStatuses === 'Cancelled'
      ) {
        return 'danger';
      }
    };
    const brokerLabel = (
      <Label className="pull-right" bsStyle={labelStyle()}>
        {displayName(brokerStatus)}
      </Label>
    );

    return (
      <InternalContainer title="Broker" buttons={buttons}>
        {resource ? (
          <InternalTabs
            id={TAB_NAME}
            tabs={[
              {
                permissions: ['broker.view'],
                title: 'Details',
                content: (
                  <BrokerDetails
                    {...this.props}
                    resource={resource}
                    brokerLabel={brokerLabel}
                  />
                ),
              },
              {
                permissions: ['user.view'],
                title: 'Users',
                content: (
                  <BrokerUsers
                    {...this.props}
                    resource={resource}
                    brokerLabel={brokerLabel}
                  />
                ),
              },
              {
                permissions: ['broker.edit'],
                title: 'Products',
                content: (
                  <BrokerProducts
                    {...this.props}
                    resource={resource}
                    brokerLabel={brokerLabel}
                    commissions={getIncludedResource(
                      resource.data,
                      resource.included,
                      'active_commissions',
                    )}
                  />
                ),
              },
              {
                permissions: ['broker.approve'],
                title: 'Contracts',
                content: (
                  <BrokerContracts
                    {...this.props}
                    resource={resource}
                    brokerLabel={brokerLabel}
                  />
                ),
              },
              {
                permissions: ['broker.notes_view'],
                title: 'Notes',
                content: (
                  <BrokerNotes
                    {...this.props}
                    resource={resource}
                    brokerLabel={brokerLabel}
                  />
                ),
              },
              {
                permissions: ['system.audit_logs_view'],
                orgTypes: ['owner'],
                title: 'Audit',
                content: (
                  <BrokerAudit
                    {...this.props}
                    resource={resource}
                    brokerLabel={brokerLabel}
                  />
                ),
              },
            ]}
          />
        ) : (
          <Loader />
        )}
      </InternalContainer>
    );
  }
}

BrokerShowContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  broker: PropTypes.object.isRequired,
  params: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    broker: state.broker,
  };
}

export default connect(mapStateToProps)(BrokerShowContainer);
