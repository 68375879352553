import React from 'react';
import { FormattedDate } from 'react-intl';
import FaFilePdf from 'react-icons/lib/fa/file';
import DynamicDocumentContent from './DynamicDocumentContent';
import DynamicDocumentVersionSelector from './DynamicDocumentVersionSelector';
import DynamicDocumentDropdownActions from './DynamicDocumentDropdownActions';
import UpdateDynamicDocumentForm from '../forms/UpdateDynamicDocumentForm';

class DynamicDocumentListItem extends React.Component {
  state = {
    showForm: null,
    activeDocument: {},
  };

  componentWillMount() {
    const { documents } = this.props;
    this.setState({ activeDocument: documents[0] });
  }

  render() {
    const { index, activeItem, isLoading, documents, productId } = this.props;
    const { showForm, activeDocument } = this.state;
    const fromDate = activeDocument ? activeDocument.attributes.from_date : '';
    const toDate = activeDocument ? activeDocument.attributes.to_date : '';
    const name = activeDocument ? activeDocument.attributes.name : '';

    const isActive = activeItem === index;

    return (
      <div
        className={`document-container-section ${!isActive && 'section-collapsed'}`}
        onClick={!isActive ? this.showInsurerInfo : undefined}
      >
        <div className="document-content">
          <div className="dynamic-document-header">
            <div>
              <h4 onClick={this.hideInsurerInfo}>
                <FaFilePdf /> {name}
              </h4>
              {isActive && (
                <div>
                  <strong>Active dates:</strong>
                  <div>
                    from <FormattedDate value={fromDate} />{' '}
                    {toDate && (
                      <span>
                        {' '}
                        - to <FormattedDate value={toDate} />
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
            {isActive && (
              <div>
                <DynamicDocumentVersionSelector
                  documentList={documents}
                  isDocumentActive
                  selectedDocument={activeDocument}
                  onSelectDocument={this.selectDocument}
                />
                {activeDocument.attributes.status === 'active' && (
                  <DynamicDocumentDropdownActions
                    showForm={showForm}
                    document={activeDocument}
                    onCreateNewVersion={this.handleToggleForm}
                  />
                )}
              </div>
            )}
          </div>
          {isActive && (
            <div className="animated fadeIn mb-30 bt">
              {showForm ? (
                <UpdateDynamicDocumentForm
                  productId={productId}
                  isLoading={isLoading}
                  document={activeDocument}
                  onSubmit={this.handleSubmit}
                  onCancel={this.handleToggleForm}
                />
              ) : (
                <DynamicDocumentContent document={activeDocument} />
              )}
            </div>
          )}
        </div>
        {isActive && (
          <div onClick={this.hideInsurerInfo} className="close-document-container">
            Close
          </div>
        )}
      </div>
    );
  }

  handleToggleForm = () => {
    this.setState({ showForm: !this.state.showForm });
  };

  showInsurerInfo = () => {
    const { index, onSelectItem } = this.props;
    onSelectItem(index);
  };

  hideInsurerInfo = () => {
    const { onSelectItem } = this.props;
    onSelectItem(null);
  };

  handleSubmit = (formValues) => {
    const { onSubmit } = this.props;
    onSubmit(formValues);
  };

  selectDocument = (document) => {
    this.setState({ activeDocument: document, showForm: false });
  };
}

export default DynamicDocumentListItem;
