import React, { Component } from 'react';
import { MenuItem, Nav, NavDropdown, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Icon } from '../../../../common/components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { layoutActions as actions } from '../../redux/layoutActions';

class GuestNavigation extends Component {
  state = {
    content: null,
  };

  componentDidMount() {
    const { actions } = this.props;
    actions.getBrochureProducts();
  }

  render() {
    const { layout } = this.props;
    const hasProduct = layout.brochureProducts.length > 0;
    const brochureProducts = layout.brochureProducts;

    return (
      <div>
        <Nav className="guest-nav">
          <LinkContainer to="/">
            <NavItem eventKey={1} className="guest-nav--white">
              <Icon name="home" />
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/about">
            <NavItem eventKey={2}>About Us</NavItem>
          </LinkContainer>
          <LinkContainer to="/get-online">
            <NavItem eventKey={3}>How to get Online</NavItem>
          </LinkContainer>
          {hasProduct && (
            <NavDropdown
              eventKey={4}
              title="Products"
              id="nav-dropdown"
              className="nav-dropdown"
            >
              {brochureProducts.map((product, key) => {
                return (
                  <MenuItem
                    key={product.id}
                    eventKey={`4.${key + 1}`}
                    onClick={this.handleSelectBrochureProduct(product)}
                  >
                    {product.attributes.name}
                  </MenuItem>
                );
              })}
            </NavDropdown>
          )}
          <LinkContainer to="/open-an-account">
            <NavItem eventKey={5}>Open an Account</NavItem>
          </LinkContainer>
          <LinkContainer to="/faqs">
            <NavItem eventKey={6}>FAQs</NavItem>
          </LinkContainer>
          <LinkContainer to="/contact-us">
            <NavItem eventKey={7}>Contact Us</NavItem>
          </LinkContainer>{' '}
          <LinkContainer to="/privacy">
            <NavItem eventKey={8}>Privacy</NavItem>
          </LinkContainer>{' '}
          <LinkContainer to="/cookies">
            <NavItem eventKey={9}>Cookies</NavItem>
          </LinkContainer>
        </Nav>
      </div>
    );
  }

  handleSelectBrochureProduct = (product) => () => {
    const { router, actions } = this.props;
    actions.setActiveBrochureProduct(product);
    router.push('/our-products');
  };
}

export default connect(
  (state) => ({
    layout: state.layout,
  }),
  (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
  }),
)(GuestNavigation);
